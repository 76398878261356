/* eslint-disable react/no-array-index-key */
import { Card, Grid } from "@mui/material";
import colors from "assets/theme/base/colors";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import useBackendError from "hooks/useBackendError";
import { useEffect, useRef, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import User from "./User";

const UserList = () => {
  const [isLoading, setLoading] = useState(true);
  const userList = useRef([{}, {}, {}, {}, {}, {}, {}]);
  const [users, setUsers] = useState([{}, {}, {}, {}, {}, {}, {}]);
  const [searchTerm, setSearchTerm] = useState("");

  const axios = useAxiosPrivate();
  const { setError } = useBackendError();
  const ref = useRef();
  const [dimensions, setDimensions] = useState(120);

  function handleWindowSizeChange() {
    setDimensions(ref.current.getBoundingClientRect());
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const loadUsers = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/users/list`, {
        headers: { "Content-Type": "application/json" },
      });
      userList.current = response.data;
      setUsers(response.data);
    } catch (err) {
      setError("Failed to fetch from the backend. Please try again");
    } finally {
      setLoading(false);
    }
  };

  const loadData = async () => {
    loadUsers();
  };

  useEffect(() => {
    loadData();
    setDimensions(ref.current.getBoundingClientRect());
  }, []);

  const onSearch = (val) => {
    setSearchTerm(val);
    setUsers(
      userList.current.filter(
        (user) =>
          user.username.toLowerCase().includes(val.toLowerCase()) ||
          user.fullName.toLowerCase().includes(val.toLowerCase())
      )
    );
  };

  const navigate = useNavigate();

  const handleView = (id) => {
    navigate(`/users/user?id=${id}`);
  };

  return (
    <Card id="user-list">
      <MDBox p={2}>
        <MDTypography variant="h6" fontWeight="medium">
          All Users
        </MDTypography>
        <MDBox width="100%" display="flex" justifyContent="center" mb={1}>
          <MDBox width={400}>
            <MDInput
              placeholder="Search by name or username.."
              value={searchTerm}
              onChange={(event) => {
                onSearch(event.target.value);
              }}
              icon="search"
              id="search"
              fullWidth
            />
          </MDBox>
        </MDBox>
        <MDBox>
          <Scrollbars
            style={{
              height: 150,
              backgroundColor: colors.grey[100],
              borderRadius: 8,
              marginTop: 6,
            }}
            renderTrackHorizontal={({ style, ...props }) => <div />}
            renderTrackVertical={({ style, ...props }) => (
              <div className="scrollbar-regular-vertical-track" {...props} />
            )}
            renderThumbVertical={({ style, ...props }) => (
              <div
                style={{
                  ...style,
                  backgroundColor: "rgba(0, 0, 0, 0.2)",
                  borderRadius: 8,
                  width: 6,
                  cursor: "pointer",
                }}
                {...props}
              />
            )}
          >
            {userList.current.length === 0 && (
              <MDBox display="flex" justifyContent="center" alignItems="center" height="100%">
                <MDTypography variant="body2">
                  No users exists. Please create new users from the above tab.
                </MDTypography>
              </MDBox>
            )}
            {userList.current.length !== 0 && users.length === 0 && (
              <MDBox display="flex" justifyContent="center" alignItems="center" height="100%">
                <MDTypography variant="body2">No users match the search criteria!</MDTypography>
              </MDBox>
            )}
            {users.length !== 0 && (
              <Grid container style={{ paddingTop: 5, paddingBottom: 5, paddingRight: 10 }}>
                {users.map((user, index) => (
                  <Grid item xs={12} md={6} lg={4} xxxl={3} ref={ref} key={`userListItem-${index}`}>
                    <MDBox
                      my={0.5}
                      mx={1}
                      p={1}
                      bgColor="light"
                      style={{ borderRadius: 10 }}
                      display="flex"
                      justifyContent="space-between"
                    >
                      <User
                        width={(dimensions?.width ?? 160) - 150}
                        isLoading={isLoading}
                        user={user}
                        key={`onlineuser-${index}`}
                        showUsername
                      />
                      <MDBox>
                        <MDButton
                          variant="outlined"
                          color="info"
                          size="small"
                          onClick={() => handleView(user.id)}
                        >
                          VIEW
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </Grid>
                ))}
              </Grid>
            )}
          </Scrollbars>
        </MDBox>
      </MDBox>
    </Card>
  );
};

export default UserList;
