/* eslint-disable consistent-return */
import { makeStyles } from "@material-ui/styles";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import colors from "assets/theme/base/colors";
import { useState } from "react";

const formatSsn = (str, show) =>
  str
    .split("")
    .map((l, i) => {
      if (i === 2 || i === 4) return show ? `${l}-` : "●-";
      if (i < 5 && !show) return "●";
      return l;
    })
    .join("");

const useStyles = makeStyles({
  error: {
    borderWidth: "1px",
    borderColor: `${colors.error.main} !important`,
  },
});

const SSNMaskedInput = (props) => {
  const [show, setShow] = useState(false);

  const handleToggleShowSSN = () => {
    setShow((prev) => !prev);
  };

  const { value: ssn = undefined, onChangeSSN = null, error } = props;
  const [text, setText] = useState(ssn || "");

  const setTextFn = (val) => {
    onChangeSSN(val);
    setText(val);
  };

  const value = formatSsn(ssn === undefined ? text : ssn, show);

  const keyChange = (e) => {
    const { keyCode, key } = e;
    if ([9, 13].includes(keyCode)) return;
    if (keyCode === 8) {
      return text.length && setTextFn(text.slice(0, -1));
    }
    if (!/^\d+$/.test(key)) return;
    if (text.length === 9) return;
    setTextFn(text + key);
  };

  const classes = useStyles();

  return (
    <TextField
      error={error}
      {...props}
      value={value}
      onKeyDown={keyChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleToggleShowSSN} edge="end">
              {!show ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
        classes: {
          notchedOutline: error ? classes.error : null,
        },
      }}
    />
  );
};

export default SSNMaskedInput;
