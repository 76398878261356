/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
import { Card, FormHelperText } from "@mui/material";
import colors from "assets/theme/base/colors";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import Icon from "@mui/material/Icon";
import React, { useEffect, useState } from "react";
import MDEditor from "components/MDEditor";

const PipOccurance = ({ isMobile, data, setData, errors, setErrors }) => {
  const [pip, setPip] = useState();

  const setPipFn = (val) => {
    data.pip = val;
    setData(data);
    if (errors.pip) {
      setErrors({ ...errors, pip: false });
    }
    setPip(val);
  };

  useEffect(() => {
    if (errors.focus === "pip") {
      document.getElementById("pip").getElementsByClassName("ql-editor ")[0].focus();
    }
  }, [errors]);

  return (
    <>
      <Card style={{ backgroundColor: colors.grey[300], width: "100%", marginTop: 30 }}>
        <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
          <MDBox
            color="white"
            bgColor="dark"
            variant="gradient"
            borderRadius="xl"
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="1.5rem"
            pl={2}
            pr={2}
            mt={-3}
          >
            <MDTypography variant="body2" color="white" fontSize="xs">
              Performance Improvement Plan
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox p={3}>
          <MDTypography variant="body2" fontSize="xs">
            Enter a Performance Improvement Plan so that the situation can be improved and
            potentially avoid future occurrence.
          </MDTypography>
          <MDEditor
            placeholder={
              isMobile ? "Performance Improvement Plan.." : "Enter a Performance Improvement Plan.."
            }
            hideToolbar={isMobile}
            minHeight={100}
            value={pip}
            onChange={(newValue) => setPipFn(newValue)}
            error={errors.pip}
            id="pip"
          />
          <FormHelperText error>
            {errors.pip ? "* Please enter a Performance Improvement Plan" : ""}
          </FormHelperText>
        </MDBox>
      </Card>
      <Card style={{ backgroundColor: colors.grey[300], width: "100%", marginTop: 30 }}>
        <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
          <MDBox
            color="white"
            bgColor="dark"
            variant="gradient"
            borderRadius="xl"
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="1.5rem"
            pl={2}
            pr={2}
            mt={-3}
          >
            <MDTypography variant="body2" color="white" fontSize="xs">
              Re-occurance (Optional)
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox p={3}>
          <MDTypography variant="body2" fontSize="xs">
            Did you ever had a QAPI in the past for the same problem? If yes, link it below so that
            it can be tagged accordingly.
          </MDTypography>

          <MDButton variant="gradient" color="info" style={{ marginTop: 5 }} onClick={() => {}}>
            <Icon sx={{ fontWeight: "bold" }}>add</Icon>
            &nbsp;link past qapi
          </MDButton>
        </MDBox>
      </Card>
    </>
  );
};

export default React.memo(PipOccurance);
