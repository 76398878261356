/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import Skeleton from "components/skeletons/Skeleton";
import MDBox from "components/MDBox";

function DefaultCell({ value, suffix, isLoading }) {
  if (isLoading) {
    return <Skeleton width={80} height={18} />;
  }
  return (
    <MDBox display="flex" alignItems="center" justifyContent="center">
      <MDTypography variant="caption" fontWeight="medium" color="text">
        {value}
        {suffix && (
          <MDTypography variant="caption" fontWeight="medium" color="secondary">
            &nbsp;&nbsp;{suffix}
          </MDTypography>
        )}
      </MDTypography>
    </MDBox>
  );
}

// Setting default values for the props of DefaultCell
DefaultCell.defaultProps = {
  suffix: "",
};

export default DefaultCell;
