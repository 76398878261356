/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/no-danger */
/* eslint-disable no-plusplus */
import { FormControlLabel, FormHelperText, Radio } from "@mui/material";
import DisabledRadio from "components/DisabledRadio";
import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/MDBox";
import MDEditor from "components/MDEditor";
import MDTypography from "components/MDTypography";
import Skeleton from "components/skeletons/Skeleton";
import { useEffect, useState } from "react";

const Question = ({ data, sectionIndex, questionIndex, setData, isLoading, action }) => {
  const question = data.sections[sectionIndex].questions[questionIndex];
  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  const handleChange = (index) => {
    const tempData = { ...data };
    tempData.sections[sectionIndex].questions[questionIndex].answerIndex = index;
    tempData.sections[sectionIndex].questions[questionIndex].isError = false;
    let answered = 0;
    let total = 0;
    for (let i = 0; i < data.sections.length; i++) {
      for (let j = 0; j < data.sections[i].questions.length; j++) {
        total++;
        if (data.sections[i].questions[j].answerIndex !== -1) {
          answered++;
        }
      }
    }
    tempData.status = Math.round((answered / total) * 100);
    setData(tempData);
  };

  const handleCommentChange = (html) => {
    const tempDataComment = { ...data };
    tempDataComment.sections[sectionIndex].questions[questionIndex].comment = html;
    setData(tempDataComment);
  };

  return (
    <MDBox
      id={`question-${sectionIndex}-${questionIndex}`}
      p={2}
      mb={3}
      bgColor="white"
      borderRadius="xl"
      style={{ width: "100%" }}
      coloredShadow={question.isError ? "error" : null}
      border={question.isError ? "1px solid" : null}
      borderColor={question.isError ? "red" : null}
    >
      <MDBox>
        {isLoading && <Skeleton classes="title" width="100%" />}
        <MDTypography variant="body2" color="dark">
          {!isLoading && (
            <>
              <span style={{ fontWeight: 900 }}>{sectionIndex + 1}</span>
              <span>
                .{questionIndex + 1}) {question.question}
              </span>
            </>
          )}
        </MDTypography>
      </MDBox>
      <MDBox pl={4} mt={2}>
        {isLoading && (
          <>
            <Skeleton classes="title" width="100px" margin="30px 0px" />
            <Skeleton classes="title" width="100px" margin="30px 0px" />
          </>
        )}
        {!isLoading && action === "view" && (
          <>
            {question.options.map((option, index) => (
              <MDBox display="flex" py="7px" key={`${option.id}-${sectionIndex}-${questionIndex}`}>
                <DisabledRadio
                  checked={question.answerIndex === index}
                  tooltip="To edit, please click on the edit button at the bottom"
                />
                <MDTypography
                  display="inline"
                  variant="body2"
                  textTransform="capitalize"
                  color={option.ftag && index === question.answerIndex ? "warning" : "dark"}
                  style={{ marginLeft: 10 }}
                >
                  {option.option}
                </MDTypography>
              </MDBox>
            ))}
          </>
        )}
        {!isLoading && action !== "view" && (
          <>
            {question.options.map((option, index) => (
              <FormControlLabel
                key={`${option.id}-${sectionIndex}-${questionIndex}`}
                value={index}
                control={
                  <Radio
                    checked={question.answerIndex === index}
                    onClick={() => handleChange(index)}
                  />
                }
                label={
                  <MDTypography
                    display="inline"
                    variant="body2"
                    textTransform="capitalize"
                    color={option.ftag && index === question.answerIndex ? "warning" : "dark"}
                  >
                    {option.option}
                  </MDTypography>
                }
              />
            ))}

            <FormHelperText error>
              {question.isError ? "* Please select an opton above" : ""}
            </FormHelperText>
          </>
        )}
      </MDBox>
      <MDBox pl={4} mt={2}>
        {isLoading && <Skeleton classes="title" width="100%" height="40px" />}
        {!isLoading && action !== "view" && (
          <MDEditor
            placeholder={isMobile ? "[Optional] Comment.." : "[Optional] Please enter a comment.."}
            value={question.comment}
            onChange={handleCommentChange}
            hideToolbar={isMobile}
          />
        )}
        {!isLoading && action === "view" && question.comment != null && (
          <MDBox>
            <MDTypography display="inline" variant="body2" color="dark" fontSize="14px">
              Comment:
            </MDTypography>
            <div>
              <Tooltip
                title="To edit, please click on the edit button at the bottom"
                followCursor
                enterDelay={1000}
              >
                <div
                  style={{ display: "inline-block", fontSize: "14px" }}
                  dangerouslySetInnerHTML={{ __html: question.comment }}
                />
              </Tooltip>
            </div>
          </MDBox>
        )}
      </MDBox>
    </MDBox>
  );
};

export default Question;
