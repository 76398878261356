/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import { Stepper, Step, StepLabel } from "@mui/material";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import MDTypography from "components/MDTypography";
import useModal from "hooks/useModal";
import { useCallback, useEffect, useRef, useState } from "react";

import useAxiosPrivate from "hooks/useAxiosPrivate";
import useBackendError from "hooks/useBackendError";

import { useCache } from "hooks/useCache";

import AddInspectionDate from "components/StarRating/AddInspectionDate";
import AddInspectionPrevious from "components/StarRating/AddInspectionPrevious";
import AddInspectionNew from "components/StarRating/AddInspectionNew";

const InspectionModal = ({
  isNew,
  number,
  inspectionIndex,
  inspections,
  saveInspections,
  isComplaint,
}) => {
  const inspectionsUpdate = useRef(
    number === 0
      ? isNew
        ? { revisits: [] }
        : inspections[inspectionIndex]
      : inspections[inspectionIndex].revisits[number - 1]
  );
  const [dateError, setDateError] = useState(false);

  const resetDateError = () => {
    setDateError(false);
  };

  const prevNotPresent =
    inspections == null ||
    number === 0 ||
    (
      inspections[inspectionIndex].revisits[number - 1]?.fTags?.filter(
        (ftag) => ftag.new !== true
      ) ?? []
    ).length <= 0;

  const getSteps = useCallback(() => {
    // number is 1 based. So previous revisit's index is number - 2
    if (prevNotPresent) {
      return ["Date", "Citations"];
    }
    return ["Date", "Previous", "Citations"];
  }, [number, inspectionIndex, inspectionsUpdate]);

  let minDate;
  let curDate;

  if (inspections != null) {
    if (number === 0 && inspectionIndex > 0) {
      minDate = inspections[inspectionIndex - 1].date;
    } else if (number > 0) {
      minDate = inspections[inspectionIndex].revisits[number - 2];
    }
  }

  if (!isNew) {
    if (number === 0) {
      curDate = inspections[inspectionIndex].date;
    } else {
      curDate = inspections[inspectionIndex].revisits[number - 1].date;
    }
  }

  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  const isLastStep = activeStep === 2;

  const { closeModal } = useModal();

  const handleNext = () => {
    if (activeStep === 0) {
      if (inspectionsUpdate.current.date == null) {
        setDateError(true);
        return;
      }
    }
    if (activeStep === 0 && prevNotPresent) {
      setActiveStep(activeStep + 2);
    } else {
      setActiveStep(activeStep + 1);
    }
  };
  const handleBack = () => {
    if (activeStep === 2 && prevNotPresent) {
      setActiveStep(activeStep - 2);
    } else {
      setActiveStep(activeStep - 1);
    }
  };
  const handleSave = () => {
    saveInspections(inspectionsUpdate.current, inspectionIndex, number);
    closeModal();
  };

  const setInspection = (newInspection) => {
    inspectionsUpdate.current = newInspection;
  };

  let inspectionName = "";

  if (number === 0 && !isComplaint) {
    inspectionName = "Annual Inspection";
  } else if (number === 0 && isComplaint) {
    inspectionName = "Complaint Inspection";
  } else {
    inspectionName = `Revisit Inspection ${number}`;
  }

  const [allFtags, setAllFtags] = useState([]);

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <AddInspectionDate
            dateError={dateError}
            resetDateError={resetDateError}
            date={curDate}
            minDate={minDate}
            setInspection={setInspection}
            inspectionsUpdate={inspectionsUpdate.current}
            inspectionName={inspectionName}
          />
        );
      case 1:
        return (
          <AddInspectionPrevious
            number={number}
            inspectionIndex={inspectionIndex}
            inspections={inspections}
            setInspection={setInspection}
            inspectionsUpdate={inspectionsUpdate.current}
          />
        );
      case 2:
        return (
          <AddInspectionNew
            allFtags={allFtags}
            number={number}
            inspectionIndex={inspectionIndex}
            inspections={inspections}
            setInspection={setInspection}
            inspectionsUpdate={inspectionsUpdate.current}
          />
        );
      default:
        return null;
    }
  };

  const axios = useAxiosPrivate();
  const { setError } = useBackendError();
  const { getItem, setItem } = useCache();

  const loadData = async () => {
    const cacheVal = getItem("ftags");
    if (cacheVal !== null) {
      setAllFtags(cacheVal);
    } else {
      try {
        const response = await axios.get(`/ftag/list`, {
          headers: { "Content-Type": "application/json" },
        });
        setItem("ftags", response.data);
        setAllFtags(response.data);
      } catch (err) {
        setError("Failed to fetch ftag list from the backend. Please try again");
      }
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <MDBox width={{ xs: "100%", sm: 600 }}>
      <MDBox display="flex" justifyContent="center" alignItems="center" flexDirection="column">
        <MDTypography display="inline" variant="h6" textTransform="capitalize" fontWeight="bold">
          {inspectionName}
        </MDTypography>
        <MDBox mt={1} mx={2} sx={{ width: "100%" }}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </MDBox>
        <MDBox p={2} style={{ width: "100%" }}>
          <MDBox>
            {getStepContent(activeStep)}
            <MDBox mt={3} width="100%" display="flex" justifyContent="center">
              <MDBox>
                <MDButton
                  variant="gradient"
                  color="light"
                  onClick={closeModal}
                  style={{ margin: 5 }}
                >
                  cancel
                </MDButton>
                {activeStep !== 0 && (
                  <MDButton
                    variant="gradient"
                    color="light"
                    onClick={handleBack}
                    style={{ margin: 5 }}
                  >
                    back
                  </MDButton>
                )}
              </MDBox>

              <MDButton
                variant="gradient"
                color="info"
                onClick={!isLastStep ? handleNext : handleSave}
                style={{ margin: 5 }}
              >
                {isLastStep ? "save" : "next"}
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </MDBox>
    </MDBox>
  );
};

export default InspectionModal;
