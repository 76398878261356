import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useState, useRef, useEffect } from "react";
import useModal from "hooks/useModal";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";

const InputModal = ({ title, description, placeholder, errorMsg }) => {
  const [value, setValue] = useState();
  const { closeModal } = useModal();
  const ref = useRef();
  const [error, setError] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      ref.current.focus();
    }, 200);
  }, []);

  const tryAdd = () => {
    if (value == null || value === "") {
      setError(true);
      ref.current.focus();
    } else {
      closeModal({ action: "add", value });
    }
  };

  const TOTAL_CHARS = 24;
  const changeValue = (event) => {
    setError(false);
    if (event.target.value.toString().length <= TOTAL_CHARS) {
      setValue(event.target.value);
    } else {
      setValue(event.target.value.substring(0, TOTAL_CHARS));
    }
  };

  return (
    <div style={{ minWidth: 500 }}>
      <MDBox display="flex" flexDirection="column" textAlign="center">
        <MDTypography display="inline" variant="h6" textTransform="capitalize" fontWeight="bold">
          {title}
        </MDTypography>
        <MDBox
          mt={2}
          display="flex"
          alignItems="flex-start"
          justifyContent="center"
          flexDirection="row"
          textAlign="center"
        >
          <MDTypography m={1} display="inline" variant="body2">
            {description}
          </MDTypography>
          <MDBox textAlign="left" display="flex" flexDirection="column">
            <MDInput
              inputRef={ref}
              sx={{ width: 300 }}
              placeholder={placeholder}
              value={value}
              onChange={(event) => changeValue(event)}
              onPaste={(event) => changeValue(event)}
              error={error}
              inputProps={{ maxLength: TOTAL_CHARS }}
            />
            {error && (
              <MDTypography m={1} display="inline" variant="caption" color="error">
                {errorMsg}
              </MDTypography>
            )}
          </MDBox>
        </MDBox>
        <MDBox p={2} display="flex" textAlign="center" alignItems="center" justifyContent="center">
          <MDBox mr={1}>
            <MDButton
              onClick={() => closeModal({ action: "cancel" })}
              variant="gradient"
              color="light"
              size="medium"
              m={1}
            >
              cancel
            </MDButton>
          </MDBox>
          <MDBox mr={1}>
            <MDButton onClick={tryAdd} variant="gradient" color="info" size="medium">
              add
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </div>
  );
};

export default InputModal;
