import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

import MDTypography from "components/MDTypography";

import useModal from "hooks/useModal";
import { useEffect, useRef, useState } from "react";

const BarriersAndGoalsModal = ({ isGoal, index, data, isNew }) => {
  const { closeModal } = useModal();

  // eslint-disable-next-line no-nested-ternary
  const [text, setText] = useState(
    // eslint-disable-next-line no-nested-ternary
    isNew ? "" : isGoal ? data.goals[index].text : data.barriers[index].text
  );

  const ref = useRef();

  useEffect(() => {
    setTimeout(() => {
      ref.current.focus();
    }, 100);
  }, []);

  return (
    <MDBox width={{ xs: "100%", sm: 600 }}>
      <MDBox display="flex" justifyContent="center" alignItems="center" flexDirection="column">
        <MDTypography display="inline" variant="h6" textTransform="capitalize" fontWeight="bold">
          New {`${isGoal ? "Goal" : "Barrier"}`}
        </MDTypography>
        <MDInput
          inputRef={ref}
          placeholder={`Enter a new ${isGoal ? "goal" : "barrier"}...`}
          sx={{ width: "100%" }}
          value={text}
          onChange={(event) => setText(event.target.value)}
        />
        <MDBox display="flex">
          <MDBox m={1}>
            <MDButton
              onClick={() => closeModal({ action: "cancel" })}
              variant="gradient"
              color="light"
              size="medium"
            >
              cancel
            </MDButton>
          </MDBox>
          <MDBox m={1}>
            <MDButton
              variant="gradient"
              color="info"
              size="medium"
              mr={1}
              onClick={() =>
                closeModal({ action: isNew ? "add" : "update", newText: text, isGoal, index })
              }
            >
              {isNew ? "add" : "update"}
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </MDBox>
  );
};

export default BarriersAndGoalsModal;
