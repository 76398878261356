/* eslint-disable no-nested-ternary */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMemo, useEffect, useState, useRef } from "react";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-table components
import { useTable, usePagination, useGlobalFilter, useAsyncDebounce, useSortBy } from "react-table";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import Skeleton from "components/skeletons/Skeleton";
import { MenuItem, Select } from "@mui/material";
import Timeline from "components/Timeline";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDPagination from "components/MDPagination";
import { createStyles, makeStyles } from "@material-ui/styles";
import Scrollbars from "react-custom-scrollbars";
import DataTableHeadCell from "./DataTableHeadCell";
import DataTableBodyCell from "./DataTableBodyCell";

import "../Scrollbars/scrollbar.css";
import "./styles.css";

function DataTable({
  entriesPerPage,
  canSearch,
  showTotalEntries,
  table,
  pagination,
  isSorted,
  noEndBorder,
  title,
  setTimeline,
  rowHover = false,
  rowClick = () => {},
}) {
  const defaultValue = entriesPerPage.defaultValue ? entriesPerPage.defaultValue : 10;

  const columns = useMemo(() => table.columns, [table.columns]);
  const data = useMemo(() => table.rows, [table.rows]);

  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = tableInstance;

  // Set the default value for the entries per page when component mounts
  useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);

  const [loaded, setLoaded] = useState(false);
  const height = useRef();

  const refContainer = useRef();
  function handleResize() {
    setLoaded(false);
    height.current = refContainer.current.offsetHeight;
    setLoaded(true);
  }

  // Render the paginations
  const renderPagination = pageOptions.map((option) => (
    <MDPagination
      item
      key={option}
      onClick={() => {
        gotoPage(Number(option));
        setTimeout(() => {
          handleResize();
        }, 10);
      }}
      active={pageIndex === option}
    >
      {option + 1}
    </MDPagination>
  ));

  // Handler for the input to set the pagination index
  const handleInputPagination = ({ target: { value } }) =>
    value > pageOptions.length || value < 0 ? gotoPage(0) : gotoPage(Number(value));

  // Customized page options starting from 1
  const customizedPageOptions = pageOptions.map((option) => option + 1);

  // Setting value for the pagination input
  const handleInputPaginationValue = ({ target: value }) => gotoPage(Number(value.value - 1));

  // Search input value state
  const [search, setSearch] = useState(globalFilter);

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 100);

  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue;
    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  // Setting the entries starting point
  const entriesStart = pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

  // Setting the entries ending point
  let entriesEnd;

  if (pageIndex === 0) {
    entriesEnd = pageSize;
  } else if (pageIndex === pageOptions.length - 1) {
    entriesEnd = rows.length;
  } else {
    entriesEnd = pageSize * (pageIndex + 1);
  }

  useEffect(() => {
    height.current = refContainer.current.offsetHeight;
    setLoaded(true);

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  // Set the entries per page value based on the select value
  const setEntriesPerPage = (value) => {
    setPageSize(value);
    setTimeout(() => {
      handleResize();
    }, 10);
  };

  const useStyles = makeStyles(() =>
    createStyles({
      rowHover: {
        "&:hover": {
          cursor: "pointer",
          backgroundColor: "#edf4ff !important",
        },
      },
    })
  );

  const classes = useStyles();

  const contents = (
    <div ref={refContainer}>
      <MDBox style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
        {title != null && (
          <MDBox display="flex" ml={3} mt={3} ref={refContainer}>
            <MDTypography variant="h6" fontWeight="medium">
              {title}
            </MDTypography>
            {setTimeline && (
              <div style={{ marginTop: 2, marginLeft: 20 }}>
                <Timeline setTimeline={setTimeline} />
              </div>
            )}
          </MDBox>
        )}

        {entriesPerPage || canSearch ? (
          <MDBox display="flex" mx={2} mt={title != null ? 2 : 1}>
            {canSearch && (
              <MDBox width="12rem" ml="auto">
                <MDInput
                  placeholder="Search..."
                  value={search}
                  size="small"
                  fullWidth
                  style={{ height: 60 }}
                  onChange={({ currentTarget }) => {
                    setSearch(search);
                    onSearchChange(currentTarget.value);
                  }}
                />
              </MDBox>
            )}
            {entriesPerPage && !entriesPerPage.hide && (
              <MDBox display="flex" alignItems="center" ml={1} mt={-3}>
                <Select
                  value={pageSize.toString()}
                  labelId="demo-simple-select-label"
                  onChange={(event) => {
                    setEntriesPerPage(event.target.value);
                  }}
                  style={{ height: 36, width: 58, marginRight: 10 }}
                >
                  {entriesPerPage.entries.map((entry) => (
                    <MenuItem key={entry} value={entry} className="entriesWidth">
                      {entry}
                    </MenuItem>
                  ))}
                </Select>
                <MDTypography variant="caption" color="secondary" style={{ minWidth: 55 }}>
                  Per Page
                </MDTypography>
              </MDBox>
            )}
          </MDBox>
        ) : null}
      </MDBox>
      <Table {...getTableProps()}>
        <MDBox component="thead">
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()} hover>
              {headerGroup.headers.map((column, i) => (
                <DataTableHeadCell
                  {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                  width={column.width ? column.width : "auto"}
                  align={column.align ? column.align : "left"}
                  sorted={columns[i].isSorted === false ? false : setSortedValue(column)}
                >
                  {column.render("Header")}
                </DataTableHeadCell>
              ))}
            </TableRow>
          ))}
        </MDBox>
        <TableBody {...getTableBodyProps()}>
          {page.map((row, key) => {
            prepareRow(row);
            return (
              <TableRow
                hover={rowHover}
                {...row.getRowProps()}
                classes={{
                  hover: classes.rowHover,
                }}
                onClick={() => rowClick(row)}
              >
                {row.cells.map((cell) => (
                  <DataTableBodyCell
                    onClick={() => rowClick(row)}
                    noBorder={noEndBorder && rows.length - 1 === key}
                    align={cell.column.align ? cell.column.align : "left"}
                    width={cell.column.width}
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </DataTableBodyCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      <MDBox
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        p={!showTotalEntries && pageOptions.length === 1 ? 0 : title == null ? 2 : 3}
      >
        {showTotalEntries && (
          <MDBox mb={{ xs: 3, sm: 0 }}>
            <MDTypography variant="button" color="secondary" fontWeight="regular">
              Showing {entriesStart} to {entriesEnd} of {rows.length} entries
            </MDTypography>
          </MDBox>
        )}
        {pageOptions.length > 1 && (
          <MDPagination
            variant={pagination.variant ? pagination.variant : "gradient"}
            color={pagination.color ? pagination.color : "info"}
          >
            {canPreviousPage && (
              <MDPagination item onClick={() => previousPage()}>
                <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
              </MDPagination>
            )}
            {renderPagination.length > 6 ? (
              <MDBox width="5rem" mx={1}>
                <MDInput
                  inputProps={{ type: "number", min: 1, max: customizedPageOptions.length }}
                  value={customizedPageOptions[pageIndex]}
                  onChange={(handleInputPagination, handleInputPaginationValue)}
                />
              </MDBox>
            ) : (
              renderPagination
            )}
            {canNextPage && (
              <MDPagination item onClick={() => nextPage()}>
                <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
              </MDPagination>
            )}
          </MDPagination>
        )}
      </MDBox>
    </div>
  );

  if (!loaded) {
    return contents;
  }
  return (
    <TableContainer sx={{ boxShadow: "none" }}>
      {/* <Scrollbars
        style={{ width: "100%", height: height.current }}
        renderTrackHorizontal={({ style, ...props }) => (
          <div className="scrollbar-regular-horizontal-track" {...props} />
        )}
        renderThumbHorizontal={({ style, ...props }) => (
          <div
            style={{
              ...style,
              backgroundColor: "rgba(0, 0, 0, 0.2)",
              borderRadius: 8,
              height: 6,
              cursor: "pointer",
            }}
            {...props}
          />
        )}
          > */}
      {contents}
      {/*  </Scrollbars> */}
    </TableContainer>
  );
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: [5, 10, 25, 50, 100], hide: false },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
};

// Typechecking props for the DataTable
DataTable.propTypes = {
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
};

export default DataTable;
