import Card from "@mui/material/Card";
import DataTable from "components/DataTable";
import TimestampCell from "components/DataTable/components/TimestampCell";
import DefaultCell from "components/DataTable/components/DefaultCell";
import PersonCell from "components/DataTable/components/PersonCell";
import EmailPendingActionCell from "components/DataTable/components/ActionCells/EmailPendingActionCell";
import { Accordion, AccordionDetails, AccordionSummary, colors } from "@mui/material";
import { makeStyles } from "@material-ui/styles";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useEffect, useRef, useState } from "react";
import { useCache } from "hooks/useCache";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import useBackendError from "hooks/useBackendError";

const useStyles = makeStyles(() => ({
  accordionSummary: {
    minHeight: 40,
    maxHeight: 40,
    "&.Mui-expanded": {
      minHeight: 40,
      maxHeight: 40,
      backgroundColor: colors.grey[100],
    },
  },
  accordionDetail: {
    backgroundColor: colors.grey[200],
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  accordion: {
    boxShadow: "none",
    "&:before": {
      display: "none", // Remove the pseudo-element
    },
    "& .MuiPaper-root": {
      borderRadius: 10,
    },
  },
}));

const dataTableData = (rows, isLoading) => ({
  columns: [
    {
      Header: "Resident",
      accessor: "resident",
      isSorted: true,
      Cell: ({ value: [image, name, id] }) => (
        <PersonCell image={image} name={name} color="light" id={id} isLoading={isLoading} />
      ),
    },
    {
      Header: "Sent By",
      accessor: "sentBy",
      isSorted: true,
      Cell: ({ value: [image, name, id] }) => (
        <PersonCell image={image} name={name} color="light" id={id} isLoading={isLoading} />
      ),
    },
    {
      Header: "Sent Date",
      accessor: "sentDate",
      isSorted: false,
      Cell: ({ value }) => <TimestampCell timestamp={value} hideTime isLoading={isLoading} />,
    },
    {
      Header: "Follow-ups",
      accessor: "followUps",
      isSorted: false,
      width: "10%",
      Cell: ({ value }) => <DefaultCell value={value} isLoading={isLoading} />,
    },
    {
      Header: "Last Follow-up",
      accessor: "lastFollowUp",
      isSorted: false,
      Cell: ({ value }) => (
        <TimestampCell timestamp={value} hideTime isLoading={isLoading} showAgo />
      ),
    },
    {
      Header: "Template",
      accessor: "template",
      isSorted: false,
      Cell: ({ value }) => <DefaultCell value={value} isLoading={isLoading} />,
    },
    {
      Header: "Actions",
      accessor: "action",
      isSorted: false,
      Cell: ({ value: [id, followupDisabledReason, email, triggerLoading] }) => (
        <EmailPendingActionCell
          id={id}
          isLoading={isLoading}
          followupDisabledReason={followupDisabledReason}
          email={email}
          triggerLoading={triggerLoading}
        />
      ),
    },
  ],
  rows,
});

const EmailSurveys = () => {
  const { getItem } = useCache();
  const email = useRef();
  const [loading, setLoading] = useState(true);
  const collapsed = getItem("pending-emails-collapsed") ?? false;
  const axios = useAxiosPrivate();
  const { setError } = useBackendError();

  const loadPendingEmail = async (noLoading) => {
    if (!noLoading) {
      setLoading(true);
    }
    try {
      const response = await axios.get(`/satisfaction/pending-email`, {
        headers: { "Content-Type": "application/json" },
      });
      email.current = response.data;
    } catch (err) {
      setError("Failed to fetch from the backend. Please try again");
    } finally {
      setLoading(false);
    }
  };

  const triggerLoadingEmails = () => {
    loadPendingEmail(true);
  };

  useEffect(() => {
    loadPendingEmail(false);
  }, []);

  const [isExpanded, setExpanded] = useState(!collapsed);
  const classes = useStyles();
  const { setItem } = useCache();
  let rows = [];

  if (!loading) {
    rows = email.current?.map((row) => ({
      resident: [row.resident.url, row.resident.fullName, row.resident.id],
      sentBy: [row.user.url, row.user.fullName, row.user.id],
      sentDate: row.emailSentDate,
      followUps: row.emailFollowUpDate?.length ?? 0,
      lastFollowUp: row.emailFollowUpDate == null ? "" : Math.max.apply(0, row.emailFollowUpDate),
      template: row.template.name,
      action: [row.id, row.followupDisabledReason, row.email, triggerLoadingEmails],
    }));
  }

  if (email.current == null) {
    return null;
  }

  const handleExpandCollapse = () => {
    setExpanded(!isExpanded);
    setItem("pending-emails-collapsed", isExpanded);
  };

  return (
    <Card id="past-rating" style={{ marginBottom: 24 }}>
      <MDBox style={{ borderRadius: 10, overflow: "hidden" }}>
        <Accordion
          disableGutters
          className={classes.accordion}
          onChange={handleExpandCollapse}
          expanded={isExpanded}
        >
          <AccordionSummary>
            <MDBox mx={1} display="flex">
              <MDTypography variant="h6" fontWeight="medium" mt={0.2}>
                Response Pending Email Surveys
              </MDTypography>
              <MDButton variant="outlined" color="primary" size="small" style={{ marginLeft: 10 }}>
                {isExpanded ? "COLLAPSE" : "EXPAND"}
              </MDButton>
            </MDBox>
          </AccordionSummary>
          <AccordionDetails>
            <DataTable
              table={dataTableData(rows, loading)}
              isSorted
              entriesPerPage={{ defaultValue: 3, hide: true }}
            />
          </AccordionDetails>
        </Accordion>
      </MDBox>
    </Card>
  );
};

export default EmailSurveys;
