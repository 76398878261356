import useModal from "hooks/useModal";
import "./modal.css";
import Rodal from "rodal";

const Modal = () => {
  const { isModalOpen, modalContent } = useModal();

  if (!isModalOpen) {
    document.body.style.overflow = "visible";
  } else {
    document.body.style.overflow = "hidden";
  }

  return (
    <Rodal
      visible={isModalOpen}
      onClose={() => {}}
      showCloseButton={false}
      animation="fade"
      duration={100}
      customStyles={{
        height: "auto",
        width: "auto",
        left: "50%",
        right: "auto",
        bottom: "auto",
        top: "50%",
        transform: "translate(-50%, -50%)",
        minWidth: 400,
        paddingLeft: 20,
        paddingRight: 20,
      }}
    >
      {modalContent}
    </Rodal>
  );
};

export default Modal;
