import { Checkbox, Icon } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import useModal from "hooks/useModal";
import { useState } from "react";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import MDBox from "components/MDBox";
import colors from "assets/theme/base/colors";
import { timeout } from "utils/utils";

const UserPasswordReset = ({ user, defaultPassword }) => {
  const { closeModal } = useModal();
  const axios = useAxiosPrivate();
  const [email, setEmail] = useState(true);
  const [resetting, setResetting] = useState(false);
  const [step, setStep] = useState("initial");

  const reset = async () => {
    setResetting(true);
    try {
      await timeout(3000);
      /* await axios.post(`/password/reset`, { // use the email flag also!!!!!!
      headers: { "Content-Type": "application/json" },
    }); */
    } catch (e) {
      setStep("error");
      return;
    }
    setStep("success");
  };

  const handleOkay = () => {
    closeModal();
  };

  if (step === "initial") {
    return (
      <div style={{ maxWidth: 600 }}>
        <MDBox display="flex" flexDirection="column" textAlign="center">
          <MDTypography
            display="inline"
            variant="h6"
            textTransform="capitalize"
            fontWeight="bold"
            textAlign="center"
          >
            Reset Password?
          </MDTypography>
          <div className="warnContainer">
            <span className="warnOne" />
            <span className="warnTwo" />
          </div>
        </MDBox>
        <MDBox px={2} display="flex" justifyContent="center">
          <MDTypography textAlign="center" variant="body2">
            Do you really want to reset{" "}
            <span style={{ color: colors.info.main }}>{user.fullName}</span>
            &#39;s <br /> password to{" "}
            <span style={{ color: colors.primary.main }}>{defaultPassword}</span> (default password{" "}
            <Tooltip
              title="Default password can be changed in Settings"
              enterDelay={100}
              placement="top"
            >
              <Icon color="info" style={{ cursor: "pointer" }}>
                info
              </Icon>
            </Tooltip>
            ) ?
          </MDTypography>
        </MDBox>

        <MDBox display="flex" pt={1} justifyContent="center" alignItems="center">
          <Checkbox
            value="email"
            checked={email}
            onChange={(event) => setEmail(event.target.checked)}
          />
          <MDTypography display="inline" variant="body2">
            Send an email to {user.firstName} with the default password.
          </MDTypography>
        </MDBox>

        <MDBox
          px={2}
          pb={2}
          mt={1}
          display="flex"
          textAlign="center"
          alignItems="center"
          justifyContent="center"
        >
          <MDBox mr={1}>
            <MDButton
              onClick={() => closeModal({ action: "cancel" })}
              variant="gradient"
              color="light"
              size="medium"
              m={1}
            >
              cancel
            </MDButton>
          </MDBox>
          <MDBox mr={1}>
            <MDButton
              onClick={reset}
              variant="gradient"
              color="primary"
              size="medium"
              loading={resetting}
              loadingText="resetting..."
              style={{ width: 160 }}
            >
              Reset Password
            </MDButton>
          </MDBox>
        </MDBox>
      </div>
    );
  }
  if (step === "success") {
    return (
      <div style={{ maxWidth: 600 }}>
        <MDBox display="flex" flexDirection="column" textAlign="center">
          <MDTypography
            display="inline"
            variant="h6"
            textTransform="capitalize"
            fontWeight="bold"
            textAlign="center"
          >
            Reset Password Successfull
          </MDTypography>
          <div className="successContainer">
            <div className="successOne" />
            <span className="successTwo" />
            <span className="successThree" />
            <div className="successFour" />
            <div className="successFive" />
            <div className="successSix" />
          </div>
        </MDBox>
        <MDBox px={2} display="flex" justifyContent="center">
          <MDTypography textAlign="center" variant="body2">
            Password has been succesfully reset to{" "}
            <span style={{ color: colors.primary.main }}>{defaultPassword}</span>{" "}
            {email ? (
              <>
                {" "}
                and an email with the details has been sent to{" "}
                <span style={{ color: colors.primary.main }}>{user.email}</span>.
              </>
            ) : (
              "."
            )}
          </MDTypography>
        </MDBox>

        <MDBox
          px={2}
          pb={2}
          mt={1}
          display="flex"
          textAlign="center"
          alignItems="center"
          justifyContent="center"
        >
          <MDBox>
            <MDButton onClick={handleOkay} variant="gradient" color="info" size="medium">
              Okay
            </MDButton>
          </MDBox>
        </MDBox>
      </div>
    );
  }
  return (
    <div style={{ maxWidth: 600 }}>
      <MDBox display="flex" flexDirection="column" textAlign="center">
        <MDTypography
          display="inline"
          variant="h6"
          textTransform="capitalize"
          fontWeight="bold"
          textAlign="center"
        >
          Reset Password Failed
        </MDTypography>
        <div className="errorContainer">
          <span className="errorOne">
            <span className="errorTwo" />
            <span className="errorThree" />
          </span>
        </div>
      </MDBox>
      <MDBox px={2} display="flex" justifyContent="center">
        <MDTypography textAlign="center" variant="body2">
          An unexpected error occured. Please try again
        </MDTypography>
      </MDBox>

      <MDBox
        px={2}
        pb={2}
        mt={1}
        display="flex"
        textAlign="center"
        alignItems="center"
        justifyContent="center"
      >
        <MDBox>
          <MDButton onClick={handleOkay} variant="gradient" color="info" size="medium">
            Okay
          </MDButton>
        </MDBox>
      </MDBox>
    </div>
  );
};

export default UserPasswordReset;
