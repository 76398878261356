import MDInput from "components/MDInput";
import { useNavigate, Link } from "react-router-dom";
import useLogout from "../hooks/useLogout";

function Dashboard() {
  const navigate = useNavigate();
  const logout = useLogout();

  const signOut = async () => {
    await logout();
    navigate("/linkpage");
  };

  return (
    <section style={{ marginLeft: 500 }}>
      <h1>Dashboard</h1>
      <MDInput placeholder="Search..." size="small" fullWidth onChange={() => {}} />
      <br />
      <p>You are logged in!</p>
      <br />
      <Link to="/editor">Go to the Editor page</Link>
      <br />
      <Link to="/admin">Go to the Admin page</Link>
      <br />
      <Link to="/lounge">Go to the Lounge</Link>
      <br />
      <Link to="/linkpage">Go to the link page</Link>
      <div className="flexGrow">
        <button type="button" onClick={signOut}>
          Sign Out
        </button>
      </div>
    </section>
  );
}

export default Dashboard;
