/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-props-no-spreading */
import TagsInput from "react-tagsinput";
import "./MDTagInput.css";
import { Icon } from "@mui/material";
import MDTypography from "components/MDTypography";
import Scrollbars from "react-custom-scrollbars";
import { useRef, useState } from "react";

const MDTagInput = ({ value, onChange, placeholder, errorValues, error = false }) => {
  const ref = useRef();
  const scrollRef = useRef();
  const [infocus, setInFocus] = useState(false);
  const renderTag = (props) => {
    const { tag, key, disabled, onRemove, classNameRemove, getTagDisplayValue, ...other } = props;
    const displayName = getTagDisplayValue(tag);
    const isError = errorValues.includes(displayName);
    return (
      <span
        onClick={(e) => e.stopPropagation()}
        key={key}
        {...other}
        style={{
          color: isError ? "red" : null,
          backgroundColor: isError ? "#FFDBE7" : null,
          borderColor: isError ? "red" : null,
        }}
      >
        <MDTypography mx={0.6} variant="body2" display="inline" style={{ fontSize: 13 }}>
          {displayName}
        </MDTypography>
        {!disabled && (
          <Icon
            style={{
              cursor: "pointer",
              marginRight: 2,
              marginTop: 8,
              paddingTop: 1,
              color: "black",
            }}
            onClick={(e) => onRemove(key)}
          >
            clear
          </Icon>
        )}
      </span>
    );
  };

  const onBlurFn = () => {
    setInFocus(false);
  };

  const onFocusFn = () => {
    setInFocus(true);
  };

  const clicked = () => {
    scrollRef.current.scrollToBottom();
    ref.current.focus();
  };

  function renderLayout(tagElements, inputElement) {
    return (
      <Scrollbars
        ref={scrollRef}
        onClick={clicked}
        style={{
          height: "100%",
        }}
        renderTrackHorizontal={({ style, ...props }) => <div />}
        renderTrackVertical={({ style, ...props }) => (
          <div className="scrollbar-regular-vertical-track" {...props} />
        )}
        renderThumbVertical={({ style, ...props }) => (
          <div
            style={{
              ...style,
              backgroundColor: "rgba(0, 0, 0, 0.2)",
              borderRadius: 8,
              width: 6,
              cursor: "pointer",
            }}
            {...props}
          />
        )}
      >
        {tagElements}
        {inputElement}
      </Scrollbars>
    );
  }

  return (
    <div
      className={`container${error ? " error" : ""}${infocus ? " focus" : ""}`}
      onFocus={onFocusFn}
      onBlur={onBlurFn}
    >
      <TagsInput
        ref={ref}
        value={value}
        onChange={onChange}
        onlyUnique
        inputProps={{
          placeholder,
        }}
        addKeys={[9, 13, 188]}
        renderTag={renderTag}
        renderLayout={renderLayout}
        addOnBlur
      />
    </div>
  );
};

export default MDTagInput;
