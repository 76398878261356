import { styled } from "@material-ui/styles";
import {
  Autocomplete,
  FormControlLabel,
  Grid,
  Icon,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import colors from "assets/theme/base/colors";

import MDButton from "components/MDButton";

import MDTypography from "components/MDTypography";

import useModal from "hooks/useModal";
import { useState } from "react";
import useFacility from "hooks/useFacility";
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";

const SelectSurveyModal = ({ residents, surveys }) => {
  const GroupHeader = styled("div")(() => ({
    position: "sticky",
    top: "-8px",
    padding: "4px 10px",
    color: "white",
    backgroundColor: colors.grey[700],
    fontSize: 16,
  }));

  const GroupItems = styled("ul")({
    padding: 0,
  });

  const { closeModal } = useModal();

  const options = surveys.map((survey) => ({
    label: survey.name,
    id: survey.id,
    group: survey.mandatory ? "Mandatory Facility Tasks:" : "Optional Facility Tasks:",
  }));

  const residentsList = residents.map((res) => ({
    label: res.fullName,
    id: res.id,
    room: res.room,
    url: res.url,
  }));

  const [inputVal, setInputVal] = useState("");
  const [residentInputValue, setResidentInputValue] = useState("");
  const [survey, setSurvey] = useState();
  const [type, setType] = useState("resident");
  const [resident, setResident] = useState();
  const [surveysError, setSurveysError] = useState(false);
  const [residentsError, setResidentsError] = useState(false);

  const handleChange = (event) => {
    setType(event.target.value);
  };

  const { facility } = useFacility();

  const handleNext = () => {
    let noError = true;
    if (inputVal == null || inputVal === "") {
      noError = false;
      setSurveysError(true);
    }
    if (type === "resident" && (residentInputValue == null || residentInputValue === "")) {
      noError = false;
      setResidentsError(true);
    }
    if (noError) {
      closeModal({
        action: "next",
        surveyId: survey.id,
        surveyName: survey.label,
        type,
        value: type === "resident" ? resident.id : facility.key,
      });
    }
  };

  return (
    <div style={{ minWidth: 500 }}>
      <MDTypography display="inline" variant="h6" textTransform="capitalize" fontWeight="bold">
        Start New LTCSP Mock Survey
      </MDTypography>
      <Grid container spacing={1} mb={1} mt={3}>
        <Grid item xs={3} textAlign="right">
          <MDTypography display="inline" variant="body2" textTransform="capitalize">
            pathway
          </MDTypography>
        </Grid>
        <Grid item xs={8} textAlign="left" pr={2}>
          <Autocomplete
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={survey || null}
            onChange={(event, newValue) => {
              setSurvey(newValue);
            }}
            inputValue={inputVal}
            onInputChange={(event, newInputValue) => {
              setSurveysError(false);
              setInputVal(newInputValue);
            }}
            options={options}
            groupBy={(option) => option.group}
            renderInput={(params) => (
              <TextField
                error={surveysError}
                helperText={surveysError ? "Please select a Pathway" : ""}
                {...params}
                placeholder="Select a Pathway..."
              />
            )}
            renderGroup={(params) => (
              <li key={params.key}>
                <GroupHeader>{params.group}</GroupHeader>
                <GroupItems>{params.children}</GroupItems>
              </li>
            )}
            renderOption={(props, option, { inputValue }) => {
              const matches = match(option.label, inputValue, { insideWords: true });
              const parts = parse(option.label, matches);

              const bgColor = inputValue === option.label ? colors.grey[200] : null;
              return (
                <li {...props} style={{ backgroundColor: bgColor }}>
                  <div>
                    {parts.map((part) => (
                      <span
                        key={part.text + new Date().getTime()}
                        style={{
                          fontWeight: part.highlight ? 700 : 400,
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        {part.text}
                      </span>
                    ))}
                  </div>
                </li>
              );
            }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1} mb={1} mt={2}>
        <Grid item xs={3} textAlign="right">
          <MDTypography display="inline" variant="body2" textTransform="capitalize">
            Type
          </MDTypography>
        </Grid>
        <Grid item xs={8} textAlign="left">
          <RadioGroup
            aria-labelledby="controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={type}
            onChange={handleChange}
          >
            <FormControlLabel
              value="resident"
              control={<Radio />}
              label={
                <MDTypography display="inline" variant="body2" textTransform="capitalize">
                  By Resident
                </MDTypography>
              }
            />
            <FormControlLabel
              value="facility"
              control={<Radio />}
              label={
                <MDTypography display="inline" variant="body2" textTransform="capitalize">
                  By Facility
                </MDTypography>
              }
            />
          </RadioGroup>
        </Grid>
      </Grid>

      {type === "resident" && (
        <Grid container spacing={1} mb={1} mt={1}>
          <Grid item xs={3} textAlign="right">
            <MDTypography display="inline" variant="body2" textTransform="capitalize">
              Resident
            </MDTypography>
          </Grid>
          <Grid item xs={8} textAlign="left" pr={2}>
            <Autocomplete
              isOptionEqualToValue={(option, value) => option.id === value.id}
              value={resident || null}
              onChange={(event, newValue) => {
                setResidentsError(false);
                setResident(newValue);
              }}
              inputValue={residentInputValue}
              onInputChange={(event, newInputValue) => {
                setResidentInputValue(newInputValue);
              }}
              options={residentsList}
              renderInput={(params) => (
                <TextField
                  error={residentsError}
                  helperText={residentsError ? "Please select a Resident" : ""}
                  {...params}
                  placeholder="Select a Resident..."
                />
              )}
              renderOption={(props, option, { inputValue }) => {
                const matches = match(option.label, inputValue, { insideWords: true });
                const parts = parse(option.label, matches);

                const bgColor = inputValue === option.label ? colors.grey[200] : null;
                return (
                  <li {...props} style={{ backgroundColor: bgColor }}>
                    <MDBox display="flex">
                      <span style={{ marginRight: 10 }}>
                        <MDAvatar bgColor="light" src={option.url} alt={option.label} size="xs" />
                      </span>
                      {parts.map((part) => (
                        <span
                          key={part.text + new Date().getTime()}
                          style={{
                            fontWeight: part.highlight ? 700 : 400,
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          {part.text}
                        </span>
                      ))}
                      {option.room != null && option.room !== "" && (
                        <span style={{ color: colors.grey[500], fontSize: 12 }}>
                          &nbsp;[Room:{option.room}]
                        </span>
                      )}
                    </MDBox>
                  </li>
                );
              }}
            />
          </Grid>
        </Grid>
      )}
      {type !== "resident" && (
        <Grid container spacing={1} mb={1} mt={1}>
          <Grid item xs={3} textAlign="right">
            <MDTypography display="inline" variant="body2" textTransform="capitalize">
              Facility
            </MDTypography>
          </Grid>
          <Grid item xs={8} pr={2}>
            <TextField value={facility.name} disabled style={{ width: "100%" }} />
          </Grid>
        </Grid>
      )}

      <Grid container spacing={1} mb={1} mt={3}>
        <Grid item xs={6} textAlign="right">
          <MDButton
            onClick={() => closeModal({ action: "cancel" })}
            variant="gradient"
            color="light"
            size="medium"
          >
            cancel
          </MDButton>
        </Grid>
        <Grid item xs={6} textAlign="left">
          <MDButton onClick={handleNext} variant="gradient" color="info" size="medium">
            start&nbsp;
            <Icon sx={{ fontWeight: "bold" }}>navigate_next</Icon>
          </MDButton>
        </Grid>
      </Grid>
    </div>
  );
};

export default SelectSurveyModal;
