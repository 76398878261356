/* eslint-disable no-param-reassign */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/no-danger */
/* eslint-disable no-plusplus */

import MDBox from "components/MDBox";

import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { Icon } from "@mui/material";
import ConfirmDeleteModal from "components/modal/ConfirmDeleteModal";
import useModal from "hooks/useModal";

const Question = ({ children, data, setData, sectionIndex, questionIndex }) => {
  const { openModal } = useModal();
  const question = data[sectionIndex].questions[questionIndex];
  const handleChange = (val) => {
    data[sectionIndex].questions[questionIndex].questionText = val;
    setData([...data]);
  };

  const deleteCallback = async () => {
    data[sectionIndex].questions.splice(questionIndex, 1);
    setData([...data]);
    return "success";
  };

  const deleteMe = () => {
    openModal(
      <ConfirmDeleteModal
        title="Delete Question?"
        text="Do you really want to delete this question?"
        successText="The selected question has been successfully deleted."
        callback={deleteCallback}
      />,
      () => {}
    );
  };

  return (
    <MDBox
      px={1}
      bgColor="white"
      borderRadius="xl"
      style={{ width: "100%" }}
      coloredShadow={question.isError ? "error" : null}
      border={question.isError ? "1px solid" : null}
      borderColor={question.isError ? "red" : null}
      display="flex"
      justifyContent="space-between"
    >
      <MDBox pt={0.6} width="100%">
        <MDInput
          variant="standard"
          placeholder="Enter a question..."
          sx={{ width: "100%" }}
          value={question.questionText}
          onChange={(event) => handleChange(event.target.value)}
        />
      </MDBox>
      <MDBox display="flex">
        <MDButton variant="text" color="secondary" iconOnly circular onClick={deleteMe}>
          <Icon>delete</Icon>
        </MDButton>
        {children}
      </MDBox>
    </MDBox>
  );
};

export default Question;
