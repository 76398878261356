import PropTypes from "prop-types";

import MDBox from "components/MDBox";

import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";

const CMSAnalysisActionDetailsCell = ({ id }) => {
  const navigate = useNavigate();
  const viewSurvey = () => {
    navigate(`/compliance/survey?id=${id}`);
  };
  return (
    <MDBox display="flex" alignItems="center">
      <MDButton
        variant="gradient"
        color="dark"
        style={{ margin: 5, width: 120 }}
        onClick={viewSurvey}
      >
        VIEW
      </MDButton>
    </MDBox>
  );
};
// Typechecking props for the StatusCell
CMSAnalysisActionDetailsCell.propTypes = {
  id: PropTypes.number.isRequired,
};

export default CMSAnalysisActionDetailsCell;
