import Card from "@mui/material/Card";
import DataTable from "components/DataTable";

import DefaultCell from "components/DataTable/components/DefaultCell";
import CMSCell from "components/DataTable/components/CMSCell";
import StatusCell from "components/DataTable/components/StatusCell";
import TimestampCell from "components/DataTable/components/TimestampCell";

import CMSPastSurveyActionCell from "components/DataTable/components/ActionCells/CMSPastSurveyActionCell";

const dataTableData = (rows, isLoading) => ({
  columns: [
    {
      Header: "Critical Element (CE) Pathway",
      accessor: "pathway",
      isSorted: false,
      Cell: ({ value: [name, ftagCount] }) => (
        <CMSCell text={name} count={ftagCount} isLoading={isLoading} />
      ),
    },
    {
      Header: "Taken By",
      accessor: "takenBy",
      Cell: ({ value }) => <DefaultCell value={value} isLoading={isLoading} />,
    },
    {
      Header: "Participant",
      accessor: "participant",
      Cell: ({ value }) => <DefaultCell value={value} isLoading={isLoading} />,
    },
    {
      Header: "Timestamp",
      accessor: "timestamp",
      Cell: ({ value }) => <TimestampCell timestamp={value} isLoading={isLoading} />,
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) => <StatusCell status={value} isLoading={isLoading} />,
    },
    {
      Header: "Actions",
      accessor: "action",
      isSorted: false,
      Cell: ({ value }) => <CMSPastSurveyActionCell id={value} isLoading={isLoading} />,
    },
  ],
  rows,
});

const ComplianceOverviewPastSurveys = ({ data, setTimeline, isLoading }) => {
  let rows = [];

  if (!isLoading) {
    rows = data?.pastSurveys.map((row) => ({
      pathway: [row.pathwayName, row.ftagCount],
      timestamp: row.unixTimestamp,
      takenBy: row.takenByName,
      participant: row.participantName,
      status: row.status,
      action: row.id,
    }));
  } else {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 10; i++) {
      // eslint-disable-next-line no-new-object
      rows.push({
        pathway: ["", 0],
        timestamp: "",
        takenBy: "",
        participant: "",
        status: "",
        action: 0,
      });
    }
  }

  if (rows == null) {
    return null;
  }

  return (
    <Card id="past-surveys">
      <DataTable
        table={dataTableData(rows, isLoading)}
        canSearch
        title="Past Surveys"
        setTimeline={setTimeline}
        isSorted
      />
    </Card>
  );
};

export default ComplianceOverviewPastSurveys;
