/* eslint-disable react/no-array-index-key */
import { useEffect, useRef, useState } from "react";

import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

import colors from "assets/theme/base/colors";

import Scrollbars from "react-custom-scrollbars";
import useBackendError from "hooks/useBackendError";
import useAxiosPrivate from "hooks/useAxiosPrivate";

import TimelineItem from "components/Timeline/TimelineItem";

import dayjs from "dayjs";

const ActivityLog = ({ residentId }) => {
  const axios = useAxiosPrivate();
  const [activityLogLoading, setActivityLogLoading] = useState(true);
  const [activityLog, setActivityLog] = useState([]);
  const activityLogPaginationKey = useRef();
  const [activityFetchInProgress, setActivityFetchInProgress] = useState(false);
  const { setError } = useBackendError();

  const loadActivityLog = async () => {
    if (activityFetchInProgress) {
      return;
    }
    setActivityFetchInProgress(true);
    if (activityLogPaginationKey.current == null) {
      setActivityLogLoading(true);
    }
    try {
      const response = await axios.get(
        `/user/activity?id=${residentId}${
          activityLogPaginationKey.current == null
            ? ""
            : `&paginationKey=${activityLogPaginationKey.current}`
        }`,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      setActivityLog(activityLog.concat(response.data.activities));
      activityLogPaginationKey.current = response.data.paginationKey;
    } catch (err) {
      setError("Failed to fetch from the backend. Please try again");
    } finally {
      setActivityLogLoading(false);
      setActivityFetchInProgress(false);
    }
  };

  useEffect(() => {
    loadActivityLog();
  }, []);

  const handleScroll = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;
    const calculatedScrollPercentage = (scrollTop / (scrollHeight - clientHeight)) * 100;
    if (calculatedScrollPercentage >= 80) {
      loadActivityLog();
    }
  };

  return (
    <MDBox px={2} py={3} style={{ height: "100%" }}>
      <MDTypography variant="h6" fontWeight="medium" mb={2}>
        Activity Log
      </MDTypography>
      <Scrollbars
        onScroll={handleScroll}
        style={{
          height: "calc(100% - 35px)",
          backgroundColor: colors.grey[100],
          borderRadius: 8,
        }}
        renderTrackHorizontal={({ style, ...props }) => <div />}
        renderTrackVertical={({ style, ...props }) => (
          <div className="scrollbar-regular-vertical-track" {...props} />
        )}
        renderThumbVertical={({ style, ...props }) => (
          <div
            style={{
              ...style,
              backgroundColor: "rgba(0, 0, 0, 0.2)",
              borderRadius: 8,
              width: 6,
              cursor: "pointer",
            }}
            {...props}
          />
        )}
      >
        {!activityLogLoading && (
          <MDBox p={2}>
            {activityLog.map((log, index) => (
              <TimelineItem
                key={`activity-${index}`}
                color={log.color}
                icon={log.icon}
                title={log.title}
                dateTime={dayjs.unix(log.dateTime).format("MMM DD, YYYY - hh:mm A")}
                description={log.description}
                lastItem={index === activityLog.length - 1}
              />
            ))}
          </MDBox>
        )}
      </Scrollbars>
    </MDBox>
  );
};
export default ActivityLog;
