/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable react/no-array-index-key */
import { Card } from "@mui/material";
import colors from "assets/theme/base/colors";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import Icon from "@mui/material/Icon";
import Skeleton from "components/skeletons/Skeleton";

const BarriersAndGoalsSkeleton = () => (
  <>
    <Card style={{ backgroundColor: colors.grey[300], width: "100%", marginTop: 30 }}>
      <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
        <MDBox
          color="white"
          bgColor="dark"
          variant="gradient"
          borderRadius="xl"
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="1.5rem"
          pl={2}
          pr={2}
          mt={-3}
        >
          <MDTypography variant="body2" color="white" fontSize="xs">
            Barriers
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox p={3}>
        <MDTypography variant="body2" fontSize="xs">
          List out the barriers so that you can set some goals in the next section to resolve them.
          You can evaluate them on the next evaluation.
        </MDTypography>
        <Skeleton width="100%" height="40px" dark margin="5px" borderRadius={8} />
        <Skeleton width="100%" height="40px" dark margin="5px" borderRadius={8} />
        <Skeleton width="100%" height="40px" dark margin="5px" borderRadius={8} />
        <MDBox style={{ borderTop: `1px solid ${colors.grey[400]}`, marginTop: 10, paddingTop: 5 }}>
          <MDButton variant="gradient" color="info" style={{ marginTop: 5 }} onClick={() => {}}>
            <Icon sx={{ fontWeight: "bold" }}>add</Icon>
            &nbsp;add new
          </MDButton>
        </MDBox>
      </MDBox>
    </Card>
    <Card style={{ backgroundColor: colors.grey[300], width: "100%", marginTop: 30 }}>
      <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
        <MDBox
          color="white"
          bgColor="dark"
          variant="gradient"
          borderRadius="xl"
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="1.5rem"
          pl={2}
          pr={2}
          mt={-3}
        >
          <MDTypography variant="body2" color="white" fontSize="xs">
            Goals
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox p={3}>
        <MDTypography variant="body2" fontSize="xs">
          Set some goals to arrive at a resolution. You can evaluate the set goals on the next
          evaluation.
        </MDTypography>
        <Skeleton width="100%" height="40px" dark margin="5px" borderRadius={8} />
        <Skeleton width="100%" height="40px" dark margin="5px" borderRadius={8} />
        <Skeleton width="100%" height="40px" dark margin="5px" borderRadius={8} />
        <MDBox style={{ borderTop: `1px solid ${colors.grey[400]}`, marginTop: 10, paddingTop: 5 }}>
          <MDButton variant="gradient" color="info" style={{ marginTop: 5 }} onClick={() => {}}>
            <Icon sx={{ fontWeight: "bold" }}>add</Icon>
            &nbsp;add new
          </MDButton>
        </MDBox>
      </MDBox>
    </Card>
  </>
);

export default BarriersAndGoalsSkeleton;
