import { useNavigate } from "react-router-dom";

function Unauthorized() {
  const navigate = useNavigate();

  const goBack = () => navigate(-1);

  return (
    <section style={{ marginLeft: 500 }}>
      <h1>Unauthorized</h1>
      <br />
      <p>You do not have access to the requested page.</p>
      <div className="flexGrow">
        <button type="button" onClick={goBack}>
          Go Back
        </button>
      </div>
    </section>
  );
}

export default Unauthorized;
