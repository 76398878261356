import PropTypes from "prop-types";

import MDBox from "components/MDBox";
import Skeleton from "components/skeletons/Skeleton";
import MDBadge from "components/MDBadge";

function ResidentStatusCell({ value, isLoading }) {
  if (isLoading) {
    return (
      <MDBox alignItems="left" display="flex">
        <Skeleton width={30} height={16} />
      </MDBox>
    );
  }
  return (
    <MDBox alignItems="left" display="flex">
      <MDBadge
        size="xs"
        badgeContent={value}
        container
        color={value === "ACTIVE" ? "success" : "warning"}
      />
    </MDBox>
  );
}

// Typechecking props for the CustomerCell
ResidentStatusCell.propTypes = {
  value: PropTypes.string.isRequired,
};

export default ResidentStatusCell;
