/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable react/no-array-index-key */
import { Card, FormHelperText, Icon } from "@mui/material";
import colors from "assets/theme/base/colors";
import MDBox from "components/MDBox";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import ConfirmDeleteModal from "components/modal/ConfirmDeleteModal";
import useModal from "hooks/useModal";
import { useEffect, useRef } from "react";
import Question from "./Question";

const Section = ({ children, isLoading, isDragging, sectionIndex, data, setData }) => {
  const { openModal } = useModal();

  const ref = useRef();

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const grid = 1;

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 20px ${grid}px 20px`,
    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? colors.grey[400] : null,
    padding: grid,
  });

  const items = data[sectionIndex].questions;

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    data[sectionIndex].questions = reorder(items, result.source.index, result.destination.index);
    setData([...data]);
  };

  const newQuestion = () => {
    data[sectionIndex].noQuestionError = false;
    data[sectionIndex].questions.push({ id: Math.random() });
    setData([...data]);
  };

  const handleChange = (val) => {
    data[sectionIndex].titleError = false;
    data[sectionIndex].name = val;
    setData([...data]);
  };

  const deleteCallback = async () => {
    data.splice(sectionIndex, 1);
    setData([...data]);
    return "success";
  };

  const deleteMe = () => {
    openModal(
      <ConfirmDeleteModal
        title="Delete Section?"
        text="Do you really want to delete this section?"
        successText="The selected section has been successfully deleted."
        callback={deleteCallback}
      />,
      () => {}
    );
  };

  const isError =
    data[sectionIndex].titleError ||
    data[sectionIndex].noQuestionError ||
    data[sectionIndex].blankQuestionError;

  useEffect(() => {
    if (data[sectionIndex].focus) {
      console.log(sectionIndex);
      ref.current.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
    }
  }, [data]);

  return (
    <MDBox
      ref={ref}
      style={{
        backgroundColor: isDragging ? colors.grey[200] : colors.grey[300],
        width: "100%",
        marginBottom: 20,
        paddingBottom: 10,
      }}
      borderRadius="xl"
      coloredShadow={isError ? "error" : null}
      border={isError ? "1px solid" : null}
      borderColor={isError ? "red" : null}
    >
      <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
        {!isLoading && (
          <MDBox
            color="white"
            borderRadius="md"
            display="flex"
            height="2.5rem"
            px={2}
            mt={-3}
            mb={1}
            width="100%"
            style={{ background: isDragging ? "#4C6693" : "#344767" }}
            justifyContent="space-between"
          >
            <MDBox pt={0.5}>
              <MDInput
                variant="standard"
                placeholder="Enter a section title..."
                inputProps={{
                  style: { color: "white", background: isDragging ? "#4C6693" : "#344767" },
                }}
                sx={{ width: 400 }}
                value={data[sectionIndex].name}
                onChange={(event) => handleChange(event.target.value)}
              />
            </MDBox>
            <MDBox display="flex">
              <MDButton
                variant="text"
                color="secondary"
                iconOnly
                circular
                onClick={deleteMe}
                isLoading={isLoading}
              >
                <Icon>delete</Icon>
              </MDButton>
              {children}
            </MDBox>
          </MDBox>
        )}
      </MDBox>
      <MDBox>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {items.map((item, index) => (
                  <Draggable key={item.id} draggableId={`id-${item.id}`} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                      >
                        <Question
                          isDragging={snapshot.isDragging}
                          sectionIndex={sectionIndex}
                          setData={setData}
                          questionIndex={index}
                          data={data}
                        >
                          <div {...provided.dragHandleProps}>
                            <Icon
                              fontSize="default"
                              style={{ color: colors.grey[600], marginTop: 8 }}
                            >
                              drag_indicator
                            </Icon>
                          </div>
                        </Question>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </MDBox>
      {data[sectionIndex].questions.length === 0 && (
        <MDBox px={4} display="flex" justifyContent="flex-start" alignItems="flex-start">
          <MDTypography variant="body2" color="text" fontSize="xs">
            Start by adding a new question.
          </MDTypography>
        </MDBox>
      )}
      <MDBox px={3} pt={1}>
        <MDButton
          variant="gradient"
          color="info"
          width={100}
          onClick={newQuestion}
          style={{ marginBottom: isError ? 10 : 0 }}
        >
          New Question&nbsp;
          <Icon sx={{ fontWeight: "bold" }}>add</Icon>
        </MDButton>

        <FormHelperText error>
          {data[sectionIndex].titleError ? "* A title is required for the section." : ""}
        </FormHelperText>
        <FormHelperText error>
          {data[sectionIndex].noQuestionError
            ? "* At least one survey question is required for a section."
            : ""}
        </FormHelperText>
        <FormHelperText error>
          {data[sectionIndex].blankQuestionError ? "* A survey question cannot be blank." : ""}
        </FormHelperText>
      </MDBox>
    </MDBox>
  );
};

export default Section;
