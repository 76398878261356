import { Autocomplete, Grid, TextField } from "@mui/material";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import colors from "assets/theme/base/colors";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import useModal from "hooks/useModal";
import { useEffect, useRef, useState } from "react";
import useBackendError from "hooks/useBackendError";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import MDBox from "components/MDBox";
import { pdf } from "@react-pdf/renderer";
import FileSaver from "file-saver";
import SatisfactionSurvey from "pages/satisfaction/print/SatisfactionSurvey";
import useFacility from "hooks/useFacility";

const PrintSatisfactionSurveyModal = () => {
  const { closeModal } = useModal();
  const [templateInputValue, setTemplateInputValue] = useState("");
  const { setError } = useBackendError();
  const axios = useAxiosPrivate();
  const [template, setTemplate] = useState();
  const [templatesError, setTemplatesError] = useState(false);
  const templateRef = useRef();
  const [templates, setTemplates] = useState([]);
  const [printing, setPrinting] = useState(false);
  const { facility } = useFacility();
  const myPdf = pdf([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get("/satisfaction/templates", {
          headers: { "Content-Type": "application/json" },
        });
        setTemplates(response.data);
      } catch (err) {
        if (!err?.response) {
          setError("Failed to fetch the list of templates. Please try again");
        }
      }
    }
    fetchData();
  }, [axios, setError]);

  const tryPrint = async () => {
    if (templateInputValue == null || templateInputValue === "") {
      setTemplatesError(true);
      templateRef.current.focus();
      return;
    }
    try {
      setPrinting(true);
      const response = await axios.post(`/satisfaction/template?id=${template.id}`, {
        headers: { "Content-Type": "application/json" },
      });

      myPdf.updateContainer(
        <SatisfactionSurvey
          data={response.data}
          filename={template.label}
          facility={facility.name}
        />
      );
      const blob = await myPdf.toBlob();
      FileSaver.saveAs(blob, `${template.label.replace(/ /g, "_")}.pdf`);
    } catch (err) {
      console.log(template);
      setError("Failed to print. Please try again");
    } finally {
      setPrinting(false);
    }
    closeModal({
      action: "print",
    });
  };
  const templateList =
    templates != null
      ? templates.map((temp) => ({
          label: temp.name,
          id: temp.id,
        }))
      : [];

  return (
    <div style={{ maxWidth: 600 }}>
      <MDBox display="flex" flexDirection="column" textAlign="center">
        <MDTypography
          display="inline"
          variant="h6"
          textTransform="capitalize"
          fontWeight="bold"
          textAlign="center"
        >
          Print Survey Form
        </MDTypography>
      </MDBox>
      <MDBox px={2}>
        <MDTypography display="inline" variant="body2">
          Please select the survey template that you want to print.
        </MDTypography>
      </MDBox>
      <MDBox px={4} pb={2} pt={1}>
        <Autocomplete
          isOptionEqualToValue={(option, value) => option.id === value.id}
          value={template || null}
          onChange={(event, newValue) => {
            setTemplatesError(false);
            setTemplate(newValue);
          }}
          inputValue={templateInputValue}
          onInputChange={(event, newInputValue) => {
            setTemplateInputValue(newInputValue);
          }}
          options={templateList}
          renderInput={(params) => (
            <TextField
              inputRef={templateRef}
              error={templatesError}
              helperText={templatesError ? "*Template is required" : ""}
              {...params}
              placeholder="Enter or select a Template..."
            />
          )}
          renderOption={(props, option, { inputValue }) => {
            const matches = match(option.label, inputValue, { insideWords: true });
            const parts = parse(option.label, matches);

            const bgColor = inputValue === option.label ? colors.grey[200] : null;
            return (
              <li {...props} style={{ backgroundColor: bgColor }}>
                <MDBox display="flex">
                  {parts.map((part) => (
                    <span
                      key={part.text + new Date().getTime()}
                      style={{
                        fontWeight: part.highlight ? 700 : 400,
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      {part.text}
                    </span>
                  ))}
                </MDBox>
              </li>
            );
          }}
        />
      </MDBox>
      <MDBox
        px={2}
        pb={2}
        display="flex"
        textAlign="center"
        alignItems="center"
        justifyContent="center"
      >
        <MDBox mr={1}>
          <MDButton
            onClick={() => closeModal({ action: "cancel" })}
            variant="gradient"
            color="light"
            size="medium"
            m={1}
          >
            cancel
          </MDButton>
        </MDBox>
        <MDBox mr={1}>
          <MDButton
            onClick={tryPrint}
            variant="gradient"
            color="primary"
            size="medium"
            loading={printing}
            loadingText="printing..."
          >
            print
          </MDButton>
        </MDBox>
      </MDBox>
    </div>
  );
};

export default PrintSatisfactionSurveyModal;
