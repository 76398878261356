/* eslint-disable react/no-array-index-key */
import { Card } from "@mui/material";
import colors from "assets/theme/base/colors";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import Skeleton from "components/skeletons/Skeleton";
import Question from "./Question";

const Section = ({ data, sectionIndex, setData, isLoading, action }) => {
  const section = data.template.sections[sectionIndex];
  return (
    <Card
      id={`section-${sectionIndex}`}
      style={{ backgroundColor: colors.grey[300], width: "100%", marginBottom: 30 }}
    >
      <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
        {!isLoading && (
          <MDBox
            color="white"
            bgColor="dark"
            variant="gradient"
            borderRadius="xl"
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="1.5rem"
            pl={2}
            pr={2}
            mt={-3}
          >
            <MDTypography variant="body2" color="white" fontSize="xs">
              {section.name}
            </MDTypography>
          </MDBox>
        )}
        {isLoading && (
          <MDBox
            color="white"
            bgColor="dark"
            variant="gradient"
            borderRadius="xl"
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="1.5rem"
            pl={2}
            pr={2}
            mt={-3}
          >
            <Skeleton width={Math.floor(Math.random() * 60 + 80)} height={18} dark />
          </MDBox>
        )}
      </MDBox>

      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        px={2}
        py={2}
      >
        {section.questions.map((question, questionIndex) => (
          <Question
            data={data}
            sectionIndex={sectionIndex}
            questionIndex={questionIndex}
            setData={setData}
            isLoading={isLoading}
            key={`${sectionIndex}-${questionIndex}`}
            action={action}
          />
        ))}
      </MDBox>
    </Card>
  );
};

export default Section;
