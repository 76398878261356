/* eslint-disable react/no-array-index-key */
import { Grid, Select, MenuItem } from "@mui/material";
import colors from "assets/theme/base/colors";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import MDTypography from "components/MDTypography";

import useModal from "hooks/useModal";
import { useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { pdf } from "@react-pdf/renderer";
import MockSurvey from "pages/compliance/print/MockSurvey";
import * as FileSaver from "file-saver";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import useBackendError from "hooks/useBackendError";
import useFacility from "hooks/useFacility";

const PrintModal = ({ type, title, data }) => {
  const { closeModal } = useModal();
  const [docData, setDocData] = useState(data);
  const [allDownload, setAllDownload] = useState(false);
  const changeable = type == null;
  const [fileType, setFileType] = useState(type ?? "mockSurvey");
  const axios = useAxiosPrivate();
  const { setError } = useBackendError();
  const myPdf = pdf([]);
  const { facility } = useFacility();

  const printPdf = async (isPrintAll, id, filename) => {
    if (type === "mockSurvey") {
      try {
        const response = await axios.get(`/compliance/survey/questions?surveyId=${id}`, {
          headers: { "Content-Type": "application/json" },
        });

        myPdf.updateContainer(
          <MockSurvey data={response.data} filename={filename} facility={facility.name} />
        );
        const blob = await myPdf.toBlob();
        FileSaver.saveAs(blob, `${filename.replace(/ /g, "_")}.pdf`);
      } catch (err) {
        console.error(err);
        setError("Failed to fetch from the backend. Please try again");
      }
    }
  };

  const print = async (isPrintAll, id, filename) => {
    if (isPrintAll) {
      setAllDownload(true);
      await printPdf(isPrintAll, id, filename);
      setAllDownload(false);
    } else {
      let index = 0;
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < data.length; i++) {
        if (data[i].id === id) {
          index = i;
          break;
        }
      }

      const tmpData = [...data];
      tmpData[index].isLoading = true;
      setDocData(tmpData);
      await printPdf(isPrintAll, id, filename);

      const newTempData = [...tmpData];
      newTempData[index].isLoading = false;
      setDocData(newTempData);
    }
  };

  return (
    <MDBox width={{ xs: "100%", sm: 600 }}>
      <MDBox display="flex" justifyContent="center" alignItems="center" flexDirection="column">
        <MDTypography display="inline" variant="h6" textTransform="capitalize" fontWeight="bold">
          {title != null ? title : "Print PDFs"}
        </MDTypography>
        {changeable && (
          <Grid container spacing={1} mb={1} mt={1}>
            <Grid item xs={6} textAlign="right">
              <MDTypography display="inline" variant="body2">
                Select Document type
              </MDTypography>
            </Grid>
            <Grid item xs={6} textAlign="left">
              <Select
                value={fileType}
                labelId="demo-simple-select-label"
                onChange={(event) => {
                  setFileType(event.target.value);
                }}
                style={{ height: 40, width: 200, marginRight: 10 }}
              >
                <MenuItem value="mockSurvey">PDFs for Mock Survey</MenuItem>
                <MenuItem value="anotherExample">Another Example</MenuItem>
              </Select>
            </Grid>
          </Grid>
        )}
        <Scrollbars
          style={{
            maxHeight: "calc(100vh - 150px)",
            minHeight: 200,
            backgroundColor: colors.grey[100],
            borderRadius: 8,
            marginTop: 20,
          }}
          renderTrackHorizontal={({ style, ...props }) => <div />}
          renderTrackVertical={({ style, ...props }) => (
            <div className="scrollbar-regular-vertical-track" {...props} />
          )}
          renderThumbVertical={({ style, ...props }) => (
            <div
              style={{
                ...style,
                backgroundColor: "rgba(0, 0, 0, 0.2)",
                borderRadius: 8,
                width: 6,
                cursor: "pointer",
              }}
              {...props}
            />
          )}
        >
          {docData.map((val, index) => (
            <div key={`${index}-div`}>
              <Grid container spacing={1} mb={1} mt={1} key={`${index}-grid`}>
                <Grid item xs={9} textAlign="right">
                  <MDTypography display="inline" variant="body2">
                    {val.name}
                  </MDTypography>
                </Grid>
                <Grid item xs={3} textAlign="left">
                  <MDButton
                    onClick={() => print(false, val.id, val.name)}
                    variant="outlined"
                    color="info"
                    size="small"
                    loading={val.isLoading}
                    loadingText="loading..."
                    loadingColor="info"
                  >
                    download pdf
                  </MDButton>
                </Grid>
              </Grid>
              <hr key={`${index}-hr`} style={{ borderTop: "1px", borderColor: colors.grey[300] }} />
            </div>
          ))}
        </Scrollbars>

        <Grid container spacing={1} mb={1} mt={1}>
          <Grid item xs={7} textAlign="right">
            <MDButton
              variant="gradient"
              color="info"
              size="medium"
              mr={1}
              loading={allDownload}
              loadingText="loading..."
              onClick={() => print(true, null, "All")}
            >
              download all
            </MDButton>
          </Grid>
          <Grid item xs={5} textAlign="left">
            <MDButton
              onClick={() => closeModal({ action: "done" })}
              variant="gradient"
              color="light"
              size="medium"
            >
              done
            </MDButton>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
};

export default PrintModal;
