import { CircularProgress, Grid, Skeleton } from "@mui/material";
import colors from "assets/theme/base/colors";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import StarRating from "components/StarRating/StarRating";

const StickyStarRating = (params) => {
  const { isLoading, scoreData } = params;

  return (
    <MDBox
      justifyContent="center"
      alignItems="center"
      style={{
        minWidth: 460,
        width: "50%",
      }}
    >
      <Grid container alignItems="center">
        <Grid item xs={7} textAlign="right">
          {isLoading && (
            <MDTypography variant="body" fontWeight="bold" color="text" mr={1}>
              Overall Star Rating (
              <CircularProgress color="grey" style={{ height: 14, width: 14 }} /> stars)
            </MDTypography>
          )}
          {!isLoading && (
            <MDTypography variant="body" fontWeight="bold" color="text" mr={1}>
              Overall Star Rating ({scoreData?.overallRating}{" "}
              {scoreData?.overallRating === 1 ? "star" : "stars"})
            </MDTypography>
          )}
        </Grid>
        <Grid item xs={5} pt={1}>
          <MDBox display="flex">
            {isLoading && <Skeleton width={160} height={24} margin={4} />}
            {!isLoading && (
              <StarRating
                rating={scoreData?.overallRating}
                fontSize="large"
                color={colors.warning.main}
              />
            )}
          </MDBox>
        </Grid>
      </Grid>

      <Grid container alignItems="center">
        <Grid item xs={7} textAlign="right">
          {isLoading && (
            <MDTypography variant="body" color="text" mr={1} style={{ fontSize: 15 }}>
              Health Inspection Rating (
              <CircularProgress color="grey" style={{ height: 10, width: 10 }} /> stars)
            </MDTypography>
          )}
          {!isLoading && (
            <MDTypography variant="body" color="text" mr={1} style={{ fontSize: 15 }}>
              Health Inspection Rating ({scoreData?.healthInspectionRating}{" "}
              {scoreData?.healthInspectionRating === 1 ? "star" : "stars"})
            </MDTypography>
          )}
        </Grid>
        <Grid item xs={5} pt={1}>
          <MDBox display="flex">
            {isLoading && <Skeleton width={112} height={16} margin={4} />}
            {!isLoading && (
              <MDTypography variant="body2" color="text">
                <StarRating
                  rating={scoreData?.healthInspectionRating}
                  fontSize="medium"
                  color={colors.warning.main}
                />
              </MDTypography>
            )}
          </MDBox>
        </Grid>
      </Grid>

      <Grid container alignItems="center">
        <Grid item xs={7} textAlign="right">
          {isLoading && (
            <MDTypography variant="body" color="text" mr={1} style={{ fontSize: 15 }}>
              Staff Rating (
              <CircularProgress color="grey" style={{ height: 10, width: 10 }} /> stars)
            </MDTypography>
          )}
          {!isLoading && (
            <MDTypography variant="body" color="text" mr={1} style={{ fontSize: 15 }}>
              Staff Rating ({scoreData?.staffRating}{" "}
              {scoreData?.staffRating === 1 ? "star" : "stars"})
            </MDTypography>
          )}
        </Grid>
        <Grid item xs={5} pt={1}>
          <MDBox display="flex">
            {isLoading && <Skeleton width={112} height={16} margin={4} />}
            {!isLoading && (
              <MDTypography variant="body2" color="text">
                <StarRating
                  rating={scoreData?.staffRating}
                  fontSize="medium"
                  color={colors.warning.main}
                />
              </MDTypography>
            )}
          </MDBox>
        </Grid>
      </Grid>

      <Grid container alignItems="center">
        <Grid item xs={7} textAlign="right">
          {isLoading && (
            <MDTypography variant="body" color="text" mr={1} style={{ fontSize: 15 }}>
              Quality Measures Rating (
              <CircularProgress color="grey" style={{ height: 10, width: 10 }} /> stars)
            </MDTypography>
          )}
          {!isLoading && (
            <MDTypography variant="body" color="text" mr={1} style={{ fontSize: 15 }}>
              Quality Measures Rating ({scoreData?.qualityMeasuresRating}{" "}
              {scoreData?.qualityMeasuresRating === 1 ? "star" : "stars"})
            </MDTypography>
          )}
        </Grid>
        <Grid item xs={5} pt={1}>
          <MDBox display="flex">
            {isLoading && <Skeleton width={112} height={16} margin={4} />}
            {!isLoading && (
              <MDTypography variant="body2" color="text">
                <StarRating
                  rating={scoreData?.qualityMeasuresRating}
                  fontSize="medium"
                  color={colors.warning.main}
                />
              </MDTypography>
            )}
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
  );
};

export default StickyStarRating;
