import MDBox from "components/MDBox";

import { deepOrange } from "@mui/material/colors";
import { Grid } from "@mui/material";
import colors from "assets/theme/base/colors";
import MDAvatar from "./MDAvatar";
import MDTypography from "./MDTypography";
import MDButton from "./MDButton";

const Resident = ({ resident }) => {
  const firstLetter = (str) => str?.charAt(0)?.toUpperCase();
  return (
    <MDBox p={1} style={{ borderBottom: `1px solid ${colors.grey[200]}` }}>
      <Grid container>
        <Grid item xs={6}>
          <MDBox display="flex" alignItems="center">
            <MDAvatar sx={{ bgcolor: deepOrange[500] }} alt={resident.fullName} src={resident.url}>
              {firstLetter(resident.firstName)}
              {resident.lastName != null
                ? firstLetter(resident.lastName)
                : firstLetter(resident.middleName)}
            </MDAvatar>
            <MDTypography variant="body2" color="dark" fontSize="xs" ml={1}>
              {resident.fullName}
            </MDTypography>
          </MDBox>
        </Grid>
        <Grid item xs={2} py={1.5}>
          {resident.room != null && resident.room !== "" && (
            <MDTypography variant="body2" color="text" fontSize="xs" style={{ fontSize: 14 }}>
              Room: {resident.room}
            </MDTypography>
          )}
        </Grid>
        <Grid item xs={4}>
          <MDBox display="flex" justifyContent="flex-end" mr={2}>
            <MDButton variant="gradient" color="info" onClick={() => {}}>
              View Resident
            </MDButton>
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
  );
};

export default Resident;
