import { Grid, Icon, MenuItem, Select, TextField } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import MDTypography from "components/MDTypography";
import dayjs from "dayjs";
import useAuth from "hooks/useAuth";
import useModal from "hooks/useModal";
import { useState } from "react";

const StartFivestarModal = () => {
  const { auth } = useAuth();
  const { closeModal } = useModal();

  const [selectionType, setSelectionType] = useState(auth.profile.timeline.type);
  const [quarter, setQuarter] = useState(auth.profile.timeline.quarter);

  const years = [2023, 2022, 2021];
  const [year, setYear] = useState(auth.profile.timeline.year);

  const [startDate, setStartDate] = useState(
    dayjs.unix(auth.profile.timeline.startDate).format("MM/DD/YYYY")
  );
  const [endDate, setEndDate] = useState(
    dayjs.unix(auth.profile.timeline.endDate).format("MM/DD/YYYY")
  );

  return (
    <div>
      <MDBox display="flex" justifyContent="center">
        <MDTypography display="inline" variant="h6" textTransform="capitalize" fontWeight="bold">
          Five Star Rating
        </MDTypography>
      </MDBox>
      <MDBox display="flex" justifyContent="center" m={2}>
        <MDTypography display="inline" variant="body2" textAlign="center">
          Please select the timeline for which you want to do the Five Star rating. <br /> Typically
          these are calculated for a quarter.
        </MDTypography>
      </MDBox>
      <Grid container spacing={1} mb={1} mt={1}>
        <Grid item xs={4} textAlign="right">
          <MDTypography display="inline" variant="body2" textTransform="capitalize">
            For
          </MDTypography>
        </Grid>
        <Grid item xs={8} textAlign="left">
          <Select
            value={selectionType}
            labelId="demo-simple-select-label"
            onChange={(event) => {
              setSelectionType(event.target.value);
            }}
            style={{ height: 40, width: 200, marginRight: 10 }}
          >
            <MenuItem value="quarter">Quarter</MenuItem>
            <MenuItem value="range">Custom Date Range</MenuItem>
          </Select>
        </Grid>
      </Grid>
      {selectionType === "quarter" ? (
        <>
          <Grid container spacing={1} mb={1}>
            <Grid item xs={4} textAlign="right">
              <MDTypography display="inline" variant="body2" textTransform="capitalize">
                Quarter
              </MDTypography>
            </Grid>
            <Grid item xs={8} textAlign="left">
              <Select
                value={quarter}
                labelId="demo-simple-select-label"
                onChange={(event) => {
                  setQuarter(event.target.value);
                }}
                style={{ height: 40, width: 200, marginRight: 10 }}
              >
                <MenuItem value="1">Q1 (Jan - Mar)</MenuItem>
                <MenuItem value="2">Q2 (Apr - Jun)</MenuItem>
                <MenuItem value="3">Q3 (Jul - Sep)</MenuItem>
                <MenuItem value="4">Q4 (Oct - Dec)</MenuItem>
              </Select>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={4} textAlign="right">
              <MDTypography display="inline" variant="body2" textTransform="capitalize">
                Year
              </MDTypography>
            </Grid>
            <Grid item xs={8} textAlign="left">
              <Select
                value={year}
                labelId="demo-simple-select-label"
                onChange={(event) => {
                  setYear(event.target.value);
                }}
                style={{ height: 40, width: 200, marginRight: 10 }}
              >
                {years.map((yr) => (
                  <MenuItem key={yr} value={yr}>
                    {yr}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        </>
      ) : (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Grid container spacing={1} mb={1}>
            <Grid item xs={4} textAlign="right">
              <MDTypography display="inline" variant="body2" textTransform="capitalize">
                Start Date
              </MDTypography>
            </Grid>
            <Grid item xs={8} textAlign="left">
              <DesktopDatePicker
                value={startDate}
                onChange={(newValue) => {
                  setStartDate(newValue);
                }}
                maxDate={endDate}
                renderInput={(params) => <TextField {...params} style={{ width: 200 }} />}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={4} textAlign="right">
              <MDTypography display="inline" variant="body2" textTransform="capitalize">
                End Date
              </MDTypography>
            </Grid>
            <Grid item xs={8} textAlign="left">
              <DesktopDatePicker
                value={endDate}
                onChange={(newValue) => {
                  setEndDate(newValue);
                }}
                minDate={startDate}
                renderInput={(params) => <TextField {...params} style={{ width: 200 }} />}
              />
            </Grid>
          </Grid>
        </LocalizationProvider>
      )}
      <Grid container spacing={1} mb={1} mt={2}>
        <Grid item xs={6} textAlign="right">
          <MDButton
            onClick={() => closeModal({ action: "cancel" })}
            variant="gradient"
            color="light"
            size="medium"
          >
            cancel
          </MDButton>
        </Grid>
        <Grid item xs={6} textAlign="left">
          <MDButton
            onClick={() =>
              closeModal({
                action: "update",
                type: selectionType,
                startDate: Math.floor(new Date(startDate).getTime() / 1000),
                endDate: Math.floor(new Date(endDate).getTime() / 1000),
                quarter,
                year,
              })
            }
            variant="gradient"
            color="info"
            size="medium"
          >
            continue&nbsp;<Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
          </MDButton>
        </Grid>
      </Grid>
    </div>
  );
};

export default StartFivestarModal;
