import Grid from "@mui/material/Grid";

import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import { Card, Icon } from "@mui/material";
import MDButton from "components/MDButton";

import useModal from "hooks/useModal";
import StartSatisfactionSurveyModal from "components/modal/StartSatisfactionSurveyModal";
import { useNavigate } from "react-router-dom";

import EmailSatisfactionSurveyModal from "components/modal/EmailSatisfactionSurveyModal";
import ConfirmModal from "components/modal/ConfirmModal";
import colors from "assets/theme/base/colors";
import PrintSatisfactionSurveyModal from "components/modal/PrintSatisfactionSurveyModal";

import EmailSurveys from "./EmailSurveys";
import PastSurveys from "./PastSurveys";
import SatisfactionScore from "./SatisfactionScore";

const SatisfactionOverview = () => {
  const navigate = useNavigate();

  const { openModal } = useModal();

  const startNew = (returnVal) => {
    if (returnVal.action === "start") {
      navigate(`/resident-satisfaction/survey`, {
        state: {
          resident: returnVal.resident,
          template: returnVal.template,
          participant: returnVal.participant,
          type: returnVal.type,
          action: "edit",
        },
      });
    }
  };

  const emailNew = (returnVal) => {
    if (returnVal.action === "send") {
      let html = `<p>An email with the survey link and instructions has been sent to <span style='color: ${colors.primary.main}'>${returnVal.email}</span>.`;
      if (returnVal.notify) {
        html += " <br>You will be notified once they complete the survey.</p>";
      }
      setTimeout(() => {
        openModal(
          <ConfirmModal buttonLabels={["okay"]} icon="success" htmlText={html} />,
          () => {}
        );
      }, 100);
    }
  };

  const handlePrint = () => {
    openModal(<PrintSatisfactionSurveyModal />, () => {});
  };
  const handleEmail = () => {
    openModal(<EmailSatisfactionSurveyModal />, emailNew);
  };
  const handleSurvey = () => {
    openModal(<StartSatisfactionSurveyModal />, startNew);
  };

  const handleTemplates = () => {
    navigate(`/resident-satisfaction/templates`);
  };

  return (
    <Grid container spacing={3} mb={3}>
      <Grid item lg={7} xs={12}>
        <Card id="compliance-overview">
          <MDBox pt={2} px={2} display="flex" justifyContent="left" alignItems="center">
            <MDBox
              color="white"
              bgColor="info"
              variant="gradient"
              borderRadius="lg"
              shadow="lg"
              display="flex"
              justifyContent="center"
              alignItems="center"
              py={1}
              px={2}
              mt={-4}
              mb={3}
            >
              <MDTypography variant="h6" fontWeight="medium" color="white">
                Resident Satisfaction
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDBox p={2} fontSize="1rem" style={{ textAlign: "center", width: "100%" }}>
            Take a new survey now, or email a survey link to the resident. <br />
            You can also print the offline survey forms now and enter them later.
          </MDBox>
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexWrap="wrap"
            pt={2}
            pb={6}
          >
            <MDButton
              variant="gradient"
              color="light"
              style={{ margin: 5 }}
              onClick={handleTemplates}
            >
              <Icon sx={{ fontWeight: "bold" }}>settings</Icon>
              &nbsp; Templates
            </MDButton>
            <MDButton variant="gradient" color="light" style={{ margin: 5 }} onClick={handlePrint}>
              <Icon sx={{ fontWeight: "bold" }}>print</Icon>
              &nbsp;print form
            </MDButton>

            <MDButton variant="gradient" color="info" onClick={handleEmail} size="medium">
              <Icon sx={{ fontWeight: "bold" }}>email</Icon> &nbsp; email survey
            </MDButton>

            <MDButton
              variant="gradient"
              color="primary"
              style={{ margin: 5 }}
              loadingText="submitting..."
              onClick={handleSurvey}
            >
              <Icon sx={{ fontWeight: "bold" }}>print</Icon>
              &nbsp;take survey
            </MDButton>
          </MDBox>
        </Card>
      </Grid>
      <Grid item xs={12} lg={5}>
        <SatisfactionScore />
      </Grid>

      <Grid item xs={12}>
        <EmailSurveys />
        <PastSurveys />
      </Grid>
    </Grid>
  );
};
export default SatisfactionOverview;
