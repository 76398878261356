import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import Skeleton from "components/skeletons/Skeleton";
import MDBadgeDot from "components/MDBadgeDot";
import { Icon } from "@mui/material";
import colors from "assets/theme/base/colors";
import dayjs from "dayjs";
import Marquee from "components/Marquee";

const relativeTime = require("dayjs/plugin/relativeTime");

function User({ isLoading, user, showUsername, width = 120 }) {
  const isOnline = user.lastOnline >= Date.now() - 1000 * 60 * 2;
  dayjs.extend(relativeTime);

  const ago = dayjs(dayjs.unix(user.lastOnline)).fromNow();
  return (
    <MDBox display="flex" alignItems="center">
      <MDBox mr={1}>
        <MDAvatar bgColor="light" src={user.url} alt={user.fullName} size="sm" />
      </MDBox>
      {isLoading && (
        <MDBox>
          <Skeleton width={width} height={16} />
          <Skeleton width={100} height={12} />
        </MDBox>
      )}
      {!isLoading && (
        <MDBox mt={1}>
          <MDBox sx={{ height: 20, marginBottom: -1.5, marginTop: -0.5 }} display="flex">
            <Marquee
              text={user.fullName}
              width={width}
              fontWeight="regular"
              underlineOnHover={false}
              color="dark"
              fontSize={13}
            />
          </MDBox>
          {showUsername && (
            <MDBox pt={1} display="flex">
              <MDTypography sx={{ fontSize: 11, color: colors.grey[500] }}>
                {user.username}
              </MDTypography>
            </MDBox>
          )}
          {!showUsername && isOnline && (
            <MDBox pt={0.6}>
              <MDBadgeDot badgeContent="online" color="success" font={{ color: "text" }} />
            </MDBox>
          )}
          {!showUsername && !isOnline && (
            <MDBox pt={1} display="flex">
              <Icon sx={{ color: colors.grey[500], marginTop: 0.5, fontSize: "12px !important" }}>
                access_time
              </Icon>
              <MDTypography color="text" sx={{ fontSize: 12, marginLeft: 0.4 }}>
                {ago}
              </MDTypography>
            </MDBox>
          )}
        </MDBox>
      )}
    </MDBox>
  );
}

export default User;
