/* eslint-disable react/no-array-index-key */
import MDBox from "components/MDBox";
import InspectionSeries from "./InspectionSeries";

const Inspections = ({ data, isLoading, saveInspections, isComplaint }) => (
  <MDBox>
    {data.map((inspections, index) => (
      <InspectionSeries
        key={index}
        inspectionIndex={index}
        isLoading={isLoading}
        inspections={data}
        saveInspections={saveInspections}
        isComplaint={isComplaint}
      />
    ))}
  </MDBox>
);

export default Inspections;
