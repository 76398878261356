/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */

import MDBox from "components/MDBox";
import { Card } from "@mui/material";
import Icon from "@mui/material/Icon";
import colors from "assets/theme/base/colors";
import { useEffect, useRef, useState } from "react";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Scrollbars from "react-custom-scrollbars";
import Inspections from "components/StarRating/Inspections";
import InspectionModal from "components/modal/InspectionModal";
import useModal from "hooks/useModal";
import useBackendError from "hooks/useBackendError";
import useAxiosPrivate from "hooks/useAxiosPrivate";

const HealthInspectionsStep = ({ errors, resetError, setCalculationData, calculationData }) => {
  const [isLoadingInspections, setLoadingInsepctions] = useState(true);
  const inspections = useRef([{}, {}, {}]);
  const axios = useAxiosPrivate();
  const { setError } = useBackendError();
  const annualError = errors.find((obj) => obj.key === "annual")?.value;
  const complaintError = null;

  const loadInspectionData = async () => {
    setLoadingInsepctions(true);
    inspections.current = [];

    try {
      const response = await axios.get(
        `/inspections/list?timelineType=${calculationData.timelineType}&startDate=${calculationData.startDate}&endDate=${calculationData.endDate}&quarter=${calculationData.quarter}&year=${calculationData.year}`,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      inspections.current = response.data;
    } catch (err) {
      setError("Failed to fetch from the backend. Please try again");
    } finally {
      calculationData.inspections = inspections.current;
      setCalculationData(calculationData);
      setLoadingInsepctions(false);
    }
  };

  const saveData = async (inspectionsUpdate, inspectionIndex, number) => {
    console.log("saving..");
    setLoadingInsepctions(true);

    /*
    try {
      const response = await axios.get(
        `/inspections/list?type=${timeline.type}&startDate=${timeline.startDate}&endDate=${timeline.endDate}&quarter=${timeline.quarter}&year=${timeline.year}`,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      inspections.current = response.data;
      setItem("inspections", response.data);
    } catch (err) {
      setError("Failed to fetch from the backend. Please try again");
    } finally {
      setLoadingInsepctions(false);
    }
    */

    setLoadingInsepctions(false);
    console.log(inspectionsUpdate);
  };

  const saveInspections = (inspectionsUpdate, inspectionIndex, number) => {
    calculationData.inspections = inspections.current;
    setCalculationData(calculationData);
    saveData(inspectionsUpdate, inspectionIndex, number);
  };

  const { openModal } = useModal();

  const newAnnualInspection = () => {
    inspections.current.push({ revisits: [], fTags: [] });
    openModal(
      <InspectionModal
        isNew
        number={0}
        inspectionIndex={inspections.current.length - 1}
        inspections={inspections.current}
        saveInspections={saveInspections}
        isComplaint={false}
      />,
      () => {}
    );
    resetError("annual");
  };

  const newComplaintInspection = () => {
    inspections.current.push({ revisits: [], fTags: [] });
    openModal(
      <InspectionModal
        isNew
        number={0}
        inspectionIndex={inspections.current.length - 1}
        inspections={inspections.current}
        saveInspections={saveInspections}
        isComplaint
      />,
      () => {}
    );
  };

  const scrollbar = useRef();

  useEffect(() => {
    loadInspectionData();
  }, []);

  return (
    <>
      <MDBox display="flex" justifyContent="center" px={2} pt={2} alignItems="flex-end">
        <MDTypography variant="h6" fontWeight="medium">
          Health Inspections
        </MDTypography>
      </MDBox>
      <Card
        style={{
          backgroundColor: colors.grey[200],
          width: "100%",
          marginTop: 30,
          paddingBottom: 20,
        }}
      >
        <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
          <MDBox
            color="white"
            bgColor="dark"
            variant="gradient"
            borderRadius="xl"
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="1.5rem"
            pl={2}
            pr={2}
            mt={-3}
          >
            <MDTypography variant="body2" color="white" fontSize="xs">
              Annual Health Inspections
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox px={2} pb={1} pt={2}>
          <MDTypography variant="subtitle1" style={{ fontSize: 14, marginLeft: 10 }} color="text">
            Please add details of the <b>latest three</b>{" "}
            <span style={{ color: colors.info.main }}>Annual Health Inspections</span> and{" "}
            <span style={{ color: colors.info.main }}>Revisits</span> for the selected timeframe.
            Details of the available last three has been automatically imported.
          </MDTypography>
        </MDBox>
        <MDBox display="flex" justifyContent="flex-end" px={2} alignItems="flex-end">
          <MDButton variant="gradient" color="info" onClick={newAnnualInspection}>
            <Icon sx={{ fontWeight: "bold" }}>add</Icon> &nbsp; NEW ANNUAL INSPECTION
          </MDButton>
        </MDBox>

        <Scrollbars
          ref={scrollbar}
          style={{
            height:
              !isLoadingInspections && inspections.current != null && inspections.current.length > 1
                ? 222
                : 170,
            backgroundColor: annualError != null ? "#ffd9d9" : colors.grey[400],
            borderRadius: 8,
            marginLeft: 20,
            marginTop: 10,
            width: "calc(100% - 40px)",
            border: `${annualError != null ? 2 : 1}px solid ${
              annualError != null ? colors.error.main : colors.grey[400]
            }`,
          }}
          renderTrackHorizontal={({ style, ...props }) => <div />}
          renderTrackVertical={({ style, ...props }) => (
            <div className="scrollbar-regular-vertical-track" {...props} />
          )}
          renderThumbVertical={({ style, ...props }) => (
            <div
              style={{
                ...style,
                backgroundColor: "rgba(0, 0, 0, 0.4)",
                borderRadius: 8,
                width: 6,
                marginRight: -3,
                cursor: "pointer",
              }}
              {...props}
            />
          )}
        >
          <Inspections
            data={inspections.current.filter((insp) => insp.complaint !== true)}
            isLoading={isLoadingInspections}
            saveInspections={saveInspections}
          />
        </Scrollbars>

        {annualError != null && (
          <MDBox px={3}>
            <MDTypography display="inline" variant="caption" color="error">
              *{annualError}
            </MDTypography>
          </MDBox>
        )}
      </Card>
      <Card
        style={{
          backgroundColor: colors.grey[200],
          width: "100%",
          marginTop: 30,
          paddingBottom: 20,
        }}
      >
        <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
          <MDBox
            color="white"
            bgColor="dark"
            variant="gradient"
            borderRadius="xl"
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="1.5rem"
            pl={2}
            pr={2}
            mt={-3}
          >
            <MDTypography variant="body2" color="white" fontSize="xs">
              Complaint Health Inspections
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox px={2} pb={1} pt={2}>
          <MDTypography variant="subtitle1" style={{ fontSize: 14, marginLeft: 10 }} color="text">
            Please add all the{" "}
            <span style={{ color: colors.info.main }}>Complaint Inspections</span> and{" "}
            <span style={{ color: colors.info.main }}>Revisits</span> that happened in the{" "}
            <b>last 36 months</b> of the selected timeframe. Existing Inspections are automatically
            imported.
          </MDTypography>
        </MDBox>
        <MDBox display="flex" justifyContent="flex-end" px={2} alignItems="flex-end">
          <MDButton variant="gradient" color="info" onClick={newComplaintInspection}>
            <Icon sx={{ fontWeight: "bold" }}>add</Icon> &nbsp; NEW COMPLAINT INSPECTION
          </MDButton>
        </MDBox>

        <Scrollbars
          ref={scrollbar}
          style={{
            height:
              !isLoadingInspections && inspections.current != null && inspections.current.length > 1
                ? 222
                : 170,
            backgroundColor: complaintError != null ? "#ffd9d9" : colors.grey[400],
            borderRadius: 8,
            marginLeft: 20,
            marginTop: 10,
            width: "calc(100% - 40px)",
            border: `${complaintError != null ? 2 : 1}px solid ${
              complaintError != null ? colors.error.main : colors.grey[400]
            }`,
          }}
          renderTrackHorizontal={({ style, ...props }) => <div />}
          renderTrackVertical={({ style, ...props }) => (
            <div className="scrollbar-regular-vertical-track" {...props} />
          )}
          renderThumbVertical={({ style, ...props }) => (
            <div
              style={{
                ...style,
                backgroundColor: "rgba(0, 0, 0, 0.4)",
                borderRadius: 8,
                width: 6,
                marginRight: -3,
                cursor: "pointer",
              }}
              {...props}
            />
          )}
        >
          <Inspections
            data={inspections.current.filter((insp) => insp.complaint === true)}
            isLoading={isLoadingInspections}
            saveInspections={saveInspections}
            isComplaint
          />
        </Scrollbars>
        {complaintError != null && (
          <MDBox px={3}>
            <MDTypography display="inline" variant="caption" color="error">
              *{complaintError}
            </MDTypography>
          </MDBox>
        )}
      </Card>
    </>
  );
};

export default HealthInspectionsStep;
