import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import InspectionSkeleton from "pages/fivestar/overview/skeleton/InspectionSkeleton";
import Inspection from "./Inspection";

const InspectionSeries = ({
  inspectionIndex,
  isLoading,
  inspections,
  saveInspections,
  isComplaint,
}) => {
  const rand = Math.random() < 0.5;
  const data = isLoading ? null : inspections[inspectionIndex];
  if (isLoading) {
    return (
      <MDBox pr={2.5} bgColor="white" borderRadius="lg" m={2} shadow="sm">
        <Grid container>
          <Grid item xs={12} sm={6} xxl={3}>
            <InspectionSkeleton />
          </Grid>
          <Grid item xs={12} sm={6} xxl={3}>
            <InspectionSkeleton />
          </Grid>
          <Grid item xs={12} sm={6} xxl={3}>
            {rand && <InspectionSkeleton />}
            {!rand && <Inspection number={3} isNew />}
          </Grid>
          <Grid item xs={12} sm={6} xxl={3}>
            <Inspection number={3} isNew />
          </Grid>
        </Grid>
      </MDBox>
    );
  }

  return (
    <MDBox pr={2.5} bgColor="white" borderRadius="lg" m={2} shadow="sm">
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} xxl={3}>
          <Inspection
            isNew={false}
            number={0}
            inspectionIndex={inspectionIndex}
            inspections={inspections}
            saveInspections={saveInspections}
            isComplaint={isComplaint}
          />
        </Grid>
        <Grid item xs={12} sm={6} xxl={3}>
          <Inspection
            isNew={data?.revisits?.[0] == null || data?.revisits?.[0].id === null}
            number={1}
            inspectionIndex={inspectionIndex}
            inspections={inspections}
            saveInspections={saveInspections}
          />
        </Grid>
        <Grid item xs={12} sm={6} xxl={3}>
          <Inspection
            isNew={data?.revisits?.[1] == null || data?.revisits?.[1].id === null}
            number={2}
            inspectionIndex={inspectionIndex}
            inspections={inspections}
            saveInspections={saveInspections}
          />
        </Grid>
        <Grid item xs={12} sm={6} xxl={3}>
          <Inspection
            isNew={data?.revisits?.[2] == null || data?.revisits?.[2].id === null}
            number={3}
            inspectionIndex={inspectionIndex}
            inspections={inspections}
            saveInspections={saveInspections}
          />
        </Grid>
      </Grid>
    </MDBox>
  );
};

export default InspectionSeries;
