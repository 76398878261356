import { Icon } from "@mui/material";

import colors from "assets/theme/base/colors";

import TimelineModal from "components/modal/TimelineModal";
import useAuth from "hooks/useAuth";
import dayjs from "dayjs";
import useModal from "hooks/useModal";
import MDTypography from "./MDTypography";

const { grey } = colors;

const Timeline = ({ setTimeline }) => {
  const { auth } = useAuth();
  const { timeline } = auth.profile;

  let startDate;
  let endDate;
  if (timeline.type === "quarter") {
    if (timeline.quarter === 1) {
      startDate = `Jan ${timeline.year}`;
      endDate = `Mar ${timeline.year}`;
    } else if (timeline.quarter === 2) {
      startDate = `Apr ${timeline.year}`;
      endDate = `Jun ${timeline.year}`;
    } else if (timeline.quarter === 3) {
      startDate = `Jul ${timeline.year}`;
      endDate = `Sep ${timeline.year}`;
    } else {
      startDate = `Oct ${timeline.year}`;
      endDate = `Dec ${timeline.year}`;
    }
  } else {
    startDate = dayjs.unix(timeline.startDate).format("MMM DD, YYYY");
    endDate = dayjs.unix(timeline.endDate).format("MMM DD, YYYY");
  }

  const { openModal } = useModal();
  const modalReturnedValue = (result) => {
    if (result.action === "cancel") {
      return;
    }
    const newTimeline = {
      type: result.type,
      startDate: result.startDate,
      endDate: result.endDate,
      quarter: result.quarter,
      year: result.year,
    };

    setTimeline(newTimeline);
  };

  const openModalForTimeline = () => {
    openModal(<TimelineModal />, modalReturnedValue);
  };
  return (
    <MDTypography
      variant="body2"
      fontWeight="regular"
      width={200}
      style={{ fontSize: 14, color: grey[600], cursor: "pointer" }}
      onClick={openModalForTimeline}
    >
      {startDate} - {endDate} {timeline.type === "quarter" && `(Q${timeline.quarter})`}
      <Icon color="inherit" style={{ marginLeft: 8 }}>
        edit
      </Icon>
    </MDTypography>
  );
};

export default Timeline;
