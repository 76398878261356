/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/prop-types */
import { createContext, useState } from "react";

const BackedErrorContext = createContext({});

export const BackendErrorProvider = ({ children }) => {
  const [error, setError] = useState();

  return (
    <BackedErrorContext.Provider value={{ error, setError }}>
      {children}
    </BackedErrorContext.Provider>
  );
};

export default BackedErrorContext;
