import { makeStyles } from "@material-ui/styles";
import colors from "assets/theme/base/colors";

const useStyles = makeStyles({
  link: {
    background: "none",
    border: "none",
    padding: 0,
    font: 14,
    color: colors.info.main,
    textDecoration: "underline",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "none", // Remove underline on hover
      color: colors.info.focus,
    },
  },
});
const MDLink = ({ text, onClick }) => {
  const classes = useStyles();

  return (
    <button display="inline" role="link" type="button" className={classes.link} onClick={onClick}>
      {text}
    </button>
  );
};

export default MDLink;
