import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { Icon } from "@mui/material";
import MDTypography from "components/MDTypography";
import Grid from "@mui/material/Grid";
import useModal from "hooks/useModal";
import { makeStyles } from "@material-ui/styles";
import colors from "assets/theme/base/colors";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useState } from "react";
import { styled } from "@mui/system";
import useBackendError from "hooks/useBackendError";
import useAxiosPrivate from "hooks/useAxiosPrivate";

const useStyles = makeStyles({
  scope: {
    "&:hover": {
      cursor: "pointer",
      backgroundColor: colors.grey[300],

      "& $text": {
        color: `${colors.primary.main} !important`,
      },
    },
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    color: `${colors.text.main} !important`,
  },
  selected: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: `${colors.primary.main} !important`,
  },
  textSelected: {
    color: "white !important",
  },
});

const ScopeModal = ({ ftag, selected }) => {
  const [tooltip, setTooltip] = useState(-1);
  const [scope, setScopeMain] = useState(selected);
  const { setError } = useBackendError();

  const axios = useAxiosPrivate();

  const updateBackend = async (tag, value) => {
    try {
      await axios.post(`/compliance/analysis/scope?ftag=${tag}&value=${value}`, {
        headers: { "Content-Type": "application/json" },
      });
    } catch (err) {
      setError("Failed to update the scope in the backend. Please try again");
    }
  };

  const setScope = (val) => {
    setTooltip(-1);
    setScopeMain(val);
  };

  const { closeModal } = useModal();

  const update = () => {
    updateBackend(ftag, scope);
    closeModal({ scope });
  };

  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 300,
    },
  });

  const classes = useStyles();

  return (
    <div style={{ minWidth: 600 }}>
      <MDBox display="flex" justifyContent="center" alignItems="center" flexDirection="column">
        <MDTypography
          mb={4}
          display="inline"
          variant="h6"
          textTransform="capitalize"
          fontWeight="bold"
        >
          Severity of the Deficiency v/s Scope of the Deficiency
        </MDTypography>
        <Grid container>
          <Grid
            p={1}
            item
            xs={6}
            textAlign="center"
            sx={{
              borderRight: `1px solid ${colors.grey[400]}`,
              borderBottom: `1px solid ${colors.grey[400]}`,
              height: 80,
            }}
          >
            <MDBox>
              <MDBox>
                <MDTypography
                  display="inline"
                  variant="h6"
                  textTransform="capitalize"
                  fontWeight="bold"
                >
                  Level 4
                </MDTypography>

                <CustomWidthTooltip
                  placement="right"
                  PopperProps={{
                    disablePortal: true,
                  }}
                  open={tooltip === 4}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title="Deficient practice caused or is likely to cause serious injury, serious harm, serious impairment or death. Immediate corrective action is needed."
                >
                  <Icon
                    color="info"
                    style={{ cursor: "pointer", marginBottom: -4, marginLeft: 8 }}
                    onClick={() => setTooltip(4)}
                    onMouseMove={() => setTooltip(-1)}
                  >
                    info
                  </Icon>
                </CustomWidthTooltip>
              </MDBox>
              <MDTypography variant="body2" sx={{ fontSize: 12 }}>
                (Immediate jeopardy to resident health or safety)
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid
            item
            xs={2}
            textAlign="center"
            className={scope === "J" ? classes.selected : classes.scope}
            sx={{
              borderBottom: `1px solid ${colors.grey[400]}`,
              borderRight: `1px solid ${colors.grey[400]}`,
            }}
            onClick={() => setScope("J")}
          >
            <MDTypography
              variant="h6"
              textTransform="capitalize"
              fontWeight="bold"
              className={scope === "J" ? classes.textSelected : classes.text}
            >
              J
            </MDTypography>
          </Grid>
          <Grid
            item
            xs={2}
            textAlign="center"
            className={scope === "K" ? classes.selected : classes.scope}
            sx={{
              borderBottom: `1px solid ${colors.grey[400]}`,
              borderRight: `1px solid ${colors.grey[400]}`,
            }}
            onClick={() => setScope("K")}
          >
            <MDTypography
              variant="h6"
              textTransform="capitalize"
              fontWeight="bold"
              className={scope === "K" ? classes.textSelected : classes.text}
            >
              K
            </MDTypography>
          </Grid>
          <Grid
            item
            xs={2}
            textAlign="center"
            className={scope === "L" ? classes.selected : classes.scope}
            sx={{
              borderBottom: `1px solid ${colors.grey[400]}`,
            }}
            onClick={() => setScope("L")}
          >
            <MDTypography
              variant="h6"
              textTransform="capitalize"
              fontWeight="bold"
              className={scope === "L" ? classes.textSelected : classes.text}
            >
              L
            </MDTypography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid
            p={1}
            item
            xs={6}
            textAlign="center"
            sx={{
              borderBottom: `1px solid ${colors.grey[400]}`,
              borderRight: `1px solid ${colors.grey[400]}`,
              height: 80,
            }}
          >
            <MDBox>
              <MDBox>
                <MDTypography
                  display="inline"
                  variant="h6"
                  textTransform="capitalize"
                  fontWeight="bold"
                >
                  Level 3
                </MDTypography>

                <CustomWidthTooltip
                  placement="right"
                  PopperProps={{
                    disablePortal: true,
                  }}
                  open={tooltip === 3}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title="Deficient practice led to a negative outcome that has compromised the resident's ability to maintain and/or reach his/her highest practicable physical, mental, and/or psychosocial well being."
                >
                  <Icon
                    color="info"
                    style={{ cursor: "pointer", marginBottom: -4, marginLeft: 8 }}
                    onClick={() => setTooltip(3)}
                    onMouseMove={() => setTooltip(-1)}
                  >
                    info
                  </Icon>
                </CustomWidthTooltip>
              </MDBox>
              <MDTypography variant="body2" sx={{ fontSize: 12 }}>
                (Actual harm that is not immediate jeopardy)
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid
            item
            xs={2}
            textAlign="center"
            className={scope === "G" ? classes.selected : classes.scope}
            sx={{
              borderRight: `1px solid ${colors.grey[400]}`,
              borderBottom: `1px solid ${colors.grey[400]}`,
            }}
            onClick={() => setScope("G")}
          >
            <MDTypography
              variant="h6"
              textTransform="capitalize"
              fontWeight="bold"
              className={scope === "G" ? classes.textSelected : classes.text}
            >
              G
            </MDTypography>
          </Grid>
          <Grid
            item
            xs={2}
            textAlign="center"
            className={scope === "H" ? classes.selected : classes.scope}
            sx={{
              borderRight: `1px solid ${colors.grey[400]}`,
              borderBottom: `1px solid ${colors.grey[400]}`,
            }}
            onClick={() => setScope("H")}
          >
            <MDTypography
              variant="h6"
              textTransform="capitalize"
              fontWeight="bold"
              className={scope === "H" ? classes.textSelected : classes.text}
            >
              H
            </MDTypography>
          </Grid>
          <Grid
            item
            xs={2}
            textAlign="center"
            className={scope === "I" ? classes.selected : classes.scope}
            sx={{
              borderBottom: `1px solid ${colors.grey[400]}`,
            }}
            onClick={() => setScope("I")}
          >
            <MDTypography
              variant="h6"
              textTransform="capitalize"
              fontWeight="bold"
              className={scope === "I" ? classes.textSelected : classes.text}
            >
              I
            </MDTypography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid
            p={1}
            item
            xs={6}
            textAlign="center"
            sx={{
              borderBottom: `1px solid ${colors.grey[400]}`,
              borderRight: `1px solid ${colors.grey[400]}`,
              height: 80,
            }}
          >
            <MDBox>
              <MDBox>
                <MDTypography
                  display="inline"
                  variant="h6"
                  textTransform="capitalize"
                  fontWeight="bold"
                >
                  Level 2
                </MDTypography>

                <CustomWidthTooltip
                  placement="right"
                  PopperProps={{
                    disablePortal: true,
                  }}
                  open={tooltip === 2}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title="Deficient practice has led to minimal physical, mental, and/or psychosocial discomfort to the resident and/or a yet unrealized potential for compromising the resident's ability to maintain and/or reach his/her highest practicable level of physical, mental, and/or psychosocial well being."
                >
                  <Icon
                    color="info"
                    style={{ cursor: "pointer", marginBottom: -4, marginLeft: 8 }}
                    onClick={() => setTooltip(2)}
                    onMouseMove={() => setTooltip(-1)}
                  >
                    info
                  </Icon>
                </CustomWidthTooltip>
              </MDBox>
              <MDTypography variant="body2" sx={{ fontSize: 12 }}>
                (No actual harm with potential for more than minimal harm that is not immediate
                jeopardy)
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid
            item
            xs={2}
            textAlign="center"
            className={scope === "D" ? classes.selected : classes.scope}
            sx={{
              borderRight: `1px solid ${colors.grey[400]}`,
              borderBottom: `1px solid ${colors.grey[400]}`,
            }}
            onClick={() => setScope("D")}
          >
            <MDTypography
              variant="h6"
              textTransform="capitalize"
              fontWeight="bold"
              className={scope === "D" ? classes.textSelected : classes.text}
            >
              D
            </MDTypography>
          </Grid>
          <Grid
            item
            xs={2}
            textAlign="center"
            className={scope === "E" ? classes.selected : classes.scope}
            sx={{
              borderRight: `1px solid ${colors.grey[400]}`,
              borderBottom: `1px solid ${colors.grey[400]}`,
            }}
            onClick={() => setScope("E")}
          >
            <MDTypography
              variant="h6"
              textTransform="capitalize"
              fontWeight="bold"
              className={scope === "E" ? classes.textSelected : classes.text}
            >
              E
            </MDTypography>
          </Grid>
          <Grid
            item
            xs={2}
            textAlign="center"
            className={scope === "F" ? classes.selected : classes.scope}
            sx={{
              borderBottom: `1px solid ${colors.grey[400]}`,
            }}
            onClick={() => setScope("F")}
          >
            <MDTypography
              variant="h6"
              textTransform="capitalize"
              fontWeight="bold"
              className={scope === "F" ? classes.textSelected : classes.text}
            >
              F
            </MDTypography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid
            p={1}
            item
            xs={6}
            textAlign="center"
            sx={{
              borderRight: `1px solid ${colors.grey[400]}`,
              height: 80,
            }}
          >
            <MDBox>
              <MDBox>
                <MDTypography
                  display="inline"
                  variant="h6"
                  textTransform="capitalize"
                  fontWeight="bold"
                >
                  Level 1
                </MDTypography>

                <CustomWidthTooltip
                  placement="right"
                  PopperProps={{
                    disablePortal: true,
                  }}
                  open={tooltip === 1}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title="Deficient practice has the potential for causing no more than minor negative impact on residents."
                >
                  <Icon
                    color="info"
                    style={{ cursor: "pointer", marginBottom: -4, marginLeft: 8 }}
                    onClick={() => setTooltip(1)}
                    onMouseMove={() => setTooltip(-1)}
                  >
                    info
                  </Icon>
                </CustomWidthTooltip>
              </MDBox>
              <MDTypography variant="body2" sx={{ fontSize: 12 }}>
                (No actual harm with potential for no more than minimal harm)
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid
            item
            xs={2}
            textAlign="center"
            className={scope === "A" ? classes.selected : classes.scope}
            sx={{
              borderRight: `1px solid ${colors.grey[400]}`,
            }}
            onClick={() => setScope("A")}
          >
            <MDTypography
              variant="h6"
              textTransform="capitalize"
              fontWeight="bold"
              className={scope === "A" ? classes.textSelected : classes.text}
            >
              A
            </MDTypography>
          </Grid>
          <Grid
            item
            xs={2}
            textAlign="center"
            className={scope === "B" ? classes.selected : classes.scope}
            sx={{
              borderRight: `1px solid ${colors.grey[400]}`,
            }}
            onClick={() => setScope("B")}
          >
            <MDTypography
              variant="h6"
              textTransform="capitalize"
              fontWeight="bold"
              className={scope === "B" ? classes.textSelected : classes.text}
            >
              B
            </MDTypography>
          </Grid>
          <Grid
            item
            xs={2}
            textAlign="center"
            className={scope === "C" ? classes.selected : classes.scope}
            onClick={() => setScope("C")}
          >
            <MDTypography
              variant="h6"
              textTransform="capitalize"
              fontWeight="bold"
              className={scope === "C" ? classes.textSelected : classes.text}
            >
              C
            </MDTypography>
          </Grid>
        </Grid>
        <MDBox flexDirection="row" display="flex" m={1}>
          <MDBox m={1}>
            <MDButton onClick={update} variant="gradient" color="info" size="medium">
              Okay
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </div>
  );
};

export default ScopeModal;
