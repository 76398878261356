import PropTypes from "prop-types";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import Skeleton from "components/skeletons/Skeleton";
import MDBadge from "components/MDBadge";

function ResidentCell({ image, name, color, room, isLoading, isLongTerm }) {
  if (isLoading) {
    return (
      <MDBox alignItems="left" display="flex">
        <MDBox mr={1}>
          <Skeleton width={36} height={36} borderRadius={50} />
        </MDBox>
        <MDBox display="flex" flexDirection="column" justifyContent="center">
          <Skeleton width={Math.floor(Math.random() * 80 + 80)} height={16} />

          {room != null && room !== "" && <Skeleton width={30} height={16} />}
        </MDBox>
      </MDBox>
    );
  }
  return (
    <MDBox alignItems="left" display="flex">
      {(image != null || color != null) && (
        <MDBox mr={1}>
          <MDAvatar bgColor={color} src={image} alt={name} size="md" />
        </MDBox>
      )}
      <MDBox display="flex" flexDirection="column" justifyContent="center">
        <MDTypography variant="body2" fontWeight="medium" color="dark" style={{ fontSize: 13 }}>
          {name}
        </MDTypography>

        <MDBox display="flex">
          <MDBadge
            size="xs"
            badgeContent={isLongTerm ? "Long Term" : "Short Term"}
            color="light"
            style={{ marginRight: 10, marginLeft: -8 }}
          />
          {room != null && room !== "" && (
            <MDTypography variant="body2" color="text" fontSize="xs" style={{ fontSize: 12 }}>
              Room: {room}
            </MDTypography>
          )}
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

// Typechecking props for the CustomerCell
ResidentCell.propTypes = {
  name: PropTypes.string.isRequired,
};

export default ResidentCell;
