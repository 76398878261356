/* eslint-disable no-nested-ternary */
import { useEffect, useRef, useState } from "react";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import useBackendError from "hooks/useBackendError";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CircularProgress,
  Grid,
  Icon,
} from "@mui/material";
import MDTypography from "components/MDTypography";
import useModal from "hooks/useModal";
import StarRating from "components/StarRating/StarRating";
import colors from "assets/theme/base/colors";
import { makeStyles } from "@material-ui/styles";

import MDHr from "components/MDHr";
import Skeleton from "components/skeletons/Skeleton";
import FiveStarPast from "../overview/FiveStarPast";

const Score = () => {
  const axios = useAxiosPrivate();
  const [isLoading, setLoading] = useState(true);
  const [isLoadingPastScores, setLoadingPastScores] = useState(true);
  const pastScores = useRef([]);
  const scoreData = useRef([{}, {}, {}]);
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const { setError } = useBackendError();
  const { openModal } = useModal();
  const { state } = useLocation();
  const newCalculation = state?.type === "new";
  const navigate = useNavigate();
  // eslint-disable-next-line no-bitwise
  const [expanded, setExpanded] = useState(false);
  const [hovered, setHovered] = useState(false);

  const handleHover = (panel) => {
    setHovered(panel);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const simulator = (e) => {
    navigate(`/5-star-rating/simulator?id=${id}`);
  };

  const loadData = async () => {
    setLoading(true);
    scoreData.current = {};

    try {
      const response = await axios.get(`/fivestar/score?id=${id}&calculate=${newCalculation}`, {
        headers: { "Content-Type": "application/json" },
      });
      scoreData.current = response.data;
    } catch (err) {
      setError("Failed to fetch from the backend. Please try again");
    } finally {
      setLoading(false);
    }
  };

  const loadPastScoreData = async () => {
    setLoadingPastScores(true);
    pastScores.current = [];

    try {
      const response = await axios.get(`/fivestar/past`, {
        headers: { "Content-Type": "application/json" },
      });
      pastScores.current = response.data;
    } catch (err) {
      setError("Failed to fetch from the backend. Please try again");
    } finally {
      setLoadingPastScores(false);
    }
  };

  const useStyles = makeStyles(() => ({
    accordionSummary: {
      minHeight: 40,
      maxHeight: 40,
      "&.Mui-expanded": {
        minHeight: 40,
        maxHeight: 40,
        backgroundColor: colors.grey[100],
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
      },
    },
    accordionDetail: {
      backgroundColor: colors.grey[200],
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
    },
    accordion: {
      boxShadow: "none",
      "&:before": {
        display: "none", // Remove the pseudo-element
      },
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    loadData();
    loadPastScoreData();
  }, []);

  return (
    <Grid container spacing={3} mb={3}>
      <Grid item xs={12}>
        <Card id="compliance-overview">
          <MDBox pt={2} px={2} display="flex" justifyContent="left" alignItems="center">
            <MDBox
              color="white"
              bgColor="info"
              variant="gradient"
              borderRadius="lg"
              shadow="lg"
              display="flex"
              justifyContent="center"
              alignItems="center"
              py={1}
              px={2}
              mt={-4}
              mb={3}
            >
              <MDTypography variant="h6" fontWeight="medium" color="white">
                5 Star Rating
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDBox
            justifyContent="center"
            display="flex"
            flexDirection="column"
            alignItems="center"
            style={{ width: "100%" }}
          >
            <MDBox display="flex" justifyContent="center" alignItems="center" pt={1}>
              <MDBox textAlign="right" sx={{ marginRight: 2 }}>
                {isLoading && (
                  <MDTypography variant="body" fontWeight="bold" color="text">
                    Overall Star Rating (
                    <CircularProgress color="text" style={{ height: 14, width: 14 }} /> stars)
                  </MDTypography>
                )}
                {!isLoading && (
                  <MDTypography variant="body" fontWeight="bold" color="text">
                    Overall Star Rating ({scoreData.current.overallRating}{" "}
                    {scoreData.current.overallRating === 1 ? "star" : "stars"})
                  </MDTypography>
                )}
              </MDBox>
              <MDBox>
                {isLoading && <Skeleton width={160} height={24} margin={4} />}
                {!isLoading && (
                  <StarRating
                    rating={scoreData.current.overallRating}
                    fontSize="large"
                    color={colors.warning.main}
                  />
                )}
              </MDBox>
            </MDBox>

            <MDBox
              justifyContent="center"
              alignItems="center"
              style={{ minWidth: 460, width: "50%" }}
            >
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
                onMouseEnter={() => handleHover("panel1")}
                onMouseLeave={() => handleHover("")}
                className={classes.accordion}
                disableGutters
              >
                <AccordionSummary
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  className={classes.accordionSummary}
                >
                  <Grid container alignItems="center">
                    <Grid item xs={7} textAlign="right">
                      {isLoading && (
                        <MDTypography variant="body" color="text" mr={2} style={{ fontSize: 15 }}>
                          Health Inspection Rating (
                          <CircularProgress color="text" style={{ height: 10, width: 10 }} /> stars)
                        </MDTypography>
                      )}
                      {!isLoading && (
                        <MDTypography variant="body" color="text" mr={2} style={{ fontSize: 15 }}>
                          Health Inspection Rating ({scoreData.current.healthInspectionRating}{" "}
                          {scoreData.current.healthInspectionRating === 1 ? "star" : "stars"})
                        </MDTypography>
                      )}
                    </Grid>
                    <Grid item xs={5} pt={1}>
                      <MDBox display="flex">
                        {isLoading && <Skeleton width={112} height={16} margin={4} />}
                        {!isLoading && (
                          <>
                            <MDTypography variant="body2" color="text">
                              <StarRating
                                rating={scoreData.current.healthInspectionRating}
                                fontSize="medium"
                                color={colors.warning.main}
                              />
                            </MDTypography>
                            <Icon
                              fontSize="small"
                              style={{
                                transform: expanded === "panel1" ? "rotate(180deg)" : "",
                                transition: "transform 0.3s ease, color 0.3s ease",
                                marginLeft: 10,
                                marginTop: 2,
                                color:
                                  expanded === "panel1"
                                    ? colors.dark.main
                                    : hovered === "panel1"
                                    ? colors.info.main
                                    : colors.grey[300],
                              }}
                            >
                              expand_circle_down
                            </Icon>
                          </>
                        )}
                      </MDBox>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetail}>
                  {scoreData.current.healthInspectionRatingBreakdown?.map((details) => (
                    <Grid container alignItems="center">
                      <Grid item xs={8} textAlign="right">
                        <MDTypography variant="body2" color="dark" mr={1} style={{ fontSize: 13 }}>
                          {details.breakdown[0]} :
                        </MDTypography>
                      </Grid>
                      <Grid item xs={4}>
                        <MDTypography variant="body2" color="dark" style={{ fontSize: 13 }}>
                          {details.breakdown[1]}{" "}
                          <span style={{ color: colors.primary.main, fontSize: 12 }}>
                            x {details.breakdown[2]}
                          </span>
                        </MDTypography>
                      </Grid>
                    </Grid>
                  ))}
                  <MDHr color={colors.grey[300]} style={{ margin: 10 }} />
                  <Grid container alignItems="center">
                    <Grid item xs={8} textAlign="right">
                      <MDTypography
                        variant="body2"
                        fontWeight="bold"
                        color="text"
                        mr={2}
                        style={{ fontSize: 13 }}
                      >
                        {scoreData.current.healthInspectionRatingTotal?.[0].breakdown?.[0]} :
                      </MDTypography>
                    </Grid>
                    <Grid item xs={4}>
                      <MDTypography
                        variant="body2"
                        fontWeight="bold"
                        color="text"
                        style={{ fontSize: 13 }}
                      >
                        {scoreData.current.healthInspectionRatingTotal?.[0].breakdown?.[1]}
                      </MDTypography>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
                onMouseEnter={() => handleHover("panel2")}
                onMouseLeave={() => handleHover("")}
                className={classes.accordion}
                disableGutters
              >
                <AccordionSummary
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                  className={classes.accordionSummary}
                >
                  <Grid container alignItems="center">
                    <Grid item xs={7} textAlign="right">
                      {isLoading && (
                        <MDTypography variant="body" color="text" mr={2} style={{ fontSize: 15 }}>
                          Staff Rating (
                          <CircularProgress color="text" style={{ height: 10, width: 10 }} /> stars)
                        </MDTypography>
                      )}
                      {!isLoading && (
                        <MDTypography variant="body" color="text" mr={2} style={{ fontSize: 15 }}>
                          Staff Rating ({scoreData.current.staffRating}{" "}
                          {scoreData.current.staffRating === 1 ? "star" : "stars"})
                        </MDTypography>
                      )}
                    </Grid>
                    <Grid item xs={5} pt={1}>
                      <MDBox display="flex">
                        {isLoading && <Skeleton width={112} height={16} margin={4} />}
                        {!isLoading && (
                          <>
                            <MDTypography variant="body2" color="text">
                              <StarRating
                                rating={scoreData.current.staffRating}
                                fontSize="medium"
                                color={colors.warning.main}
                              />
                            </MDTypography>
                            <Icon
                              fontSize="small"
                              style={{
                                transform: expanded === "panel2" ? "rotate(180deg)" : "",
                                transition: "transform 0.3s ease, color 0.3s ease",
                                marginLeft: 10,
                                marginTop: 2,
                                color:
                                  expanded === "panel2"
                                    ? colors.dark.main
                                    : hovered === "panel2"
                                    ? colors.info.main
                                    : colors.grey[300],
                              }}
                            >
                              expand_circle_down
                            </Icon>
                          </>
                        )}
                      </MDBox>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetail}>
                  {scoreData.current.staffRatingBreakdown?.map((details) => (
                    <Grid container alignItems="center">
                      <Grid item xs={8} textAlign="right">
                        <MDTypography variant="body2" color="dark" mr={1} style={{ fontSize: 13 }}>
                          {details.breakdown[0]} :
                        </MDTypography>
                      </Grid>
                      <Grid item xs={4}>
                        <MDTypography variant="body2" color="dark" style={{ fontSize: 13 }}>
                          {details.breakdown[1]}
                        </MDTypography>
                      </Grid>
                    </Grid>
                  ))}
                  <MDHr color={colors.grey[300]} style={{ margin: 10 }} />
                  <Grid container alignItems="center">
                    <Grid item xs={8} textAlign="right">
                      <MDTypography
                        variant="body2"
                        fontWeight="bold"
                        color="text"
                        mr={2}
                        style={{ fontSize: 13 }}
                      >
                        {scoreData.current.staffRatingTotal?.[0].breakdown?.[0]} :
                      </MDTypography>
                    </Grid>
                    <Grid item xs={4}>
                      <MDTypography
                        variant="body2"
                        fontWeight="bold"
                        color="text"
                        style={{ fontSize: 13 }}
                      >
                        {scoreData.current.staffRatingTotal?.[0].breakdown?.[1]}
                      </MDTypography>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
                onMouseEnter={() => handleHover("panel3")}
                onMouseLeave={() => handleHover("")}
                className={classes.accordion}
                disableGutters
              >
                <AccordionSummary
                  aria-controls="panel3bh-content"
                  id="panel3bh-header"
                  className={classes.accordionSummary}
                >
                  <Grid container alignItems="center">
                    <Grid item xs={7} textAlign="right">
                      {isLoading && (
                        <MDTypography variant="body" color="text" mr={2} style={{ fontSize: 15 }}>
                          Quality Measures Rating (
                          <CircularProgress color="text" style={{ height: 10, width: 10 }} /> stars)
                        </MDTypography>
                      )}
                      {!isLoading && (
                        <MDTypography variant="body" color="text" mr={2} style={{ fontSize: 15 }}>
                          Quality Measures Rating ({scoreData.current.qualityMeasuresRating}{" "}
                          {scoreData.current.qualityMeasuresRating === 1 ? "star" : "stars"})
                        </MDTypography>
                      )}
                    </Grid>
                    <Grid item xs={5} pt={1}>
                      <MDBox display="flex">
                        {isLoading && <Skeleton width={112} height={16} margin={4} />}
                        {!isLoading && (
                          <>
                            <MDTypography variant="body2" color="text">
                              <StarRating
                                rating={scoreData.current.qualityMeasuresRating}
                                fontSize="medium"
                                color={colors.warning.main}
                              />
                            </MDTypography>
                            <Icon
                              fontSize="small"
                              style={{
                                transform: expanded === "panel3" ? "rotate(180deg)" : "",
                                transition: "transform 0.3s ease, color 0.3s ease",
                                marginLeft: 10,
                                marginTop: 2,
                                color:
                                  expanded === "panel3"
                                    ? colors.dark.main
                                    : hovered === "panel3"
                                    ? colors.info.main
                                    : colors.grey[300],
                              }}
                            >
                              expand_circle_down
                            </Icon>
                          </>
                        )}
                      </MDBox>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetail}>
                  <MDTypography
                    variant="body2"
                    color="text"
                    fontWeight="bold"
                    mr={1}
                    style={{ fontSize: 13 }}
                    textAlign="center"
                  >
                    Long Stay Points
                  </MDTypography>
                  {scoreData.current.qualityMeasuresRatingBreakdown
                    ?.filter((details) => details.breakdown[2] === "long")
                    .map((details) => (
                      <Grid container alignItems="center">
                        <Grid item xs={11} textAlign="right">
                          <MDTypography
                            variant="body2"
                            color="dark"
                            mr={1}
                            style={{ fontSize: 12 }}
                          >
                            {details.breakdown[0]} :
                          </MDTypography>
                        </Grid>
                        <Grid item xs={1}>
                          <MDTypography variant="body2" color="dark" style={{ fontSize: 12 }}>
                            {details.breakdown[1]}
                          </MDTypography>
                        </Grid>
                      </Grid>
                    ))}
                  <MDHr color={colors.grey[300]} style={{ margin: 10 }} />
                  <MDTypography
                    variant="body2"
                    color="text"
                    fontWeight="bold"
                    mr={1}
                    style={{ fontSize: 13 }}
                    textAlign="center"
                  >
                    Short Stay Points
                  </MDTypography>
                  {scoreData.current.qualityMeasuresRatingBreakdown
                    ?.filter((details) => details.breakdown[2] === "short")
                    .map((details) => (
                      <Grid container alignItems="center">
                        <Grid item xs={11} textAlign="right">
                          <MDTypography
                            variant="body2"
                            color="dark"
                            mr={1}
                            style={{ fontSize: 12 }}
                          >
                            {details.breakdown[0]} :
                          </MDTypography>
                        </Grid>
                        <Grid item xs={1}>
                          <MDTypography variant="body2" color="dark" style={{ fontSize: 12 }}>
                            {details.breakdown[1]}
                          </MDTypography>
                        </Grid>
                      </Grid>
                    ))}
                  <MDHr color={colors.grey[300]} style={{ margin: 10 }} />
                  {scoreData.current.qualityMeasuresRatingTotal?.map((details) => (
                    <Grid container alignItems="center">
                      <Grid item xs={7} textAlign="right">
                        <MDTypography
                          variant="body2"
                          color="text"
                          mr={1}
                          fontWeight="bold"
                          style={{ fontSize: 12 }}
                        >
                          {details.breakdown[0]} :
                        </MDTypography>
                      </Grid>
                      <Grid item xs={5}>
                        <MDTypography
                          variant="body2"
                          color="text"
                          fontWeight="bold"
                          style={{ fontSize: 12 }}
                        >
                          {details.breakdown[1]}
                        </MDTypography>
                      </Grid>
                    </Grid>
                  ))}
                </AccordionDetails>
              </Accordion>
            </MDBox>
          </MDBox>
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexWrap="wrap"
            pb={3}
            pl={3}
            pr={3}
            mt={3}
          >
            <MDButton variant="gradient" color="light" style={{ margin: 5 }}>
              print report
            </MDButton>
            <MDButton variant="gradient" color="light" style={{ margin: 5 }}>
              download report
            </MDButton>

            <MDButton variant="gradient" color="info" style={{ margin: 5 }} loadingText="saving...">
              <Icon sx={{ fontWeight: "regular" }}>save</Icon>
              &nbsp;save as current score
            </MDButton>

            <MDButton
              variant="gradient"
              color="primary"
              style={{ margin: 5 }}
              loadingText="submitting..."
              onClick={simulator}
            >
              <Icon sx={{ fontWeight: "bold" }}>tune</Icon>
              &nbsp;simulator
            </MDButton>
          </MDBox>
        </Card>
      </Grid>
      {!isLoading && scoreData.current?.ratingSuggestion != null && (
        <Grid item xs={12}>
          <Card id="past-surveys" mt={10}>
            <MDBox
              display="flex"
              justifyContent="space-between"
              px={2}
              pt={2}
              alignItems="flex-end"
            >
              <MDTypography variant="h6" fontWeight="medium" width={280}>
                Ways to improve your rating
              </MDTypography>
            </MDBox>
            <MDBox display="flex" justifyContent="space-between" px={2} alignItems="flex-end">
              <Grid container spacing={1} mb={3}>
                {scoreData.current?.ratingSuggestion?.map((suggestion) => (
                  <Grid item xs={12}>
                    <Card
                      style={{
                        backgroundColor: colors.grey[300],
                        width: "100%",
                        marginTop: 30,
                        paddingBottom: 16,
                      }}
                    >
                      <MDBox
                        pt={2}
                        px={2}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        {!isLoading && (
                          <MDBox
                            color="white"
                            bgColor="dark"
                            variant="gradient"
                            borderRadius="xl"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            height="1.5rem"
                            pl={2}
                            pr={2}
                            mt={-3}
                          >
                            <MDTypography variant="body2" color="white" fontSize="xs">
                              {suggestion.title}
                            </MDTypography>
                          </MDBox>
                        )}
                      </MDBox>
                      <MDBox pl={3} pt={1} display="flex">
                        <MDTypography variant="body2" color="dark" mr={1} style={{ fontSize: 14 }}>
                          From <b>{suggestion.from}</b> {suggestion.from === 1 ? "star " : "stars "}
                        </MDTypography>
                        <StarRating
                          rating={suggestion.from}
                          fontSize="small"
                          color={colors.warning.main}
                        />
                        <MDTypography
                          variant="body2"
                          color="dark"
                          mr={1}
                          ml={1}
                          style={{ fontSize: 14 }}
                        >
                          to <b>{suggestion.to}</b> {suggestion.to === 1 ? "star " : "stars "}
                        </MDTypography>
                        <StarRating
                          rating={suggestion.to}
                          fontSize="small"
                          color={colors.warning.main}
                        />
                      </MDBox>
                      {suggestion.suggestionTexts.map((text) => (
                        <MDBox display="flex" ml={4}>
                          <Icon color="success" fontSize="small">
                            check
                          </Icon>
                          <MDTypography
                            variant="body2"
                            color="dark"
                            mr={1}
                            ml={1}
                            style={{ fontSize: 14 }}
                          >
                            {text}
                          </MDTypography>
                        </MDBox>
                      ))}
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </MDBox>
          </Card>
        </Grid>
      )}

      <Grid item xs={12}>
        <FiveStarPast data={pastScores.current} isLoading={isLoadingPastScores} />
      </Grid>
    </Grid>
  );
};
export default Score;
