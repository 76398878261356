import DataTable from "components/DataTable";

import TimestampCell from "components/DataTable/components/TimestampCell";

import ResidentCell from "components/DataTable/components/ResidentCell";
import ResidentStatusCell from "components/DataTable/components/ResidentStatusCell";
import ResidentActionCell from "components/DataTable/components/ActionCells/ResidentActionCell";
import React from "react";

const dataTableData = (rows, isLoading, refreshCallback) => ({
  columns: [
    {
      Header: "Resident",
      accessor: "resident",
      isSorted: true,
      Cell: ({ value: [id, name, room, image, isLongTerm] }) => (
        <ResidentCell
          id={id}
          image={image}
          name={name}
          room={room}
          isLoading={isLoading}
          isLongTerm={isLongTerm}
        />
      ),
    },

    {
      Header: "Admission",
      accessor: "admissionDate",
      width: 80,
      Cell: ({ value }) => <TimestampCell timestamp={value} isLoading={isLoading} hideTime />,
    },
    {
      Header: "Status",
      accessor: "status",
      isSorted: true,
      width: 50,
      Cell: ({ value }) => <ResidentStatusCell value={value} isLoading={isLoading} />,
    },
    {
      Header: "Discharge",
      accessor: "dischargeDate",
      width: 80,
      Cell: ({ value }) => <TimestampCell timestamp={value} isLoading={isLoading} hideTime />,
    },

    {
      Header: "Actions",
      accessor: "action",
      isSorted: false,
      width: 50,
      Cell: ({ value: [id, name, room, email] }) => (
        <ResidentActionCell
          id={id}
          name={name}
          room={room}
          emailId={email}
          isLoading={isLoading}
          refreshCallback={refreshCallback}
        />
      ),
    },
  ],
  rows,
});

const ResidentList = React.memo(({ data, isLoading, refreshCallback }) => {
  let rows = [];
  if (!isLoading) {
    rows = data?.map((row) => ({
      resident: [row.id, row.fullName, row.room, row.url, row.longTerm],
      status: row.status,
      admissionDate: row.admissionDate,
      dischargeDate: row.dischargeDate,
      action: [row.id, row.fullName, row.room, row.email],
    }));
  } else {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 10; i++) {
      // eslint-disable-next-line no-new-object
      rows.push({
        resident: [0, "", "", ""],
        status: "",
        admissionDate: 0,
        dischargeDate: 0,
        action: [0],
      });
    }
  }

  return <DataTable table={dataTableData(rows, isLoading, refreshCallback)} canSearch isSorted />;
});

export default ResidentList;
