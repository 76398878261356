import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import { Card, Icon } from "@mui/material";
import MDButton from "components/MDButton";
import Timeline from "components/Timeline";
import { useEffect, useRef, useState } from "react";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import useBackendError from "hooks/useBackendError";
import SatisfactionChart from "./SatisfactionChart";
import SatisfactionChartSkeleton from "./skeleton/SatisfactionChartSkeleton";

const SatisfactionScore = () => {
  const [isLoading, setLoading] = useState(true);
  const score = useRef();
  const axios = useAxiosPrivate();
  const { setError } = useBackendError();

  const loadScore = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/satisfaction/score`, {
        headers: { "Content-Type": "application/json" },
      });
      score.current = response.data;
    } catch (err) {
      setError("Failed to fetch from the backend. Please try again");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadScore();
  }, []);

  const handlePastAnalysis = () => {};
  const handleNewAnalysis = () => {};
  const setTimeline = () => {};

  return (
    <Card id="top-five" style={{ height: "100%", minHeight: 240 }}>
      <MDBox
        pt={2}
        px={2}
        display="flex"
        justifyContent="center"
        alignItems="flex-start"
        style={{ flexDirection: "column", height: "100%", margin: 0 }}
      >
        <MDBox
          px={1}
          style={{ top: 10, position: "absolute", width: "100%" }}
          display="flex"
          justifyContent="space-between"
        >
          <MDTypography variant="h6" fontWeight="medium">
            Satisfaction Score
          </MDTypography>
          <Timeline setTimeline={setTimeline} />
        </MDBox>
        <MDBox width="100%">
          {!isLoading && <SatisfactionChart score={score.current} />}
          {isLoading && <SatisfactionChartSkeleton />}
          <MDBox display="flex" justifyContent="center" alignItems="center" flexWrap="wrap" pt={2}>
            <MDButton variant="gradient" color="light" onClick={handlePastAnalysis} size="medium">
              past analysis &nbsp;
              <Icon sx={{ fontWeight: "bold" }}>access_time</Icon>
            </MDButton>
            <MDButton
              variant="gradient"
              color="primary"
              style={{ margin: 5 }}
              loadingText="submitting..."
              onClick={handleNewAnalysis}
            >
              new analysis &nbsp;<Icon sx={{ fontWeight: "bold" }}>equalizer</Icon>
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
};
export default SatisfactionScore;
