/* eslint-disable react/no-array-index-key */
/* eslint-disable no-plusplus */
import { Grid } from "@mui/material";
import CMSAnalysisActionDetailsCell from "components/DataTable/components/ActionCells/CMSAnalysisActionDetailsCell";
import CommentCell from "components/DataTable/components/CommentCell";
import ResidentCell from "components/DataTable/components/ResidentCell";
import TimestampCell from "components/DataTable/components/TimestampCell";

const Details = ({ data }) => {
  const d = ";";
  return (
    <>
      {data.map((row, index) => (
        <Grid
          container
          width="100%"
          p={2}
          style={{ borderTop: "1px solid #ced4da" }}
          key={`${index}-details`}
        >
          <Grid item xs={2} p={1}>
            <ResidentCell name={row.name} room={row.room} />
          </Grid>
          <Grid item xs={6} p={1}>
            <CommentCell comment={row.comment} />
          </Grid>
          <Grid item xs={2} p={1}>
            <TimestampCell timestamp={row.time} />
          </Grid>
          <Grid item xs={2} p={1}>
            <CMSAnalysisActionDetailsCell id={row.id} />
          </Grid>
        </Grid>
      ))}
    </>
  );
};
export default Details;
