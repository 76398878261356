/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

import { Icon } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import useModal from "hooks/useModal";
import ConfirmDeleteModal from "components/modal/ConfirmDeleteModal";
import { timeout } from "utils/utils";
import MDButton from "components/MDButton";

const QapiActionCell = ({ id, isLoading }) => {
  const { openModal } = useModal();
  const axios = useAxiosPrivate();
  const navigate = useNavigate();
  const edit = () => {
    navigate(`/qapi/details?id=${id}`, { state: { action: "edit" } });
  };

  const deleteCallback = async () => {
    console.log("deleteing...");
    await timeout(3000);
    /* await axios.post(`/qapi/delete`, {
      headers: { "Content-Type": "application/json" },
    }); */
    return "success";
  };

  const deleteSurvey = () => {
    openModal(
      <ConfirmDeleteModal
        title="Delete QAPI?"
        text="Do you really want to delete the seleted QAPI?"
        successText="The selected QAPI has been successfully deleted."
        callback={deleteCallback}
      />,
      () => {}
    );
  };

  return (
    <MDBox display="flex" alignItems="center">
      <MDButton
        variant="gradient"
        color="info"
        style={{ margin: 5, width: 50 }}
        onClick={edit}
        isLoading={isLoading}
      >
        View
      </MDButton>
      <MDButton
        variant="text"
        color="secondary"
        iconOnly
        circular
        onClick={deleteSurvey}
        isLoading={isLoading}
      >
        <Icon>delete</Icon>
      </MDButton>
    </MDBox>
  );
};

// Typechecking props for the StatusCell
QapiActionCell.propTypes = {
  id: PropTypes.number.isRequired,
};

export default QapiActionCell;
