/* eslint-disable no-param-reassign */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/no-danger */
/* eslint-disable no-plusplus */
import { FormHelperText, Radio } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Skeleton from "components/skeletons/Skeleton";
import colors from "assets/theme/base/colors";
import DisabledRadio from "components/DisabledRadio";

const Question = ({ data, sectionIndex, questionIndex, setData, isLoading, action }) => {
  const question = data.template.sections[sectionIndex].questions[questionIndex];
  const handleChange = (val) => {
    const dataTemp = { ...data };
    dataTemp.template.sections[sectionIndex].questions[questionIndex].score = val;
    dataTemp.template.sections[sectionIndex].questions[questionIndex].isError = false;
    setData(dataTemp);
  };

  return (
    <MDBox
      id={`question-${sectionIndex}-${questionIndex}`}
      mb={1}
      bgColor="white"
      borderRadius="xl"
      style={{ width: "100%" }}
      coloredShadow={question.isError ? "error" : null}
      border={question.isError ? "1px solid" : null}
      borderColor={question.isError ? "red" : null}
    >
      <MDBox display="flex" justifyContent="space-between">
        <MDBox px={2} py={1}>
          {isLoading && (
            <Skeleton
              classes="title"
              width={Math.floor((Math.random() * window.innerWidth) / 2)}
              height={18}
              dark
            />
          )}

          <MDTypography variant="body2" color="dark">
            {!isLoading && question.questionText}
          </MDTypography>
          {!isLoading && question.isError && (
            <FormHelperText error>* Please select a value</FormHelperText>
          )}
        </MDBox>

        {!isLoading && action === "edit" && (
          <MDBox display="flex">
            <MDBox
              width={52}
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <Radio checked={question.score === 3} onClick={() => handleChange(3)} />
              <MDTypography
                variant="body2"
                style={{ fontSize: 12, marginTop: -8 }}
                color={question.score === 3 ? "success" : "text"}
              >
                Excellent
              </MDTypography>
            </MDBox>

            <MDBox
              width={52}
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <Radio checked={question.score === 2} onClick={() => handleChange(2)} />
              <MDTypography
                variant="body2"
                style={{
                  fontSize: 12,
                  marginTop: -8,
                  color: question.score === 2 ? "#ADB80E" : colors.text.main,
                }}
              >
                Good
              </MDTypography>
            </MDBox>
            <MDBox
              width={52}
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <Radio checked={question.score === 1} onClick={() => handleChange(1)} />
              <MDTypography
                variant="body2"
                style={{ fontSize: 12, marginTop: -8 }}
                color={question.score === 1 ? "warning" : "text"}
              >
                Fair
              </MDTypography>
            </MDBox>
            <MDBox
              width={52}
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <Radio checked={question.score === 0} onClick={() => handleChange(0)} />
              <MDTypography
                variant="body2"
                style={{ fontSize: 12, marginTop: -8 }}
                color={question.score === 0 ? "error" : "text"}
              >
                Poor
              </MDTypography>
            </MDBox>
            <MDBox
              width={52}
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <Radio checked={question.score === -1} onClick={() => handleChange(-1)} />
              <MDTypography variant="body2" style={{ fontSize: 12, marginTop: -8 }}>
                N/A
              </MDTypography>
            </MDBox>
          </MDBox>
        )}
        {!isLoading && action === "view" && (
          <MDBox display="flex">
            <MDBox
              width={52}
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              pt={1}
            >
              <DisabledRadio
                checked={question.score === 3}
                tooltip="To edit, please click on the edit button at the bottom"
              />
              <MDTypography
                variant="body2"
                style={{ fontSize: 12, marginTop: 1 }}
                color={question.score === 3 ? "success" : "text"}
              >
                Excellent
              </MDTypography>
            </MDBox>

            <MDBox
              width={52}
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              pt={1}
            >
              <DisabledRadio
                checked={question.score === 2}
                tooltip="To edit, please click on the edit button at the bottom"
              />
              <MDTypography
                variant="body2"
                style={{
                  fontSize: 12,
                  marginTop: 1,
                  color: question.score === 2 ? "#ADB80E" : colors.text.main,
                }}
              >
                Good
              </MDTypography>
            </MDBox>
            <MDBox
              width={52}
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              pt={1}
            >
              <DisabledRadio
                checked={question.score === 1}
                tooltip="To edit, please click on the edit button at the bottom"
              />
              <MDTypography
                variant="body2"
                style={{ fontSize: 12, marginTop: 1 }}
                color={question.score === 1 ? "warning" : "text"}
              >
                Fair
              </MDTypography>
            </MDBox>
            <MDBox
              width={52}
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              pt={1}
            >
              <DisabledRadio
                checked={question.score === 0}
                tooltip="To edit, please click on the edit button at the bottom"
              />
              <MDTypography
                variant="body2"
                style={{ fontSize: 12, marginTop: 1 }}
                color={question.score === 0 ? "error" : "text"}
              >
                Poor
              </MDTypography>
            </MDBox>
            <MDBox
              width={52}
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              pt={1}
            >
              <DisabledRadio
                checked={question.score === -1}
                tooltip="To edit, please click on the edit button at the bottom"
                style={{ marginLeft: 10 }}
              />
              <MDTypography variant="body2" style={{ fontSize: 12, marginTop: 1 }}>
                N/A
              </MDTypography>
            </MDBox>
          </MDBox>
        )}
      </MDBox>
    </MDBox>
  );
};

export default Question;
