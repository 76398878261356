import { Autocomplete, Grid, TextField } from "@mui/material";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import colors from "assets/theme/base/colors";
import MDButton from "components/MDButton";

import MDTypography from "components/MDTypography";

import useModal from "hooks/useModal";
import { useEffect, useRef, useState } from "react";
import useResidents from "hooks/useResidents";
import useBackendError from "hooks/useBackendError";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import MDBox from "components/MDBox";
import MDAvatar from "components/MDAvatar";
import MDSelect from "components/MDSelect";

const StartSatisfactionSurveyModal = ({ residentId, residentName }) => {
  const { closeModal } = useModal();
  const [residentInputValue, setResidentInputValue] = useState(residentName ?? "");
  const [templateInputValue, setTemplateInputValue] = useState("");
  const { setError } = useBackendError();
  const axios = useAxiosPrivate();
  const [resident, setResident] = useState(
    residentId != null ? { label: residentName, id: residentId } : null
  );
  const [template, setTemplate] = useState();
  const [participant, setParticipant] = useState("");
  const [type, setType] = useState("");

  const [residentsError, setResidentsError] = useState(false);
  const [templatesError, setTemplatesError] = useState(false);
  const [participantError, setParticipantError] = useState(false);
  const [typeError, setTypeError] = useState(false);
  const residentRef = useRef();
  const templateRef = useRef();
  const { residents, setResidents } = useResidents();
  const [templates, setTemplates] = useState([]);

  const setParticipantAndResetError = (val) => {
    setParticipant(val);
    setParticipantError(false);
  };

  const setTypeAndResetError = (val) => {
    setType(val);
    setTypeError(false);
  };

  useEffect(() => {
    async function fetchData() {
      residentRef.current.focus();
      if (residents == null) {
        try {
          const response = await axios.get("/residents/list", {
            headers: { "Content-Type": "application/json" },
          });
          setResidents(response.data);
        } catch (err) {
          if (!err?.response) {
            setError("Failed to fetch the list of users in this facility. Please try again");
          }
        }
      }
      try {
        const response = await axios.get("/satisfaction/templates", {
          headers: { "Content-Type": "application/json" },
        });
        setTemplates(response.data);
      } catch (err) {
        if (!err?.response) {
          setError("Failed to fetch the list of templates. Please try again");
        }
      }
    }
    fetchData();
  }, [axios, residents, setError, setResidents]);

  const tryAdd = () => {
    let error = false;
    if (templateInputValue == null || templateInputValue === "") {
      error = true;
      setTemplatesError(true);
      templateRef.current.focus();
    }
    if (residentInputValue == null || residentInputValue === "") {
      error = true;
      setResidentsError(true);
      residentRef.current.focus();
    }

    if (participant == null || participant === "") {
      error = true;
      setParticipantError(true);
    }
    if (type == null || type === "") {
      error = true;
      setTypeError(true);
    }
    if (!error) {
      closeModal({
        action: "start",
        resident,
        template,
        participant,
        type,
      });
    }
  };

  const residentsList =
    residents != null
      ? residents.map((res) => ({
          label: res.fullName,
          id: res.id,
          room: res.room,
          url: res.url,
        }))
      : [];

  const templateList =
    templates != null
      ? templates.map((temp) => ({
          label: temp.name,
          id: temp.id,
        }))
      : [];
  const participantType = [
    { value: "resident", label: "Resident" },
    { value: "family", label: "Family Member" },
  ];

  const typeList = [
    { value: "intitial", label: "Initial" },
    { value: "midstay", label: "Mid-stay" },
    { value: "discharge", label: "Discharge" },
  ];

  return (
    <div style={{ maxWidth: 600 }}>
      <MDBox display="flex" flexDirection="column" textAlign="center">
        <MDTypography
          display="inline"
          variant="h6"
          textTransform="capitalize"
          fontWeight="bold"
          textAlign="center"
        >
          New Resident Satisfaction Survey
        </MDTypography>
      </MDBox>
      <Grid container spacing={1} mb={1} mt={0.5}>
        <Grid container mb={1} mt={1}>
          <Grid item xs={2.5} textAlign="right" mr={1}>
            <MDTypography display="inline" variant="body2" textTransform="capitalize">
              Resident
            </MDTypography>
          </Grid>
          <Grid item xs={8.5} textAlign="left" pr={2}>
            <Autocomplete
              isOptionEqualToValue={(option, value) => option.id === value.id}
              value={resident || null}
              onChange={(event, newValue) => {
                setResidentsError(false);
                setResident(newValue);
              }}
              inputValue={residentInputValue}
              onInputChange={(event, newInputValue) => {
                setResidentInputValue(newInputValue);
              }}
              options={residentsList}
              renderInput={(params) => (
                <TextField
                  inputRef={residentRef}
                  error={residentsError}
                  helperText={residentsError ? "*Resident is required" : ""}
                  {...params}
                  placeholder="Enter or select a Resident..."
                />
              )}
              renderOption={(props, option, { inputValue }) => {
                const matches = match(option.label, inputValue, { insideWords: true });
                const parts = parse(option.label, matches);

                const bgColor = inputValue === option.label ? colors.grey[200] : null;
                return (
                  <li {...props} style={{ backgroundColor: bgColor }}>
                    <MDBox display="flex">
                      <span style={{ marginRight: 10 }}>
                        <MDAvatar bgColor="light" src={option.url} alt={option.label} size="xs" />
                      </span>
                      {parts.map((part) => (
                        <span
                          key={part.text + new Date().getTime()}
                          style={{
                            fontWeight: part.highlight ? 700 : 400,
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          {part.text}
                        </span>
                      ))}
                      {option.room != null && option.room !== "" && (
                        <span style={{ color: colors.grey[500], fontSize: 12 }}>
                          &nbsp;[Room:{option.room}]
                        </span>
                      )}
                    </MDBox>
                  </li>
                );
              }}
            />
          </Grid>
        </Grid>
        <Grid container mb={1} mt={1}>
          <Grid item xs={2.5} textAlign="right" mr={1}>
            <MDTypography display="inline" variant="body2" textTransform="capitalize">
              Template
            </MDTypography>
          </Grid>
          <Grid item xs={8.5} textAlign="left" pr={2}>
            <Autocomplete
              isOptionEqualToValue={(option, value) => option.id === value.id}
              value={template || null}
              onChange={(event, newValue) => {
                setTemplatesError(false);
                setTemplate(newValue);
              }}
              inputValue={templateInputValue}
              onInputChange={(event, newInputValue) => {
                setTemplateInputValue(newInputValue);
              }}
              options={templateList}
              renderInput={(params) => (
                <TextField
                  inputRef={templateRef}
                  error={templatesError}
                  helperText={templatesError ? "*Template is required" : ""}
                  {...params}
                  placeholder="Enter or select a Template..."
                />
              )}
              renderOption={(props, option, { inputValue }) => {
                const matches = match(option.label, inputValue, { insideWords: true });
                const parts = parse(option.label, matches);

                const bgColor = inputValue === option.label ? colors.grey[200] : null;
                return (
                  <li {...props} style={{ backgroundColor: bgColor }}>
                    <MDBox display="flex">
                      {parts.map((part) => (
                        <span
                          key={part.text + new Date().getTime()}
                          style={{
                            fontWeight: part.highlight ? 700 : 400,
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          {part.text}
                        </span>
                      ))}
                    </MDBox>
                  </li>
                );
              }}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xl={2.5} xs={2.5} textAlign="right" mb={1} mt={1} mr={1}>
            <MDTypography display="inline" variant="body2" textTransform="capitalize">
              Participant
            </MDTypography>
          </Grid>
          <Grid item xl={4.2} xs={8.5} textAlign="left" mb={1} mt={1}>
            <MDBox mr={2}>
              <MDSelect
                list={participantType}
                placeholder="Select a Participant..."
                value={participant}
                setValue={setParticipantAndResetError}
                errorMessage={participantError ? "*Participant is required" : null}
              />
            </MDBox>
          </Grid>
          <Grid item xl={0.8} xs={2.5} textAlign="right" mb={1} mt={1} mr={1}>
            <MDTypography display="inline" variant="body2" textTransform="capitalize">
              Type
            </MDTypography>
          </Grid>
          <Grid item xl={3.3} xs={8.5} textAlign="left" mb={1} mt={1}>
            <MDBox mr={2}>
              <MDSelect
                list={typeList}
                placeholder="Select a Type..."
                value={type}
                setValue={setTypeAndResetError}
                errorMessage={typeError ? "*Type is required" : null}
              />
            </MDBox>
          </Grid>
        </Grid>
      </Grid>
      <MDBox p={2} display="flex" textAlign="center" alignItems="center" justifyContent="center">
        <MDBox mr={1}>
          <MDButton
            onClick={() => closeModal({ action: "cancel" })}
            variant="gradient"
            color="light"
            size="medium"
            m={1}
          >
            cancel
          </MDButton>
        </MDBox>
        <MDBox mr={1}>
          <MDButton onClick={tryAdd} variant="gradient" color="primary" size="medium">
            start survey
          </MDButton>
        </MDBox>
      </MDBox>
    </div>
  );
};

export default StartSatisfactionSurveyModal;
