import PropTypes from "prop-types";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import useModal from "hooks/useModal";
import ScopeModal from "components/modal/ScopeModal";
import { makeStyles } from "@material-ui/styles";
import colors from "assets/theme/base/colors";
import { useState } from "react";

function ScopeCell({ scope, ftag, index, callback }) {
  const [scopeState, setScopeState] = useState(scope ?? "?");
  const { openModal } = useModal();

  const modalReturnedValue = (result) => {
    setScopeState(result.scope);
    callback(result.scope, index);
  };

  const openScopeSelect = (e) => {
    e.stopPropagation();
    openModal(<ScopeModal ftag={ftag} selected={scopeState} />, modalReturnedValue);
  };

  const useStyles = makeStyles({
    button: {
      "&:hover": {
        color: colors.primary.main,
      },
    },
  });

  const classes = useStyles();

  return (
    <MDBox display="flex" alignItems="center">
      <MDBox mr={1} display="flex" flexDirection="column">
        <MDTypography
          variant="body2"
          color="text"
          mb={0.5}
          fontWeight="medium"
          style={{ "&:hover": { color: "red !important" } }}
          className={classes.button}
          onClick={openScopeSelect}
          px={2}
        >
          {scopeState}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

// Typechecking props for the StatusCell
ScopeCell.propTypes = {
  scope: PropTypes.string.isRequired,
};

export default ScopeCell;
