import colors from "assets/theme/base/colors";
import { FormControl, FormHelperText, MenuItem, Select } from "@mui/material";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  select_text: {
    color: colors.grey[500],
  },
  error: {
    borderWidth: "2px",
    borderColor: `${colors.error.main} !important`,
  },
});

const MDSelect = ({ errorMessage, list, placeholder, value, setValue }) => {
  const classes = useStyles();
  return (
    <FormControl error={errorMessage != null} style={{ width: "100%" }}>
      <Select
        style={{ height: 40 }}
        displayEmpty
        value={value}
        onChange={(event) => {
          setValue(event.target.value);
        }}
        inputProps={{
          classes: {
            select: value === "" ? classes.select_text : null,
            error: classes.error,
          },
        }}
        renderValue={(val) =>
          val === "" ? placeholder : list.find((x) => x.value === value)?.label
        }
      >
        {list.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{errorMessage}</FormHelperText>
    </FormControl>
  );
};

export default MDSelect;
