/* eslint-disable no-nested-ternary */
import PropTypes from "prop-types";
import MDTypography from "components/MDTypography";
import colors from "assets/theme/base/colors";
import Skeleton from "components/skeletons/Skeleton";

function SatisfactionScoreCell({ value, isLoading }) {
  if (isLoading) {
    return <Skeleton width={40} height={20} />;
  }
  const percent = isLoading ? 0 : value / 10;
  return (
    <MDTypography
      lineHeight="4"
      variant="h6"
      fontWeight="medium"
      color={percent >= 0.7 ? "success" : percent >= 0.4 ? "warning" : "error"}
    >
      <span style={{ fontSize: 16 }}>{value}</span>
      <span style={{ fontSize: 13, color: colors.grey[500] }}>/10</span>
    </MDTypography>
  );
}

// Typechecking props for the FtagCell
SatisfactionScoreCell.propTypes = {
  value: PropTypes.number.isRequired,
};

export default SatisfactionScoreCell;
