/* eslint-disable no-nested-ternary */
import { Checkbox, FormControlLabel, FormHelperText, Grid, MenuItem, Select } from "@mui/material";
import "./confirmModal.css";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import useModal from "hooks/useModal";
import { useRef, useState } from "react";
import useBackendError from "hooks/useBackendError";

import MDBox from "components/MDBox";

import MDHr from "components/MDHr";
import useAuth from "hooks/useAuth";
import colors from "assets/theme/base/colors";
import Scrollbars from "react-custom-scrollbars";
import MDLink from "components/MDLink";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { isValidEmailId } from "utils/utils";

import MDTagInput from "components/MDTagInput/MDTagInput";

const EmailNewUsersModal = () => {
  const [isSuccess, setSuccess] = useState(false);
  const axios = useAxiosPrivate();
  const { closeModal } = useModal();
  const [trying, setTrying] = useState(false);
  const { auth } = useAuth();

  const [selected, setSelected] = useState([]);
  const emailErrors = useRef([]);
  const [emailIdError, setEmailIdError] = useState("");

  const setSelectedFn = (vals) => {
    emailErrors.current = vals.filter((val) => !isValidEmailId(val));
    setSelected(vals);
    setEmailIdError("");
  };

  const { setError } = useBackendError();

  const [access, setAccess] = useState("user");

  const [facilityError, setFacilityError] = useState("");

  const [facilities, setFacilities] = useState(
    auth?.profile?.facilities.map((fac) => ({
      name: fac.name,
      id: fac.id,
      selected: false,
    }))
  );
  const facScrollHeight = facilities.length === 1 ? 40 : facilities.length === 2 ? 75 : 95;

  const setFacilityAccessFn = (event, index) => {
    facilities[index].selected = event.target.checked;
    setFacilities([...facilities]);
    setFacilityError("");
  };

  const tryCreate = async () => {
    setTrying(true);
    let error = false;

    if (selected.length === 0) {
      setEmailIdError("* At least one email Id is required");
      error = true;
    } else if (emailErrors.current.length > 0) {
      setEmailIdError("* One or more email Ids are invalid");
      error = true;
    }

    if (facilities.filter((fac) => fac.selected).length === 0) {
      setFacilityError("* You need to choose at least one facility");
      error = true;
    }
    setTrying(false);
    if (!error) {
      setTrying(true);
      try {
        const user = {
          emails: selected,
          facilities: facilities.map((fac) => ({ id: fac.id })),
          admin: access === "admin",
        };
        await axios.post(`/users`, user, {
          headers: { "Content-Type": "application/json" },
        });
        setSuccess(true);
      } catch (err) {
        setError("Failed to post to the backend. Please try again");
      }
    }
  };

  const selectAll = () => {
    const facs = facilities.map((facility) => ({
      name: facility.name,
      id: facility.id,
      selected: true,
    }));
    setFacilities(facs);
    setFacilityError("");
  };

  const deselectAll = () => {
    const facs = facilities.map((facility) => ({
      name: facility.name,
      id: facility.id,
      selected: false,
    }));
    setFacilities(facs);
    setFacilityError("");
  };

  const createAnother = () => {
    setSelected([]);
    setAccess("user");
    deselectAll();
    setTrying(false);
    setSuccess(false);
  };

  if (isSuccess) {
    return (
      <div style={{ minWidth: 300 }}>
        <MDBox display="flex" justifyContent="center" alignItems="center" flexDirection="column">
          <MDTypography display="inline" variant="h6" textTransform="capitalize" fontWeight="bold">
            Successfully sent!
          </MDTypography>

          <div className="successContainer">
            <div className="successOne" />
            <span className="successTwo" />
            <span className="successThree" />
            <div className="successFour" />
            <div className="successFive" />
            <div className="successSix" />
          </div>

          <MDTypography display="inline" variant="body2" textAlign="center">
            An email invitation to register as a new user has been sent to the given email Id(s).
          </MDTypography>

          <MDBox flexDirection="row" display="flex" m={1}>
            <MDBox m={1}>
              <MDButton onClick={createAnother} variant="gradient" color="info" size="medium">
                email another
              </MDButton>
            </MDBox>
            <MDBox m={1}>
              <MDButton
                onClick={() => closeModal({ action: "done" })}
                variant="gradient"
                color="primary"
                size="medium"
              >
                done
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </div>
    );
  }

  return (
    <div style={{ maxWidth: 600 }}>
      <MDBox display="flex" flexDirection="column" textAlign="center">
        <MDTypography
          display="inline"
          variant="h6"
          textTransform="capitalize"
          fontWeight="bold"
          textAlign="center"
        >
          Email Invite
        </MDTypography>
      </MDBox>

      <Grid container spacing={1} mt={1}>
        <Grid item xs={12}>
          <MDTypography variant="body2" textAlign="left">
            Email Id(s)
          </MDTypography>
        </Grid>
        <Grid item xs={12}>
          <MDTagInput
            error={emailIdError !== ""}
            value={selected}
            onChange={setSelectedFn}
            placeholder="Email Ids separated by comma"
            errorValues={emailErrors.current}
          />
          <FormHelperText error>{emailIdError !== "" ? emailIdError : null}</FormHelperText>
        </Grid>
      </Grid>
      <MDBox mt={2}>
        <MDBox display="flex" width={220} justifyContent="space-between">
          <MDTypography variant="body2" textAlign="left">
            Facilities
          </MDTypography>
          {facilities?.length > 1 && (
            <>
              <MDLink text="Select All" onClick={selectAll} />
              <MDLink text="Deselect All" onClick={deselectAll} />
            </>
          )}
        </MDBox>

        <Scrollbars
          style={{
            height: facScrollHeight,
            backgroundColor: colors.grey[100],
            borderRadius: 8,
            marginTop: 6,
            marginBottom: 6,
            border: facilityError === "" ? null : `1px solid ${colors.error.main}`,
          }}
          renderTrackHorizontal={({ style, ...props }) => <div />}
          renderTrackVertical={({ style, ...props }) => (
            <div className="scrollbar-regular-vertical-track" {...props} />
          )}
          renderThumbVertical={({ style, ...props }) => (
            <div
              style={{
                ...style,
                backgroundColor: "rgba(0, 0, 0, 0.2)",
                borderRadius: 8,
                width: 6,
                cursor: "pointer",
              }}
              {...props}
            />
          )}
        >
          <MDBox py={1} height="100%" style={{ borderColor: "red" }}>
            {facilities.map((facility, index) => (
              <MDBox py={0.2} pl={2} pr={3} key={`facility-${facility.id}`}>
                <MDBox display="flex" my={-0.8}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value="notification"
                        checked={facility.selected}
                        onChange={(event) => setFacilityAccessFn(event, index)}
                      />
                    }
                    label={
                      <MDTypography display="inline" variant="body2">
                        {facility.name}
                      </MDTypography>
                    }
                  />
                </MDBox>

                {index !== facilities.length - 1 && (
                  <MDHr color={colors.grey[300]} style={{ width: "100%", marginTop: 6 }} />
                )}
              </MDBox>
            ))}
          </MDBox>
        </Scrollbars>
        <FormHelperText error>{facilityError !== "" ? facilityError : null}</FormHelperText>
      </MDBox>
      <Grid item xs={12} lg={4} textAlign="left">
        <MDTypography variant="body2" textAlign="left">
          Access Type
        </MDTypography>

        <Select
          value={access}
          onChange={(event) => {
            setAccess(event.target.value);
          }}
          style={{ height: 40, width: "100%" }}
        >
          <MenuItem value="user">User</MenuItem>
          <MenuItem value="admin">Admin</MenuItem>
        </Select>
      </Grid>
      <MDTypography variant="body2" my={3}>
        An email will be send to the email Id(s) provided above, with instructions on how to
        register for user credentials.
      </MDTypography>

      <MDBox
        px={2}
        pb={2}
        display="flex"
        textAlign="center"
        alignItems="center"
        justifyContent="center"
      >
        <MDBox mr={1}>
          <MDButton
            onClick={() => closeModal({ action: "cancel" })}
            variant="gradient"
            color="light"
            size="medium"
            m={1}
          >
            cancel
          </MDButton>
        </MDBox>
        <MDBox mr={1}>
          <MDButton
            onClick={tryCreate}
            variant="gradient"
            color="primary"
            size="medium"
            loading={trying}
            loadingText="processing..."
            style={{ width: 160 }}
          >
            send Email
          </MDButton>
        </MDBox>
      </MDBox>
    </div>
  );
};

export default EmailNewUsersModal;
