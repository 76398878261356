/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-lonely-if */
/* eslint-disable no-param-reassign */
import colors from "assets/theme/base/colors";
import { Switch } from "@mui/material";
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { makeStyles } from "@material-ui/styles";
import Icon from "@mui/material/Icon";
import ConfirmDeleteModal from "components/modal/ConfirmDeleteModal";
import useModal from "hooks/useModal";
import React from "react";

const BarriersAndGoalsExisting = ({
  isMobile,
  data,
  index,
  setData,
  onClick,
  deleteMe,
  editMe,
  isGoal,
}) => {
  const { openModal } = useModal();

  const useStyles = makeStyles(() => ({
    switch_track: {
      backgroundColor: colors.warning.main,
      borderColor: `${colors.grey[500]} !important`,
    },
    switch_base: {
      borderColor: `${colors.grey[500]} !important`,
      color: colors.warning.main,
      "&.Mui-disabled": {
        color: "#e886a9",
      },
      "&.Mui-checked": {
        color: "#95cc97",
        borderColor: `${colors.grey[500]} !important`,
      },
      "&.Mui-checked + .MuiSwitch-track": {
        backgroundColor: "#4CAF50 !important",
        borderColor: `${colors.grey[500]} !important`,
      },
    },
    switch_primary: {
      borderColor: `${colors.grey[500]} !important`,
      "&.Mui-checked": {
        color: "#4CAF50  !important",
      },
      "&.Mui-checked + .MuiSwitch-track": {
        backgroundColor: "#4CAF50  !important",
      },
    },
    button_delete: {
      "&:hover": {
        color: colors.error.main,
      },
    },
    button_edit: {
      "&:hover": {
        color: colors.info.main,
      },
    },
  }));

  const classes = useStyles();

  const handleStatus = (event) => {
    if (event.target.checked) {
      data.status = "COMPLETED";
    } else {
      data.status = "ON_GOING";
    }
    setData(data, index);
  };

  const deleteCurrent = async () => {
    deleteMe(index, isGoal);
    return "success";
  };

  const editCurrent = () => {
    editMe(index, isGoal);
  };

  const deleteNow = () => {
    openModal(
      <ConfirmDeleteModal
        title={`Delete ${isGoal ? "Goal?" : "Barrier?"}`}
        text={`Do you really want to delete the seleted ${isGoal ? "goal?" : "barrier?"}?`}
        successText="The barrier has been successfully deleted."
        callback={deleteCurrent}
      />,
      () => {}
    );
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus
    <div role="row" className="barriers-goals">
      <MDBox
        borderRadius="lg"
        shadow="md"
        sx={{ border: `1px solid ${colors.grey[500]}` }}
        className="barriers-goals"
      >
        <MDBox
          display="flex"
          justifyContent="space-between"
          flexDirection={isMobile ? "column" : "row"}
        >
          <MDBox p={1} style={{ width: isMobile ? "100%" : "calc(100% - 220px)" }}>
            <MDTypography
              variant="body2"
              fontSize={14}
              onClick={onClick}
              sx={{ cursor: "pointer" }}
            >
              {data.text}
            </MDTypography>
          </MDBox>
          <MDBox style={{ minWidth: "220px", textAlign: "right" }}>
            <MDBadge
              badgeContent={data.status === "COMPLETED" ? "resolved" : "On-going"}
              container
              color={data.status === "COMPLETED" ? "success" : "warning"}
              sx={{ marginRight: 1, marginTop: 0.2 }}
            />
            <Switch
              classes={{
                track: classes.switch_track,
                switchBase: classes.switch_base,
                colorPrimary: classes.switch_primary,
              }}
              color="secondary"
              checked={data.status === "COMPLETED"}
              onChange={handleStatus}
            />

            <MDButton
              variant="text"
              color="secondary"
              iconOnly
              circular
              sx={{ marginLeft: -1 }}
              className={classes.button_edit}
              onClick={editCurrent}
            >
              <Icon>edit</Icon>
            </MDButton>
            <MDButton
              variant="text"
              color="secondary"
              iconOnly
              circular
              sx={{ marginLeft: -1 }}
              className={classes.button_delete}
              onClick={deleteNow}
            >
              <Icon>delete</Icon>
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </div>
  );
};

export default React.memo(BarriersAndGoalsExisting);
