/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/prop-types */
import { createContext, useState } from "react";

const SizeContext = createContext({});

export const SizeProvider = ({ children }) => {
  const [scrollY, setScrollY] = useState({});

  return <SizeContext.Provider value={{ scrollY, setScrollY }}>{children}</SizeContext.Provider>;
};

export default SizeContext;
