/* eslint-disable no-param-reassign */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-array-index-key */

import { useEffect } from "react";

import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import colors from "assets/theme/base/colors";

import dayjs from "dayjs";
import MDAvatar from "components/MDAvatar";
import TimelineItem from "components/Timeline/TimelineItem";
import Skeleton from "components/skeletons/Skeleton";

const relativeTime = require("dayjs/plugin/relativeTime");

dayjs.extend(relativeTime);

const MDSDetails = ({ data, showTime, isLoading }) => {
  if (isLoading) {
    data = { updates: [{}, {}, {}] };
  }

  if (isLoading) {
    return (
      <MDBox m={1}>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
          pt={1}
          pb={1}
          pl={1}
          bgColor={colors.grey[300]}
          borderRadius={25}
          width="100%"
          style={{ minWidth: 600 }}
        >
          <MDBox display="flex" justifyContent="space-between" alignItems="center">
            <MDAvatar bgColor="light" size="sm" />
            <MDBox ml={1}>
              <MDTypography variant="body2" color="dark" sx={{ fontSize: 14 }}>
                <Skeleton width={240} height={18} dark />
              </MDTypography>
              <MDTypography variant="body2" color="text" sx={{ fontSize: 12 }}>
                <Skeleton width={80} height={16} dark />
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDBox display="flex" justifyContent="space-between" alignItems="center">
            <MDBox>
              <MDTypography
                variant="body2"
                fontWeight="medium"
                color="dark"
                sx={{ fontSize: 14, marginRight: 1 }}
                textAlign="right"
              >
                <Skeleton width={260} height={18} dark />
              </MDTypography>
            </MDBox>

            <Skeleton width={80} height={32} margin="0px 16px" dark />
          </MDBox>
        </MDBox>
        <MDBox p={2}>
          <div
            style={{
              height: 24,
              borderLeft: `2px solid ${colors.grey[300]}`,
              marginLeft: 17,
              position: "relative",
              top: -16,
              marginBottom: -22,
            }}
          />
          {data.updates.map((up, index) => (
            <TimelineItem
              key={`${up.key}-item${index}`}
              isLoading
              color="light"
              lastItem={index === data.updates.length - 1}
            />
          ))}
        </MDBox>
      </MDBox>
    );
  }

  return (
    <MDBox m={1}>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
        pt={1}
        pb={1}
        pl={1}
        bgColor={colors.grey[300]}
        borderRadius={25}
        width="100%"
        style={{ minWidth: 600 }}
      >
        <MDBox display="flex" justifyContent="space-between" alignItems="center">
          <MDAvatar
            bgColor="dark"
            src="https://i.postimg.cc/3NSQ7qr5/pexels-karolina-grabowska-8528405.jpg"
            size="sm"
          />
          <MDBox ml={1}>
            <MDTypography variant="body2" color="dark" sx={{ fontSize: 14 }}>
              {data.resident.fullName}
            </MDTypography>
            <MDTypography variant="body2" color="text" sx={{ fontSize: 12 }}>
              Room: {data.resident.room}
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox display="flex" justifyContent="space-between" alignItems="center">
          <MDBox>
            <MDTypography
              variant="body2"
              fontWeight="medium"
              color="dark"
              sx={{ fontSize: 14, marginRight: 1 }}
              textAlign="right"
            >
              {data.filename}
            </MDTypography>
            {showTime && (
              <MDTypography
                variant="body2"
                color="text"
                textAlign="right"
                sx={{ fontSize: 14, marginRight: 1 }}
              >
                {dayjs.unix(data.uploadedDate).format("MMM DD, YYYY hh:mm A")} ({" "}
                {dayjs(dayjs.unix(data.uploadedDate)).fromNow()})
              </MDTypography>
            )}
          </MDBox>

          <MDButton variant="gradient" color="info" size="medium" style={{ marginRight: 10 }}>
            download
          </MDButton>
        </MDBox>
      </MDBox>
      <MDBox p={2}>
        <div
          style={{
            height: 24,
            borderLeft: `2px solid ${colors.grey[300]}`,
            marginLeft: 17,
            position: "relative",
            top: -16,
            marginBottom: -22,
          }}
        />
        {data.updates.map((log, index) => (
          <TimelineItem
            key={`activity-${index}`}
            color={log.color}
            icon={log.icon}
            title={log.title}
            lastItem={index === data.updates.length - 1}
          />
        ))}
      </MDBox>
    </MDBox>
  );
};
export default MDSDetails;
