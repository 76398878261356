import colors from "assets/theme/base/colors";
import Tooltip from "@mui/material/Tooltip";

const DisabledRadio = ({ checked, tooltip }) => (
  <Tooltip title={tooltip} enterDelay={1000}>
    <div
      style={{
        borderRadius: "50%",
        width: 20,
        height: 20,
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: colors.grey[300],
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 2,
      }}
    >
      {checked && (
        <div
          style={{
            borderRadius: "50%",
            width: 14,
            height: 14,
            backgroundColor: colors.grey[500],
          }}
        />
      )}
    </div>
  </Tooltip>
);

export default DisabledRadio;
