/* eslint-disable no-useless-return */
/* eslint-disable no-else-return */
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { Icon } from "@mui/material";

import { useEffect, useState } from "react";

import Timeline from "components/Timeline";
import StarRating from "components/StarRating/StarRating";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import useBackendError from "hooks/useBackendError";
import useModal from "hooks/useModal";
import SavedFivestarModal from "components/modal/SavedFivestarModal";
import StartFivestarModal from "components/modal/StartFivestarModal";
import { useNavigate } from "react-router-dom";

const FiveStarOverviewMain = () => {
  const axios = useAxiosPrivate();
  const { setError } = useBackendError();
  const [width, setWidth] = useState(window.innerWidth);
  const [saved, setSaved] = useState();
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 985;

  const setTimeline = () => {};
  const { openModal } = useModal();
  const navigate = useNavigate();

  const simulator = (e) => {
    navigate(`/5-star-rating/simulator`);
  };

  const redirectToFiveStarStep = async (args) => {
    console.log(args); // all the datetime info for this calculation
    let id = -1;
    let response;
    try {
      response = await axios.post(`/fivestar/start`, {
        headers: { "Content-Type": "application/json" },
      });
      if (response?.data != null) {
        id = response.data.id;
      }
    } catch (err) {
      setError("Something went wrong. Please try again or you might hit an error in the flow.");
    }
    navigate(`/5-star-rating/calculate?id=${id}`);
  };

  const calculate = (id, step = 0) => {
    if (step === 0) {
      setTimeout(() => {
        openModal(<StartFivestarModal />, redirectToFiveStarStep);
      }, 100);
    } else {
      navigate(`/5-star-rating/calculate?id=${id}`);
    }
  };

  const modalReturnedValue = (result) => {
    if (result.action === "cancel") {
      return;
    } else if (result.action === "new") {
      calculate(null, 0);
    } else {
      calculate(saved.id, saved.step);
    }
  };

  const newCalculation = async () => {
    if (saved == null) {
      calculate(null, 0);
    } else {
      openModal(<SavedFivestarModal />, modalReturnedValue);
    }
  };

  const continueSaved = () => {
    calculate(saved.id, saved.step);
  };

  useEffect(() => {
    async function fetchData() {
      let response;
      try {
        response = await axios.get(`/fivestar/saved`, {
          headers: { "Content-Type": "application/json" },
        });
        if (response?.data != null) {
          setSaved(response.data);
        }
      } catch (err) {
        if (err.response.status !== 404) {
          setError("Failed to fetch from the backend. Please try again");
        }
      }
    }
    fetchData();
  }, [axios, setError]);

  return (
    <Grid container spacing={3}>
      <Grid item style={{ width: isMobile ? "100%" : "calc(100% - 540px)" }}>
        <Card id="compliance-overview" style={{ height: "100%" }}>
          <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
            <MDBox
              color="white"
              bgColor="info"
              variant="gradient"
              borderRadius="lg"
              shadow="lg"
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="2.5rem"
              pl={2}
              pr={2}
              mt={-4}
            >
              <MDTypography variant="h6" fontWeight="medium" color="white">
                5 Star Rating
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexWrap="wrap"
            pl={3}
            pr={3}
          >
            <MDBox pt={2} pb={2} fontSize="1rem" style={{ textAlign: "center", width: "100%" }}>
              Get insights and improve your 5 Star Rating ahead of CMS. <br />
              Try the 5 star simulator to see how the rating changes in realtime.
            </MDBox>
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexWrap="wrap"
              pb={1}
            >
              {saved != null && (
                <MDButton
                  variant="gradient"
                  color="info"
                  style={{ margin: 5 }}
                  onClick={continueSaved}
                >
                  continue saved &nbsp;<Icon sx={{ fontWeight: "bold" }}>bookmark</Icon>
                </MDButton>
              )}

              <MDButton variant="gradient" color="primary" onClick={newCalculation} size="medium">
                new calculation &nbsp;
                <Icon sx={{ fontWeight: "bold" }}>auto_awesome</Icon>
              </MDButton>
              <MDButton
                variant="gradient"
                color="dark"
                style={{ margin: 5 }}
                loadingText="submitting..."
                onClick={simulator}
              >
                <Icon sx={{ fontWeight: "bold" }}>tune</Icon>
                &nbsp;simulator
              </MDButton>
            </MDBox>
          </MDBox>
        </Card>
      </Grid>

      <Grid item style={{ minWidth: isMobile ? "100%" : "500px", minHeight: "100%" }}>
        <Card id="saved-qapi" style={{ height: "100%" }}>
          <MDBox py={1} style={{ height: "100%" }}>
            <MDBox
              pt={2}
              px={2}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              style={{ minHeight: "100%" }}
            >
              <MDBox
                color="white"
                bgColor="success"
                variant="gradient"
                borderRadius="lg"
                shadow="lg"
                justifyContent="center"
                alignItems="center"
                pl={2}
                pr={2}
                mt={-5}
                position="relative"
                style={{ width: "100%" }}
              >
                <MDBox display="flex" justifyContent="center" alignItems="center" pt={1}>
                  <MDBox textAlign="right" sx={{ marginRight: 2 }}>
                    <MDTypography variant="body" fontWeight="bold" color="white">
                      Overall Star Rating (4 stars)
                    </MDTypography>
                  </MDBox>
                  <MDBox>
                    <StarRating rating={2} fontSize="large" />
                  </MDBox>
                </MDBox>
                <div style={{ borderBottom: "1px solid white" }} />
                <MDBox display="flex" justifyContent="center" alignItems="center" p={1}>
                  <MDBox textAlign="right" sx={{ marginRight: 2 }}>
                    <MDTypography variant="body2" color="white" sx={{ marginBottom: 0.6 }}>
                      Health Inspection Rating (4 stars)
                    </MDTypography>
                    <MDTypography variant="body2" color="white" sx={{ marginBottom: 0.6 }}>
                      Staff Rating (3 stars)
                    </MDTypography>
                    <MDTypography variant="body2" color="white" sx={{ marginBottom: 0.6 }}>
                      Quality Measures Rating (5 stars)
                    </MDTypography>
                  </MDBox>
                  <MDBox>
                    <MDBox>
                      <StarRating rating={2} />
                    </MDBox>
                    <MDBox>
                      <StarRating rating={4} />
                    </MDBox>
                    <MDBox>
                      <StarRating rating={3} />
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDBox>
            </MDBox>
          </MDBox>
          <MDBox pl={3} pr={3} pb={1} display="flex" justifyContent="space-between">
            <MDBox pt={1}>
              <Timeline setTimeline={setTimeline} />
            </MDBox>
            <MDButton variant="outlined" color="text" size="small">
              DETAILS
            </MDButton>
          </MDBox>
        </Card>
      </Grid>
    </Grid>
  );
};

export default FiveStarOverviewMain;
