import { Visibility } from "@mui/icons-material";
import { Icon, Slider } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import useBackendError from "hooks/useBackendError";
import useModal from "hooks/useModal";
import { useRef, useState } from "react";
import AvatarEditor from "react-avatar-editor";

// Container
const UploadProfilePictureModal = ({ initialImageUrl }) => {
  // image src
  const [src, setSrc] = useState(null);
  const { closeModal } = useModal();
  const axios = useAxiosPrivate();
  const { setError } = useBackendError();
  const [canvas, setCanvas] = useState();

  // preview
  const [preview, setPreview] = useState(null);

  // modal state
  const [step, setStep] = useState(0);

  // ref to control input element
  const inputRef = useRef(null);

  // handle Click
  const handleInputClick = (e) => {
    e.preventDefault();
    inputRef.current.click();
  };
  // handle Change
  const handleImgChange = (e) => {
    setSrc(URL.createObjectURL(e.target.files[0]));
    setStep(1);
  };

  const [slideValue, setSlideValue] = useState(10);
  const cropRef = useRef(null);

  // handle next
  const handleNext = async () => {
    if (cropRef) {
      const dataUrl = cropRef.current.getImage().toDataURL();
      const result = await fetch(dataUrl);
      const blob = await result.blob();
      setPreview(URL.createObjectURL(blob));
      setCanvas(cropRef.current.getImageScaledToCanvas());
      setStep(2);
    }
  };

  const handleSave = async () => {
    // Step 1: Get canvas from editor and convert to Blob

    canvas.toBlob(async (blob) => {
      // Step 2: Prepare FormData and append Blob as 'file'
      const formData = new FormData();
      formData.append("files", blob, "avatar.png");

      // Step 3: Send multipart request
      try {
        const response = await axios.post("/files/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        if (response.status === 200) {
          setStep(3);
        }
      } catch (error) {
        setError("Failed to upload to the backend. Please try again");
      }
    }, "image/png"); // Specify the Blob type (e.g., PNG or JPEG)
  };

  const [position, setPosition] = useState({ x: 0.5, y: 0.5 }); // Centered initially

  const handlePositionChange = (newPosition) => {
    setPosition(newPosition);
  };

  return (
    <MDBox display="flex" justifyContent="center" alignItems="center" flexDirection="column">
      <MDTypography display="inline" variant="h6" textTransform="capitalize" fontWeight="bold">
        Upload Image
      </MDTypography>

      {step === 0 && (
        <>
          <MDTypography display="inline" variant="body2">
            Upload a new image to crop and adjust.
          </MDTypography>
          <MDBox p={1}>
            <img
              src={preview || initialImageUrl}
              alt=""
              width="200"
              height="200"
              style={{ borderRadius: "50%" }}
            />
          </MDBox>
          <MDBox display="flex">
            <MDButton
              type="color"
              variant="gradient"
              color="info"
              style={{ height: 40, margin: 5 }}
              onClick={closeModal}
            >
              cancel
            </MDButton>
            <MDButton
              type="color"
              variant="gradient"
              color="primary"
              style={{ height: 40, margin: 5 }}
              onClick={handleInputClick}
            >
              Upload Image
            </MDButton>
          </MDBox>

          <input
            type="file"
            accept="image/*"
            ref={inputRef}
            onChange={handleImgChange}
            style={{ Visibility: 0, opacity: 0 }}
          />
        </>
      )}
      {step === 1 && (
        <MDBox display="flex" flexDirection="column">
          <MDBox
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            p={1}
          >
            <MDTypography variant="body2">To position the picture, click and drag.</MDTypography>
            <MDTypography variant="body2">To zoom in/out, use the slider.</MDTypography>
          </MDBox>

          <AvatarEditor
            ref={cropRef}
            image={src}
            border={50}
            borderRadius={150}
            color={[0, 0, 0, 0.72]}
            scale={slideValue / 10}
            rotate={0}
            position={position} // Set initial position
            onPositionChange={handlePositionChange}
          />
          <Slider
            min={10}
            max={50}
            sx={{
              margin: "0 auto",
              width: "100%",
            }}
            size="medium"
            defaultValue={slideValue}
            value={slideValue}
            onChange={(e) => setSlideValue(e.target.value)}
          />
          <MDBox display="flex">
            <MDButton
              type="color"
              variant="gradient"
              color="info"
              style={{ height: 40, margin: 5 }}
              onClick={closeModal}
            >
              cancel
            </MDButton>
            <MDButton
              type="color"
              variant="gradient"
              color="info"
              style={{ height: 40, margin: 5 }}
              onClick={() => {
                setStep(0);
              }}
            >
              back
            </MDButton>
            <MDButton variant="gradient" color="primary" style={{ margin: 5 }} onClick={handleNext}>
              next
            </MDButton>
          </MDBox>
        </MDBox>
      )}
      {step === 2 && (
        <>
          <MDBox
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            p={1}
          >
            <MDTypography variant="body2">
              If the preview below looks good, please save it.
            </MDTypography>
          </MDBox>
          <MDBox p={1}>
            <img
              src={preview || " https://www.signivis.com/img/custom/avatars/member-avatar-01.png"}
              alt=""
              width="200"
              height="200"
              style={{ borderRadius: "50%" }}
            />
          </MDBox>
          <MDBox display="flex">
            <MDButton
              type="color"
              variant="gradient"
              color="info"
              style={{ height: 40, margin: 5 }}
              onClick={closeModal}
            >
              cancel
            </MDButton>
            <MDButton
              type="color"
              variant="gradient"
              color="info"
              style={{ height: 40, margin: 5 }}
              onClick={() => {
                setStep(1);
              }}
            >
              back
            </MDButton>
            <MDButton
              type="color"
              variant="gradient"
              color="primary"
              style={{ height: 40, margin: 5 }}
              onClick={handleSave}
            >
              save
            </MDButton>
          </MDBox>

          <input
            type="file"
            accept="image/*"
            ref={inputRef}
            onChange={handleImgChange}
            style={{ Visibility: 0, opacity: 0 }}
          />
        </>
      )}
      {step === 3 && (
        <div style={{ minWidth: 300 }}>
          <MDBox display="flex" justifyContent="center" alignItems="center" flexDirection="column">
            <div className="successContainer">
              <div className="successOne" />
              <span className="successTwo" />
              <span className="successThree" />
              <div className="successFour" />
              <div className="successFive" />
              <div className="successSix" />
            </div>

            <MDTypography display="inline" variant="body2" textAlign="center">
              Profile picture has ben successfully updated.
            </MDTypography>

            <MDBox flexDirection="row" display="flex" m={1}>
              <MDBox m={1}>
                <MDButton
                  onClick={() => closeModal({ action: "done" })}
                  variant="gradient"
                  color="info"
                  size="medium"
                  style={{ margin: 5 }}
                >
                  done
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </div>
      )}
    </MDBox>
  );
};

export default UploadProfilePictureModal;
