import Card from "@mui/material/Card";
import DataTable from "components/DataTable";

import RatingCell from "components/DataTable/components/RatingCell";

import RatingActionCell from "components/DataTable/components/ActionCells/RatingActionCell";
import TimestampCell from "components/DataTable/components/TimestampCell";
import React from "react";

const dataTableData = (rows, isLoading) => ({
  columns: [
    {
      Header: "Overall",
      accessor: "overall",
      isSorted: false,
      Cell: ({ value }) => <RatingCell rating={value} isLarge isLoading={isLoading} />,
    },
    {
      Header: "Health Inspection",
      accessor: "health",
      isSorted: false,
      Cell: ({ value }) => <RatingCell rating={value} isLoading={isLoading} />,
    },
    {
      Header: "Staff",
      accessor: "staff",
      isSorted: false,
      Cell: ({ value }) => <RatingCell rating={value} isLoading={isLoading} />,
    },
    {
      Header: "Quality Measures",
      accessor: "quality",
      isSorted: false,
      Cell: ({ value }) => <RatingCell rating={value} isLoading={isLoading} />,
    },
    {
      Header: "Date",
      accessor: "date",
      isSorted: false,
      Cell: ({ value }) => <TimestampCell timestamp={value} hideTime isLoading={isLoading} />,
    },
    {
      Header: "Actions",
      accessor: "action",
      isSorted: false,
      Cell: ({ value }) => <RatingActionCell id={value} isLoading={isLoading} />,
    },
  ],
  rows,
});

const FiveStarPast = ({ data, isLoading }) => {
  let rows = [];

  if (!isLoading) {
    rows = data?.map((row) => ({
      overall: row.overallRating,
      health: row.healthInspectionRating,
      staff: row.staffRating,
      quality: row.qualityMeasuresRating,
      date: row.date,
      action: row.id,
    }));
  } else {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 10; i++) {
      // eslint-disable-next-line no-new-object
      rows.push({
        overall: 0,
        health: 0,
        staff: 0,
        quality: 0,
        date: 0,
        action: 0,
      });
    }
  }

  if (rows == null) {
    return null;
  }

  return (
    <Card id="past-rating">
      <DataTable
        table={dataTableData(rows, isLoading)}
        title="Previous Rating Calculations"
        isSorted
        entriesPerPage={{ defaultValue: 5, hide: true }}
      />
    </Card>
  );
};

export default React.memo(FiveStarPast);
