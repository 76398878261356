/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */

import MDBox from "components/MDBox";
import { Autocomplete, TextField, Checkbox } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Icon from "@mui/material/Icon";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import colors from "assets/theme/base/colors";
import { useRef, useState } from "react";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Scrollbars from "react-custom-scrollbars";
import { useTransition, animated } from "react-spring";
import FTag from "./Ftag";

const AddInspectionNew = ({
  allFtags,
  number,
  inspections,
  inspectionIndex,
  setInspection,
  inspectionsUpdate,
}) => {
  const annual = inspections[inspectionIndex];

  const [fTags, setFTags] = useState(
    inspectionsUpdate?.fTags?.filter((tag) => tag.new === true) ?? []
  );

  const height = 60;
  const transitions = useTransition(
    fTags.map((dataVal, i) => ({ ...dataVal, y: i * height })),
    (d) => d.name,
    {
      from: { position: "absolute", opacity: 0, color: "blue" },
      leave: { height: 0, opacity: 0, color: "red" },
      enter: ({ y }) => ({ y, opacity: 1, color: "black" }),
      update: ({ y }) => ({ y }),
    }
  );

  const scrollbar = useRef();

  const fTagsToBeExcluded =
    number === 0
      ? annual?.fTags?.map((tag) => tag.name) ?? []
      : annual?.revisits[number - 1] != null
      ? annual?.revisits[number - 1]?.fTags?.map((tag) => tag.name)
      : [];

  const remainingFTagsOnly = useRef(
    allFtags
      ?.map((tag) => tag.name)
      .filter((el) => !fTagsToBeExcluded?.includes(el))
      .sort() ?? []
  );

  const allScope = ["A", "B", "C", "D", "E", "F", "G", "H", "I"];
  const allScopeSet = new Set(allScope);

  const [isAdding, setIsAdding] = useState(false);

  const [ftag, setFtag] = useState();
  const [ftagError, setFtagError] = useState();
  const [ftagInputValue, setFtagInputValue] = useState("");

  const [scope, setScope] = useState();
  const [scopeError, setScopeError] = useState();
  const [scopeInputValue, setScopeInputValue] = useState("");

  const [subStandard, setSubStandard] = useState(false);
  const [pastNonCompliance, setPastNonCompliance] = useState(false);

  const setSubStandardFn = (event) => {
    setSubStandard(event.target.checked);
  };

  const setPastNonComplianceFn = (event) => {
    setPastNonCompliance(event.target.checked);
  };

  const addNew = () => {
    setIsAdding(true);
  };

  const cancelAdd = () => {
    setIsAdding(false);
  };

  const removeElementFromList = (val) => {
    const index = remainingFTagsOnly.current.indexOf(val);
    remainingFTagsOnly.current.splice(index, 1);
  };

  const addElementToList = (val) => {
    remainingFTagsOnly.current.push(val);
    remainingFTagsOnly.current.sort();
  };

  const addComplete = () => {
    let isError = false;
    if (ftagInputValue === "") {
      isError = true;
      setFtagError("*FTag Required");
    }
    if (!remainingFTagsOnly.current.includes(ftagInputValue)) {
      isError = true;
      setFtagError("*Invalid FTag");
    }
    if (scopeInputValue === "") {
      isError = true;
      setScopeError("*Scope/Severity Required");
    }
    if (!allScopeSet.has(scopeInputValue)) {
      isError = true;
      setScopeError("*Invalid Scope/Severity");
    }

    if (isError) {
      return;
    }
    const updatedFTags = [
      {
        name: ftagInputValue,
        scope: scopeInputValue,
        resolved: false,
        pastNonCompliant: pastNonCompliance,
        subStandard,
      },
      ...fTags,
    ];
    setFTags(updatedFTags);
    setIsAdding(false);
    setFtag("");
    setScope("");
    setFtagInputValue("");
    setScopeInputValue("");
    removeElementFromList(ftagInputValue);
    setSubStandard(false);
    setPastNonCompliance(false);
    scrollbar.current.scrollToTop();
    const oldFTags = updatedFTags.concat(
      inspectionsUpdate?.fTags?.filter((tag) => tag.new !== true) ?? []
    );

    // eslint-disable-next-line no-param-reassign
    inspectionsUpdate.fTags = oldFTags;
    setInspection(inspectionsUpdate);
  };

  const styles = {
    emptyContainer: {
      width: "100%",
      height: 52,
      border: `2px dashed ${colors.grey[400]}`,
      borderRadius: 8,
      margin: 8,
      marginLeft: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    newContainer: {
      width: "100%",
      height: 52,
      border: `1px solid ${colors.grey[500]}`,
      borderRadius: 8,
      margin: 8,
      marginLeft: 0,
      backgroundColor: `${colors.grey[100]}`,
    },
  };

  const allFTagsList = remainingFTagsOnly.current.map((res) => ({
    label: res,
    id: res,
  }));

  const allScopeList = allScope.map((scopCur) => ({ label: scopCur, id: scopCur }));

  const deleteFTag = (fTag) => {
    addElementToList(fTag);
    const updatedFTags = fTags.filter((item) => item.name !== fTag);
    setFTags(updatedFTags);
    const oldFTags = updatedFTags.concat(inspectionsUpdate.fTags.filter((tag) => tag.new !== true));
    // eslint-disable-next-line no-param-reassign
    inspectionsUpdate.fTags = oldFTags;
    setInspection(inspectionsUpdate);
  };

  return (
    <>
      <MDTypography display="inline" variant="body2">
        Please add all the new citations (if any) from this inspection.
      </MDTypography>
      {isAdding && (
        <>
          <MDBox
            style={styles.newContainer}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            p={1}
            className="ftagContainer"
          >
            <MDBox pt={0.7}>
              <Autocomplete
                disableClearable
                freeSolo
                isOptionEqualToValue={(option, value) => option.id === value.id}
                value={ftag || null}
                onChange={(event, newValue) => {
                  setFtagError(null);
                  setFtag(newValue);
                }}
                inputValue={ftagInputValue}
                onInputChange={(event, newInputValue) => {
                  setFtagInputValue(newInputValue);
                }}
                options={allFTagsList}
                disablePortal
                sx={{ width: 90 }}
                renderInput={(params) => (
                  <TextField
                    error={ftagError != null}
                    {...params}
                    label="FTag"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
                renderOption={(props, option, { inputValue }) => {
                  const matches = match(option.label, inputValue, { insideWords: true });
                  const parts = parse(option.label, matches);

                  const bgColor = inputValue === option.label ? colors.grey[200] : null;
                  return (
                    <li {...props} style={{ backgroundColor: bgColor }}>
                      <div>
                        {parts.map((part) => (
                          <span
                            key={part.text + new Date().getTime()}
                            style={{
                              fontWeight: part.highlight ? 700 : 400,
                            }}
                          >
                            {part.text}
                          </span>
                        ))}
                      </div>
                    </li>
                  );
                }}
              />
            </MDBox>
            <MDBox pt={0.7}>
              <Autocomplete
                disableClearable
                freeSolo
                isOptionEqualToValue={(option, value) => option.id === value.id}
                value={scope || null}
                onChange={(event, newValue) => {
                  setScopeError(null);
                  setScope(newValue);
                }}
                inputValue={scopeInputValue}
                onInputChange={(event, newInputValue) => {
                  setScopeInputValue(newInputValue);
                }}
                options={allScopeList}
                disablePortal
                id="scope-input"
                sx={{ width: 110 }}
                renderInput={(params) => (
                  <TextField
                    error={scopeError != null}
                    {...params}
                    label="Scope/Severity"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
                renderOption={(props, option, { inputValue }) => {
                  const matches = match(option.label, inputValue, { insideWords: true });
                  const parts = parse(option.label, matches);

                  const bgColor = inputValue === option.label ? colors.grey[200] : null;
                  return (
                    <li {...props} style={{ backgroundColor: bgColor }}>
                      <div>
                        {parts.map((part) => (
                          <span
                            key={part.text + new Date().getTime()}
                            style={{
                              fontWeight: part.highlight ? 700 : 400,
                            }}
                          >
                            {part.text}
                          </span>
                        ))}
                      </div>
                    </li>
                  );
                }}
              />
            </MDBox>
            <MDBox>
              <FormControlLabel
                control={
                  <Checkbox
                    disableRipple
                    value="notification"
                    checked={subStandard}
                    onChange={(event) => setSubStandardFn(event)}
                  />
                }
                label={
                  <MDTypography
                    display="inline"
                    variant="body2"
                    style={{ fontSize: 13, marginLeft: -8 }}
                  >
                    Sub-standard quality of care
                  </MDTypography>
                }
              />
              <FormControlLabel
                sx={{ marginTop: "-18px" }}
                control={
                  <Checkbox
                    disableRipple
                    value="email"
                    checked={pastNonCompliance}
                    onChange={(event) => setPastNonComplianceFn(event)}
                  />
                }
                label={
                  <MDTypography
                    display="inline"
                    variant="body2"
                    style={{ fontSize: 13, marginLeft: -8 }}
                  >
                    Past non-complaint
                  </MDTypography>
                }
              />
            </MDBox>
            <MDBox display="flex" flexDirection="row">
              <MDBox mr={1}>
                <MDButton
                  variant="gradient"
                  color="light"
                  size="small"
                  onClick={cancelAdd}
                  sx={{ minWidth: "62px !important", width: "62px !important" }}
                >
                  cancel
                </MDButton>
              </MDBox>
              <MDBox>
                <MDButton
                  variant="gradient"
                  color="primary"
                  size="small"
                  onClick={addComplete}
                  sx={{ minWidth: "42px !important", width: "42px !important" }}
                >
                  add
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
          {(ftagError || scopeError) && (
            <MDBox mt={-1.5}>
              <MDTypography
                display="inline"
                variant="caption"
                color="error"
                style={ftagError != null ? { marginRight: 10 } : null}
              >
                {ftagError}
              </MDTypography>
              <MDTypography display="inline" variant="caption" color="error">
                {scopeError}
              </MDTypography>
            </MDBox>
          )}
        </>
      )}
      {!isAdding && (
        <MDBox style={styles.emptyContainer}>
          <MDButton variant="gradient" color="light" onClick={addNew} size="small">
            <Icon sx={{ fontWeight: "bold" }}>add</Icon>
            &nbsp;Add Ftag
          </MDButton>
        </MDBox>
      )}
      {fTags.length !== 0 && (
        <Scrollbars
          ref={scrollbar}
          style={{
            maxHeight: "calc(100vh - 150px)",
            minHeight: 150,
            backgroundColor: colors.grey[300],
            borderRadius: 8,
          }}
          renderTrackHorizontal={({ style, ...props }) => <div />}
          renderTrackVertical={({ style, ...props }) => (
            <div className="scrollbar-regular-vertical-track" {...props} />
          )}
          renderThumbVertical={({ style, ...props }) => (
            <div
              style={{
                ...style,
                backgroundColor: "rgba(0, 0, 0, 0.4)",
                borderRadius: 8,
                width: 6,
                marginRight: -3,
                cursor: "pointer",
              }}
              {...props}
            />
          )}
        >
          <MDBox>
            {transitions.map(({ item, props: { y, ...rest }, key }, index) => (
              <animated.div
                key={key}
                style={{
                  transform: y.interpolate((yVal) => `translate3d(0,${yVal}px,0)`),
                  width: "100%",
                  ...rest,
                }}
              >
                <FTag key={item.name} ftag={item} deleteMe={() => deleteFTag(fTags[index].name)} />
              </animated.div>
            ))}
          </MDBox>
        </Scrollbars>
      )}
    </>
  );
};

export default AddInspectionNew;
