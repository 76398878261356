import { Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import useBackendError from "hooks/useBackendError";
import useRefreshToken from "../hooks/useRefreshToken";
import useAuth from "../hooks/useAuth";

function PersistLogin() {
  const [isLoading, setIsLoading] = useState(true);
  const refresh = useRefreshToken();
  const { auth } = useAuth();
  const { setError } = useBackendError();
  useEffect(() => {
    let isMounted = true;
    const verifyRefreshToken = async () => {
      try {
        await refresh();
      } catch (err) {
        // setError("Your session expired. Please login again");
      } finally {
        isMounted && setIsLoading(false);
      }
    };

    !auth?.accessToken ? verifyRefreshToken() : setIsLoading(false);
    return () => {
      isMounted = false;
    };
  }, []);

  return isLoading ? <p>Loading..</p> : <Outlet />;
}

export default PersistLogin;
