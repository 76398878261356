/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */

import MDBox from "components/MDBox";
import { TextField } from "@mui/material";

import { useState } from "react";
import MDTypography from "components/MDTypography";

import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const AddInspectionDate = ({
  dateError,
  resetDateError,
  date,
  minDate,
  number,
  setInspection,
  inspectionsUpdate,
  inspectionName,
}) => {
  const [inspectionDate, setInspectionDate] = useState(
    date != null && date !== 0 ? dayjs.unix(date).format("MM/DD/YYYY") : null
  );

  const setInspectionDateFn = (value) => {
    const val = value != null ? dayjs(value).unix() : null;
    inspectionsUpdate.date = val;
    resetDateError();
    setInspection(inspectionsUpdate);
    setInspectionDate(value);
  };

  return (
    <>
      <MDBox display="flex" justifyContent="center" alignItems="center" width="100%">
        <MDTypography display="inline" variant="body2">
          Please add the date on which the {inspectionName} was held.
        </MDTypography>
      </MDBox>
      <MDBox
        m={2}
        display="flex"
        justifyContent="center"
        alignItems="flex-start"
        width="100%"
        px={2}
      >
        <MDTypography variant="body2" fontSize="xs" mr={1} pt={1}>
          Date of Inspection
        </MDTypography>
        <MDBox display="flex" flexDirection="column">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              minDate={minDate}
              maxDate={dayjs().format("MM/DD/YYYY")}
              value={inspectionDate}
              onChange={(newValue) => {
                setInspectionDateFn(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={dateError}
                  style={{ width: 200, backgroundColor: "white", borderRadius: "8px" }}
                />
              )}
            />
          </LocalizationProvider>
          {dateError && (
            <MDTypography m={1} display="inline" variant="caption" color="error">
              * Please provide a date
            </MDTypography>
          )}
        </MDBox>
      </MDBox>
    </>
  );
};

export default AddInspectionDate;
