import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Grid } from "@mui/material";
import MDBadge from "components/MDBadge";
import Skeleton from "components/skeletons/Skeleton";

const getBadgeColor = (tag) => {
  if (tag === "ONGOING_FOR_A_WHILE") {
    return "error";
  }
  return "warning";
};

const getBadgeText = (tag) => tag.replace(/_/g, " ");

const getColorInfo = (color) => {
  let colorInfo;
  if (color === "GREEN") {
    colorInfo = "success";
  } else if (color === "RED") {
    colorInfo = "error";
  } else if (color === "ORANGE") {
    colorInfo = "warning";
  } else {
    colorInfo = "dark";
  }
  return colorInfo;
};

function QapiCell({ title, problemStatement, color, tags, showMetaData, isLoading }) {
  let topMargin = 1;
  if (showMetaData) {
    topMargin = tags == null || tags.length === 0 ? 2.5 : 0.8;
  }

  let topPadding = 0;
  if (showMetaData) {
    topPadding = tags == null || tags.length === 0 ? 1.7 : 0;
  }

  return (
    <MDBox display="flex" alignItems="center">
      <Grid container style={{ width: "100%" }}>
        {!isLoading && (
          <Grid item xs={0.5}>
            <MDBox
              mt={topMargin}
              bgColor={getColorInfo(color)}
              sx={{ width: 10, height: 10, borderRadius: "50%" }}
            />
          </Grid>
        )}
        {!isLoading && (
          <Grid item xs={11.5}>
            <MDBox
              mr={1}
              display="flex"
              flexDirection="column"
              sx={{
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2,
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              my={topPadding}
            >
              <MDTypography
                display="inline"
                variant="caption"
                fontWeight="medium"
                fontSize={14}
                color="text"
              >
                {title}
                <MDTypography display="inline" variant="caption" fontSize={14} color="text">
                  &nbsp;- {problemStatement}
                </MDTypography>
              </MDTypography>
            </MDBox>
            {showMetaData && (
              <MDBox mr={1} display="flex" flexDirection="row">
                {tags != null &&
                  tags.map((tag, index) => (
                    <MDBadge
                      // eslint-disable-next-line react/no-array-index-key
                      key={`badge-${index}`}
                      badgeContent={getBadgeText(tag)}
                      container
                      color={getBadgeColor(tag)}
                      sx={{ marginRight: 1, marginTop: 0.2 }}
                    />
                  ))}
              </MDBox>
            )}
          </Grid>
        )}
        {isLoading && (
          <Grid item xs={11.5}>
            <MDBox
              mr={1}
              display="flex"
              flexDirection="column"
              sx={{
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2,
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              my={topPadding}
            >
              <Skeleton
                width={`calc(100% - ${Math.floor(Math.random() * 60 + 40)}px)`}
                height={16}
                margin={10}
              />
              <Skeleton
                width={`calc(100% - ${Math.floor(Math.random() * 60 + 40)}px)`}
                height={16}
                margin={10}
              />
            </MDBox>
          </Grid>
        )}
      </Grid>
    </MDBox>
  );
}

// Typechecking props for the StatusCell
QapiCell.propTypes = {
  color: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default QapiCell;
