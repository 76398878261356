/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import Grid from "@mui/material/Grid";

import { useEffect, useState } from "react";
import useAxiosPrivate from "hooks/useAxiosPrivate";

import useBackendError from "hooks/useBackendError";

import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import { Card, Icon, IconButton } from "@mui/material";
import MDButton from "components/MDButton";

import { useNavigate, useSearchParams } from "react-router-dom";
import colors from "assets/theme/base/colors";
import dayjs from "dayjs";

import MDVr from "components/MDVr";
import MDBadge from "components/MDBadge";
import UploadMdsModal from "components/modal/UploadMdsModal";
import useModal from "hooks/useModal";
import MDAvatar from "components/MDAvatar";
import { grey } from "@mui/material/colors";
import UploadProfilePictureModal from "components/modal/UploadProfilePictureModal";
import AddUpdateResidentModal from "components/modal/AddUpdateResidentModal";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Skeleton from "components/skeletons/Skeleton";
import FTagLog from "./FTagLog";
import ActivityLog from "./ActivityLog";
import MDSUploadHistory from "../overview/MDSUploadHistory";

const ResidentDetail = () => {
  const [isLoading, setLoading] = useState(true);
  const [resident, setResident] = useState([]);
  const [isHovered, setIsHovered] = useState(false);
  const [showSSN, setShowSSN] = useState(false);
  const { openModal } = useModal();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const axios = useAxiosPrivate();
  const { setError } = useBackendError();

  const loadResident = async () => {
    try {
      const response = await axios.get(`resident?id=${id}`, {
        headers: { "Content-Type": "application/json" },
      });
      setResident(response.data);
    } catch (err) {
      setError("Failed to fetch from the backend. Please try again");
    } finally {
      setLoading(false);
    }
  };

  const firstLetter = (str) => str?.charAt(0)?.toUpperCase();

  const handleBack = () => {
    navigate(-1);
  };

  const updateInfo = () => {
    openModal(<AddUpdateResidentModal resident={resident} />, loadResident);
  };

  const uploadMds = () => {
    openModal(<UploadMdsModal />, loadResident);
  };

  const uploadImage = () => {
    openModal(<UploadProfilePictureModal initialImageUrl={resident.url} />, loadResident);
  };

  useEffect(() => {
    loadResident();
  }, []);

  const handleToggleShowSSN = () => {
    setShowSSN(!showSSN);
  };

  const formatSSN = (val) => `${val.substring(0, 3)}-${val.substring(3, 5)}-${val.substring(5, 9)}`;

  return (
    <MDBox>
      <Grid container spacing={3}>
        <Grid item xs={12} md={7}>
          <Card id="resident-main" style={{ marginBottom: 24 }}>
            <MDBox display="flex" justifyContent="space-around" alignItems="center" pr={1}>
              <MDBox pt={2} px={2} justifyContent="left" alignItems="center">
                {isLoading && (
                  <MDBox
                    shadow="lg"
                    mt={-4}
                    mb={1}
                    bgColor={colors.grey[300]}
                    width={110}
                    height={110}
                    style={{ borderRadius: 55 }}
                  />
                )}
                {!isLoading && (
                  <MDBox
                    style={{ borderRadius: "50%", width: 110, height: 110, cursor: "pointer" }}
                    shadow="lg"
                    mt={-4}
                    mb={1}
                  >
                    <div
                      style={{
                        borderRadius: "50%",
                        width: 110,
                        height: 110,
                        position: "absolute",
                        zIndex: 100,
                        backgroundColor: colors.grey[200],
                        border: `2px dashed ${colors.info.main}`,
                        opacity: isHovered ? 0.9 : 0,
                        transition: "opacity 0.1s ease",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onMouseEnter={() => setIsHovered(true)}
                      onMouseLeave={() => setIsHovered(false)}
                      onClick={uploadImage}
                    >
                      <MDTypography
                        variant="body2"
                        fontWeight="bold"
                        color="info"
                        style={{ fontSize: 14 }}
                      >
                        Upload Image
                      </MDTypography>
                    </div>
                    <MDAvatar
                      size="xxl"
                      sx={{ bgcolor: grey[500] }}
                      alt={resident.fullName}
                      src={resident.url}
                    >
                      <MDTypography
                        variant="body2"
                        fontWeight="bold"
                        color="light"
                        style={{ fontSize: 46 }}
                      >
                        {resident.url == null && (
                          <>
                            {firstLetter(resident.firstName)}
                            {resident.lastName != null
                              ? firstLetter(resident.lastName)
                              : firstLetter(resident.middleName)}
                          </>
                        )}
                      </MDTypography>
                    </MDAvatar>
                  </MDBox>
                )}

                <MDBox pb={2}>
                  {!isLoading && (
                    <>
                      <MDBox display="flex" alignItems="center" pb={1}>
                        <MDTypography variant="body2" fontWeight="bold" color="dark">
                          {resident.fullName}
                        </MDTypography>
                        {resident.status === "ACTIVE" && (
                          <MDBadge
                            variant="contained"
                            badgeContent="Active"
                            container
                            color="success"
                            sx={{ marginLeft: 1, marginTop: 0.2 }}
                          />
                        )}
                        {resident.status === "DISCHARGED" && (
                          <MDBadge
                            variant="contained"
                            badgeContent="Discharged"
                            container
                            color="warning"
                            sx={{ marginLeft: 1, marginTop: 0.2 }}
                          />
                        )}
                        {resident.longTerm && (
                          <MDBadge
                            variant="contained"
                            badgeContent="Long Term"
                            container
                            color="primary"
                            sx={{ marginLeft: 1, marginTop: 0.2 }}
                          />
                        )}
                        {!resident.longTerm && (
                          <MDBadge
                            variant="contained"
                            badgeContent="Short Term"
                            container
                            color="info"
                            sx={{ marginLeft: 1, marginTop: 0.2 }}
                          />
                        )}
                      </MDBox>

                      <MDTypography
                        variant="body2"
                        fontWeight="regular"
                        color="text"
                        style={{ fontSize: 13, marginTop: 6 }}
                      >
                        This resident does not have any prior stay records.
                      </MDTypography>
                    </>
                  )}
                  {isLoading && (
                    <>
                      <MDBox display="flex" alignItems="center" pb={1}>
                        <Skeleton width={160} height={20} margin={6} />
                        <Skeleton width={80} height={24} margin={6} />
                        <Skeleton width={80} height={24} margin={6} />
                      </MDBox>

                      <Skeleton width={300} height={18} margin={6} />
                    </>
                  )}
                </MDBox>
              </MDBox>
              <MDVr style={{ height: 120 }} />
              <MDBox py={2} px={4}>
                {!isLoading && (
                  <>
                    <MDTypography
                      variant="body2"
                      fontWeight="regular"
                      color="text"
                      style={{ fontSize: 13, marginTop: 4 }}
                    >
                      Admission Date:{" "}
                      <span style={{ color: colors.dark.main, fontWeight: 700, marginLeft: 8 }}>
                        {dayjs.unix(resident.admissionDate).format("DD MMM, YYYY")}
                      </span>
                    </MDTypography>
                    {resident.status === "DISCHARGED" && (
                      <MDTypography
                        variant="body2"
                        fontWeight="regular"
                        color="text"
                        style={{ fontSize: 13, marginTop: 8 }}
                      >
                        Discharge Date:{" "}
                        <span style={{ color: colors.dark.main, fontWeight: 700, marginLeft: 8 }}>
                          {dayjs.unix(resident.dischargeDate).format("DD MMM, YYYY")}
                        </span>
                      </MDTypography>
                    )}

                    <MDTypography
                      variant="body2"
                      fontWeight="regular"
                      color="text"
                      style={{ fontSize: 13, marginTop: 10 }}
                    >
                      Room No:{" "}
                      <span style={{ color: colors.dark.main, fontWeight: 700, marginLeft: 8 }}>
                        {resident.room}
                      </span>
                    </MDTypography>
                    <MDTypography
                      variant="body2"
                      fontWeight="regular"
                      color="text"
                      style={{ fontSize: 13, marginTop: 2 }}
                    >
                      SSN:{" "}
                      <span style={{ color: colors.dark.main, fontWeight: 700, marginLeft: 8 }}>
                        {resident.ssn != null
                          ? showSSN
                            ? formatSSN(resident.ssn)
                            : `●●●-●●-${resident.ssn?.substring(5, 9)}`
                          : "Not Specified"}
                      </span>
                      <IconButton onClick={handleToggleShowSSN} edge="end">
                        {!showSSN ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </MDTypography>

                    <MDTypography
                      variant="body2"
                      fontWeight="regular"
                      color="text"
                      style={{ fontSize: 13 }}
                    >
                      Satisfaction Rating:{" "}
                      {resident.satisfactionScore < 0 && (
                        <span style={{ color: colors.dark.main, fontWeight: 700, marginLeft: 8 }}>
                          Not Available
                        </span>
                      )}
                      {resident.satisfactionScore >= 0 && (
                        <>
                          <span
                            style={{
                              color: colors.success.main,
                              fontWeight: 700,
                              marginLeft: 8,
                              fontSize: 16,
                            }}
                          >
                            {resident.satisfactionScore}
                          </span>
                          /10
                        </>
                      )}
                    </MDTypography>
                  </>
                )}
                {isLoading && (
                  <>
                    <Skeleton width={160} height={20} margin={6} />
                    <Skeleton width={200} height={20} margin={6} />

                    <Skeleton width={120} height={20} margin={6} />
                    <Skeleton width={140} height={20} margin={6} />

                    <Skeleton width={200} height={20} margin={6} />
                  </>
                )}
              </MDBox>
            </MDBox>
            <MDBox display="flex" mb={3} justifyContent="center">
              <MDButton variant="gradient" color="info" onClick={updateInfo}>
                Update info &nbsp;
                <Icon sx={{ fontWeight: "bold" }}>edit</Icon>
              </MDButton>
              <MDButton
                variant="gradient"
                color="primary"
                style={{ marginLeft: 5, marginRight: 10 }}
                onClick={uploadMds}
              >
                Upload MDS FILE&nbsp;
                <Icon sx={{ fontWeight: "bold" }}>upload</Icon>
              </MDButton>
            </MDBox>
          </Card>
        </Grid>
        <Grid item xs={12} md={5} style={{ paddingBottom: 24 }}>
          <Card id="resident-main" style={{ height: "100%" }}>
            <FTagLog residentId={id} />
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={7}>
          <Card id="surveys" style={{ marginBottom: 24, height: 220 }}>
            <MDSUploadHistory residentId={id} />
          </Card>
          <Card id="QAPIS" style={{ marginBottom: 24 }}>
            Test 2
          </Card>
          <Card id="surveys" style={{ marginBottom: 24 }}>
            Test
          </Card>
          <Card id="QAPIS" style={{ marginBottom: 24 }}>
            Test 2
          </Card>
          <Card id="surveys" style={{ marginBottom: 24 }}>
            Test
          </Card>
          <Card id="QAPIS" style={{ marginBottom: 24 }}>
            Test 2
          </Card>
        </Grid>
        <Grid item xs={12} md={5}>
          <Card id="resident-main" style={{ height: "100%" }}>
            <ActivityLog />
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
};
export default ResidentDetail;
