/* eslint-disable react/no-danger */
import PropTypes from "prop-types";

import MDBox from "components/MDBox";

import colors from "assets/theme/base/colors";

function CommentCell({ comment }) {
  const content = (
    <MDBox display="flex" alignItems="center" width="100%">
      <MDBox mr={1} display="flex" flexDirection="column" width="100%">
        <div
          style={{ display: "inline-block", fontSize: "14px", color: colors.text.main }}
          dangerouslySetInnerHTML={{ __html: comment }}
        />
      </MDBox>
    </MDBox>
  );

  return content;
}

// Typechecking props for the StatusCell
CommentCell.propTypes = {
  comment: PropTypes.string.isRequired,
};

export default CommentCell;
