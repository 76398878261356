/* eslint-disable react/button-has-type */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import colors from "assets/theme/base/colors";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import SimulateResidentModal from "components/modal/SimulateResidentModal";
import useModal from "hooks/useModal";
import { useState } from "react";

const PrettoSlider = styled(Slider)({
  color: colors.info.main,
  height: 8,
  "& .MuiSlider-track": {
    backgroundImage: `linear-gradient(to right, ${colors.gradients.primary.state} , ${colors.gradients.primary.main})`,
    height: 6,
    borderRadius: 6,
  },
  "& .MuiSlider-rail": {
    backgroundColor: colors.grey[400],
    height: 6,
    borderRadius: 6,
  },
  "& .MuiSlider-thumb": {
    height: 20,
    width: 20,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&::before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    color: "white",
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: colors.info.main,
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&::before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
  "& .MuiSlider-mark": {
    backgroundColor: colors.info.main,
    height: 4,
    width: 4,
    borderRadius: 6,
    zIndex: 10,
  },
});

export default function SimulateSlider(prop) {
  const { index, text, type, currentValue, updatedValue, minValue, maxValue, residents } =
    prop.item;

  const [value, setValue] = useState(updatedValue);
  const { openModal } = useModal();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    prop.setSimData(type, index, newValue, false);
  };

  const handleConfirm = (event, newValue) => {
    setValue(newValue);
    prop.setSimData(type, index, newValue, true);
  };

  const handleResidents = () => {
    openModal(<SimulateResidentModal residents={residents} />, () => {});
  };

  const diff = value - currentValue;

  return (
    <MDBox width="100%" display="flex">
      <MDBox width="100%">
        <MDTypography variant="body2" color="dark" fontSize="xs" style={{ fontSize: 14 }}>
          {text}{" "}
          {residents != null && [
            <button
              key={`button-${index}`}
              style={{
                background: "none !important",
                border: "none",
                padding: "0 !important",
                color: colors.info.main,
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={handleResidents}
            >
              Impacted Residents
            </button>,
          ]}
        </MDTypography>
        <PrettoSlider
          size="large"
          valueLabelDisplay="auto"
          aria-label="pretto slider"
          marks={[{ value: currentValue }]}
          style={{ padding: 0, top: -10 }}
          min={minValue}
          max={maxValue}
          value={value}
          onChange={handleChange}
          onChangeCommitted={handleConfirm}
          defaultValue={updatedValue}
        />
      </MDBox>
      {diff !== 0 && (
        <MDBox width="160px" ml={1} mt={1.2}>
          <MDTypography
            variant="body2"
            color="text"
            fontSize="xs"
            style={{ fontSize: 13, textDecoration: "line-through" }}
          >
            {currentValue} {type === "hours" ? "Hrs" : type === "percent" ? "%" : null}
          </MDTypography>
          <MDTypography
            fontWeight="bold"
            variant="body2"
            color="dark"
            fontSize="xs"
            style={{ fontSize: 13 }}
          >
            {value} {type === "hours" ? "Hrs" : type === "percent" ? "%" : null}{" "}
            <span style={{ color: diff > 0 ? colors.success.main : colors.warning.main }}>
              ({diff > 0 ? "+" : ""}
              {diff})
            </span>
          </MDTypography>
        </MDBox>
      )}
      {diff === 0 && (
        <MDBox width="160px" ml={1} mt={2.5}>
          <MDTypography
            variant="body2"
            fontWeight="bold"
            color="dark"
            fontSize="xs"
            style={{ fontSize: 13 }}
          >
            {currentValue} {type === "hours" ? "Hrs" : type === "percent" ? "%" : null}
          </MDTypography>
        </MDBox>
      )}
    </MDBox>
  );
}
