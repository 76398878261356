/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Skeleton from "components/skeletons/Skeleton";

function CMSCell({ count, text, isLoading }) {
  if (isLoading) {
    return <Skeleton width={Math.floor(Math.random() * 20 + 230)} height={18} />;
  }
  return (
    <MDBox display="flex" alignItems="center">
      <MDBox mr={1}>
        {count === 0 && (
          <Icon color="success" sx={{ fontWeight: "bold" }}>
            check
          </Icon>
        )}
        {count !== 0 && (
          <Icon color="warning" sx={{ fontWeight: "bold" }}>
            priority_high
          </Icon>
        )}
      </MDBox>
      <MDBox mr={1} display="flex" flexDirection="column">
        <MDTypography variant="caption" fontWeight="medium" color="text">
          {text}
        </MDTypography>
        <MDTypography variant="caption" color={count === 0 ? "success" : "warning"}>
          {`${count === 0 ? "No" : count} Ftag${count === 1 ? "" : "s"}`}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

// Typechecking props for the StatusCell
CMSCell.propTypes = {
  count: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
};

export default CMSCell;
