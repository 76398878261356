/* eslint-disable react/destructuring-assignment */
const MDHr = (param) => (
  <hr
    style={{
      ...param.style,
      height: "1px",
      color: param.color,
      backgroundColor: param.color,
      border: "none",
    }}
  />
);

export default MDHr;
