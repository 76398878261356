/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */

import MDBox from "components/MDBox";
import { FormHelperText, Card, Grid } from "@mui/material";

import Icon from "@mui/material/Icon";

import colors from "assets/theme/base/colors";
import { useState } from "react";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import MDInput from "components/MDInput";
import MDDropzone from "components/MDDropzone";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import useBackendError from "hooks/useBackendError";

const QualityMeasuresStep = ({ errors, resetError, setCalculationData, calculationData }) => {
  const [longHospitalization, setLongHospitalization] = useState(
    calculationData.quality?.longHospitalization ?? ""
  );
  const [longERVisit, setLongERVisit] = useState(calculationData.quality?.longERVisit ?? "");
  const [shortReHospitalization, setShortReHospitalization] = useState(
    calculationData.quality?.shortReHospitalization ?? ""
  );
  const [shortEDVisit, setShortEDVisit] = useState(calculationData.quality?.shortEDVisit ?? "");
  const [shortReturnRate, setShortReturnRate] = useState(
    calculationData.quality?.shortReturnRate ?? ""
  );

  const errorLongHospitalization = errors.find((obj) => obj.key === "longHospitalization")?.value;
  const errorLongERVisit = errors.find((obj) => obj.key === "longERVisit")?.value;
  const errorShortReHospitalization = errors.find(
    (obj) => obj.key === "shortReHospitalization"
  )?.value;
  const errorShortEDVisit = errors.find((obj) => obj.key === "shortEDVisit")?.value;
  const errorShortReturnRate = errors.find((obj) => obj.key === "shortReturnRate")?.value;

  const axios = useAxiosPrivate();
  const { setError } = useBackendError();

  const setLongHospitalizationFn = (val) => {
    calculationData.quality.longHospitalization = val;
    setCalculationData(calculationData);
    resetError("longHospitalization");
    setLongHospitalization(val);
  };

  const setLongERVisitFn = (val) => {
    calculationData.quality.longERVisit = val;
    setCalculationData(calculationData);
    resetError("longERVisit");
    setLongERVisit(val);
  };

  const setShortReHospitalizationFn = (val) => {
    calculationData.quality.shortReHospitalization = val;
    setCalculationData(calculationData);
    resetError("shortReHospitalization");
    setShortReHospitalization(val);
  };

  const setShortEDVisitFn = (val) => {
    calculationData.quality.shortEDVisit = val;
    setCalculationData(calculationData);
    resetError("shortEDVisit");
    setShortEDVisit(val);
  };

  const setShortReturnRateFn = (val) => {
    calculationData.quality.shortReturnRate = val;
    setCalculationData(calculationData);
    resetError("shortReturnRate");
    setShortReturnRate(val);
  };

  const uploadMds = () => {};
  return (
    <>
      <MDBox display="flex" justifyContent="center" px={2} pt={2}>
        <MDTypography variant="h6" fontWeight="medium">
          Quality Measures
        </MDTypography>
      </MDBox>
      <MDBox display="flex" justifyContent="center" px={2} pt={1}>
        <MDTypography
          variant="subtitle1"
          style={{ fontSize: 14, marginLeft: 10 }}
          color="text"
          justifyContent="center"
        >
          Quality Measures will be calculated based on MDS and Claim data.
        </MDTypography>
      </MDBox>

      <Card
        style={{
          backgroundColor: colors.grey[200],
          width: "100%",
          marginTop: 30,
          paddingBottom: 20,
        }}
      >
        <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
          <MDBox
            color="white"
            bgColor="dark"
            variant="gradient"
            borderRadius="xl"
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="1.5rem"
            pl={2}
            pr={2}
            mt={-3}
          >
            <MDTypography variant="body2" color="white" fontSize="xs">
              Staff Hours
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox mx={5}>
          <MDTypography
            variant="subtitle1"
            style={{ fontSize: 14, margin: 6, minWidth: 160 }}
            color="dark"
            justifyContent="center"
            textAlign="left"
          >
            MDS data has automatically been extracted based on the available MDS files. If you want
            to upload additional MDS files, you can add it here, otherwise{" "}
            <span style={{ color: colors.primary.main }}>skip</span> to the next section.{" "}
            <b>
              Total MDS files for the Quarter:{" "}
              <span style={{ color: colors.primary.main }}>256</span>
            </b>
          </MDTypography>
        </MDBox>
        <MDBox mx={5}>
          <MDDropzone
            override={
              <MDButton type="color" variant="gradient" color="info" style={{ height: 40 }}>
                Upload MDS &nbsp;<Icon sx={{ fontWeight: "bold" }}>upload</Icon>
              </MDButton>
            }
            onFilesChangedCallback={uploadMds}
            multiple={false}
          >
            upload
          </MDDropzone>
        </MDBox>
      </Card>
      <Card
        style={{
          backgroundColor: colors.grey[200],
          width: "100%",
          marginTop: 30,
          paddingBottom: 20,
        }}
      >
        <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
          <MDBox
            color="white"
            bgColor="dark"
            variant="gradient"
            borderRadius="xl"
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="1.5rem"
            pl={2}
            pr={2}
            mt={-3}
          >
            <MDTypography variant="body2" color="white" fontSize="xs">
              Claim details
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox mx={5}>
          <MDTypography
            variant="subtitle1"
            fontWeight="medium"
            style={{ fontSize: 14, marginRight: 10, marginTop: 16 }}
          >
            Measures for Long-Stay residents:
          </MDTypography>
          <Grid container style={{ width: "100%" }}>
            <Grid item xs={12} md={9}>
              <MDTypography
                variant="subtitle1"
                style={{ fontSize: 14, margin: 6, minWidth: 160 }}
                color="dark"
                justifyContent="center"
                textAlign="right"
              >
                Number of hospitalizations per 1,000 long-stay resident days (0 - 1000)
              </MDTypography>
            </Grid>
            <Grid item xs={12} md={3}>
              <MDBox display="flex" justifyContent="left" mr={2}>
                <MDInput
                  placeholder="Enter Count.."
                  style={{ width: "120px" }}
                  value={longHospitalization}
                  onChange={(event) => setLongHospitalizationFn(event.target.value)}
                  error={errorLongHospitalization != null}
                  id="title"
                />
              </MDBox>
              <MDBox display="flex" justifyContent="left" mr={2}>
                <FormHelperText error>
                  {errorLongHospitalization != null
                    ? `* ${errorLongHospitalization}`
                    : errorLongHospitalization}
                </FormHelperText>
              </MDBox>
            </Grid>
          </Grid>
          <Grid
            container
            style={{
              width: "100%",
              borderTop: `1px solid ${colors.grey[300]}`,
              borderBottom: `1px solid ${colors.grey[600]}`,
            }}
            mt={1}
            pt={1}
            mb={1}
            pb={1}
          >
            <Grid item xs={12} md={9}>
              <MDTypography
                variant="subtitle1"
                style={{ fontSize: 14, margin: 6, minWidth: 160 }}
                color="dark"
                justifyContent="center"
                textAlign="right"
              >
                Number of outpatient emergency department (ED) visits per 1,000 long-stay resident
                days (0 - 1000)
              </MDTypography>
            </Grid>
            <Grid item xs={12} md={3}>
              <MDBox display="flex" justifyContent="left" mr={2}>
                <MDInput
                  placeholder="Enter Count.."
                  style={{ width: "120px" }}
                  value={longERVisit}
                  onChange={(event) => setLongERVisitFn(event.target.value)}
                  error={errorLongERVisit != null}
                  id="title"
                />
              </MDBox>
              <MDBox display="flex" justifyContent="left" mr={2}>
                <FormHelperText error>
                  {errorLongERVisit != null ? `* ${errorLongERVisit}` : errorLongERVisit}
                </FormHelperText>
              </MDBox>
            </Grid>
          </Grid>
          <MDTypography
            variant="subtitle1"
            fontWeight="medium"
            style={{ fontSize: 14, marginRight: 10, marginTop: 16 }}
          >
            Measures for Short-Stay residents:
          </MDTypography>
          <Grid container style={{ width: "100%" }} pt={1}>
            <Grid item xs={12} md={9}>
              <MDTypography
                variant="subtitle1"
                style={{ fontSize: 14, margin: 6, minWidth: 160 }}
                color="dark"
                justifyContent="center"
                textAlign="right"
              >
                Percent of short-stay residents who were re-hospitalized after a nursing home
                admission (0 - 100%)
              </MDTypography>
            </Grid>
            <Grid item xs={12} md={3}>
              <MDBox display="flex" justifyContent="left" mr={2}>
                <MDInput
                  placeholder="Enter Percent.."
                  style={{ width: "120px" }}
                  value={shortReHospitalization}
                  onChange={(event) => setShortReHospitalizationFn(event.target.value)}
                  error={errorShortReHospitalization != null}
                  id="title"
                />
              </MDBox>
              <MDBox display="flex" justifyContent="left" mr={2}>
                <FormHelperText error>
                  {errorShortReHospitalization != null
                    ? `* ${errorShortReHospitalization}`
                    : errorShortReHospitalization}
                </FormHelperText>
              </MDBox>
            </Grid>
          </Grid>
          <Grid
            container
            style={{ width: "100%", borderTop: `1px solid ${colors.grey[300]}` }}
            mt={1}
            pt={1}
          >
            <Grid item xs={12} md={9}>
              <MDTypography
                variant="subtitle1"
                style={{ fontSize: 14, margin: 6, minWidth: 160 }}
                color="dark"
                justifyContent="center"
                textAlign="right"
              >
                Percent of short-stay residents who have had an outpatient emergency department (ED)
                visit (0 - 100%)
              </MDTypography>
            </Grid>
            <Grid item xs={12} md={3}>
              <MDBox display="flex" justifyContent="left" mr={2}>
                <MDInput
                  placeholder="Enter Percent.."
                  style={{ width: "120px" }}
                  value={shortEDVisit}
                  onChange={(event) => setShortEDVisitFn(event.target.value)}
                  error={errorShortEDVisit != null}
                  id="title"
                />
              </MDBox>
              <MDBox display="flex" justifyContent="left" mr={2}>
                <FormHelperText error>
                  {errorShortEDVisit != null ? `* ${errorShortEDVisit}` : errorShortEDVisit}
                </FormHelperText>
              </MDBox>
            </Grid>
          </Grid>
          <Grid
            container
            style={{ width: "100%", borderTop: `1px solid ${colors.grey[300]}` }}
            mt={1}
            pt={1}
          >
            <Grid item xs={12} md={9}>
              <MDTypography
                variant="subtitle1"
                style={{ fontSize: 14, margin: 6, minWidth: 160 }}
                color="dark"
                justifyContent="center"
                textAlign="right"
              >
                Rate of successful return to home and community from a SNF (0 - 100%)
              </MDTypography>
            </Grid>
            <Grid item xs={12} md={3}>
              <MDBox display="flex" justifyContent="left" mr={2}>
                <MDInput
                  placeholder="Enter Percent.."
                  style={{ width: "120px" }}
                  value={shortReturnRate}
                  onChange={(event) => setShortReturnRateFn(event.target.value)}
                  error={errorShortReturnRate != null}
                  id="title"
                />
              </MDBox>
              <MDBox display="flex" justifyContent="left" mr={2}>
                <FormHelperText error>
                  {errorShortReturnRate != null
                    ? `* ${errorShortReturnRate}`
                    : errorShortReturnRate}
                </FormHelperText>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </Card>
    </>
  );
};

export default QualityMeasuresStep;
