/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
import { FormHelperText, Grid, Icon, MenuItem, Select, TextField, Tooltip } from "@mui/material";
import "./confirmModal.css";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import useModal from "hooks/useModal";
import { useRef, useState } from "react";
import useBackendError from "hooks/useBackendError";
import dayjs from "dayjs";
import MDBox from "components/MDBox";

import useAxiosPrivate from "hooks/useAxiosPrivate";

import MDInput from "components/MDInput";
import { isValidName, isValidSSN, isValidRoomNo, isValidDate } from "utils/utils";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useNavigate } from "react-router-dom";
import SSNMaskedInput from "components/SSNMaskedInput";

const AddUpdateResidentModal = ({ resident }) => {
  const isNew = resident == null;
  const [isSuccess, setSuccess] = useState(false);
  const id = useRef();
  const axios = useAxiosPrivate();
  const { closeModal } = useModal();
  const [trying, setTrying] = useState(false);
  const navigate = useNavigate();

  const fNameRef = useRef();
  const mNameRef = useRef();
  const lNameRef = useRef();
  const roomNoRef = useRef();
  const ssnRef = useRef();

  const [fName, setFName] = useState(isNew ? "" : resident.firstName ?? "");
  const [fNameError, setFNameError] = useState("");

  const [mName, setMName] = useState(isNew ? "" : resident.middleName ?? "");
  const [mNameError, setMNameError] = useState("");

  const [lName, setLName] = useState(isNew ? "" : resident.lastName ?? "");
  const [lNameError, setLNameError] = useState("");

  const [roomNo, setRoomNo] = useState(isNew ? "" : resident.room ?? "");
  const [roomNoError, setRoomNoError] = useState("");

  const [ssn, setSsn] = useState(isNew ? "" : resident.ssn ?? "");
  const [ssnError, setSsnError] = useState("");

  const [status, setStatus] = useState(isNew ? "active" : resident.status?.toLowerCase());

  const [admissionDate, setAdmissionDate] = useState(
    isNew ? "" : dayjs.unix(resident.admissionDate).format("10/23/2025") ?? ""
  );
  const [admissionDateError, setAdmissionDateError] = useState("");

  const [dischargeDate, setDischargeDate] = useState(
    isNew ? "" : dayjs.unix(resident.dischargeDate).format("10/23/2026") ?? ""
  );
  const [dischargeDateError, setDischargeDateError] = useState("");

  const { setError } = useBackendError();

  const createAnother = () => {
    setSuccess(false);
    setTrying(false);
    setFName("");
    setMName("");
    setLName("");
    setSsn("");
    setRoomNo("");
    setStatus("active");
    setAdmissionDate("");
    setDischargeDate("");
  };

  const viewResident = () => {
    navigate(`/residents/resident?id=${id.current}`);
    closeModal();
  };
  3;

  const changeFName = (event) => {
    setFName(event.target.value);
    setFNameError("");
  };

  const changeMName = (event) => {
    setMName(event.target.value);
    setMNameError("");
  };

  const changeLName = (event) => {
    setLName(event.target.value);
    setLNameError("");
  };

  const changeRoomNo = (event) => {
    setRoomNo(event.target.value);
    setRoomNoError("");
  };

  const changeStatus = (event) => {
    setStatus(event.target.value);
    setDischargeDateError("");
  };

  const changeSsn = (ssnVal) => {
    setSsn(ssnVal);
    setSsnError("");
  };

  const changeAdmissionDate = (val) => {
    setAdmissionDate(val);
    setAdmissionDateError("");
  };

  const changeDischargeDate = (val) => {
    setDischargeDate(val);
    setDischargeDateError("");
  };

  const tryCreate = async () => {
    let error = false;
    if (status === "discharged" && (dischargeDate == null || dischargeDate === "")) {
      setDischargeDateError("* Discharge date is required if the status is Discharged");
      error = true;
    }
    if (status === "discharged" && !isValidDate(dischargeDate)) {
      setDischargeDateError("* Invalid date");
      error = true;
    }
    if (admissionDate == null || admissionDate === "") {
      setAdmissionDateError("* Admission date is required");
      error = true;
    }
    if (!isValidDate(admissionDate)) {
      setAdmissionDateError("* Invalid date");
      error = true;
    }
    if (!isValidSSN(ssn)) {
      setSsnError("* Invalid SSN");
      error = true;
      ssnRef.current.focus();
    }
    if (roomNo !== "" && !isValidRoomNo(roomNo)) {
      setRoomNoError("* Invalid Room Number");
      error = true;
      roomNoRef.current.focus();
    }
    if (lName === "") {
      setLNameError("* Last Name is required");
      error = true;
      lNameRef.current.focus();
    } else if (!isValidName(lName)) {
      setLNameError("* Invalid characters in name");
      error = true;
      lNameRef.current.focus();
    }
    if (mName !== "" && !isValidName(mName)) {
      setMNameError("* Invalid characters in name");
      error = true;
      mNameRef.current.focus();
    }
    if (fName === "") {
      setFNameError("* First Name is required");
      error = true;
      fNameRef.current.focus();
    } else if (!isValidName(fName)) {
      setFNameError("* Invalid characters in name");
      error = true;
      fNameRef.current.focus();
    }

    if (!error) {
      setTrying(true);
      try {
        resident = {
          id: resident?.id,
          firstName: fName,
          middleName: mName,
          lastName: lName,
          room: roomNo,
          ssn,
          status: status === "discharged" ? "DISCHARGED" : "ACTIVE",
          admissionDate: dayjs(admissionDate).unix(),
          dischargeDate: status === "discharged" ? dayjs(dischargeDate).unix() : null,
        };
        const response = await axios.post(`/resident`, resident, {
          headers: { "Content-Type": "application/json" },
        });
        id.current = response.data.id;
        setSuccess(true);
      } catch (err) {
        setError("Failed to fetch from the backend. Please try again");
      }
    }
  };

  if (isSuccess) {
    return (
      <div style={{ minWidth: 300 }}>
        <MDBox display="flex" justifyContent="center" alignItems="center" flexDirection="column">
          <MDTypography display="inline" variant="h6" textTransform="capitalize" fontWeight="bold">
            {isNew && "Successfully added!"}
            {!isNew && "Successfully updated!"}
          </MDTypography>

          <div className="successContainer">
            <div className="successOne" />
            <span className="successTwo" />
            <span className="successThree" />
            <div className="successFour" />
            <div className="successFive" />
            <div className="successSix" />
          </div>

          <MDTypography display="inline" variant="body2" textAlign="center">
            {isNew && "Resident has been successfully created."}
            {!isNew && " Resident has been successfully updated."}
          </MDTypography>

          <MDBox flexDirection="row" display="flex" m={1}>
            <MDBox m={1}>
              {isNew && (
                <MDButton
                  onClick={createAnother}
                  variant="gradient"
                  color="info"
                  size="medium"
                  style={{ margin: 5 }}
                >
                  create another
                </MDButton>
              )}

              <MDButton
                onClick={() => closeModal({ action: "done" })}
                variant="gradient"
                color="info"
                size="medium"
                style={{ margin: 5 }}
              >
                done
              </MDButton>
              {isNew && (
                <MDButton
                  onClick={viewResident}
                  variant="gradient"
                  color="primary"
                  size="medium"
                  style={{ margin: 5 }}
                >
                  view
                </MDButton>
              )}
            </MDBox>
          </MDBox>
        </MDBox>
      </div>
    );
  }

  return (
    <div style={{ maxWidth: 600 }}>
      <MDBox display="flex" flexDirection="column" textAlign="center">
        <MDTypography
          display="inline"
          variant="h6"
          textTransform="capitalize"
          fontWeight="bold"
          textAlign="center"
        >
          {isNew && "Add New Resident"}
          {!isNew && "Update Resident"}
        </MDTypography>
      </MDBox>

      <Grid container spacing={1} mt={1}>
        <Grid item xs={12} lg={4} textAlign="left">
          <MDTypography variant="body2" textAlign="left">
            First Name
          </MDTypography>

          <MDInput
            inputRef={fNameRef}
            placeholder="Enter First Name"
            value={fName}
            onChange={(event) => changeFName(event)}
            onPaste={(event) => changeFName(event)}
            error={fNameError !== ""}
            inputProps={{ maxLength: 25 }}
            style={{ width: "100%" }}
          />
          <FormHelperText error>
            {fNameError !== "" ? (
              <>
                {fNameError}{" "}
                <Tooltip
                  title="Valid name should start with a letter and contain only alphanummeric characters, spaces, commas, dots, hyphens and apostrophes"
                  enterDelay={100}
                  placement="top"
                >
                  <Icon color="error" style={{ cursor: "pointer" }}>
                    info
                  </Icon>
                </Tooltip>
              </>
            ) : null}
          </FormHelperText>
        </Grid>
        <Grid item xs={12} lg={4} textAlign="left">
          <MDTypography variant="body2" textAlign="left">
            Middle Name
          </MDTypography>

          <MDInput
            inputRef={mNameRef}
            placeholder="Enter Middle Name"
            value={mName}
            onChange={(event) => changeMName(event)}
            onPaste={(event) => changeMName(event)}
            error={mNameError !== ""}
            inputProps={{ maxLength: 25 }}
            style={{ width: "100%" }}
          />
          <FormHelperText error>
            {mNameError !== "" ? (
              <>
                {mNameError}{" "}
                <Tooltip
                  title="Valid name should start with a letter and contain only alphanummeric characters, spaces, commas, dots, hyphens and apostrophes"
                  enterDelay={100}
                  placement="top"
                >
                  <Icon color="error" style={{ cursor: "pointer" }}>
                    info
                  </Icon>
                </Tooltip>
              </>
            ) : null}
          </FormHelperText>
        </Grid>
        <Grid item xs={12} lg={4} textAlign="left">
          <MDTypography variant="body2" textAlign="left">
            Last Name
          </MDTypography>

          <MDInput
            inputRef={lNameRef}
            placeholder="Enter Last Name"
            value={lName}
            onChange={(event) => changeLName(event)}
            onPaste={(event) => changeLName(event)}
            error={lNameError !== ""}
            inputProps={{ maxLength: 25 }}
            style={{ width: "100%" }}
          />
          <FormHelperText error>
            {lNameError !== "" ? (
              <>
                {lNameError}{" "}
                <Tooltip
                  title="Valid name should start with a letter and contain only alphanummeric characters, spaces, commas, dots, hyphens and apostrophes"
                  enterDelay={100}
                  placement="top"
                >
                  <Icon color="error" style={{ cursor: "pointer" }}>
                    info
                  </Icon>
                </Tooltip>
              </>
            ) : null}
          </FormHelperText>
        </Grid>
      </Grid>
      <Grid container spacing={1} mt={1}>
        <Grid item xs={12} lg={4} textAlign="left">
          <MDTypography variant="body2" textAlign="left">
            Room Number
          </MDTypography>

          <MDInput
            inputRef={roomNoRef}
            placeholder="Enter Room Number"
            value={roomNo}
            onChange={(event) => changeRoomNo(event)}
            onPaste={(event) => changeRoomNo(event)}
            error={roomNoError !== ""}
            inputProps={{ maxLength: 25 }}
            style={{ width: "100%" }}
          />
          <FormHelperText error>
            {roomNoError !== "" ? (
              <>
                {roomNoError}{" "}
                <Tooltip
                  title="Valid room number should countain only alphanumeric characters, hyphens, underscores, dots, slashes (/), hashes (#) and spaces"
                  enterDelay={100}
                  placement="top"
                >
                  <Icon color="error" style={{ cursor: "pointer" }}>
                    info
                  </Icon>
                </Tooltip>
              </>
            ) : null}
          </FormHelperText>
        </Grid>
        <Grid item xs={12} lg={4} textAlign="left">
          <MDTypography variant="body2" textAlign="left">
            SSN
          </MDTypography>
          <SSNMaskedInput
            value={ssn}
            inputRef={ssnRef}
            placeholder="Enter SSN"
            onChangeSSN={changeSsn}
            error={ssnError !== ""}
          />
          <FormHelperText error>
            {ssnError !== "" ? (
              <>
                {ssnError}{" "}
                <Tooltip
                  title="Valid SSN should only contain numbers in the format 000-00-0000"
                  enterDelay={100}
                  placement="top"
                >
                  <Icon color="error" style={{ cursor: "pointer" }}>
                    info
                  </Icon>
                </Tooltip>
              </>
            ) : null}
          </FormHelperText>
        </Grid>
        <Grid item xs={12} lg={4} textAlign="left">
          <MDTypography variant="body2" textAlign="left">
            Resident Status
          </MDTypography>

          <Select
            value={status}
            onChange={(event) => {
              changeStatus(event);
            }}
            style={{ height: 40, width: "100%" }}
          >
            <MenuItem value="active">Active</MenuItem>
            <MenuItem value="discharged">Discharged</MenuItem>
          </Select>
        </Grid>
      </Grid>
      <Grid container spacing={1} mt={1}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Grid item xs={12} lg={4} textAlign="left">
            <MDTypography variant="body2" textAlign="left">
              Admission Date
            </MDTypography>
            <DesktopDatePicker
              maxDate={dischargeDate}
              value={admissionDate}
              onChange={(newValue) => {
                changeAdmissionDate(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={admissionDateError !== ""}
                  style={{
                    width: 160,
                    backgroundColor: "white",
                    borderRadius: "8px",
                  }}
                />
              )}
            />
            <FormHelperText error>{admissionDateError !== "" && admissionDateError}</FormHelperText>
          </Grid>
          {status === "discharged" && (
            <Grid item xs={12} lg={8} textAlign="left">
              <MDTypography variant="body2" textAlign="left">
                Discharge Date
              </MDTypography>

              <DesktopDatePicker
                minDate={admissionDate}
                value={dischargeDate}
                onChange={(newValue) => {
                  changeDischargeDate(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={dischargeDateError !== ""}
                    style={{
                      width: 160,
                      backgroundColor: "white",
                      borderRadius: "8px",
                    }}
                  />
                )}
              />
              <FormHelperText error>
                {dischargeDateError !== "" && dischargeDateError}
              </FormHelperText>
            </Grid>
          )}
        </LocalizationProvider>
      </Grid>
      <MDBox p={2} display="flex" textAlign="center" alignItems="center" justifyContent="center">
        <MDBox mr={1}>
          <MDButton
            onClick={() => closeModal({ action: "cancel" })}
            variant="gradient"
            color="light"
            size="medium"
            m={1}
          >
            cancel
          </MDButton>
        </MDBox>
        <MDBox mr={1}>
          <MDButton
            onClick={tryCreate}
            variant="gradient"
            color="primary"
            size="medium"
            loading={trying}
            loadingText="processing..."
            style={{ width: 160 }}
          >
            {isNew ? "Add Resident" : "Update Resident"}
          </MDButton>
        </MDBox>
      </MDBox>
    </div>
  );
};

export default AddUpdateResidentModal;
