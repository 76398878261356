import colors from "assets/theme/base/colors";
import MDBox from "components/MDBox";

import Skeleton from "components/skeletons/Skeleton";

function InspectionSkeleton() {
  return (
    <MDBox
      display="flex"
      flexDirection="column"
      style={{
        width: "calc(100% - 10px)",
        borderRadius: 8,
        margin: 10,
        border: `1px solid ${colors.grey[400]}`,
      }}
      shadow="sm"
    >
      <MDBox display="flex" pl={1.2} pt={1}>
        <Skeleton
          width={`calc(100% - ${Math.floor(Math.random() * 60 + 40)}px)`}
          height={16}
          margin={10}
        />
        <Skeleton
          width={`calc(100% - ${Math.floor(Math.random() * 60 + 40)}px)`}
          height={16}
          margin={10}
        />
      </MDBox>
      <Skeleton width="calc(100% - 20px)" height={3} margin={10} />
      <MDBox display="flex" justifyContent="flex-end" pb={1}>
        <Skeleton width={40} height={16} margin={10} />
        <Skeleton width={60} height={16} margin={10} />
      </MDBox>
    </MDBox>
  );
}

export default InspectionSkeleton;
