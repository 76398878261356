import dayjs from "dayjs";
import colors from "assets/theme/base/colors";
import { makeStyles } from "@material-ui/styles";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import MDBox from "./MDBox";
import MDTypography from "./MDTypography";
import Marquee from "./Marquee";
import MDButton from "./MDButton";

const useStyles = makeStyles({
  button_delete: {
    "&:hover": {
      color: colors.error.main,
    },
  },
});

const AttachedDocument = ({ file, deleteMe, undoDelete, hideMetadata }) => {
  const classes = useStyles();
  return (
    <MDBox sx={{ width: 300 }} display="flex" flexDirection="row">
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="3rem"
        height="3rem"
        variant="gradient"
        bgColor="dark"
        color="light"
        shadow="md"
        borderRadius="md"
        mr={2}
      >
        <Icon fontSize="medium" color="inherit">
          description
        </Icon>
      </MDBox>
      <MDBox display="flex" flexDirection="column" justifyContent="center">
        <MDBox sx={{ maxWidth: 230, height: 24 }} display="flex">
          <Marquee text={file.fileName} width={200} />
        </MDBox>
        {!hideMetadata && (
          <MDBox sx={{ maxWidth: 230, height: 24 }} display="flex">
            {file.markedForDelete && (
              <MDTypography variant="body2" style={{ fontSize: 12, color: colors.error.main }}>
                Marked For Delete
              </MDTypography>
            )}
            {!file.markedForDelete && file.dateUploaded === null && (
              <MDTypography variant="body2" style={{ fontSize: 12, color: colors.warning.main }}>
                Not Saved Yet
              </MDTypography>
            )}
            {!file.markedForDelete && file.dateUploaded !== null && (
              <MDTypography variant="body2" style={{ fontSize: 12, color: colors.grey[600] }}>
                {dayjs.unix(file.dateUploaded).format("MMM DD, YYYY")}
                {" - "}
                {dayjs.unix(file.dateUploaded).format("hh:mm A")}
              </MDTypography>
            )}
            {!file.markedForDelete && (
              <MDButton
                variant="text"
                color="secondary"
                iconOnly
                circular
                sx={{ marginTop: "-10px" }}
                className={classes.button_delete}
                onClick={deleteMe}
              >
                <Tooltip title="delete" enterDelay={500}>
                  <Icon>delete</Icon>
                </Tooltip>
              </MDButton>
            )}
            {file.markedForDelete && (
              <MDButton
                variant="text"
                color="secondary"
                iconOnly
                circular
                sx={{ marginTop: "-10px" }}
                onClick={undoDelete}
              >
                <Tooltip title="undo delete" enterDelay={500}>
                  <Icon>replay</Icon>
                </Tooltip>
              </MDButton>
            )}
          </MDBox>
        )}
      </MDBox>
    </MDBox>
  );
};

export default AttachedDocument;
