import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import useModal from "hooks/useModal";
import colors from "assets/theme/base/colors";
import Scrollbars from "react-custom-scrollbars";
import Resident from "components/Resident";

const SimulateResidentModal = ({ residents }) => {
  const { closeModal } = useModal();

  return (
    <div style={{ minWidth: 600 }}>
      <Scrollbars
        style={{
          maxHeight: "calc(100vh - 150px)",
          minHeight: residents.length <= 4 ? residents.length * 65 : 220,
          backgroundColor: colors.grey[100],
          borderRadius: 8,
          marginTop: 20,
        }}
        renderTrackHorizontal={({ style, ...props }) => <div />}
        renderTrackVertical={({ style, ...props }) => (
          <div className="scrollbar-regular-vertical-track" {...props} />
        )}
        renderThumbVertical={({ style, ...props }) => (
          <div
            style={{
              ...style,
              backgroundColor: "rgba(0, 0, 0, 0.2)",
              borderRadius: 8,
              width: 6,
              cursor: "pointer",
            }}
            {...props}
          />
        )}
      >
        {residents?.map((resident) => (
          <Resident key={resident.id} resident={resident} />
        ))}
      </Scrollbars>
      <MDBox display="flex" justifyContent="center" mt={2}>
        <MDButton onClick={closeModal} variant="gradient" color="info" size="medium">
          Okay
        </MDButton>
      </MDBox>
    </div>
  );
};

export default SimulateResidentModal;
