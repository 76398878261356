/* eslint-disable react/no-array-index-key */
import { Card, Icon } from "@mui/material";
import colors from "assets/theme/base/colors";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import Skeleton from "components/skeletons/Skeleton";

const ReadonlySection = ({ sectionIndex, data }) => (
  <Card
    style={{
      backgroundColor: colors.grey[300],
      width: "100%",
      marginBottom: 20,
      paddingBottom: 10,
    }}
  >
    <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
      <MDBox
        color="white"
        borderRadius="md"
        display="flex"
        height="2.5rem"
        px={2}
        mt={-3}
        mb={1}
        width="100%"
        style={{ background: "#344767" }}
        justifyContent="space-between"
      >
        <MDBox pt={1}>
          <MDTypography variant="body2" color="white">
            {data[sectionIndex].name}
          </MDTypography>
        </MDBox>
      </MDBox>
    </MDBox>
    <MDBox>
      {data[sectionIndex].questions.map((item, index) => (
        <MDBox p={1} mx={2} my={1} bgColor="white" borderRadius="xl">
          <MDBox width="100%">
            <MDTypography variant="body2">{item.questionText}</MDTypography>
          </MDBox>
        </MDBox>
      ))}
    </MDBox>
  </Card>
);

export default ReadonlySection;
