/* eslint-disable react/no-array-index-key */
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { Icon } from "@mui/material";
import useModal from "hooks/useModal";
import useResidents from "hooks/useResidents";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { useEffect, useState } from "react";
import useCompliance from "hooks/useCompliance";
import useBackendError from "hooks/useBackendError";
import { useNavigate } from "react-router-dom";
import { timeout } from "utils/utils";
import colors from "assets/theme/base/colors";
import Scrollbars from "react-custom-scrollbars";
import Marquee from "components/Marquee";
import ConfirmDeleteModal from "components/modal/ConfirmDeleteModal";

const QapiOverviewMain = ({ data, isLoading }) => {
  const { residents, setResidents } = useResidents();
  const { surveys, setSurveys } = useCompliance();
  const axios = useAxiosPrivate();
  const { openModal } = useModal();
  const { setError } = useBackendError();
  const navigate = useNavigate();
  const haveSaved = isLoading ? false : data?.filter((val) => val.status === "SAVED").length > 0;
  useEffect(() => {
    async function fetchData() {
      if (residents == null) {
        try {
          const response = await axios.get("/residents/list", {
            headers: { "Content-Type": "application/json" },
          });
          setResidents(response.data);
        } catch (err) {
          if (!err?.response) {
            setError("Failed to fetch the list of users in this facility. Please try again");
          }
        }
      }
    }
    fetchData();
  }, [axios, residents, setError, setResidents]);

  useEffect(() => {
    async function fetchData() {
      if (surveys == null) {
        try {
          const response = await axios.get("/compliance/list", {
            headers: { "Content-Type": "application/json" },
          });
          setSurveys(response.data);
        } catch (err) {
          if (!err?.response) {
            setError("Failed to fetch the list of pathways. Please try again");
          }
        }
      }
    }
    fetchData();
  }, [axios, setError, setSurveys, surveys]);

  const edit = (id) => {
    navigate(`/qapi/details?id=${id}`, { state: { action: "edit" } });
  };

  const newQapi = (id) => {
    navigate(`/qapi/details`, { state: { action: "new" } });
  };

  const deleteCallback = async () => {
    console.log("deleteing...");
    await timeout(3000);
    /* await axios.post(`/qapi/delete`, {
      headers: { "Content-Type": "application/json" },
    }); */
    return "success";
  };

  const deleteRow = (id) => {
    openModal(
      <ConfirmDeleteModal
        title="Delete QAPI?"
        text="Do you really want to delete the seleted QAPI?"
        successText="The selected QAPI has been successfully deleted."
        callback={deleteCallback}
        arg={id}
      />,
      () => {}
    );
  };

  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 985;

  return (
    <Grid container spacing={3}>
      <Grid item style={{ width: isMobile || !haveSaved ? "100%" : "calc(100% - 500px)" }}>
        <Card id="compliance-overview" style={{ height: "100%" }}>
          <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
            <MDBox
              color="white"
              bgColor="info"
              variant="gradient"
              borderRadius="lg"
              shadow="lg"
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="2.5rem"
              pl={2}
              pr={2}
              mt={-4}
            >
              <MDTypography variant="h6" fontWeight="medium" color="white">
                QAPI
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexWrap="wrap"
            pl={3}
            pr={3}
          >
            <MDBox pt={2} pb={2} fontSize="1rem" style={{ textAlign: "center", width: "100%" }}>
              QAPI is a process of meeting quality standards and assuring that care reaches an
              acceptable level.
            </MDBox>
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexWrap="wrap"
              pb={haveSaved ? 1 : 3}
            >
              <MDButton variant="gradient" color="info" style={{ margin: 5 }} onClick={() => {}}>
                clone existing &nbsp;<Icon sx={{ fontWeight: "bold" }}>content_copy</Icon>
              </MDButton>

              <MDButton variant="gradient" color="primary" style={{ margin: 5 }} onClick={newQapi}>
                start new qapi &nbsp;
                <Icon sx={{ fontWeight: "bold" }}>inventory</Icon>
              </MDButton>
            </MDBox>
          </MDBox>
        </Card>
      </Grid>
      {haveSaved && (
        <Grid item style={{ minWidth: isMobile ? "100%" : "500px" }}>
          <Card id="saved-qapi">
            <MDBox px={3} py={1}>
              <MDTypography variant="h6" fontWeight="medium" width={280}>
                Saved QAPIs
              </MDTypography>
              <MDBox
                className="innerShadow"
                mt={1}
                borderRadius="lg"
                bgColor={colors.grey[100]}
                style={{ height: "120px", border: `1px solid ${colors.grey[300]}` }}
              >
                <Scrollbars
                  renderTrackHorizontal={({ style, ...props }) => <div />}
                  renderTrackVertical={({ style, ...props }) => (
                    <div className="scrollbar-regular-vertical-track" {...props} />
                  )}
                  renderThumbVertical={({ style, ...props }) => (
                    <div
                      style={{
                        ...style,
                        backgroundColor: "rgba(0, 0, 0, 0.2)",
                        borderRadius: 8,
                        width: 6,
                        cursor: "pointer",
                      }}
                      {...props}
                    />
                  )}
                >
                  {data
                    ?.filter((val) => val.status === "SAVED")
                    .map((row, index) => (
                      <MDBox
                        sx={{
                          marginTop: "5px",
                          marginBottom: "5px",
                          borderTop: index !== 0 ? `1px solid ${colors.grey[400]}` : null,
                        }}
                        display="flex"
                        justifyContent="space-between"
                        pl={3}
                        pr={3}
                        pt={0.5}
                        key={`qapi-${index}`}
                      >
                        <MDBox sx={{ maxWidth: 230, height: 24 }} display="flex">
                          <Marquee
                            text={row.title}
                            width={200}
                            fontWeight="regular"
                            underlineOnHover={false}
                          />
                        </MDBox>

                        <MDBox>
                          <MDButton
                            variant="outlined"
                            color="primary"
                            size="small"
                            style={{ marginBottom: "10" }}
                            onClick={() => edit(row.id)}
                          >
                            continue
                          </MDButton>
                          <MDButton
                            variant="text"
                            color="secondary"
                            iconOnly
                            circular
                            onClick={() => deleteRow(row.id)}
                            isLoading={isLoading}
                          >
                            <Icon>delete</Icon>
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    ))}
                </Scrollbars>
              </MDBox>
            </MDBox>
          </Card>
        </Grid>
      )}
    </Grid>
  );
};

export default QapiOverviewMain;
