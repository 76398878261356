import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { MaterialUIControllerProvider } from "context";
import { AuthProvider } from "context/AuthProvider";
import { ModalProvider } from "context/ModalProvider";
import { BackendErrorProvider } from "context/BackendErrorProvider";
import { ResidentProvider } from "context/ResidentProvider";
import Modal from "components/modal/Modal";
import { SizeProvider } from "context/SizeProvider";
// Material Dashboard 2 React themes
import theme from "assets/theme";
// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import App from "App";
import { ComplianceProvider } from "context/ComplianceProvider";
import { FacilityProvider } from "context/FacilityProvider";
import { ScrollProvider } from "context/ScrollProvider";

ReactDOM.render(
  <React.StrictMode>
    <BackendErrorProvider>
      <SizeProvider>
        <BrowserRouter>
          <ScrollProvider>
            <FacilityProvider>
              <AuthProvider>
                <MaterialUIControllerProvider>
                  <ThemeProvider theme={theme}>
                    <ComplianceProvider>
                      <ResidentProvider>
                        <ModalProvider>
                          <Modal />
                          <Routes>
                            <Route path="/*" element={<App />} />
                          </Routes>
                        </ModalProvider>
                      </ResidentProvider>
                    </ComplianceProvider>
                  </ThemeProvider>
                </MaterialUIControllerProvider>
              </AuthProvider>
            </FacilityProvider>
          </ScrollProvider>
        </BrowserRouter>
      </SizeProvider>
    </BackendErrorProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
