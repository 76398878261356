import Card from "@mui/material/Card";
import DataTable from "components/DataTable";

import DefaultCell from "components/DataTable/components/DefaultCell";
import TimestampCell from "components/DataTable/components/TimestampCell";

import { useEffect, useState } from "react";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import useBackendError from "hooks/useBackendError";
import PendingEmailInviteActionCell from "components/DataTable/components/ActionCells/PendingEmailInviteActionCell";

const dataTableData = (rows, isLoading) => ({
  columns: [
    {
      Header: "Name",
      accessor: "fullName",
      isSorted: false,
      Cell: ({ value }) => <DefaultCell value={value} isLoading={isLoading} />,
    },
    {
      Header: "Email",
      accessor: "email",
      Cell: ({ value }) => <DefaultCell value={value} isLoading={isLoading} />,
    },
    {
      Header: "Sent By",
      accessor: "sentBy",
      Cell: ({ value }) => <DefaultCell value={value} isLoading={isLoading} />,
    },
    {
      Header: "Sent On",
      accessor: "sentDate",
      Cell: ({ value }) => <TimestampCell timestamp={value} isLoading={isLoading} />,
    },
    {
      Header: "Actions",
      accessor: "action",
      isSorted: false,
      Cell: ({ value }) => <PendingEmailInviteActionCell id={value} isLoading={isLoading} />,
    },
  ],
  rows,
});

const PendingEmailInvites = () => {
  let rows = [];
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const axios = useAxiosPrivate();
  const { setError } = useBackendError();

  const loadData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/users/pending-email`, {
        headers: { "Content-Type": "application/json" },
      });
      setData(response.data);
    } catch (err) {
      setError("Failed to fetch from the backend. Please try again");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  if (!isLoading) {
    rows = data?.map((row) => ({
      fullName: row.fullName,
      email: row.email,
      sentBy: row.sentBy,
      sentDate: row.sentDate,
      action: row.id,
    }));
  } else {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 5; i++) {
      // eslint-disable-next-line no-new-object
      rows.push({
        fullName: "",
        email: "",
        sentBy: "",
        sentDate: 0,
        action: 0,
      });
    }
  }

  if (rows == null) {
    return null;
  }

  return (
    <Card id="past-surveys">
      <DataTable
        table={dataTableData(rows, isLoading)}
        canSearch
        title="Response Pending Email Invitations"
        isSorted
      />
    </Card>
  );
};

export default PendingEmailInvites;
