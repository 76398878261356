/* eslint-disable react/no-array-index-key */
import Grid from "@mui/material/Grid";

import { useCallback, useEffect, useState } from "react";
import useAxiosPrivate from "hooks/useAxiosPrivate";

import useBackendError from "hooks/useBackendError";

import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import { Card, Icon } from "@mui/material";
import MDButton from "components/MDButton";

import dayjs from "dayjs";

import useModal from "hooks/useModal";
import UploadMdsModal from "components/modal/UploadMdsModal";

import AddUpdateResidentModal from "components/modal/AddUpdateResidentModal";
import ResidentList from "./ResidentList";
import MDSUploadHistory from "./MDSUploadHistory";

const relativeTime = require("dayjs/plugin/relativeTime");

dayjs.extend(relativeTime);

const ResidentsOverview = () => {
  const [isResidentListLoading, setResidentListLoading] = useState(true);
  const [residentList, setResidentList] = useState([]);

  const { openModal } = useModal();

  const axios = useAxiosPrivate();
  const { setError } = useBackendError();

  const loadUserList = useCallback(async () => {
    try {
      const response = await axios.get(`/residents/list`, {
        headers: { "Content-Type": "application/json" },
      });
      setResidentList(response.data);
    } catch (err) {
      setError("Failed to fetch from the backend. Please try again");
    } finally {
      setResidentListLoading(false);
    }
  }, []);

  useEffect(() => {
    loadUserList();
  }, []);

  const uploadMds = () => {
    openModal(<UploadMdsModal />, () => {});
  };

  const addResident = () => {
    openModal(<AddUpdateResidentModal />, () => {});
  };

  return (
    <Grid container spacing={3} mb={3}>
      <Grid item md={7.5} xs={12}>
        <Card id="residents-overview">
          <MDBox pt={2} px={2} display="flex" justifyContent="left" alignItems="center">
            <MDBox
              color="white"
              bgColor="info"
              variant="gradient"
              borderRadius="lg"
              shadow="lg"
              display="flex"
              justifyContent="center"
              alignItems="center"
              py={1}
              px={2}
              mt={-4}
              mb={3}
            >
              <MDTypography variant="h6" fontWeight="medium" color="white">
                Residents
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDBox p={1} fontSize="1rem" style={{ textAlign: "center", width: "100%" }}>
            Add a resident manually or upload MDS file(s) to add or update resident(s).
          </MDBox>
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexWrap="wrap"
            pt={1}
            pb={4}
          >
            <MDButton variant="gradient" color="info" style={{ margin: 5 }} onClick={addResident}>
              <Icon sx={{ fontWeight: "bold" }}>add</Icon>
              &nbsp;ADD RESIDENT
            </MDButton>
            <MDButton variant="gradient" color="primary" style={{ margin: 5 }} onClick={uploadMds}>
              <Icon sx={{ fontWeight: "bold" }}>upload</Icon>
              &nbsp;Upload MDS File
            </MDButton>
          </MDBox>
        </Card>
        <Card id="users" style={{ marginTop: 20 }}>
          <MDBox p={2}>
            <MDTypography variant="h6" fontWeight="medium">
              All Residents
            </MDTypography>
            <MDBox mt={2}>
              <ResidentList
                data={residentList}
                isLoading={isResidentListLoading}
                refreshCallback={loadUserList}
              />
            </MDBox>
          </MDBox>
        </Card>
      </Grid>
      <Grid item md={4.5} xs={12}>
        <Card id="mds-log" style={{ height: "100%" }}>
          <MDSUploadHistory />
        </Card>
      </Grid>
    </Grid>
  );
};

export default ResidentsOverview;
