/* eslint-disable react/no-array-index-key */
import { makeStyles } from "@material-ui/styles";
import Icon from "@mui/material/Icon";
import colors from "assets/theme/base/colors";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import ConfirmDeleteModal from "components/modal/ConfirmDeleteModal";
import InspectionModal from "components/modal/InspectionModal";
import dayjs from "dayjs";
import useBackendError from "hooks/useBackendError";
import useModal from "hooks/useModal";
import Scrollbars from "react-custom-scrollbars";
import { timeout } from "utils/utils";

const useStyles = makeStyles({
  link_button_delete: {
    "&:hover": {
      color: colors.error.main,
    },
  },
  link_button_edit: {
    "&:hover": {
      color: colors.warning.main,
    },
  },
});

const Inspection = ({
  isNew,
  number,
  inspectionIndex,
  inspections,
  saveInspections,
  isComplaint,
}) => {
  const classes = useStyles();

  const styles = {
    emptyContainer: {
      width: "calc(100% - 10px)",
      height: "calc(100% - 20px)",
      border: `2px dashed ${
        isNew && inspections?.[inspectionIndex]?.revisits?.[number - 1] == null
          ? colors.grey[200]
          : colors.grey[400]
      }`,
      borderRadius: 8,
      margin: 10,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: 115,
    },
  };

  const { openModal } = useModal();
  const { error, setError } = useBackendError();

  const newInspection = () => {
    openModal(
      <InspectionModal
        isNew
        number={number}
        inspectionIndex={inspections.length - 1}
        inspections={inspections}
        saveInspections={saveInspections}
      />,
      () => {}
    );
  };

  if (isNew) {
    return (
      <MDBox style={styles.emptyContainer}>
        <MDButton
          variant={
            inspections != null && inspections?.[inspectionIndex]?.revisits?.[number - 1] == null
              ? null
              : "gradient"
          }
          color="light"
          onClick={newInspection}
          disabled={
            inspections != null && inspections?.[inspectionIndex]?.revisits?.[number - 1] == null
          }
        >
          {inspections != null &&
            inspections?.[inspectionIndex]?.revisits?.[number - 2] != null && (
              <Icon sx={{ fontWeight: "bold" }}>add</Icon>
            )}
          &nbsp; revisit {number}
        </MDButton>
      </MDBox>
    );
  }

  const annualInspection = inspections?.[inspectionIndex];

  const id = number === 0 ? annualInspection.id : annualInspection.revisits[number - 1].id;
  const fTags = number === 0 ? annualInspection.fTags : annualInspection.revisits[number - 1].fTags;
  const date = number === 0 ? annualInspection.date : annualInspection.revisits[number - 1].date;

  const isRevist = number !== 0;

  const editInspection = () => {
    openModal(
      <InspectionModal
        isNew={isNew}
        id={id}
        number={number}
        fTags={fTags}
        date={date}
        inspectionIndex={inspectionIndex}
        inspections={inspections}
        saveInspections={saveInspections}
        isComplaint={isComplaint}
      />,
      () => {}
    );
  };

  const deleteCallback = async () => {
    console.log("deleteing...");
    await timeout(3000);
    /* await axios.post(`/qapi/delete`, {
      headers: { "Content-Type": "application/json" },
    }); */
    return "success";
  };

  const deleteInspection = () => {
    openModal(
      <ConfirmDeleteModal
        title="Delete Inspection?"
        text="Do you really want to delete the seleted inspection?"
        successText="The selected inspection has been successfully deleted."
        callback={deleteCallback}
        args={id}
      />,
      () => {}
    );
  };

  return (
    <MDBox
      display="flex"
      flexDirection="column"
      style={{
        width: "calc(100% - 10px)",
        borderRadius: 8,
        margin: 10,
        border: isRevist ? `1px solid ${colors.grey[400]}` : "",
      }}
      shadow={isRevist ? "sm" : ""}
    >
      <MDBox display="flex" pl={1.2} pt={1}>
        <MDTypography
          display="inline"
          variant="h6"
          textTransform="capitalize"
          fontWeight="bold"
          style={{ fontSize: 13 }}
        >
          {!isRevist && !isComplaint && "Annual Inspection"}
          {!isRevist && isComplaint && "Complaint Inspection"}
          {isRevist && `Revisit ${number}`}
        </MDTypography>
        <MDTypography
          ml={0.8}
          display="inline"
          variant="body2"
          style={{ fontSize: 13, color: colors.grey[500] }}
        >
          | {dayjs.unix(date).format("MMM DD, YYYY")}
        </MDTypography>
      </MDBox>
      <MDBox display="flex" style={{ margin: 10 }}>
        <Scrollbars
          style={{
            width: "100%",
            height: 42,
            backgroundColor: colors.grey[200],
            borderRadius: 8,
          }}
          renderTrackHorizontal={({ style, ...props }) => (
            <div className="scrollbar-regular-horizontal-track" {...props} />
          )}
          renderThumbHorizontal={({ style, ...props }) => (
            <div
              style={{
                ...style,
                backgroundColor: "rgba(0, 0, 0, 0.2)",
                borderRadius: 8,
                height: 5,
                cursor: "pointer",
                top: 5,
              }}
              {...props}
            />
          )}
        >
          <MDBox display="flex" pt={1} px={1}>
            {fTags != null &&
              fTags.map((ftag, index) => (
                <MDBox
                  key={`ftag-${index}-${number}`}
                  bgColor={ftag.resolved ? "white" : "secondary"}
                  borderRadius="lg"
                  mx={0.2}
                  px={1}
                  style={{ height: 24, marginRight: 5 }}
                  display="flex"
                >
                  {ftag.resolved ? (
                    <MDTypography
                      display="inline"
                      variant="body2"
                      style={{ fontSize: 13 }}
                      lineHeight={2}
                    >
                      <Icon color="success">check</Icon>
                    </MDTypography>
                  ) : null}

                  <MDTypography
                    display="inline"
                    variant="body2"
                    style={{ fontSize: 12 }}
                    lineHeight={2}
                    color={ftag.resolved ? "dark" : "white"}
                  >
                    {ftag.name}
                  </MDTypography>
                </MDBox>
              ))}
            {(fTags == null || fTags.length === 0) && (
              <>
                <MDTypography
                  display="inline"
                  variant="caption"
                  color="success"
                  fontWeight="bold"
                  sx={{ marginTop: 0.7, marginRight: 0.5 }}
                >
                  <Icon color="success">check</Icon>
                </MDTypography>
                <MDTypography
                  display="inline"
                  variant="caption"
                  color="success"
                  fontWeight="bold"
                  sx={{ marginTop: 0.6 }}
                >
                  No Citations
                </MDTypography>
              </>
            )}
          </MDBox>
        </Scrollbars>
      </MDBox>
      <MDBox display="flex" justifyContent={isRevist ? "flex-end" : "flex-start"} pb={1}>
        <MDTypography
          display="inline"
          variant="caption"
          color="info"
          fontWeight="bold"
          style={{ cursor: "pointer", fontSize: 12, marginRight: 15, marginLeft: 10 }}
          className={classes.link_button_edit}
          onClick={editInspection}
        >
          EDIT
        </MDTypography>
        <MDTypography
          display="inline"
          variant="caption"
          color="text"
          fontWeight="bold"
          style={{ cursor: "pointer", fontSize: 12, marginRight: 15 }}
          className={classes.link_button_delete}
          onClick={deleteInspection}
        >
          DELETE
        </MDTypography>
      </MDBox>
    </MDBox>
  );
};

export default Inspection;
