/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/prop-types */
import { createContext, useState } from "react";

const ResidentContext = createContext({});

export const ResidentProvider = ({ children }) => {
  const [residents, setResidents] = useState();

  return (
    <ResidentContext.Provider value={{ residents, setResidents }}>
      {children}
    </ResidentContext.Provider>
  );
};

export default ResidentContext;
