import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import BaseLayout from "components/BaseLayout";

import { useEffect, useRef, useState } from "react";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import useAuth from "hooks/useAuth";

import useBackendError from "hooks/useBackendError";
import { useCache } from "hooks/useCache";
import ComplianceOverviewMain from "./ComplianceOverviewMain";
import ComplianceOverviewTopFive from "./ComplianceOverviewTopFive";
import ComplianceOverviewPastSurveys from "./ComplianceOverviewPastSurveys";
import ComplianceOverviewTopFiveSkeleton from "./skeleton/ComplianceOverviewTopFiveSkeleton";

const ComplianceOverview = () => {
  const [isLoading, setLoading] = useState(true);
  const data = useRef();
  const axios = useAxiosPrivate();
  const { auth, setAuth } = useAuth();
  const { timeline } = auth.profile;
  const { setError } = useBackendError();
  const { getItem, setItem } = useCache();

  const loadData = async () => {
    setLoading(true);
    data.current = getItem("compliance-overview");
    if (data.current !== null) {
      setLoading(false);
      return;
    }
    try {
      const response = await axios.post(
        `/compliance?type=${timeline.type}&startDate=${timeline.startDate}&endDate=${timeline.endDate}&quarter=${timeline.quarter}&year=${timeline.year}`,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      data.current = response.data;
      setItem("compliance-overview", response.data);
    } catch (err) {
      setError("Failed to fetch from the backend. Please try again");
    } finally {
      setLoading(false);
    }
  };

  const updateTimeline = (val) => {
    const newAuth = auth;
    newAuth.profile.timeline = val;
    setAuth(newAuth);
    loadData();
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <MDBox mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={7} xxl={8}>
            <ComplianceOverviewMain />
          </Grid>
          <Grid item xs={12} md={6} lg={5} xxl={4}>
            {!isLoading && (
              <ComplianceOverviewTopFive data={data.current} setTimeline={updateTimeline} />
            )}
            {isLoading && <ComplianceOverviewTopFiveSkeleton />}
          </Grid>
        </Grid>
      </MDBox>
      <MDBox mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ComplianceOverviewPastSurveys
              data={data.current}
              setTimeline={updateTimeline}
              isLoading={isLoading}
            />
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
};
export default ComplianceOverview;
