/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

import { Icon, Tooltip } from "@mui/material";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import useModal from "hooks/useModal";
import ConfirmDeleteModal from "components/modal/ConfirmDeleteModal";
import { timeout } from "utils/utils";
import MDButton from "components/MDButton";
import colors from "assets/theme/base/colors";
import ConfirmModalWithSuccess from "components/modal/ConfirmModalWithSuccess";

const EmailPendingActionCell = ({
  id,
  isLoading,
  followupDisabledReason,
  email,
  triggerLoading,
}) => {
  const { openModal } = useModal();
  const axios = useAxiosPrivate();

  const deleteCallback = async () => {
    console.log("deleteing...");
    await timeout(3000);
    /* await axios.post(`/qapi/delete`, {
      headers: { "Content-Type": "application/json" },
    }); */
    return "success";
  };

  const deleteSurvey = () => {
    openModal(
      <ConfirmDeleteModal
        title="Delete Reminder?"
        text="Do you really want to delete the email reminder?"
        successText="The selected reminder has been successfully deleted."
        callback={deleteCallback}
      />,
      () => {}
    );
  };

  const sendCallback = async () => {
    console.log("sending...");
    await timeout(3000);
    /* await axios.post(`/qapi/delete`, {
      headers: { "Content-Type": "application/json" },
    }); */
    triggerLoading();
    return "success";
  };

  const followup = () => {
    openModal(
      <ConfirmModalWithSuccess
        title="Email follow-up?"
        successText="The follow-up email has been successfuflly sent."
        successTitle="Successfully sent!"
        htmlText={`<p>Do you really want to send a follow-up email?<br> Clicking YES will send an email to <span style='color: ${colors.primary.main}'>${email}</span> right away.</p>`}
        callback={sendCallback}
        loadingText="sending.."
      />,
      () => {}
    );
  };

  return (
    <MDBox display="flex" alignItems="center">
      {followupDisabledReason != null && (
        <MDBox style={{ margin: 5 }}>
          <Tooltip title={followupDisabledReason} placement="top">
            <MDBox>
              <MDButton variant="gradient" color="info" isLoading={isLoading} disabled>
                follow up
              </MDButton>
            </MDBox>
          </Tooltip>
        </MDBox>
      )}
      {followupDisabledReason == null && (
        <MDButton
          variant="gradient"
          color="info"
          style={{ margin: 5 }}
          onClick={followup}
          isLoading={isLoading}
        >
          follow up
        </MDButton>
      )}

      <MDButton
        variant="text"
        color="secondary"
        iconOnly
        circular
        onClick={deleteSurvey}
        isLoading={isLoading}
      >
        <Icon>delete</Icon>
      </MDButton>
    </MDBox>
  );
};

// Typechecking props for the StatusCell
EmailPendingActionCell.propTypes = {
  id: PropTypes.number.isRequired,
};

export default EmailPendingActionCell;
