/* eslint-disable react/no-array-index-key */
import { Document, Page, Text, View, StyleSheet, Font } from "@react-pdf/renderer";
import colors from "assets/theme/base/colors";

// Create styles
const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    flex: 1,
  },
  facility: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
  },
  text: {
    margin: 12,
    fontSize: 12,
    textAlign: "justify",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 10,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 10,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  section: { borderRadius: 8, backgroundColor: colors.grey[300], marginBottom: 20 },
  sectionTitle: {
    margin: 5,
    padding: 5,
    fontSize: 14,
    textAlign: "left",
    backgroundColor: colors.grey[800],
    color: "white",
    borderRadius: 8,
    display: "inline-block",
  },
  question: {
    borderRadius: 8,
    backgroundColor: colors.grey[100],
    margin: 8,
    fontSize: 12,
  },

  option: { fontSize: 12, width: 200 },
  comment: {
    width: "490",
    height: 80,
    borderRadius: 8,
    backgroundColor: "white",
    margin: 10,
    border: "1px solid grey",
  },
  optionCircle: {
    width: 15,
    height: 15,
    borderRadius: "50%",
    backgroundColor: "white",
    border: "1px solid grey",
    marginRight: 5,
  },
  optionContainer: {
    flexDirection: "row",
    marginLeft: 30,
    marginTop: 4,
  },
  optionText: {
    fontSize: 12,
    width: "450",
  },
});

const hyphenationCallback = (word) => [word];

Font.registerHyphenationCallback(hyphenationCallback);

// Create Document Component
const MockSurvey = ({ filename, facility, data }) => (
  <Document>
    <Page style={styles.body}>
      <Text style={styles.header} fixed>
        Generated @ starsforcare.com on 02/03/2023 06:30 PM
      </Text>
      <Text style={styles.title} hyphenationCallback={hyphenationCallback}>
        {filename}
      </Text>
      <Text style={styles.facility}>{facility}</Text>

      {data.sections.map((section, i) => (
        <View style={styles.section} key={`section-${i}`}>
          <Text style={styles.sectionTitle}>{section.section}</Text>
          {section.questions.map((question, j) => (
            <View style={styles.question} key={`section-${i}-${j}`} wrap={false}>
              <Text style={styles.text}>{question.question}</Text>
              {question.options.map((option, k) => (
                <View style={styles.optionContainer} key={`section-${i}-${j}-${k}`}>
                  <View style={styles.optionCircle} />
                  <Text style={styles.optionText}>{option.option}</Text>
                </View>
              ))}

              <View style={styles.comment} />
            </View>
          ))}
        </View>
      ))}

      <Text
        style={styles.pageNumber}
        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
        fixed
      />
    </Page>
  </Document>
);
export default MockSurvey;
