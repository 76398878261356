import axios, { enableMocks } from "../api/axios";
import useAuth from "./useAuth";
import useBackendError from "./useBackendError";

const useLogout = () => {
  const { setAuth } = useAuth();
  const { setError } = useBackendError();
  const logout = async () => {
    setAuth({});
    try {
      await axios.get("signout", {
        withCredentials: !enableMocks,
      });
    } catch (err) {
      setError("Did not receive a response from the backend. Please try again");
    }
  };
  return logout;
};

export default useLogout;
