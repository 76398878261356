/* eslint-disable react/no-array-index-key */
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

const StarRating = ({ color = "white", rating, fontSize = "default" }) => {
  const stars = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 1; i <= 5; i++) {
    if (i <= rating) {
      stars.push("star");
    } else {
      stars.push("star_notfilled");
    }
  }

  return (
    <span>
      {stars.map((star, index) =>
        star === "star" ? (
          <Icon
            fontSize={fontSize}
            baseClassName="material-icons-sharp"
            style={{ color }}
            key={`star-${index}`}
          >
            star
          </Icon>
        ) : (
          <Icon
            baseClassName="material-icons-sharp"
            fontSize={fontSize}
            color="disabled"
            key={`star-${index}`}
          >
            star
          </Icon>
        )
      )}
    </span>
  );
};

export default StarRating;
