import { makeStyles } from "@material-ui/styles";
import MDTypography from "components/MDTypography";
import { useRef, useState } from "react";

const Marquee = ({
  text,
  width,
  height,
  variant = "body2",
  fontSize,
  fontWeight = "medium",
  color = "dark",
  underlineOnHover = true,
}) => {
  const mainRef = useRef();
  const useStyles = makeStyles({
    wrapper: {
      position: "relative",
      overflow: "hidden",
      cursor: "pointer",
      width,
    },
    slide: {
      position: "absolute",
      transition: "0.5s",
      transitionProperty: "left",
    },
    slideHover: {
      position: "absolute",
      transition: "1s",
      transitionProperty: "left",
      textDecoration: underlineOnHover ? "underline" : null,
    },
  });

  const [hover, setHover] = useState(false);

  const classes = useStyles();

  const [displayEllipsis, setDisplayEllipsis] = useState(true);

  const handleHover = () => {
    if (!hover) {
      setDisplayEllipsis(false);
    } else {
      setTimeout(() => {
        setDisplayEllipsis(true);
      }, 400);
    }
    setHover(!hover);
  };

  return (
    <div className={classes.wrapper} style={{ height }}>
      <MDTypography
        id="slide"
        style={{
          left:
            hover && width - mainRef.current.clientWidth < 0
              ? `${width - mainRef.current.clientWidth - 10}px`
              : 0,
        }}
        className={hover ? classes.slideHover : classes.slide}
        onMouseLeave={handleHover}
        onMouseEnter={handleHover}
        variant={variant}
        fontWeight={fontWeight}
        color={color}
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          WebkitBoxOrient: !hover ? "vertical" : null,
          WebkitLineClamp: !hover ? 1 : null,
          textOverflow: displayEllipsis ? "ellipsis" : null,
          maxWidth: !hover ? width : null,
          fontSize,
        }}
      >
        {text}
      </MDTypography>
      <MDTypography
        ref={mainRef}
        id="slide"
        style={{
          left:
            hover && width - mainRef.current.clientWidth < 0
              ? `${width - mainRef.current.clientWidth - 10}px`
              : 0,
        }}
        className={hover ? classes.slideHover : classes.slide}
        variant={variant}
        fontWeight={fontWeight}
        color={color}
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          visibility: "hidden",
          fontSize,
        }}
      >
        {text}
      </MDTypography>
    </div>
  );
};
export default Marquee;
