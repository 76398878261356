/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */

import MDBox from "components/MDBox";
import { Card, FormHelperText, Grid } from "@mui/material";
import Icon from "@mui/material/Icon";
import colors from "assets/theme/base/colors";
import { useState } from "react";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import MDInput from "components/MDInput";
import MDDropzone from "components/MDDropzone";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import useBackendError from "hooks/useBackendError";
import ConfirmModal from "components/modal/ConfirmModal";
import useModal from "hooks/useModal";

const StaffingDetailsStep = ({ errors, resetError, setCalculationData, calculationData }) => {
  const [census, setCensus] = useState(
    calculationData.staffing?.censusOverride != null
      ? calculationData.staffing?.censusOverride
      : calculationData.staffing?.census
  );
  const [totalNurseHr, setTotalNurseHr] = useState(calculationData.staffing?.totalNurseHr ?? "");
  const [totalRNHr, setTotalRNHr] = useState(calculationData.staffing?.totalRNHr ?? "");
  const [totalStaffHrWeekends, setTotalStaffHrWeekends] = useState(
    calculationData.staffing?.totalStaffHrWeekends ?? ""
  );
  const [totalNurseTurnover, setTotalNurseTurnover] = useState(
    calculationData.staffing?.totalNurseTurnover ?? ""
  );
  const [rNTurnover, setRNTurnover] = useState(calculationData.staffing?.rNTurnover ?? "");
  const [adminTurnover, setAdminTurnover] = useState(calculationData.staffing?.adminTurnover ?? "");

  const errorCensus = errors.find((obj) => obj.key === "census")?.value;
  const errorTotalNurseHr = errors.find((obj) => obj.key === "totalNurseHr")?.value;
  const errorTotalRNHr = errors.find((obj) => obj.key === "totalRNHr")?.value;
  const errorTotalStaffHrWeekends = errors.find((obj) => obj.key === "totalStaffHrWeekends")?.value;
  const errorTotalNurseTurnover = errors.find((obj) => obj.key === "totalNurseTurnover")?.value;
  const errorRNTurnover = errors.find((obj) => obj.key === "rNTurnover")?.value;
  const errorAdminTurnover = errors.find((obj) => obj.key === "adminTurnover")?.value;

  const axios = useAxiosPrivate();
  const { setError } = useBackendError();
  const { openModal } = useModal();

  const setCensusFn = (val) => {
    calculationData.staffing.censusOverride = val;
    setCalculationData(calculationData);
    resetError("census");
    setCensus(val);
  };
  const setTotalNurseHrFn = (val) => {
    calculationData.staffing.totalNurseHr = val;
    setCalculationData(calculationData);
    resetError("totalNurseHr");
    setTotalNurseHr(val);
  };
  const setTotalRNHrFn = (val) => {
    calculationData.staffing.totalRNHr = val;
    setCalculationData(calculationData);
    resetError("totalRNHr");
    setTotalRNHr(val);
  };
  const setTotalStaffHrWeekendsFn = (val) => {
    calculationData.staffing.totalStaffHrWeekends = val;
    setCalculationData(calculationData);
    resetError("totalStaffHrWeekends");
    setTotalStaffHrWeekends(val);
  };
  const setTotalNurseTurnoverFn = (val) => {
    calculationData.staffing.totalNurseTurnover = val;
    setCalculationData(calculationData);
    resetError("totalNurseTurnover");
    setTotalNurseTurnover(val);
  };
  const setRNTurnoverFn = (val) => {
    calculationData.staffing.rNTurnover = val;
    setCalculationData(calculationData);
    resetError("rNTurnover");
    setRNTurnover(val);
  };
  const setAdminTurnoverFn = (val) => {
    calculationData.staffing.adminTurnover = val;
    setCalculationData(calculationData);
    resetError("adminTurnover");
    setAdminTurnover(val);
  };

  const autoPopulateCensus = () => {
    if (census === "") {
      calculationData.staffing.censusOverride = calculationData.staffing.census;
      setCalculationData(calculationData);
      setCensus(calculationData.staffing.census);
    }
  };

  const uploadPbj = async (files) => {
    try {
      const response = await axios.get(`/staffing/file?id=${files[0].serverId}`, {
        headers: { "Content-Type": "application/json" },
      });
      setTotalNurseHrFn(response.data.totalNurseHr);
      setTotalRNHrFn(response.data.totalRNHr);
      setTotalStaffHrWeekendsFn(response.data.totalStaffHrWeekends);
      openModal(
        <ConfirmModal
          buttonLabels={["okay"]}
          icon="success"
          text="The PBJ file has been successfully uploaded and the hours has been updated."
        />,
        () => {}
      );
    } catch (err) {
      setError("Failed to parse the PBJ file. Please try again!");
    }
  };

  return (
    <>
      <MDBox display="flex" justifyContent="center" px={2} pt={2}>
        <MDTypography variant="h6" fontWeight="medium">
          Staffing Details
        </MDTypography>
      </MDBox>
      <MDBox display="flex" justifyContent="center" px={2} pt={1}>
        <MDTypography
          variant="subtitle1"
          style={{ fontSize: 14, marginLeft: 10 }}
          color="text"
          justifyContent="center"
        >
          Please enter the staffing details below. Alternatively, you can upload the PBJ file for
          the quarter to auto-populate.
        </MDTypography>
      </MDBox>

      <MDBox display="flex" justifyContent="space-between" px={2} pt={2} alignItems="flex-end">
        <MDBox display="flex">
          <MDTypography
            variant="subtitle1"
            fontWeight="medium"
            style={{ fontSize: 14, marginRight: 10, marginTop: 6 }}
          >
            Average Daily Census:
          </MDTypography>
          <MDBox>
            <MDInput
              placeholder="Average Daily Census.."
              style={{ width: "220px" }}
              value={census}
              onChange={(event) => setCensusFn(event.target.value)}
              error={errorCensus != null}
              id="title"
              onBlur={autoPopulateCensus}
            />
            <FormHelperText error>
              {errorCensus != null ? `* ${errorCensus}` : errorCensus}
            </FormHelperText>
          </MDBox>
          {(calculationData.staffing.census === calculationData.staffing.censusOverride ||
            calculationData.staffing.censusOverride === null) && (
            <MDTypography
              variant="subtitle1"
              style={{ fontSize: 14, margin: 10 }}
              color="warning"
              justifyContent="center"
            >
              Prepopulated based on available MDS data. Override if not accurate.
            </MDTypography>
          )}
        </MDBox>

        <MDDropzone
          override={
            <MDButton type="color" variant="gradient" color="primary" style={{ height: 40 }}>
              Upload PBJ &nbsp;<Icon sx={{ fontWeight: "bold" }}>upload</Icon>
            </MDButton>
          }
          onFilesChangedCallback={uploadPbj}
          multiple={false}
        >
          upload
        </MDDropzone>
      </MDBox>

      <Card
        style={{
          backgroundColor: colors.grey[200],
          width: "100%",
          marginTop: 30,
          paddingBottom: 20,
        }}
      >
        <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
          <MDBox
            color="white"
            bgColor="dark"
            variant="gradient"
            borderRadius="xl"
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="1.5rem"
            pl={2}
            pr={2}
            mt={-3}
          >
            <MDTypography variant="body2" color="white" fontSize="xs">
              Staff Hours
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox mx={5}>
          <Grid container style={{ width: "100%" }}>
            <Grid item xs={12} md={5}>
              <MDBox display="flex" justifyContent="right" mr={2}>
                <MDTypography
                  variant="subtitle1"
                  style={{ fontSize: 14, margin: 6, minWidth: 160 }}
                  color="dark"
                  justifyContent="center"
                  textAlign="right"
                >
                  Total Nursing Staff Hours
                </MDTypography>
                <MDInput
                  placeholder="Enter Hours.."
                  style={{ width: "120px" }}
                  value={totalNurseHr}
                  onChange={(event) => setTotalNurseHrFn(event.target.value)}
                  error={errorTotalNurseHr != null}
                  id="title"
                />
              </MDBox>
              <MDBox display="flex" justifyContent="right" mr={2}>
                <FormHelperText error>
                  {errorTotalNurseHr != null ? `* ${errorTotalNurseHr}` : errorTotalNurseHr}
                </FormHelperText>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={7}>
              <MDBox display="flex" height="100%" alignItems="center">
                <MDTypography
                  variant="subtitle1"
                  style={{ fontSize: 13 }}
                  color="dark"
                  justifyContent="center"
                >
                  Case-mix adjusted total nursing hours per resident day (RN + LPN + nurse aide
                  hours) for the quarter averaged across all days (weekdays and weekends)
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
          <Grid
            container
            style={{ width: "100%", borderTop: `1px solid ${colors.grey[300]}` }}
            mt={1}
            pt={1}
          >
            <Grid item xs={12} md={5}>
              <MDBox display="flex" justifyContent="right" mr={2}>
                <MDTypography
                  variant="subtitle1"
                  style={{ fontSize: 14, margin: 6, minWidth: 160 }}
                  color="dark"
                  justifyContent="center"
                  textAlign="right"
                >
                  Total Registered Nurse Hours
                </MDTypography>
                <MDInput
                  placeholder="Enter Hours.."
                  style={{ width: "120px" }}
                  value={totalRNHr}
                  onChange={(event) => setTotalRNHrFn(event.target.value)}
                  error={errorTotalRNHr != null}
                  id="title"
                />
              </MDBox>
              <MDBox display="flex" justifyContent="right" mr={2}>
                <FormHelperText error>
                  {errorTotalRNHr != null ? `* ${errorTotalRNHr}` : errorTotalRNHr}
                </FormHelperText>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={7}>
              <MDBox display="flex" height="100%" alignItems="center">
                <MDTypography
                  variant="subtitle1"
                  style={{ fontSize: 13 }}
                  color="dark"
                  justifyContent="center"
                >
                  Case-mix adjusted RN hours per resident day for the quarter, averaged across all
                  days (weekdays and weekends)
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
          <Grid
            container
            style={{ width: "100%", borderTop: `1px solid ${colors.grey[300]}` }}
            mt={1}
            pt={1}
          >
            <Grid item xs={12} md={5}>
              <MDBox display="flex" justifyContent="right" mr={2}>
                <MDTypography
                  variant="subtitle1"
                  style={{ fontSize: 14, margin: 6, minWidth: 160 }}
                  color="dark"
                  justifyContent="center"
                  textAlign="right"
                >
                  Total Nursing Staff Hours On Weekends
                </MDTypography>
                <MDInput
                  placeholder="Enter Hours.."
                  style={{ width: "120px" }}
                  value={totalStaffHrWeekends}
                  onChange={(event) => setTotalStaffHrWeekendsFn(event.target.value)}
                  error={errorTotalStaffHrWeekends != null}
                  id="title"
                />
              </MDBox>
              <MDBox display="flex" justifyContent="right" mr={2}>
                <FormHelperText error>
                  {errorTotalStaffHrWeekends != null
                    ? `* ${errorTotalStaffHrWeekends}`
                    : errorTotalStaffHrWeekends}
                </FormHelperText>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={7}>
              <MDBox display="flex" height="100%" alignItems="center">
                <MDTypography
                  variant="subtitle1"
                  style={{ fontSize: 13 }}
                  color="dark"
                  justifyContent="center"
                >
                  Case-mix adjusted total nursing hours per resident day (RN + LPN + nurse aide
                  hours) for the quarter averaged across all weekend days (Saturdays and Sundays)
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </Card>
      <Card
        style={{
          backgroundColor: colors.grey[200],
          width: "100%",
          marginTop: 30,
          paddingBottom: 20,
        }}
      >
        <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
          <MDBox
            color="white"
            bgColor="dark"
            variant="gradient"
            borderRadius="xl"
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="1.5rem"
            pl={2}
            pr={2}
            mt={-3}
          >
            <MDTypography variant="body2" color="white" fontSize="xs">
              Staff Turnover
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox mx={5}>
          <Grid container style={{ width: "100%" }}>
            <Grid item xs={12} md={5}>
              <MDBox display="flex" justifyContent="right" mr={2}>
                <MDTypography
                  variant="subtitle1"
                  style={{ fontSize: 14, margin: 6, minWidth: 160 }}
                  color="dark"
                  justifyContent="center"
                  textAlign="right"
                >
                  Total Nursing Turnover (%)
                </MDTypography>
                <MDInput
                  placeholder="Enter Percent.."
                  style={{ width: "120px" }}
                  value={totalNurseTurnover}
                  onChange={(event) => setTotalNurseTurnoverFn(event.target.value)}
                  error={errorTotalNurseTurnover != null}
                  id="title"
                />
              </MDBox>
              <MDBox display="flex" justifyContent="right" mr={2}>
                <FormHelperText error>
                  {errorTotalNurseTurnover != null
                    ? `* ${errorTotalNurseTurnover}`
                    : errorTotalNurseTurnover}
                </FormHelperText>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={7}>
              <MDBox display="flex" height="100%" alignItems="center">
                <MDTypography
                  variant="subtitle1"
                  style={{ fontSize: 13 }}
                  color="dark"
                  justifyContent="center"
                >
                  The percentage of nursing staff that left the nursing home over a twelve-month
                  period.
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
          <Grid
            container
            style={{ width: "100%", borderTop: `1px solid ${colors.grey[300]}` }}
            mt={1}
            pt={1}
          >
            <Grid item xs={12} md={5}>
              <MDBox display="flex" justifyContent="right" mr={2}>
                <MDTypography
                  variant="subtitle1"
                  style={{ fontSize: 14, margin: 6, minWidth: 160 }}
                  color="dark"
                  justifyContent="center"
                  textAlign="right"
                >
                  Registered Nurse Turnover (%)
                </MDTypography>
                <MDInput
                  placeholder="Enter Percent.."
                  style={{ width: "120px" }}
                  value={rNTurnover}
                  onChange={(event) => setRNTurnoverFn(event.target.value)}
                  error={errorRNTurnover != null}
                  id="title"
                />
              </MDBox>
              <MDBox display="flex" justifyContent="right" mr={2}>
                <FormHelperText error>
                  {errorRNTurnover != null ? `* ${errorRNTurnover}` : errorRNTurnover}
                </FormHelperText>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={7}>
              <MDBox display="flex" height="100%" alignItems="center">
                <MDTypography
                  variant="subtitle1"
                  style={{ fontSize: 13 }}
                  color="dark"
                  justifyContent="center"
                >
                  The percentage of RNs that left the nursing home over a twelve-month period.
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
          <Grid
            container
            style={{ width: "100%", borderTop: `1px solid ${colors.grey[300]}` }}
            mt={1}
            pt={1}
          >
            <Grid item xs={12} md={5}>
              <MDBox display="flex" justifyContent="right" mr={2}>
                <MDTypography
                  variant="subtitle1"
                  style={{ fontSize: 14, margin: 6, minWidth: 160 }}
                  color="dark"
                  justifyContent="center"
                  textAlign="right"
                >
                  Administrator Turnover (count)
                </MDTypography>
                <MDInput
                  placeholder="Enter Count.."
                  style={{ width: "120px" }}
                  value={adminTurnover}
                  onChange={(event) => setAdminTurnoverFn(event.target.value)}
                  error={errorAdminTurnover != null}
                  id="title"
                />
              </MDBox>
              <MDBox display="flex" justifyContent="right" mr={2}>
                <FormHelperText error>
                  {errorAdminTurnover != null ? `* ${errorAdminTurnover}` : errorAdminTurnover}
                </FormHelperText>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={7}>
              <MDBox display="flex" height="100%" alignItems="center">
                <MDTypography
                  variant="subtitle1"
                  style={{ fontSize: 13 }}
                  color="dark"
                  justifyContent="center"
                >
                  The number of administrators that left the nursing home over a twelve-month
                  period.
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </Card>
    </>
  );
};

export default StaffingDetailsStep;
