import { useEffect } from "react";
import useAuth from "hooks/useAuth";
import useBackendError from "hooks/useBackendError";
import axios, { enableMocks } from "../api/axios";

const Logout = () => {
  const { setAuth } = useAuth();
  const { setError } = useBackendError();
  useEffect(() => {
    setAuth({});
    try {
      axios.get("signout", {
        withCredentials: !enableMocks,
      });
    } catch (err) {
      setError("Did not receive a response from the backend. Please try again");
    }
  }, []);
  return (
    <section style={{ marginLeft: 500 }}>
      <h1>Log Out Success!!</h1>
    </section>
  );
};

export default Logout;
