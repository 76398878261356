/* eslint-disable react/no-danger */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-plusplus */
import MDBox from "components/MDBox";

import Grid from "@mui/material/Grid";
import useBackendError from "hooks/useBackendError";
import MDTypography from "components/MDTypography";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Card, Tooltip } from "@mui/material";
import MDButton from "components/MDButton";
import { useEffect, useRef, useState } from "react";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import colors from "assets/theme/base/colors";
import PersonCell from "components/DataTable/components/PersonCell";
import Skeleton from "components/skeletons/Skeleton";
import MDEditor from "components/MDEditor";
import useScroll from "hooks/useScroll";
import ConfirmModal from "components/modal/ConfirmModal";
import useModal from "hooks/useModal";
import { useCache } from "hooks/useCache";
import Section from "./Section";

const SatisfactionSurvey = () => {
  const { state } = useLocation();
  const [survey, setSurvey] = useState({
    template: {
      sections: [
        {
          questions: [{}, {}, {}],
        },
        {
          questions: [{}, {}, {}, {}],
        },
        {
          questions: [{}, {}, {}],
        },
      ],
    },
  });
  const [isLoading, setLoading] = useState(true);
  const [isSaving, setSaving] = useState(false);
  const { setScrollTo } = useScroll();
  const { setError } = useBackendError();
  const axios = useAxiosPrivate();
  const [searchParams] = useSearchParams();
  const surveyId = searchParams.get("id");
  const navigate = useNavigate();
  const [action, setAction] = useState(state.action);
  const { openModal } = useModal();
  const { setItem } = useCache();
  const [comment, setComment] = useState();
  const changed = useRef(false);

  const getTotalRestHeight = (num) => {
    let height = 0;
    for (let i = 0; i < num; i++) {
      const curHeight = document.getElementById(`section-${i}`).clientHeight + 30;
      height += curHeight;
    }
    return height;
  };

  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  const loadSurvey = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `/satisfaction/survey${surveyId == null ? "" : `?id=${surveyId}`}`,
        state,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      setSurvey(response.data);
      setComment(response.data.template.comments);
    } catch (err) {
      setError("Failed to fetch from the backend. Please try again");
    } finally {
      setLoading(false);
    }
  };

  const setDataFn = (data) => {
    changed.current = true;
    setSurvey(data);
  };

  const handleCommentChange = (html) => {
    setComment(html);
  };

  const modalReturnedValue = () => {
    setItem("pending-emails-collapsed", true);
    navigate("/resident-satisfaction");
  };

  const handleEdit = () => {
    setAction("edit");
  };

  const handleSubmit = async () => {
    let errorElem = "";
    let offsetTop = 0;
    let isError = false;
    for (let i = survey.template.sections.length - 1; i >= 0; i--) {
      const section = survey.template.sections[i];
      for (let j = section.questions.length - 1; j >= 0; j--) {
        if (section.questions[j].score == null) {
          survey.template.sections[i].questions[j].isError = true;
          isError = true;
          errorElem = `question-${i}-${j}`;
          offsetTop = document.getElementById(errorElem).offsetTop + getTotalRestHeight(i) + 60;
        }
      }
    }

    if (isError) {
      setSurvey({ ...survey });
      setTimeout(() => {
        setScrollTo(offsetTop);
      }, 100);
    } else {
      setSaving(true);
      survey.template.comments = comment;
      setSurvey(survey);
      try {
        await axios.post(`/satisfaction/survey?id=${survey.id}`, survey, {
          headers: { "Content-Type": "application/json" },
        });
        setSaving(false);
        openModal(
          <ConfirmModal
            buttonLabels={["okay"]}
            icon="success"
            text="Survey has been successfully submitted!"
          />,
          modalReturnedValue
        );
        // navigate
      } catch (err) {
        setError("Failed to save to the backend. Please try again");
        setSaving(false);
      }
    }
  };

  useEffect(() => {
    loadSurvey();
  }, []);

  const cancelConfirm = (prop) => {
    if (prop.action === "confirm") {
      navigate("/resident-satisfaction");
    }
  };

  const cancelClick = () => {
    if (changed.current) {
      openModal(
        <ConfirmModal
          buttonLabels={["no", "yes"]}
          icon="warn"
          text="You have unsaved changes. Do you really want to cancel?"
        />,
        cancelConfirm
      );
    } else {
      navigate("/resident-satisfaction");
    }
  };

  return (
    <Card id="compliance-overview">
      <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
        <MDBox
          color="white"
          bgColor="info"
          variant="gradient"
          borderRadius="lg"
          shadow="lg"
          display="flex"
          justifyContent="center"
          alignItems="center"
          py={1}
          px={2}
          mt={-4}
          mb={3}
        >
          <MDTypography variant="h6" fontWeight="medium" color="white">
            {isLoading && "Resident Satisfaction"}
            {!isLoading && survey.template.name}
          </MDTypography>
        </MDBox>
      </MDBox>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <MDBox px={4}>
            <Card style={{ backgroundColor: colors.grey[200], marginBottom: 20 }}>
              <MDBox p={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} lg={4}>
                    <MDBox display="flex">
                      <MDTypography variant="body2">Resident: &nbsp;</MDTypography>
                      <PersonCell
                        image={survey?.resident?.url}
                        name={survey?.resident?.fullName}
                        color="light"
                        isLoading={isLoading}
                        textVariant="body2"
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4}>
                    <MDBox display="flex">
                      <MDTypography variant="body2">Participant: &nbsp;</MDTypography>
                      <MDTypography variant="body2" fontWeight="medium">
                        {isLoading ? (
                          <Skeleton width={45} height={18} />
                        ) : survey.participant === "resident" ? (
                          "Resident"
                        ) : (
                          "Family Member"
                        )}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4}>
                    <MDBox display="flex">
                      <MDTypography variant="body2">Type: &nbsp;</MDTypography>
                      <MDTypography variant="body2" fontWeight="medium">
                        {isLoading ? (
                          <Skeleton width={45} height={18} />
                        ) : survey.type === "midStay" ? (
                          "Mid Stay"
                        ) : (
                          survey.type[0].toUpperCase() + survey.type.slice(1)
                        )}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
            {survey.template?.sections?.map((section, index) => (
              <Section
                data={survey}
                sectionIndex={index}
                setData={setDataFn}
                isLoading={isLoading}
                // eslint-disable-next-line react/no-array-index-key
                key={`section-${index}`}
                action={action}
              />
            ))}

            {!isLoading && action !== "view" && (
              <MDBox mt={2}>
                <MDEditor
                  placeholder={
                    isMobile ? "[Optional] Comments.." : "[Optional] Please enter any comments.."
                  }
                  value={comment}
                  onChange={handleCommentChange}
                  hideToolbar={isMobile}
                  minHeight={100}
                />
              </MDBox>
            )}
            {!isLoading && action === "view" && survey.template.comments != null && (
              <MDBox mt={-3}>
                <MDTypography display="inline" variant="body2" color="dark" fontSize={12}>
                  Comment:
                </MDTypography>
                <div
                  style={{
                    border: `1px solid ${colors.grey[300]}`,
                    borderRadius: 10,
                    paddingLeft: 10,
                    paddingRight: 10,
                    minHeight: 100,
                  }}
                >
                  <Tooltip
                    title="To edit, please click on the edit button at the bottom"
                    followCursor
                    enterDelay={1000}
                  >
                    <div
                      style={{ display: "inline-block", fontSize: "14px" }}
                      dangerouslySetInnerHTML={{ __html: survey.template.comments }}
                    />
                  </Tooltip>
                </div>
              </MDBox>
            )}
          </MDBox>
        </Grid>
      </Grid>
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        pb={3}
        pl={3}
        pr={3}
      >
        <MDButton variant="gradient" color="light" style={{ margin: 5 }} onClick={cancelClick}>
          cancel
        </MDButton>
        <MDButton
          variant="gradient"
          color="primary"
          style={{ margin: 5 }}
          onClick={action === "edit" ? handleSubmit : handleEdit}
          loading={isSaving}
          loadingText="saving..."
        >
          {action === "view" ? "EDIT" : "SUBMIT"}
        </MDButton>
      </MDBox>
    </Card>
  );
};
export default SatisfactionSurvey;
