/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import Skeleton from "components/skeletons/Skeleton";

function PersonCell({ image, name, color, id, isLoading, textVariant = "caption" }) {
  return (
    <MDBox display="flex" alignItems="center" style={{ cursor: "pointer" }}>
      <MDBox mr={1}>
        <MDAvatar bgColor={isLoading ? "light" : color} src={image} alt={name} size="xs" />
      </MDBox>
      {isLoading && <Skeleton width={160} height={16} />}
      {!isLoading && (
        <MDTypography variant={textVariant} fontWeight="medium" color="text" sx={{ lineHeight: 0 }}>
          {name}
        </MDTypography>
      )}
    </MDBox>
  );
}

// Setting default value for the props of CustomerCell
PersonCell.defaultProps = {
  image: "",
  color: "dark",
};

// Typechecking props for the CustomerCell
PersonCell.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string.isRequired,
  color: PropTypes.oneOf([
    "transparent",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
};

export default PersonCell;
