/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */

import MDBox from "components/MDBox";
import { Grid, Switch } from "@mui/material";

import colors from "assets/theme/base/colors";
import MDTypography from "components/MDTypography";

import Scrollbars from "react-custom-scrollbars";

import { makeStyles } from "@material-ui/styles";
import { useState } from "react";

const AddInspectionPrevious = ({
  number,
  inspections,
  inspectionIndex,
  setInspection,
  inspectionsUpdate,
}) => {
  console.log(inspectionsUpdate);
  const [previousFTags, setPreviousFTags] = useState(
    inspections[inspectionIndex].revisits[number - 1]?.fTags.filter((tag) => tag.new === false)
  );

  const [newFtags] = useState(
    inspections[inspectionIndex].revisits[number - 1]?.fTags.filter((tag) => tag.new === true)
  );

  const handleStatus = (event, index) => {
    if (event.target.checked) {
      previousFTags[index].resolved = true;
    } else {
      previousFTags[index].resolved = false;
    }
    setPreviousFTags([...previousFTags]);
    inspectionsUpdate.fTags = newFtags.concat(previousFTags);
    setInspection(inspectionsUpdate);
  };

  const resolveAll = () => {
    for (let i = 0; i < previousFTags.length; i++) {
      previousFTags[i].resolved = true;
    }
    setPreviousFTags([...previousFTags]);
    inspectionsUpdate.fTags = newFtags.concat(previousFTags);
    setInspection(inspectionsUpdate);
  };

  const useStyles = makeStyles(() => ({
    switch_track: {
      backgroundColor: colors.warning.main,
      borderColor: `${colors.grey[500]} !important`,
    },
    switch_base: {
      borderColor: `${colors.grey[500]} !important`,
      color: colors.warning.main,
      "&.Mui-disabled": {
        color: "#e886a9",
      },
      "&.Mui-checked": {
        color: "#95cc97",
        borderColor: `${colors.grey[500]} !important`,
      },
      "&.Mui-checked + .MuiSwitch-track": {
        backgroundColor: "#4CAF50 !important",
        borderColor: `${colors.grey[500]} !important`,
      },
    },
    switch_primary: {
      borderColor: `${colors.grey[500]} !important`,
      "&.Mui-checked": {
        color: "#4CAF50  !important",
      },
      "&.Mui-checked + .MuiSwitch-track": {
        backgroundColor: "#4CAF50  !important",
      },
    },
    button_delete: {
      "&:hover": {
        color: colors.error.main,
      },
    },
    button_edit: {
      "&:hover": {
        color: colors.info.main,
      },
    },
  }));

  const classes = useStyles();

  return (
    <>
      <MDTypography display="inline" variant="body2">
        Please indicate the status of each citation from the previous inspection.
      </MDTypography>
      <MDBox>
        <MDTypography
          display="inline"
          variant="body2"
          color="info"
          sx={{ marginRight: 2, textDecoration: "underline", fontSize: 13, cursor: "pointer" }}
          onClick={resolveAll}
        >
          Resolve All
        </MDTypography>
      </MDBox>
      <Scrollbars
        style={{
          maxHeight: "calc(100vh - 150px)",
          minHeight: 160,
          backgroundColor: colors.grey[300],
          borderRadius: 8,
          marginTop: 10,
        }}
        renderTrackHorizontal={({ style, ...props }) => <div />}
        renderTrackVertical={({ style, ...props }) => (
          <div className="scrollbar-regular-vertical-track" {...props} />
        )}
        renderThumbVertical={({ style, ...props }) => (
          <div
            style={{
              ...style,
              backgroundColor: "rgba(0, 0, 0, 0.4)",
              borderRadius: 8,
              width: 6,
              marginRight: -3,
              cursor: "pointer",
            }}
            {...props}
          />
        )}
      >
        <Grid container pr={1}>
          {previousFTags.map((fTag, index) => (
            <Grid item xs={12} sm={6} key={`ftags-${index}`}>
              <MDBox
                bgColor="white"
                mx={1}
                my={0.5}
                px={2}
                borderRadius="lg"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography
                  display="inline"
                  variant="body2"
                  color="dark"
                  fontWeight="bold"
                  style={{ fontSize: 13, marginRight: 20 }}
                >
                  {fTag.name}
                </MDTypography>
                <MDBox>
                  <MDBox
                    variant="gradient"
                    bgColor={previousFTags[index].resolved ? "success" : "warning"}
                    borderRadius="lg"
                    px={1}
                    py={0.5}
                    display="flex"
                    justifyContent="center"
                    sx={{ width: 104 }}
                  >
                    <MDTypography
                      variant="body2"
                      color="white"
                      fontWeight="bold"
                      style={{ fontSize: 12 }}
                    >
                      {previousFTags[index].resolved ? "RESOLVED" : "NOT RESOLVED"}
                    </MDTypography>
                  </MDBox>
                </MDBox>
                <Switch
                  classes={{
                    track: classes.switch_track,
                    switchBase: classes.switch_base,
                    colorPrimary: classes.switch_primary,
                  }}
                  color="secondary"
                  checked={previousFTags[index].resolved}
                  onChange={(event) => handleStatus(event, index)}
                />
              </MDBox>
            </Grid>
          ))}
        </Grid>
      </Scrollbars>
    </>
  );
};

export default AddInspectionPrevious;
