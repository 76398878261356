/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for MDInput
import MDInputRoot from "components/MDInput/MDInputRoot";
import { Icon } from "@mui/material";
import { grey } from "@mui/material/colors";
import { makeStyles } from "@material-ui/styles";
import colors from "assets/theme/base/colors";

const greyColor = grey[800];

const useStyles = makeStyles({
  error: {
    borderWidth: "1px",
    borderColor: `${colors.error.main} !important`,
  },
});

const MDInput = forwardRef(({ error, success, disabled, icon, ...rest }, ref) => {
  const classes = useStyles();

  return (
    <>
      <MDInputRoot
        {...rest}
        ref={ref}
        ownerState={{ error, success, disabled }}
        InputProps={
          error
            ? {
                classes: {
                  notchedOutline: classes.error,
                },
              }
            : null
        }
      />
      {icon != null && icon === "calendar" && (
        <Icon style={{ marginLeft: -25, marginTop: 7, color: greyColor }}>calendar_month</Icon>
      )}
      {icon != null && icon === "search" && (
        <Icon style={{ marginLeft: -28, marginTop: 11, color: greyColor, position: "absolute" }}>
          search
        </Icon>
      )}
    </>
  );
});

// Setting default values for the props of MDInput
MDInput.defaultProps = {
  error: false,
  success: false,
  disabled: false,
};

// Typechecking props for the MDInput
MDInput.propTypes = {
  error: PropTypes.bool,
  success: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default MDInput;
