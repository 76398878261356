/* eslint-disable react/no-array-index-key */
import Grid from "@mui/material/Grid";

import { useEffect, useRef, useState } from "react";
import useAxiosPrivate from "hooks/useAxiosPrivate";

import useBackendError from "hooks/useBackendError";

import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import { Card, Icon } from "@mui/material";
import MDButton from "components/MDButton";

import useModal from "hooks/useModal";

import colors from "assets/theme/base/colors";

import Scrollbars from "react-custom-scrollbars";
import MDHr from "components/MDHr";
import { useNavigate } from "react-router-dom";
import CreateNewUserModal from "components/modal/CreateNewUserModal";
import EmailNewUsersModal from "components/modal/EmailNewUsersModal";
import User from "./User";
import UserList from "./UserList";
import PendingEmailInvites from "./PendingEmailInvites";

const UsersOverview = () => {
  const [isLoadingOnlineUser, setLoadingOnlineUsers] = useState(true);
  const [createReloadKey, setCreateReloadKey] = useState(0);
  const [emailReloadKey, setEmailReloadKey] = useState(0);
  const [onlineUsers, setOnlineUsers] = useState([{}, {}, {}, {}, {}, {}]);
  const navigate = useNavigate();

  const ref = useRef();
  const [dimensions, setDimensions] = useState(120);

  function handleWindowSizeChange() {
    setDimensions(ref.current.getBoundingClientRect());
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const axios = useAxiosPrivate();
  const { setError } = useBackendError();

  const handleView = (id) => {
    navigate(`/users/user?id=${id}`);
  };

  const loadOnlineUsers = async () => {
    setLoadingOnlineUsers(true);
    try {
      const response = await axios.get(`/users/online`, {
        headers: { "Content-Type": "application/json" },
      });
      setOnlineUsers(response.data);
    } catch (err) {
      setError("Failed to fetch from the backend. Please try again");
    } finally {
      setLoadingOnlineUsers(false);
    }
  };

  const { openModal } = useModal();

  const loadData = async () => {
    loadOnlineUsers();
    setDimensions(ref.current.getBoundingClientRect());
  };

  const doneCreate = (result) => {
    if (result.action === "done") {
      setCreateReloadKey(createReloadKey + 1);
    }
  };

  const doneEmail = (result) => {
    if (result.action === "done") {
      setEmailReloadKey(emailReloadKey + 1);
    }
  };

  const handleCreateNew = () => {
    openModal(<CreateNewUserModal />, doneCreate);
  };
  const handleEmailSignUp = () => {
    openModal(<EmailNewUsersModal />, doneEmail);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Grid container spacing={3} mb={3}>
      <Grid item lg={8} xs={12}>
        <Card id="users-overview" style={{ height: "100%" }}>
          <MDBox pt={2} px={2} display="flex" justifyContent="left" alignItems="center">
            <MDBox
              color="white"
              bgColor="info"
              variant="gradient"
              borderRadius="lg"
              shadow="lg"
              display="flex"
              justifyContent="center"
              alignItems="center"
              py={1}
              px={2}
              mt={-4}
              mb={3}
            >
              <MDTypography variant="h6" fontWeight="medium" color="white">
                Users
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDBox p={1} fontSize="1rem" style={{ textAlign: "center", width: "100%" }}>
            Create a new user now or send an email invite with a sign up link.
          </MDBox>
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexWrap="wrap"
            pt={1}
            pb={4}
          >
            <MDButton
              variant="gradient"
              color="info"
              style={{ margin: 5 }}
              onClick={handleCreateNew}
            >
              <Icon sx={{ fontWeight: "bold" }}>add</Icon>
              &nbsp;create new user
            </MDButton>
            <MDButton variant="gradient" color="primary" onClick={handleEmailSignUp} size="medium">
              <Icon sx={{ fontWeight: "bold" }}>email</Icon> &nbsp; email sign up link
            </MDButton>
          </MDBox>
        </Card>
      </Grid>
      <Grid item lg={4} xs={12}>
        <Card id="compliance-overview">
          <MDBox p={2} fontSize="1rem" style={{ textAlign: "left", width: "100%" }}>
            <MDTypography variant="h6" fontWeight="medium">
              User Log{" "}
              <span style={{ color: colors.grey[600], fontWeight: 100, fontSize: 13 }}>
                (last 7 days)
              </span>
            </MDTypography>
            <Scrollbars
              style={{
                height: 120,
                backgroundColor: colors.grey[100],
                borderRadius: 8,
                marginTop: 6,
              }}
              renderTrackHorizontal={({ style, ...props }) => <div />}
              renderTrackVertical={({ style, ...props }) => (
                <div className="scrollbar-regular-vertical-track" {...props} />
              )}
              renderThumbVertical={({ style, ...props }) => (
                <div
                  style={{
                    ...style,
                    backgroundColor: "rgba(0, 0, 0, 0.2)",
                    borderRadius: 8,
                    width: 6,
                    cursor: "pointer",
                  }}
                  {...props}
                />
              )}
            >
              <MDBox py={1} height="100%" ref={ref}>
                {onlineUsers.map((user, index) => (
                  <MDBox py={0.5} pl={2} pr={3} key={`user-${index}`}>
                    <MDBox display="flex" justifyContent="space-between">
                      <User
                        isLoading={isLoadingOnlineUser}
                        user={user}
                        key={`onlineuser-${index}`}
                        showUsername={false}
                        width={(dimensions?.width ?? 160) - 160}
                      />
                      <MDBox>
                        <MDButton
                          variant="outlined"
                          color="info"
                          size="small"
                          onClick={() => handleView(user.id)}
                        >
                          VIEW
                        </MDButton>
                      </MDBox>
                    </MDBox>
                    {index !== onlineUsers.length - 1 && (
                      <MDHr color={colors.grey[300]} style={{ width: "100%", marginTop: 6 }} />
                    )}
                  </MDBox>
                ))}
                {onlineUsers.length === 0 && (
                  <MDBox display="flex" height="100%" justifyContent="center" alignItems="center">
                    <MDTypography variant="body2">
                      No user activity in the last 7 days!
                    </MDTypography>
                  </MDBox>
                )}
              </MDBox>
            </Scrollbars>
          </MDBox>
        </Card>
      </Grid>
      <Grid item lg={12} xs={12}>
        <UserList reloadKey={createReloadKey} />
      </Grid>
      <Grid item lg={12} xs={12}>
        <PendingEmailInvites reloadKey={emailReloadKey} />
      </Grid>
    </Grid>
  );
};
export default UsersOverview;
