import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DefaultDoughnutChart from "components/charts/DoughnutCharts/DefaultDoughnutChart";
import { Link } from "react-router-dom";
import { useRef } from "react";
import Timeline from "components/Timeline";

function ComplianceOverviewTopFive({ data, setTimeline }) {
  const defaultDoughnutChartData = {
    labels: data?.topFiveFtags.map((elem) => elem.ftag),
    datasets: {
      label: "Projects",
      backgroundColors: ["info", "dark", "error", "secondary", "primary"],
      data: data?.topFiveFtags.map((elem) => elem.percentage),
    },
    cutout: [40],
  };

  const chart = useRef(<DefaultDoughnutChart chart={defaultDoughnutChartData} height={200} />);

  return (
    <Card id="top-five" style={{ height: "100%" }}>
      <MDBox
        pt={2}
        px={2}
        pb={2}
        display="flex"
        justifyContent="center"
        alignItems="flex-start"
        style={{ flexDirection: "column", height: "100%", margin: 0 }}
      >
        <MDBox style={{ top: 10, position: "absolute" }}>
          <MDTypography variant="h6" fontWeight="medium" width={280}>
            Top 5 Citations
          </MDTypography>
          <Timeline setTimeline={setTimeline} />
        </MDBox>
        <MDBox style={{ marginLeft: 25, marginTop: 25, width: "100%" }}>{chart.current}</MDBox>
        <Link
          to="/pathLink#yourAnchorTag"
          style={{ bottom: 10, fontSize: 16, position: "absolute" }}
        >
          Details
        </Link>
      </MDBox>
    </Card>
  );
}

export default ComplianceOverviewTopFive;
