/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

import MDButton from "components/MDButton";
import { Icon } from "@mui/material";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import useModal from "hooks/useModal";
import ConfirmDeleteModal from "components/modal/ConfirmDeleteModal";
import { timeout } from "utils/utils";
import ConfirmModalWithSuccess from "components/modal/ConfirmModalWithSuccess";

const PendingEmailInviteActionCell = ({ id, isLoading }) => {
  const { openModal } = useModal();
  const axios = useAxiosPrivate();

  const deleteCallback = async () => {
    console.log("deleteing...");
    await timeout(3000);
    /* await axios.post(`/compliance/survey/delete`, {
      headers: { "Content-Type": "application/json" },
    }); */
    return "success";
  };

  const deleteSurvey = () => {
    openModal(
      <ConfirmDeleteModal
        title="Delete Invite?"
        text="Deleting the invite will not undo the already sent email. This just marks it as no longer required in the system. Do you want to continue?"
        successText="The selected invite has been successfully deleted."
        callback={deleteCallback}
      />,
      () => {}
    );
  };

  const sendCallback = async () => {
    console.log("sending...");

    /* await axios.post(`/qapi/delete`, {
      headers: { "Content-Type": "application/json" },
    }); */

    return "success";
  };

  const followup = () => {
    openModal(
      <ConfirmModalWithSuccess
        title="Email Again?"
        successText="An email has been successfuflly sent."
        successTitle="Successfully sent!"
        htmlText="Do you really want to send the email again?"
        callback={sendCallback}
        loadingText="sending.."
      />,
      () => {}
    );
  };

  return (
    <MDBox display="flex" alignItems="center">
      <MDButton
        variant="gradient"
        color="info"
        style={{ margin: 5, width: 50 }}
        onClick={followup}
        isLoading={isLoading}
      >
        Resend
      </MDButton>
      <MDButton
        variant="text"
        color="secondary"
        iconOnly
        circular
        onClick={deleteSurvey}
        isLoading={isLoading}
      >
        <Icon>delete</Icon>
      </MDButton>
    </MDBox>
  );
};

// Typechecking props for the StatusCell
PendingEmailInviteActionCell.propTypes = {
  id: PropTypes.number.isRequired,
};

export default PendingEmailInviteActionCell;
