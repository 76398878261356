import { Autocomplete, Grid, TextField } from "@mui/material";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import colors from "assets/theme/base/colors";

import MDButton from "components/MDButton";

import MDTypography from "components/MDTypography";

import useModal from "hooks/useModal";
import { useEffect, useRef, useState } from "react";

import useResidents from "hooks/useResidents";
import useBackendError from "hooks/useBackendError";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import MDBox from "components/MDBox";
import MDAvatar from "components/MDAvatar";

const ResidentModal = () => {
  const { closeModal } = useModal();
  const [residentInputValue, setResidentInputValue] = useState("");
  const { setError } = useBackendError();
  const axios = useAxiosPrivate();
  const [resident, setResident] = useState();

  const [residentsError, setResidentsError] = useState(false);
  const ref = useRef();
  const { residents, setResidents } = useResidents();

  useEffect(() => {
    async function fetchData() {
      if (residents == null) {
        try {
          const response = await axios.get("/residents/list", {
            headers: { "Content-Type": "application/json" },
          });
          setResidents(response.data);
          ref.current.focus();
        } catch (err) {
          if (!err?.response) {
            setError("Failed to fetch the list of users in this facility. Please try again");
          }
        }
      }
    }
    fetchData();
  }, [axios, residents, setError, setResidents]);

  const tryAdd = () => {
    if (residentInputValue == null || residentInputValue === "") {
      setResidentsError(true);
      ref.current.focus();
    } else {
      closeModal({
        action: "add",
        fullName: residentInputValue,
        room: resident.room,
        id: resident.id,
      });
    }
  };

  const residentsList =
    residents != null
      ? residents.map((res) => ({
          label: res.fullName,
          id: res.id,
          room: res.room,
          url: res.url,
        }))
      : [];

  return (
    <div style={{ minWidth: 500 }}>
      <MDBox display="flex" flexDirection="column" textAlign="center">
        <MDTypography
          display="inline"
          variant="h6"
          textTransform="capitalize"
          fontWeight="bold"
          textAlign="center"
        >
          Select Resident
        </MDTypography>
      </MDBox>
      <Grid container spacing={1} mb={1} mt={0.5}>
        <Grid container spacing={1} mb={1} mt={1}>
          <Grid item xs={3} textAlign="right">
            <MDTypography display="inline" variant="body2" textTransform="capitalize">
              Select Resident
            </MDTypography>
          </Grid>
          <Grid item xs={8} textAlign="left" pr={2}>
            <Autocomplete
              isOptionEqualToValue={(option, value) => option.id === value.id}
              value={resident || null}
              onChange={(event, newValue) => {
                setResidentsError(false);
                setResident(newValue);
              }}
              inputValue={residentInputValue}
              onInputChange={(event, newInputValue) => {
                setResidentInputValue(newInputValue);
              }}
              options={residentsList}
              renderInput={(params) => (
                <TextField
                  inputRef={ref}
                  error={residentsError}
                  helperText={residentsError ? "*Please select a Resident" : ""}
                  {...params}
                  placeholder="Select a Resident..."
                />
              )}
              renderOption={(props, option, { inputValue }) => {
                const matches = match(option.label, inputValue, { insideWords: true });
                const parts = parse(option.label, matches);

                const bgColor = inputValue === option.label ? colors.grey[200] : null;
                return (
                  <li {...props} style={{ backgroundColor: bgColor }}>
                    <MDBox display="flex">
                      <span style={{ marginRight: 10 }}>
                        <MDAvatar bgColor="light" src={option.url} alt={option.label} size="xs" />
                      </span>
                      {parts.map((part) => (
                        <span
                          key={part.text + new Date().getTime()}
                          style={{
                            fontWeight: part.highlight ? 700 : 400,
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          {part.text}
                        </span>
                      ))}
                      {option.room != null && option.room !== "" && (
                        <span style={{ color: colors.grey[500], fontSize: 12 }}>
                          &nbsp;[Room:{option.room}]
                        </span>
                      )}
                    </MDBox>
                  </li>
                );
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <MDBox p={2} display="flex" textAlign="center" alignItems="center" justifyContent="center">
        <MDBox mr={1}>
          <MDButton
            onClick={() => closeModal({ action: "cancel" })}
            variant="gradient"
            color="light"
            size="medium"
            m={1}
          >
            cancel
          </MDButton>
        </MDBox>
        <MDBox mr={1}>
          <MDButton onClick={tryAdd} variant="gradient" color="info" size="medium">
            add
          </MDButton>
        </MDBox>
      </MDBox>
    </div>
  );
};

export default ResidentModal;
