/* eslint-disable react/no-array-index-key */
import { Document, Page, Text, View, StyleSheet, Font } from "@react-pdf/renderer";
import colors from "assets/theme/base/colors";

// Create styles
const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    flex: 1,
  },
  facility: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
  },
  text: {
    margin: 12,
    fontSize: 12,
    textAlign: "justify",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 10,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 10,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  section: { borderRadius: 8, backgroundColor: colors.grey[300], marginBottom: 20 },
  sectionTitle: {
    margin: 5,
    padding: 5,
    fontSize: 14,
    textAlign: "left",
    backgroundColor: colors.grey[800],
    color: "white",
    borderRadius: 8,
    display: "inline-block",
  },
  question: {
    borderRadius: 8,
    backgroundColor: colors.grey[100],
    margin: 8,
    fontSize: 12,
  },

  option: { fontSize: 12, width: 200 },

  optionCircle: {
    width: 15,
    height: 15,
    borderRadius: "50%",
    backgroundColor: "white",
    border: "1px solid grey",
    marginRight: 5,
  },
  optionContainer: {
    flexDirection: "row",
    marginTop: 4,
    alignItems: "center",
  },

  optionsContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 16,
  },

  optionText: {
    fontSize: 11,
    marginLeft: 2,
    marginRight: 2,
  },

  input: {
    width: 300,
    height: 24,
    borderRadius: 8,
    backgroundColor: "white",
    border: "1px solid grey",
  },

  head: {
    borderRadius: 8,
    backgroundColor: colors.grey[200],
    paddingTop: 20,
    paddingLeft: 20,
    marginBottom: 20,
  },
  headersContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginRight: 20,
    marginBottom: 16,
  },
  headerContainer: {
    width: 100,
    flexDirection: "row",
    marginRight: 20,
  },
  headerText: {
    fontSize: 11,
    marginLeft: 2,
    marginRight: 2,
    width: 80,
  },
});

const hyphenationCallback = (word) => [word];

Font.registerHyphenationCallback(hyphenationCallback);

// Create Document Component
const SatisfactionSurvey = ({ filename, facility, data }) => (
  <Document>
    <Page style={styles.body}>
      <Text style={styles.header} fixed>
        Generated @ starsforcare.com on 02/03/2023 06:30 PM
      </Text>
      <Text style={styles.title} hyphenationCallback={hyphenationCallback}>
        Resident Satisfaction Survey - {filename}
      </Text>
      <Text style={styles.facility}>{facility}</Text>

      <View style={styles.head}>
        <View style={styles.headersContainer}>
          <Text style={styles.headerText}>Resident Name:</Text>
          <View style={styles.input} />
        </View>
        <View style={styles.headersContainer}>
          <Text style={styles.headerText}>Participant:</Text>
          <View style={styles.headerContainer}>
            <View style={styles.optionCircle} />
            <Text style={styles.optionText}>Resident</Text>
          </View>
          <View style={styles.headerContainer}>
            <View style={styles.optionCircle} />
            <Text style={styles.optionText}>Family Member</Text>
          </View>
        </View>
        <View style={styles.headersContainer}>
          <Text style={styles.headerText}>Type:</Text>
          <View style={styles.headerContainer}>
            <View style={styles.optionCircle} />
            <Text style={styles.optionText}>Initial</Text>
          </View>
          <View style={styles.headerContainer}>
            <View style={styles.optionCircle} />
            <Text style={styles.optionText}>Mid Stay</Text>
          </View>
          <View style={styles.headerContainer}>
            <View style={styles.optionCircle} />
            <Text style={styles.optionText}>Discharge</Text>
          </View>
        </View>
      </View>

      {data.sections.map((section, i) => (
        <View style={styles.section} key={`section-${i}`}>
          <Text style={styles.sectionTitle}>{section.name}</Text>
          {section.questions.map((question, j) => (
            <View style={styles.question} key={`section-${i}-${j}`} wrap={false}>
              <Text style={styles.text}>{question.questionText}</Text>

              <View style={styles.optionsContainer}>
                <View style={styles.optionContainer}>
                  <View style={styles.optionCircle} />
                  <Text style={styles.optionText}>Excellent</Text>
                </View>

                <View style={styles.optionContainer}>
                  <View style={styles.optionCircle} />
                  <Text style={styles.optionText}>Good</Text>
                </View>

                <View style={styles.optionContainer}>
                  <View style={styles.optionCircle} />
                  <Text style={styles.optionText}>Fair</Text>
                </View>
                <View style={styles.optionContainer}>
                  <View style={styles.optionCircle} />
                  <Text style={styles.optionText}>Poor</Text>
                </View>
                <View style={styles.optionContainer}>
                  <View style={styles.optionCircle} />
                  <Text style={styles.optionText}>N/A</Text>
                </View>
              </View>
            </View>
          ))}
        </View>
      ))}

      <Text
        style={styles.pageNumber}
        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
        fixed
      />
    </Page>
  </Document>
);
export default SatisfactionSurvey;
