/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/prop-types */
import { createContext, useRef, useState } from "react";

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const modalCallback = useRef(null);

  const openModal = (content, callback) => {
    setIsModalOpen(true);
    setModalContent(content);
    modalCallback.current = callback;
  };

  const closeModal = (result) => {
    setIsModalOpen(false);
    modalCallback.current(result);
    setModalContent(null);
    modalCallback.current = null;
  };

  return (
    <ModalContext.Provider
      value={{
        isModalOpen,
        modalContent,
        openModal,
        closeModal,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export default ModalContext;
