/* eslint-disable react/no-array-index-key */
import { useEffect, useRef, useState } from "react";

import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import colors from "assets/theme/base/colors";

import Scrollbars from "react-custom-scrollbars";
import MDHr from "components/MDHr";
import useBackendError from "hooks/useBackendError";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import TimestampCell from "components/DataTable/components/TimestampCell";
import Skeleton from "components/skeletons/Skeleton";

const FTagLog = ({ residentId }) => {
  const [isLoading, setLoading] = useState(true);
  const [ftags, setFtags] = useState([{}, {}, {}, {}]);
  const axios = useAxiosPrivate();
  const { setError } = useBackendError();
  const [fetchInProgress, setFetchInProgress] = useState(false);
  const paginationKey = useRef();

  const loadActivityLog = async () => {
    if (fetchInProgress) {
      return;
    }
    setFetchInProgress(true);
    if (paginationKey.current == null) {
      setLoading(true);
    }
    try {
      const response = await axios.get(
        `/resident/ftags?id=${residentId}${
          paginationKey.current == null ? "" : `&paginationKey=${paginationKey.current}`
        }`,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      if (paginationKey.current == null) {
        setFtags(response.data.ftags);
      } else {
        setFtags(ftags.concat(response.data.ftags));
      }

      paginationKey.current = response.data.paginationKey;
    } catch (err) {
      setError("Failed to fetch from the backend. Please try again");
    } finally {
      setLoading(false);
      setFetchInProgress(false);
    }
  };

  useEffect(() => {
    loadActivityLog();
  }, []);

  const handleScroll = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;
    const calculatedScrollPercentage = (scrollTop / (scrollHeight - clientHeight)) * 100;
    if (calculatedScrollPercentage >= 80) {
      loadActivityLog();
    }
  };

  return (
    <MDBox p={2} fontSize="1rem" style={{ textAlign: "left", width: "100%" }}>
      <MDTypography variant="h6" fontWeight="medium">
        Recent FTags
      </MDTypography>
      <Scrollbars
        onScroll={handleScroll}
        style={{
          height: 180,
          backgroundColor: colors.grey[100],
          borderRadius: 8,
          marginTop: 6,
        }}
        renderTrackHorizontal={({ style, ...props }) => <div />}
        renderTrackVertical={({ style, ...props }) => (
          <div className="scrollbar-regular-vertical-track" {...props} />
        )}
        renderThumbVertical={({ style, ...props }) => (
          <div
            style={{
              ...style,
              backgroundColor: "rgba(0, 0, 0, 0.2)",
              borderRadius: 8,
              width: 6,
              cursor: "pointer",
            }}
            {...props}
          />
        )}
      >
        <MDBox py={1} height="100%">
          {ftags.map((ftag, index) => (
            <MDBox py={0.5} pl={2} pr={3} key={`resident-${index}`}>
              {!isLoading && (
                <MDBox display="flex" justifyContent="space-between" alignItems="center">
                  <MDBox width={200}>
                    <MDTypography variant="body2" color="text" fontWeight="bold">
                      {ftag.ftag}
                    </MDTypography>
                    <MDTypography variant="body2" color="text" style={{ fontSize: 14 }}>
                      {ftag.subtitle}
                    </MDTypography>
                  </MDBox>
                  <TimestampCell timestamp={ftag.date} showAgo />
                  <MDButton variant="outlined" color="info" size="small" style={{ height: 36 }}>
                    VIEW SURVEY
                  </MDButton>
                </MDBox>
              )}
              {isLoading && (
                <MDBox display="flex" justifyContent="space-between" alignItems="center">
                  <MDBox width={200}>
                    <Skeleton width={60} height={20} margin={6} />
                    <MDTypography variant="body2" color="text">
                      <Skeleton width={180} height={20} margin={6} />
                    </MDTypography>
                  </MDBox>
                  <MDBox width={200}>
                    <Skeleton width={80} height={20} margin={6} />
                    <MDTypography variant="body2" color="text">
                      <Skeleton width={70} height={20} margin={6} />
                    </MDTypography>
                  </MDBox>
                  <Skeleton width={120} height={36} margin={6} />
                </MDBox>
              )}

              {index !== ftags.length - 1 && (
                <MDHr color={colors.grey[300]} style={{ width: "100%", marginTop: 6 }} />
              )}
            </MDBox>
          ))}

          {ftags.length === 0 && (
            <MDBox display="flex" height="100%" justifyContent="center" alignItems="center">
              <MDTypography variant="body2">No FTags found for this resident!</MDTypography>
            </MDBox>
          )}
        </MDBox>
      </Scrollbars>
    </MDBox>
  );
};
export default FTagLog;
