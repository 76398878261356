import PropTypes from "prop-types";

import MDBox from "components/MDBox";

import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";

const CMSAnalysisActionCell = ({ qapiId, ftag }) => {
  const isNewQapi = qapiId === -1;
  const navigate = useNavigate();
  const startNew = (e) => {
    e.stopPropagation();
    navigate(`/qapi/details?ftag=${ftag}`);
  };

  const viewQapi = (e) => {
    navigate(`/qapi?id=${qapiId}`);
  };

  if (isNewQapi) {
    return (
      <MDBox display="flex" alignItems="center">
        <MDButton
          variant="gradient"
          color="info"
          style={{ margin: 5, width: 120 }}
          onClick={startNew}
        >
          Start QAPI
        </MDButton>
      </MDBox>
    );
  }
  return (
    <MDBox display="flex" alignItems="center">
      <MDButton
        variant="gradient"
        color="primary"
        style={{ margin: 5, width: 120 }}
        onClick={viewQapi}
      >
        VIEW QAPI
      </MDButton>
    </MDBox>
  );
};
// Typechecking props for the StatusCell
CMSAnalysisActionCell.propTypes = {
  qapiId: PropTypes.number.isRequired,
  ftag: PropTypes.string.isRequired,
};

export default CMSAnalysisActionCell;
