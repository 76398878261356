import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Skeleton from "components/skeletons/Skeleton";

function ComplianceOverviewTopFiveSkeleton() {
  return (
    <Card id="top-five" style={{ height: "100%" }}>
      <MDBox
        pt={2}
        px={2}
        pb={2}
        display="flex"
        justifyContent="center"
        alignItems="flex-start"
        style={{ flexDirection: "column", height: "100%", margin: 0 }}
      >
        <MDBox style={{ top: 10, position: "absolute" }}>
          <MDTypography variant="h6" fontWeight="medium" width={280}>
            Top 5 Citations
          </MDTypography>
          <Skeleton classes="title" width={130} />
        </MDBox>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          style={{ width: "100%" }}
        >
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            style={{ width: "100%", paddingTop: 60 }}
            flexDirection="column"
          >
            <Skeleton classes="title" width={40} />
            <Skeleton classes="title" width={40} />
            <Skeleton classes="title" width={40} />
            <Skeleton classes="title" width={40} />
            <Skeleton classes="title" width={40} />
          </MDBox>
          <MDBox>
            <div
              style={{
                height: 200,
                width: 200,
                backgroundColor: "white",
                borderRadius: "50%",
                border: "55px solid #EEE",
              }}
            />
          </MDBox>
        </MDBox>
        <Skeleton classes="title" width={60} />
      </MDBox>
    </Card>
  );
}

export default ComplianceOverviewTopFiveSkeleton;
