/* eslint-disable no-shadow */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/alt-text */

import { CircularProgress, Grid } from "@mui/material";
import { useDropzone } from "react-dropzone";
import { useEffect, useMemo, useState } from "react";
import colors from "assets/theme/base/colors";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import useBackendError from "hooks/useBackendError";

const baseStyle = {
  borderWidth: "2px",
  borderStyle: "dashed",
  borderColor: colors.grey[500],
  borderRadius: 8,
  cursor: "pointer",
  minHeight: 100,
  transition: "border .24s ease-in-out",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  backgroundColor: colors.grey[200],
  width: "100%",
};

const focusedStyle = {
  borderColor: colors.info.main,
};

const acceptStyle = {
  borderColor: colors.success.main,
};

const rejectStyle = {
  borderColor: colors.error.main,
};

const MDDropzone = (param) => {
  const axios = useAxiosPrivate();
  const { setError } = useBackendError();

  const [uploadPercentage, setUploadPercentage] = useState(-1);

  const config = {
    headers: { "Content-Type": "multipart/form-data" },
    onUploadProgress: (progressEvent) =>
      setUploadPercentage(Math.round((progressEvent.loaded * 100) / progressEvent.total)),
  };

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    onDrop: async (acceptedFiles) => {
      try {
        const formData = new FormData();
        // eslint-disable-next-line no-restricted-syntax
        for (const file of acceptedFiles) {
          formData.append("files", file);
        }
        const response = await axios.post(`/files/upload`, formData, config);
        const newArray = acceptedFiles.map((file, index) => ({
          id: response[index],
          fileName: file.name,
          dateUploaded: null,
        }));

        param.onFilesChangedCallback(newArray);
      } catch (err) {
        console.log(err);
        setError("Failed to upload file. Please try again");
      } finally {
        setUploadPercentage(-1);
      }
    },
    multiple: param?.multiple !== false,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  if (uploadPercentage !== -1) {
    return (
      <MDBox style={style} textAlign="center">
        <MDBox display="flex" alignItems="center">
          <CircularProgress style={{ height: 20, width: 20 }} />
          &nbsp;
          <MDTypography width="200" variant="body2" fontSize="xs" my={2}>
            Uploading...{uploadPercentage}%
          </MDTypography>
        </MDBox>
      </MDBox>
    );
  }

  return (
    <>
      {param.override != null && (
        <MDBox {...getRootProps()} textAlign="center">
          {param.override}
        </MDBox>
      )}
      {param.override == null && (
        <MDBox {...getRootProps({ style })} textAlign="center">
          <MDInput {...getInputProps()} />
          {param.override != null && param.override}
          <MDTypography variant="body2" fontSize="xs" my={2}>
            Drag and drop some files here, or click to select files
          </MDTypography>
        </MDBox>
      )}
    </>
  );
};

export default MDDropzone;
