/* eslint-disable react/no-array-index-key */
import { Card, Icon } from "@mui/material";
import colors from "assets/theme/base/colors";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import Skeleton from "components/skeletons/Skeleton";

const SectionSkeleton = ({ sectionIndex, data }) => (
  <Card
    style={{
      backgroundColor: colors.grey[300],
      width: "100%",
      marginBottom: 20,
      paddingBottom: 10,
      marginLeft: 10,
    }}
  >
    <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
      <MDBox
        color="white"
        borderRadius="md"
        display="flex"
        height="2.5rem"
        px={2}
        mt={-3}
        mb={1}
        width="100%"
        style={{ background: "#344767" }}
        justifyContent="space-between"
      >
        <MDBox pt={1}>
          <Skeleton width={Math.floor(Math.random() * 200 + 100)} height={20} dark />
        </MDBox>
      </MDBox>
    </MDBox>
    <MDBox>
      {data[sectionIndex].questions.map((item, index) => (
        <MDBox px={2} key={`sel-${index}`}>
          <Skeleton width="100%" height={40} dark key={`seli-${index}`} />
        </MDBox>
      ))}
    </MDBox>

    <MDBox px={3} pt={1}>
      <MDButton variant="gradient" color="info" width={100}>
        New Question&nbsp;
        <Icon sx={{ fontWeight: "bold" }}>add</Icon>
      </MDButton>
    </MDBox>
  </Card>
);

export default SectionSkeleton;
