/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDProgress from "components/MDProgress";
import Tooltip from "@mui/material/Tooltip";
import Skeleton from "components/skeletons/Skeleton";

function StatusCell({ status, description = true, showTooltip = false, isLoading }) {
  const content = (
    <MDBox display="flex" alignItems="center" width="100%">
      <MDBox mr={1} display="flex" flexDirection="column" width="100%">
        {!isLoading && (
          <>
            <MDTypography variant="caption" color="text" mb={0.5}>
              {status === 100 && description === true && "Complete "}
              {status !== 100 && description === true && "In Progress "}
              {status}%
            </MDTypography>
            <MDProgress
              value={status}
              variant="gradient"
              color={status === 100 ? "success" : "warning"}
              width="100%"
            />
          </>
        )}
        {isLoading && (
          <>
            <Skeleton width={100} height={14} />
            <Skeleton width={100} height={8} />
          </>
        )}
      </MDBox>
    </MDBox>
  );
  if (showTooltip) {
    return (
      <Tooltip title="Click to see the details" followCursor enterDelay={1000}>
        {content}
      </Tooltip>
    );
  }
  return content;
}

// Typechecking props for the StatusCell
StatusCell.propTypes = {
  status: PropTypes.number.isRequired,
};

export default StatusCell;
