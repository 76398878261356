/* eslint-disable react/no-array-index-key */
import Scrollbars from "react-custom-scrollbars";
import colors from "assets/theme/base/colors";
import Skeleton from "components/skeletons/Skeleton";
import MDHr from "components/MDHr";
import Marquee from "components/Marquee";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Icon } from "@mui/material";
import MDButton from "components/MDButton";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import useBackendError from "hooks/useBackendError";

const MDSUploadHistory = ({ residentId }) => {
  const [isMdsListLoading, setMdsListLoading] = useState(true);
  const [isMdsFetchInProgress, setMdsFetchInProgress] = useState(false);
  const mdsPaginationKey = useRef();
  const [mdsList, setMdsList] = useState([]);
  const navigate = useNavigate();
  const dummy = Array.from({ length: 20 }, () => ({}));
  const axios = useAxiosPrivate();
  const { setError } = useBackendError();
  const loadMDSList = async () => {
    if (isMdsFetchInProgress) {
      return;
    }
    setMdsFetchInProgress(true);
    if (mdsPaginationKey.current == null) {
      setMdsListLoading(true);
    }
    try {
      const response = await axios.get(
        `/mds/list${
          mdsPaginationKey.current == null ? "" : `?paginationKey=${mdsPaginationKey.current}`
        }`,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      setMdsList(mdsList.concat(response.data.mds));
      mdsPaginationKey.current = response.data.paginationKey;
    } catch (err) {
      setError("Failed to fetch from the backend. Please try again");
    } finally {
      setMdsFetchInProgress(false);
      setMdsListLoading(false);
    }
  };

  useEffect(() => {
    loadMDSList();
  }, []);

  const handleScroll = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;
    const calculatedScrollPercentage = (scrollTop / (scrollHeight - clientHeight)) * 100;
    if (calculatedScrollPercentage >= 80) {
      loadMDSList();
    }
  };
  const viewMDS = (id) => {
    navigate(`/residents/mds?id=${id}`);
  };

  const ref = useRef();
  const [dimensions, setDimensions] = useState(100);

  function handleWindowSizeChange() {
    setDimensions(ref.current.getBoundingClientRect());
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    setDimensions(ref.current.getBoundingClientRect());
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <MDBox p={2} ref={ref} height={residentId == null ? "100%" : 150}>
      <MDTypography variant="h6" fontWeight="medium">
        MDS Upload History
      </MDTypography>
      <MDBox mt={1}>
        <Scrollbars
          onScroll={handleScroll}
          style={{
            height: dimensions?.height,
            backgroundColor: colors.grey[100],
            borderRadius: 8,
            marginTop: 6,
            marginBottom: 6,
          }}
          renderTrackHorizontal={({ style, ...props }) => <div />}
          renderTrackVertical={({ style, ...props }) => (
            <div className="scrollbar-regular-vertical-track" {...props} />
          )}
          renderThumbVertical={({ style, ...props }) => (
            <div
              style={{
                ...style,
                backgroundColor: "rgba(0, 0, 0, 0.2)",
                borderRadius: 8,
                width: 6,
                cursor: "pointer",
              }}
              {...props}
            />
          )}
        >
          {!isMdsListLoading && (
            <MDBox py={2} px={1}>
              {mdsList?.map((mds, index) => (
                <div key={index}>
                  <MDBox display="flex" justifyContent="space-between">
                    <MDBox>
                      <MDBox display="flex">
                        <Icon fontSize="medium" style={{ marginRight: 4 }}>
                          article_outlined
                        </Icon>
                        <Marquee
                          fontSize={14}
                          text={mds.filename}
                          width={(dimensions?.width ?? 40) - 170}
                          height={24}
                          fontWeight="regular"
                          underlineOnHover={false}
                          color="dark"
                        />
                      </MDBox>

                      <MDBox display="flex" justifyContent="space-between">
                        <MDTypography
                          variant="body2"
                          style={{
                            fontSize: 12,
                            color: colors.grey[500],
                            marginLeft: 30,
                            width: 85,
                          }}
                        >
                          {dayjs(dayjs.unix(mds.uploadedDate)).fromNow()}
                        </MDTypography>
                        <MDTypography
                          variant="body2"
                          style={{ fontSize: 12, color: colors.grey[500], marginLeft: 30 }}
                        >
                          {dayjs.unix(mds.uploadedDate).format("MMM DD, YYYY hh:mm A")}
                        </MDTypography>
                      </MDBox>
                    </MDBox>

                    <MDButton
                      variant="outlined"
                      color="primary"
                      size="small"
                      style={{ height: 26, marginRight: 10 }}
                      onClick={() => viewMDS(mds.id)}
                    >
                      VIEW
                    </MDButton>
                  </MDBox>
                  <MDHr
                    color={colors.grey[300]}
                    style={{ width: "100%", marginTop: 6, marginBottom: 6 }}
                  />
                </div>
              ))}
            </MDBox>
          )}
          {isMdsListLoading && (
            <MDBox py={2} px={1}>
              {dummy.map((dum, index) => (
                <div key={index}>
                  <MDBox display="flex" justifyContent="space-between">
                    <MDBox>
                      <MDBox display="flex">
                        <Skeleton width={24} height={24} borderRadius={99} />
                        <Skeleton width={220} height={24} margin="0 0 0 10px" />
                      </MDBox>

                      <MDBox display="flex" justifyContent="space-between">
                        <Skeleton width={80} height={12} margin="0 0 0 36px" />
                        <Skeleton width={80} height={12} margin="0 0 0 10px" />
                      </MDBox>
                    </MDBox>

                    <Skeleton width={70} height={40} />
                  </MDBox>
                  <MDHr
                    color={colors.grey[300]}
                    style={{ width: "100%", marginTop: 6, marginBottom: 6 }}
                  />
                </div>
              ))}
            </MDBox>
          )}
        </Scrollbars>
      </MDBox>
    </MDBox>
  );
};
export default MDSUploadHistory;
