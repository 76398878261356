import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import colors from "assets/theme/base/colors";
import MDButton from "components/MDButton";

import MDTypography from "components/MDTypography";

import useModal from "hooks/useModal";
import { useEffect, useRef, useState } from "react";
import useResidents from "hooks/useResidents";
import useBackendError from "hooks/useBackendError";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import MDBox from "components/MDBox";
import MDAvatar from "components/MDAvatar";
import MDSelect from "components/MDSelect";
import MDHr from "components/MDHr";
import { makeStyles } from "@material-ui/styles";
import MDInput from "components/MDInput";
import { isValidEmailId } from "utils/utils";

const useStyles = makeStyles(() => ({
  accordionSummary: {
    minHeight: 40,
    maxHeight: 40,
    "&.Mui-expanded": {
      minHeight: 40,
      maxHeight: 40,
      backgroundColor: colors.grey[100],
    },
  },
  accordionDetail: {
    backgroundColor: colors.grey[200],
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  accordion: {
    boxShadow: "none",
    "&:before": {
      display: "none", // Remove the pseudo-element
    },
    "& .MuiPaper-root": {
      borderRadius: 10,
    },
  },
}));

const EmailSatisfactionSurveyModal = ({ residentId, residentName, emailId }) => {
  const classes = useStyles();
  const { closeModal } = useModal();
  const [residentInputValue, setResidentInputValue] = useState(residentName ?? "");
  const [templateInputValue, setTemplateInputValue] = useState("");
  const [email, setEmail] = useState(emailId ?? "");
  const { setError } = useBackendError();
  const axios = useAxiosPrivate();
  const [resident, setResident] = useState(
    residentId != null ? { label: residentName, id: residentId } : null
  );
  const [template, setTemplate] = useState();
  const [participant, setParticipant] = useState("");
  const [type, setType] = useState("");

  const [residentsError, setResidentsError] = useState(false);
  const [templatesError, setTemplatesError] = useState(false);
  const [participantError, setParticipantError] = useState(false);
  const [emailError, setEmailError] = useState(null);
  const [typeError, setTypeError] = useState(false);
  const [notifyError, setNotifyError] = useState(false);
  const residentRef = useRef();
  const templateRef = useRef();
  const emailRef = useRef();
  const { residents, setResidents } = useResidents();
  const [templates, setTemplates] = useState([]);
  const [sendingEmail, setSendingEmail] = useState(false);
  const [notify, setNotify] = useState(false);
  const [sendNotification, setSendNotification] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [emailWho, setEmailWho] = useState("me");

  const handleEmailWho = (event) => {
    setEmailWho(event.target.value);
  };

  const setNotifyFn = (event) => {
    setNotify(event.target.checked);
  };

  const sendNotificationFn = (event) => {
    setSendNotification(event.target.checked);
    setNotifyError(false);
  };
  const sendEmailFn = (event) => {
    setSendEmail(event.target.checked);
    setNotifyError(false);
  };

  const setParticipantAndResetError = (val) => {
    setParticipant(val);
    setParticipantError(false);
  };

  const setTypeAndResetError = (val) => {
    setType(val);
    setTypeError(false);
  };

  useEffect(() => {
    async function fetchData() {
      residentRef.current.focus();
      if (residents == null) {
        try {
          const response = await axios.get("/residents/list", {
            headers: { "Content-Type": "application/json" },
          });
          setResidents(response.data);
        } catch (err) {
          if (!err?.response) {
            setError("Failed to fetch the list of users in this facility. Please try again");
          }
        }
      }
      try {
        const response = await axios.get("/satisfaction/templates", {
          headers: { "Content-Type": "application/json" },
        });
        setTemplates(response.data);
      } catch (err) {
        if (!err?.response) {
          setError("Failed to fetch the list of templates. Please try again");
        }
      }
    }
    fetchData();
  }, [axios, residents, setError, setResidents]);

  const tryAdd = async () => {
    let error = false;
    if (notify && sendEmail === false && sendNotification === false) {
      error = true;
      setNotifyError(true);
    }

    if (templateInputValue == null || templateInputValue === "") {
      error = true;
      setTemplatesError(true);
      templateRef.current.focus();
    }
    if (residentInputValue == null || residentInputValue === "") {
      error = true;
      setResidentsError(true);
      residentRef.current.focus();
    }

    if (email == null || email === "") {
      error = true;
      setEmailError("*Email Id is required");
      emailRef.current.focus();
    } else if (!isValidEmailId(email)) {
      error = true;
      setEmailError("*Please enter a valid email Id");
      emailRef.current.focus();
    }

    if (participant == null || participant === "") {
      error = true;
      setParticipantError(true);
    }
    if (type == null || type === "") {
      error = true;
      setTypeError(true);
    }
    if (!error) {
      try {
        setSendingEmail(true);
        await axios.post(
          `/satisfaction/send-email`,
          {
            resident,
            template,
            participant,
            type,
            notify: [sendNotification ? "notification" : null, sendEmail ? "email" : null],
            notifyWhom: emailWho,
          },
          {
            headers: { "Content-Type": "application/json" },
          }
        );
      } catch (err) {
        setError("Failed to send email. Please try again");
      } finally {
        setSendingEmail(false);
      }
      closeModal({
        action: "send",
        email,
        notify,
      });
    }
  };

  const residentsList =
    residents != null
      ? residents.map((res) => ({
          label: res.fullName,
          id: res.id,
          room: res.room,
          url: res.url,
          email: res.email,
        }))
      : [];

  const templateList =
    templates != null
      ? templates.map((temp) => ({
          label: temp.name,
          id: temp.id,
        }))
      : [];
  const participantType = [
    { value: "resident", label: "Resident" },
    { value: "family", label: "Family Member" },
  ];

  const typeList = [
    { value: "intitial", label: "Initial" },
    { value: "midstay", label: "Mid-stay" },
    { value: "discharge", label: "Discharge" },
  ];

  return (
    <div style={{ maxWidth: 600 }}>
      <MDBox display="flex" flexDirection="column" textAlign="center">
        <MDTypography
          display="inline"
          variant="h6"
          textTransform="capitalize"
          fontWeight="bold"
          textAlign="center"
        >
          New Resident Satisfaction Survey
        </MDTypography>
      </MDBox>
      <Grid container spacing={1} mb={1} mt={0.5}>
        <Grid container mb={1} mt={1}>
          <Grid item xs={2.5} textAlign="right" mr={1}>
            <MDTypography display="inline" variant="body2" textTransform="capitalize">
              Resident
            </MDTypography>
          </Grid>
          <Grid item xs={8.5} textAlign="left" pr={2}>
            <Autocomplete
              isOptionEqualToValue={(option, value) => option.id === value.id}
              value={resident || null}
              onChange={(event, newValue) => {
                setResidentsError(false);
                setResident(newValue);
                setEmail(newValue?.email);
                if (emailError != null && newValue?.email != null) {
                  setEmailError(null);
                }
              }}
              inputValue={residentInputValue}
              onInputChange={(event, newInputValue) => {
                setResidentInputValue(newInputValue);
              }}
              options={residentsList}
              renderInput={(params) => (
                <TextField
                  inputRef={residentRef}
                  error={residentsError}
                  helperText={residentsError ? "*Resident is required" : ""}
                  {...params}
                  placeholder="Enter or select a Resident..."
                />
              )}
              renderOption={(props, option, { inputValue }) => {
                const matches = match(option.label, inputValue, { insideWords: true });
                const parts = parse(option.label, matches);

                const bgColor = inputValue === option.label ? colors.grey[200] : null;
                return (
                  <li {...props} style={{ backgroundColor: bgColor }}>
                    <MDBox display="flex">
                      <span style={{ marginRight: 10 }}>
                        <MDAvatar bgColor="light" src={option.url} alt={option.label} size="xs" />
                      </span>
                      {parts.map((part) => (
                        <span
                          key={part.text + new Date().getTime()}
                          style={{
                            fontWeight: part.highlight ? 700 : 400,
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          {part.text}
                        </span>
                      ))}
                      {option.room != null && option.room !== "" && (
                        <span style={{ color: colors.grey[500], fontSize: 12 }}>
                          &nbsp;[Room:{option.room}]
                        </span>
                      )}
                    </MDBox>
                  </li>
                );
              }}
            />
          </Grid>
        </Grid>
        <Grid container mb={1} mt={1}>
          <Grid item xs={2.5} textAlign="right" mr={1}>
            <MDTypography display="inline" variant="body2" textTransform="capitalize">
              Email Id
            </MDTypography>
          </Grid>
          <Grid item xs={8.5} textAlign="left" pr={2}>
            <MDInput
              ref={emailRef}
              placeholder="Enter an Email.."
              style={{ width: "100%" }}
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
                setEmailError(null);
              }}
              error={emailError != null}
              id="title"
            />
            <FormHelperText sx={{ marginLeft: 2 }} error>
              {emailError}
            </FormHelperText>
          </Grid>
        </Grid>
        <Grid container mb={1} mt={1}>
          <Grid item xs={2.5} textAlign="right" mr={1}>
            <MDTypography display="inline" variant="body2" textTransform="capitalize">
              Template
            </MDTypography>
          </Grid>
          <Grid item xs={8.5} textAlign="left" pr={2}>
            <Autocomplete
              isOptionEqualToValue={(option, value) => option.id === value.id}
              value={template || null}
              onChange={(event, newValue) => {
                setTemplatesError(false);
                setTemplate(newValue);
              }}
              inputValue={templateInputValue}
              onInputChange={(event, newInputValue) => {
                setTemplateInputValue(newInputValue);
              }}
              options={templateList}
              renderInput={(params) => (
                <TextField
                  inputRef={templateRef}
                  error={templatesError}
                  helperText={templatesError ? "*Template is required" : ""}
                  {...params}
                  placeholder="Enter or select a Template..."
                />
              )}
              renderOption={(props, option, { inputValue }) => {
                const matches = match(option.label, inputValue, { insideWords: true });
                const parts = parse(option.label, matches);

                const bgColor = inputValue === option.label ? colors.grey[200] : null;
                return (
                  <li {...props} style={{ backgroundColor: bgColor }}>
                    <MDBox display="flex">
                      {parts.map((part) => (
                        <span
                          key={part.text + new Date().getTime()}
                          style={{
                            fontWeight: part.highlight ? 700 : 400,
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          {part.text}
                        </span>
                      ))}
                    </MDBox>
                  </li>
                );
              }}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xl={2.5} xs={2.5} textAlign="right" mb={1} mt={1} mr={1}>
            <MDTypography display="inline" variant="body2" textTransform="capitalize">
              Participant
            </MDTypography>
          </Grid>
          <Grid item xl={4.2} xs={8.5} textAlign="left" mb={1} mt={1}>
            <MDBox mr={2}>
              <MDSelect
                list={participantType}
                placeholder="Select a Participant..."
                value={participant}
                setValue={setParticipantAndResetError}
                errorMessage={participantError ? "*Participant is required" : null}
              />
            </MDBox>
          </Grid>
          <Grid item xl={0.8} xs={2.5} textAlign="right" mt={1} mr={1}>
            <MDTypography display="inline" variant="body2" textTransform="capitalize">
              Type
            </MDTypography>
          </Grid>
          <Grid item xl={3.3} xs={8.5} textAlign="left" mt={1}>
            <MDBox mr={2}>
              <MDSelect
                list={typeList}
                placeholder="Select a Type..."
                value={type}
                setValue={setTypeAndResetError}
                errorMessage={typeError ? "*Type is required" : null}
              />
            </MDBox>
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: -10 }}>
          <Grid item xl={2.2} xs={2.2} textAlign="right" mt={1} mr={1}>
            {}
          </Grid>
          <Grid item textAlign="left" mt={1}>
            <Accordion disableGutters className={classes.accordion} expanded={notify}>
              <AccordionSummary>
                <FormControlLabel
                  control={
                    <Checkbox
                      value="notification"
                      checked={notify}
                      onChange={(event) => setNotifyFn(event)}
                    />
                  }
                  label={
                    <MDTypography display="inline" variant="body2">
                      Notify when the resident completes this survey
                    </MDTypography>
                  }
                />
              </AccordionSummary>
              <AccordionDetails>
                <Grid container>
                  <Grid item xs={12} mb={1}>
                    <MDBox
                      style={{ border: `1px solid ${colors.grey[300]}` }}
                      borderRadius="xl"
                      py={1}
                      px={3}
                    >
                      <RadioGroup
                        aria-labelledby="controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={emailWho}
                        onChange={handleEmailWho}
                      >
                        <MDBox display="flex">
                          <MDBox width={160}>
                            <FormControlLabel
                              value="me"
                              control={<Radio />}
                              label={
                                <MDTypography
                                  display="inline"
                                  variant="body2"
                                  textTransform="capitalize"
                                >
                                  Just Me
                                </MDTypography>
                              }
                            />
                          </MDBox>
                          <FormControlLabel
                            value="everyone"
                            control={<Radio />}
                            label={
                              <MDTypography
                                display="inline"
                                variant="body2"
                                textTransform="capitalize"
                              >
                                Everyone
                              </MDTypography>
                            }
                          />
                        </MDBox>
                      </RadioGroup>

                      <MDHr color={colors.grey[300]} style={{ margin: 2 }} />

                      <MDBox display="flex">
                        <MDBox width={160}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                value="notification"
                                checked={sendNotification}
                                onChange={(event) => sendNotificationFn(event)}
                              />
                            }
                            label={
                              <MDTypography display="inline" variant="body2">
                                Notification
                              </MDTypography>
                            }
                          />
                        </MDBox>
                        <MDBox>
                          <FormControlLabel
                            control={
                              <Checkbox
                                value="notification"
                                checked={sendEmail}
                                onChange={(event) => sendEmailFn(event)}
                              />
                            }
                            label={
                              <MDTypography display="inline" variant="body2">
                                Email
                              </MDTypography>
                            }
                          />
                        </MDBox>
                      </MDBox>
                      <FormHelperText sx={{ marginLeft: 4 }} error>
                        {notifyError === true
                          ? "*Please choose notification or email or both"
                          : null}
                      </FormHelperText>
                    </MDBox>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Grid>
      <MDBox
        px={2}
        pb={2}
        display="flex"
        textAlign="center"
        alignItems="center"
        justifyContent="center"
      >
        <MDBox mr={1}>
          <MDButton
            onClick={() => closeModal({ action: "cancel" })}
            variant="gradient"
            color="light"
            size="medium"
            m={1}
          >
            cancel
          </MDButton>
        </MDBox>
        <MDBox mr={1}>
          <MDButton
            onClick={tryAdd}
            variant="gradient"
            color="primary"
            size="medium"
            loading={sendingEmail}
            loadingText="sending email..."
          >
            send email
          </MDButton>
        </MDBox>
      </MDBox>
    </div>
  );
};

export default EmailSatisfactionSurveyModal;
