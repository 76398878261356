/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { styled } from "@mui/material/styles";
import colors from "assets/theme/base/colors";

export default styled("div")(({ theme, focused, hideToolbar, minHeight, error }) => {
  const { borders } = theme;

  const { borderRadius } = borders;

  return {
    "& .ql-toolbar": {
      borderRadius: `${borderRadius.md} ${borderRadius.md} 0 0`,
      backgroundColor: colors.grey[200],
      padding: "0px 8px !important",
      opacity: focused ? 1 : 0,
      height: focused ? "28px" : "0px",
      lineHeight: 1.2,
      transition: "height 0.3s ease, opacity 0.3s ease",
      display: hideToolbar ? "none" : null,
    },

    "& .ql-container": {
      borderRadius:
        focused && !hideToolbar ? `0 0 ${borderRadius.md} ${borderRadius.md}` : borderRadius.md,
      backgroundColor: "white",
      borderTop: focused && !hideToolbar ? null : "1px solid rgb(204, 204, 204) !important",
      border: error ? `2px solid ${colors.error.main} !important` : null,
    },

    "& .ql-editor": {
      color: colors.grey[800],
      minHeight,
      "& p": {
        color: colors.grey[800],
      },

      "& ul li": {
        color: colors.grey[800],
      },
    },

    ".quill > .ql-container > .ql-editor.ql-blank::before": {
      fontStyle: "normal",
      color: colors.grey[500],
    },
  };
});
