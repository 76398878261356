import axios, { enableMocks } from "../api/axios";
import useAuth from "./useAuth";

const useRefreshToken = () => {
  const { setAuth } = useAuth();

  const refresh = async () => {
    const response = await axios.get("/refresh", {
      withCredentials: !enableMocks,
    });
    setAuth((prev) => ({
      ...prev,
      roles: response.data.roles,
      accessToken: response.data.accessToken,
      profile: response.data.profile,
    }));
    return response.data.accessToken;
  };
  return refresh;
};

export default useRefreshToken;
