export const useCache = () => {
  const setItem = (key, value) => {
    try {
      sessionStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
      // do nothing
    }
  };

  const getItem = (key) => {
    try {
      const val = sessionStorage.getItem(key);
      return JSON.parse(val);
    } catch (e) {
      return null;
    }
  };

  const deleteItem = (key) => {
    try {
      sessionStorage.removeItem(key);
    } catch (e) {
      // do nothing
    }
  };
  return { getItem, setItem, deleteItem };
};
