import dayjs from "dayjs";

export const ROLES = {
  User: "100",
  Admin: "200",
  Corporate: "300",
};

export const AUTO_LOGOUT_MS = 60 * 60 * 1000; // 1 hour

export function timeout(delay) {
  // eslint-disable-next-line no-promise-executor-return
  return new Promise((res) => setTimeout(res, delay));
}

export function isPositiveNumber(str) {
  return /^\+?(0|[1-9]\d*)$/.test(str);
}

export function isValidEmailId(str) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(str);
}

export function isValidUserId(str) {
  return /^[a-zA-Z0-9_.-]+$/.test(str);
}

export function isValidName(str) {
  return /^[a-zA-Z][a-zA-Z0-9 ,.'"/-]*$/.test(str);
}

export function isValidRoomNo(str) {
  return /^[a-zA-Z0-9 ._/#-]*$/.test(str);
}

export function isValidSSN(str) {
  return /^\d{9}$/.test(str);
}

export function isValidDate(str) {
  const formatted = dayjs(str).format("MM/DD/YYYY");
  return /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/(19|20)\d{2}$/.test(formatted);
}
