/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import MDBox from "components/MDBox";

import useBackendError from "hooks/useBackendError";
import MDTypography from "components/MDTypography";
import { useNavigate } from "react-router-dom";
import { Card, Icon } from "@mui/material";
import MDButton from "components/MDButton";
import { useEffect, useState } from "react";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import colors from "assets/theme/base/colors";
import useModal from "hooks/useModal";
import ConfirmDeleteModal from "components/modal/ConfirmDeleteModal";
import { timeout } from "utils/utils";
import Skeleton from "components/skeletons/Skeleton";

const Templates = () => {
  const { openModal } = useModal();
  const navigate = useNavigate();
  const [templates, setTemplates] = useState([{}, {}, {}]);
  const [isLoading, setLoading] = useState(true);
  const [hover, setHover] = useState(-1);

  const { setError } = useBackendError();
  const axios = useAxiosPrivate();

  const loadTemplates = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/satisfaction/templates`, {
        headers: { "Content-Type": "application/json" },
      });
      setTemplates(response.data);
    } catch (err) {
      setError("Failed to fetch from the backend. Please try again");
    } finally {
      setLoading(false);
    }
  };

  const deleteCallback = async () => {
    console.log("deleteing...");
    await timeout(3000);
    /* await axios.post(`/compliance/survey/delete`, {
      headers: { "Content-Type": "application/json" },
    }); */
    return "success";
  };

  const handleClick = (id) => {
    navigate(`/resident-satisfaction/template?id=${id}`);
  };
  const handleDelete = (e) => {
    e.stopPropagation();
    openModal(
      <ConfirmDeleteModal
        title="Delete template?"
        text="Do you really want to delete the seleted template?"
        successText="The selected template has been successfully deleted."
        callback={deleteCallback}
      />,
      () => {}
    );
  };

  useEffect(() => {
    loadTemplates();
  }, []);

  const newTemplate = () => {
    navigate(`/resident-satisfaction/template`);
  };

  return (
    <Card id="compliance-overview">
      <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
        <MDBox
          color="white"
          bgColor="info"
          variant="gradient"
          borderRadius="lg"
          shadow="lg"
          display="flex"
          justifyContent="center"
          alignItems="center"
          py={1}
          px={2}
          mt={-4}
          mb={3}
        >
          <MDTypography variant="h6" fontWeight="medium" color="white">
            Manage Templates
          </MDTypography>
        </MDBox>
      </MDBox>

      <MDBox px={4} pb={2}>
        <MDButton variant="gradient" color="primary" width={100} onClick={newTemplate}>
          New Template&nbsp;
          <Icon sx={{ fontWeight: "bold" }}>add</Icon>
        </MDButton>
      </MDBox>

      {isLoading && (
        <MDBox>
          {templates.map((template, index) => (
            <Card
              key={`template-${index}`}
              style={{
                backgroundColor: colors.grey[200],
                marginBottom: 20,
                marginLeft: 30,
                marginRight: 30,
                border: `1px solid ${colors.grey[200]}`,
                cursor: "pointer",
              }}
            >
              <MDBox display="flex" justifyContent="space-between">
                <MDBox p={2}>
                  <MDBox>
                    <MDTypography variant="h6" fontWeight="medium">
                      <Skeleton width={Math.floor(Math.random() * 20 + 230)} height={20} />
                    </MDTypography>
                  </MDBox>
                  <MDBox>
                    <MDTypography variant="body2" style={{ fontSize: 13 }}>
                      <Skeleton width={160} height={18} />
                    </MDTypography>
                  </MDBox>
                </MDBox>
                <MDBox display="flex" alignItems="center" pr={1}>
                  <MDBox mr={1}>
                    <Skeleton width={100} height={40} />
                  </MDBox>
                  <MDBox mr={1}>
                    <Skeleton width={100} height={40} />
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          ))}
        </MDBox>
      )}

      {!isLoading && (
        <MDBox>
          {templates.map((template, index) => (
            <Card
              key={`template-${index}`}
              style={{
                backgroundColor:
                  hover === index ? "#BDD7FF" : template.editable ? "#DBE9FF" : colors.grey[300],
                marginBottom: 20,
                marginLeft: 30,
                marginRight: 30,
                border: `1px solid ${hover === index ? colors.info.main : colors.grey[500]}`,
                cursor: "pointer",
              }}
              onMouseEnter={() => setHover(index)}
              onMouseLeave={() => setHover(-1)}
              onClick={() => handleClick(template.id)}
            >
              <MDBox display="flex" justifyContent="space-between">
                <MDBox p={2}>
                  <MDBox>
                    <MDTypography variant="h6" fontWeight="medium">
                      {template.name}
                    </MDTypography>
                  </MDBox>
                  <MDBox>
                    <MDTypography variant="body2" style={{ fontSize: 13 }}>
                      {template.sections.length} sections,{" "}
                      {template.sections.reduce(
                        (accumulator, currentValue) => accumulator + currentValue.questions.length,
                        0
                      )}{" "}
                      questions
                    </MDTypography>
                  </MDBox>
                </MDBox>
                <MDBox display="flex" alignItems="center" pr={1}>
                  {template.editable && (
                    <MDBox mr={1}>
                      <MDButton
                        variant="gradient"
                        color="dark"
                        size="medium"
                        onClick={(event) => handleDelete(event)}
                      >
                        delete
                      </MDButton>
                    </MDBox>
                  )}

                  <MDBox mr={1}>
                    <MDButton variant="gradient" color="info" size="medium" onClick={handleClick}>
                      {template.editable && "view/edit"}
                      {!template.editable && "view"}
                    </MDButton>
                  </MDBox>
                  <MDBox mr={1}>
                    <MDButton variant="gradient" color="info" size="medium">
                      clone
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          ))}
        </MDBox>
      )}

      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        pb={3}
        pl={3}
        pr={3}
      >
        <MDButton variant="gradient" color="light" style={{ margin: 5 }} onClick={() => {}}>
          cancel
        </MDButton>
      </MDBox>
    </Card>
  );
};
export default Templates;
