/* eslint-disable react/no-array-index-key */

import MDBox from "components/MDBox";

import Icon from "@mui/material/Icon";

import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useState } from "react";
import { timeout } from "utils/utils";

const FTag = ({ ftag, deleteMe }) => {
  const [opacity, setOpacity] = useState(1);

  const deleteFTag = async () => {
    // setOpacity(0);
    deleteMe();
  };

  return (
    <MDBox
      display="flex"
      flexDirection="row"
      shadow="sm"
      bgColor="dark"
      borderRadius="lg"
      mx={2}
      my={1}
      px={1.5}
      py={0.8}
      justifyContent="space-between"
      alignContent="center"
      alignItems="center"
      // style={{ opacity, transition: "opacity 0.5s" }}
    >
      <MDBox display="flex">
        <MDTypography
          display="inline"
          variant="body2"
          color="light"
          fontWeight="bold"
          style={{ fontSize: 13, marginRight: 20 }}
        >
          {ftag.name}
        </MDTypography>
        <MDBox lineHeight={0}>
          <MDTypography display="inline" variant="body2" color="light" style={{ fontSize: 12 }}>
            Scope & Severity:
          </MDTypography>
          <MDTypography
            display="inline"
            variant="body2"
            color="light"
            fontWeight="bold"
            style={{ fontSize: 12, marginLeft: 12 }}
          >
            {ftag.scope}
          </MDTypography>
        </MDBox>
        {ftag.subStandard === true && (
          <MDTypography
            display="inline"
            variant="body2"
            color="light"
            style={{ fontSize: 12, marginLeft: 12 }}
          >
            <Icon color="success">check</Icon> Sub-standard quality of care
          </MDTypography>
        )}
        {ftag.pastNonCompliant === true && (
          <MDTypography
            display="inline"
            variant="body2"
            color="light"
            style={{ fontSize: 12, marginLeft: 4 }}
          >
            <Icon color="success">check</Icon> Past non-Complaint
          </MDTypography>
        )}
      </MDBox>
      <MDButton variant="text" color="secondary" iconOnly circular onClick={deleteFTag}>
        <Icon>delete</Icon>
      </MDButton>
    </MDBox>
  );
};

export default FTag;
