import { useCallback, useEffect, useRef, useState } from "react";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import useBackendError from "hooks/useBackendError";
import { isPositiveNumber } from "utils/utils";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Card, CircularProgress, Step, StepLabel, Stepper } from "@mui/material";
import MDTypography from "components/MDTypography";
import useModal from "hooks/useModal";
import ConfirmModal from "components/modal/ConfirmModal";
import HealthInspectionsStep from "./HealthInspectionsStep";
import QualityMeasuresStep from "./QualityMeasuresStep";
import StaffingDetailsStep from "./StaffingDetailsStep";

const FivestarCalculation = () => {
  const axios = useAxiosPrivate();
  const [isLoading, setLoading] = useState(true);
  const calculationData = useRef([{}, {}, {}]);
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const { setError } = useBackendError();
  const [pageErrors, setPageErrors] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const { openModal } = useModal();
  const navigate = useNavigate();

  const loadData = async () => {
    setLoading(true);
    calculationData.current = {};

    try {
      const response = await axios.get(`/fivestar/calculate?id=${id}`, {
        headers: { "Content-Type": "application/json" },
      });
      calculationData.current = response.data;
      setActiveStep(calculationData.current.step);
      console.log(calculationData.current);
    } catch (err) {
      setError("Failed to fetch from the backend. Please try again");
    } finally {
      setLoading(false);
    }
  };

  const saveData = async (stepDetails) => {
    console.log("saving step..");

    /*
    try {
      const response = await axios.get(
        `/inspections/list?type=${timeline.type}&startDate=${timeline.startDate}&endDate=${timeline.endDate}&quarter=${timeline.quarter}&year=${timeline.year}`,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      inspections.current = response.data;
      setItem("inspections", response.data);
    } catch (err) {
      setError("Failed to fetch from the backend. Please try again");
    } finally {
      setLoadingInsepctions(false);
    }
    */
  };

  useEffect(() => {
    loadData();
  }, []);

  const getSteps = useCallback(
    () => ["Health Inspections", "Staffing Details", "Quality Measures"],
    []
  );

  const steps = getSteps();

  const isLastStep = activeStep === 2;

  const goToNextStep = () => {
    calculationData.current.step = activeStep + 1;
    saveData(calculationData.current);
    setActiveStep(activeStep + 1);
  };

  const handleNext = () => {
    if (activeStep === 0) {
      const annuals = calculationData.current.inspections.filter(
        (inspection) => inspection.complaint === false
      ).length;
      if (annuals < 2) {
        setPageErrors([
          ...pageErrors,
          {
            key: "annual",
            value:
              "Please add the three most Annual Inspection details (you may continue with atleast two if the facility is new and does not had three Annual Inspection)",
          },
        ]);
        return;
      }
      if (annuals === 2) {
        openModal(
          <ConfirmModal
            buttonLabels={["no", "yes"]}
            icon="warn"
            text="It is recomended to add the latest three Annual Insepection details unless the facility is new and had only two Annual Inspections.
            Do you want to continue with just two?"
          />,
          (val) => {
            if (val.action !== "cancel") {
              goToNextStep();
            }
          }
        );
        return;
      }
      goToNextStep();
    } else if (activeStep === 1) {
      const errors = [];
      if (
        calculationData.current.staffing?.censusOverride !== null &&
        !isPositiveNumber(calculationData.current.staffing?.censusOverride)
      ) {
        errors.push({
          key: "census",
          value: "Please enter a valid average daily census",
        });
      }
      if (!isPositiveNumber(calculationData.current.staffing?.totalNurseHr)) {
        errors.push({
          key: "totalNurseHr",
          value: "Please enter a valid total nursing staff hours",
        });
      }
      if (!isPositiveNumber(calculationData.current.staffing?.totalRNHr)) {
        errors.push({
          key: "totalRNHr",
          value: "Please enter a valid total registered nurse hours",
        });
      }
      if (!isPositiveNumber(calculationData.current.staffing?.totalStaffHrWeekends)) {
        errors.push({
          key: "totalStaffHrWeekends",
          value: "Please enter a valid total nursing staff hours on weekends",
        });
      }
      if (!isPositiveNumber(calculationData.current.staffing?.totalNurseTurnover)) {
        errors.push({
          key: "totalNurseTurnover",
          value: "Please enter a valid total nursing turnover",
        });
      }
      if (!isPositiveNumber(calculationData.current.staffing?.rNTurnover)) {
        errors.push({
          key: "rNTurnover",
          value: "Please enter a valid registered nurse turnover",
        });
      }
      if (!isPositiveNumber(calculationData.current.staffing?.adminTurnover)) {
        errors.push({
          key: "adminTurnover",
          value: "Please enter a valid administrator turnover",
        });
      }
      setPageErrors(errors);
      if (errors.length === 0) {
        goToNextStep();
      }
    } else if (activeStep === 2) {
      const errors = [];
      if (!isPositiveNumber(calculationData.current.quality?.longHospitalization)) {
        errors.push({
          key: "longHospitalization",
          value: "Please enter a valid count",
        });
      }
      if (!isPositiveNumber(calculationData.current.quality?.longERVisit)) {
        errors.push({
          key: "longERVisit",
          value: "Please enter a valid count",
        });
      }
      if (!isPositiveNumber(calculationData.current.quality?.shortReHospitalization)) {
        errors.push({
          key: "shortReHospitalization",
          value: "Please enter a valid percent",
        });
      }
      if (!isPositiveNumber(calculationData.current.quality?.shortEDVisit)) {
        errors.push({
          key: "shortEDVisit",
          value: "Please enter a valid percent",
        });
      }
      if (!isPositiveNumber(calculationData.current.quality?.shortReturnRate)) {
        errors.push({
          key: "shortReturnRate",
          value: "Please enter a valid percent",
        });
      }
      setPageErrors(errors);
      if (errors.length === 0) {
        saveData(calculationData.current);
        navigate(`/5-star-rating/score?id=${calculationData.current.id}`, {
          state: { type: "new" },
        });
      }
    }
  };

  const handleBack = () => {
    if (activeStep === 2) {
      /* error update on page 
      if (inspectionsUpdate.current.date == null) {
        return;
      } */
    }
    setActiveStep(activeStep - 1);
  };

  const setCalculationData = (data) => {
    calculationData.current = data;
  };

  const resetError = (key) => {
    const temp = pageErrors.filter((obj) => obj.key !== key);
    setPageErrors(temp);
  };

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <HealthInspectionsStep
            errors={pageErrors}
            resetError={resetError}
            setCalculationData={setCalculationData}
            calculationData={calculationData.current}
          />
        );
      case 1:
        return (
          <StaffingDetailsStep
            errors={pageErrors}
            resetError={resetError}
            setCalculationData={setCalculationData}
            calculationData={calculationData.current}
          />
        );
      case 2:
        return (
          <QualityMeasuresStep
            errors={pageErrors}
            resetError={resetError}
            setCalculationData={setCalculationData}
            calculationData={calculationData.current}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Card id="compliance-overview" style={{ height: "100%" }}>
      <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
        <MDBox pl={2} pr={2} mt={-4} width="100%">
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </MDBox>
      </MDBox>
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        pl={3}
        pr={3}
      >
        <MDBox p={4} style={{ width: "100%" }}>
          <MDBox>
            {isLoading && (
              <MDBox display="flex" justifyContent="center">
                <CircularProgress color="secondary" style={{ height: 20, width: 20 }} />
                <MDTypography
                  variant="subtitle1"
                  style={{ fontSize: 16, marginLeft: 10 }}
                  color="text"
                >
                  Loading...
                </MDTypography>
              </MDBox>
            )}
            {!isLoading && getStepContent(activeStep)}
            <MDBox mt={3} width="100%" display="flex" justifyContent="center">
              <MDBox>
                <MDButton variant="gradient" color="light" onClick={() => {}} style={{ margin: 5 }}>
                  cancel
                </MDButton>
                {activeStep !== 0 && (
                  <MDButton
                    variant="gradient"
                    color="light"
                    onClick={handleBack}
                    style={{ margin: 5 }}
                  >
                    back
                  </MDButton>
                )}
              </MDBox>

              <MDButton variant="gradient" color="info" onClick={handleNext} style={{ margin: 5 }}>
                {isLastStep ? "calculate" : "next"}
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
};
export default FivestarCalculation;
