import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import dayjs from "dayjs";
import Skeleton from "components/skeletons/Skeleton";

const relativeTime = require("dayjs/plugin/relativeTime");

dayjs.extend(relativeTime);

function TimestampCell({ timestamp, hideTime = false, isLoading, showAgo }) {
  if (!isLoading && (timestamp === "" || timestamp === 0)) {
    return (
      <MDBox display="flex" alignItems="center" width={80} justifyContent="center">
        <MDTypography variant="caption" fontWeight="bold" color="text" textAlign="right">
          -
        </MDTypography>
      </MDBox>
    );
  }

  const ago = dayjs(dayjs.unix(timestamp)).fromNow();
  return (
    <MDBox display="flex" alignItems="center">
      <MDBox mr={1} display="flex" flexDirection="column" alignItems="right">
        {isLoading && <Skeleton width={90} height={20} />}
        {!isLoading && (
          <MDTypography variant="caption" fontWeight="bold" color="text" textAlign="right">
            {dayjs.unix(timestamp).format("MMM DD, YYYY")}
          </MDTypography>
        )}
        {!hideTime && isLoading && (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Skeleton width={45} height={18} />
          </div>
        )}
        {!hideTime && !isLoading && (
          <MDTypography variant="caption" color="text" textAlign="right">
            {dayjs.unix(timestamp).format("hh:mm A")}
          </MDTypography>
        )}
        {showAgo && (
          <MDTypography variant="caption" color="text" textAlign="right" style={{ fontSize: 11 }}>
            ({ago})
          </MDTypography>
        )}
      </MDBox>
    </MDBox>
  );
}
export default TimestampCell;
