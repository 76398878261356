/* eslint-disable react/no-danger */
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import MDTypography from "components/MDTypography";

import useModal from "hooks/useModal";
import "./confirmModal.css";

const ConfirmModal = ({ buttonLabels, title, icon, text, htmlText }) => {
  const { closeModal } = useModal();

  return (
    <div style={{ minWidth: 300 }}>
      <MDBox display="flex" justifyContent="center" alignItems="center" flexDirection="column">
        {title != null && (
          <MDTypography display="inline" variant="h6" textTransform="capitalize" fontWeight="bold">
            {title}
          </MDTypography>
        )}
        {icon === "success" && (
          <div className="successContainer">
            <div className="successOne" />
            <span className="successTwo" />
            <span className="successThree" />
            <div className="successFour" />
            <div className="successFive" />
            <div className="successSix" />
          </div>
        )}
        {icon === "warn" && (
          <div className="warnContainer">
            <span className="warnOne" />
            <span className="warnTwo" />
          </div>
        )}
        {icon === "error" && (
          <div className="errorContainer">
            <span className="errorOne">
              <span className="errorTwo" />
              <span className="errorThree" />
            </span>
          </div>
        )}

        {text != null && (
          <MDTypography display="inline" variant="body2" textAlign="center">
            {text}
          </MDTypography>
        )}

        {htmlText != null && (
          <MDTypography display="inline" variant="body2" textAlign="center">
            <div
              style={{ display: "inline-block" }}
              dangerouslySetInnerHTML={{ __html: htmlText }}
            />
          </MDTypography>
        )}

        <MDBox flexDirection="row" display="flex" m={1}>
          {buttonLabels.length === 2 && (
            <>
              <MDBox m={1}>
                <MDButton
                  onClick={() => closeModal({ action: "cancel" })}
                  variant="gradient"
                  color="light"
                  size="medium"
                >
                  {buttonLabels[0]}
                </MDButton>
              </MDBox>
              <MDBox m={1}>
                <MDButton
                  onClick={() => closeModal({ action: "confirm" })}
                  variant="gradient"
                  color="info"
                  size="medium"
                >
                  {buttonLabels[1]}
                </MDButton>
              </MDBox>
            </>
          )}
          {buttonLabels.length === 1 && (
            <MDBox m={1}>
              <MDButton
                onClick={() => closeModal({ action: "confirm" })}
                variant="gradient"
                color="info"
                size="medium"
              >
                {buttonLabels[0]}
              </MDButton>
            </MDBox>
          )}
        </MDBox>
      </MDBox>
    </div>
  );
};

export default ConfirmModal;
