/* eslint-disable no-plusplus */
import MDBox from "components/MDBox";

import { useEffect, useState, useRef } from "react";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import Grid from "@mui/material/Grid";
import useBackendError from "hooks/useBackendError";
import MDTypography from "components/MDTypography";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Card, Icon } from "@mui/material";
import MDButton from "components/MDButton";
import useScroll from "hooks/useScroll";
import useModal from "hooks/useModal";
import ConfirmModal from "components/modal/ConfirmModal";
import StatusCell from "components/DataTable/components/StatusCell";
import dayjs from "dayjs";
import Skeleton from "components/skeletons/Skeleton";
import Section from "./Section";

const ComplianceSurvey = () => {
  const [searchParams] = useSearchParams();
  const surveyIdFromParam = searchParams.get("id");
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState({
    sections: [
      { questions: [{}, {}, {}] },
      { questions: [{}, {}, {}] },
      { questions: [{}, {}, {}] },
    ],
  });
  const axios = useAxiosPrivate();
  const { setError } = useBackendError();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { setScrollTo } = useScroll();
  const [submitting, setSubmitting] = useState(false);
  const [saving, setSaving] = useState(false);
  const { openModal } = useModal();
  const [action, setAction] = useState(
    state != null && state.action != null ? state.action : "view"
  );
  const getTotalRestHeight = (num) => {
    let height = 0;
    for (let i = 0; i < num; i++) {
      const curHeight = document.getElementById(`section-${i}`).clientHeight + 30;
      height += curHeight;
    }
    return height;
  };

  const changed = useRef(false);

  const setDataFn = (val) => {
    changed.current = true;
    setData(val);
  };

  const modalReturnedValue = () => {
    navigate("/compliance");
  };

  const surveyId = state != null ? state.surveyId : surveyIdFromParam;
  const surveyName = data.pathwayName;
  const type = state != null ? state.type : "";
  const value = state != null ? state.value : "";

  const submitToServer = async (message) => {
    await axios.post(`/compliance/survey/save`, data, {
      headers: { "Content-Type": "application/json" },
    });
    openModal(
      <ConfirmModal buttonLabels={["okay"]} icon="success" text={message} />,
      modalReturnedValue
    );
  };

  const submit = async () => {
    const tempData = { ...data };
    let isError = false;
    let errorElem = "";
    let offsetTop = 0;
    for (let i = data.sections.length - 1; i >= 0; i--) {
      const section = data.sections[i];
      for (let j = section.questions.length - 1; j >= 0; j--) {
        const question = section.questions[j];
        if (question.answerIndex === -1) {
          tempData.sections[i].questions[j].isError = true;
          isError = true;
          errorElem = `question-${i}-${j}`;
          offsetTop = document.getElementById(errorElem).offsetTop + getTotalRestHeight(i) + 60;
        }
      }
    }

    if (isError) {
      setData(tempData);
      setTimeout(() => {
        setScrollTo(offsetTop);
      }, 100);
    } else {
      setSubmitting(true);
      try {
        await submitToServer("Mock survey has been successfully submitted!");
      } catch (err) {
        setError("Failed to submit. Please try again");
      } finally {
        setSubmitting(false);
      }
    }
  };

  const save = async () => {
    setSaving(true);
    try {
      await submitToServer("Mock survey has been successfully saved!");
    } catch (err) {
      setError("Failed to save. Please try again");
    } finally {
      setSaving(false);
    }
  };

  const loadQuestions = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/compliance/survey/questions?surveyId=${surveyId}`, {
        headers: { "Content-Type": "application/json" },
      });
      setData(response.data);
    } catch (err) {
      setError("Failed to fetch from the backend. Please try again");
      navigate("/compliance");
    } finally {
      setLoading(false);
    }
  };

  const loadDetails = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/compliance/survey/details?id=${surveyIdFromParam}`, {
        headers: { "Content-Type": "application/json" },
      });
      setData(response.data);
    } catch (err) {
      setError("Failed to fetch from the backend. Please try again");
      navigate("/compliance");
    } finally {
      setLoading(false);
    }
  };

  const edit = () => {
    setAction("edit");
  };

  useEffect(() => {
    if (action === "next") {
      loadQuestions();
    } else if (surveyIdFromParam !== null) {
      loadDetails();
    } else {
      navigate("/compliance");
      setError("Loading the survey failed because a valid Id was not provided");
    }
  }, []);

  const cancelConfirm = (prop) => {
    if (prop.action === "confirm") {
      navigate("/compliance");
    }
  };

  const cancelClick = () => {
    if (changed.current) {
      openModal(
        <ConfirmModal
          buttonLabels={["no", "yes"]}
          icon="warn"
          text="You have unsaved changes. Do you really want to go back?"
        />,
        cancelConfirm
      );
    } else {
      navigate("/compliance");
    }
  };

  return (
    <Card id="compliance-overview">
      <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
        <MDBox
          color="white"
          bgColor="info"
          variant="gradient"
          borderRadius="lg"
          shadow="lg"
          display="flex"
          justifyContent="center"
          alignItems="center"
          py={1}
          px={2}
          mt={-4}
          mb={3}
        >
          <MDTypography variant="h6" fontWeight="medium" color="white">
            New Mock Survey : {surveyName}
          </MDTypography>
        </MDBox>
      </MDBox>

      <Grid container spacing={3} mb={3}>
        <Grid item xs={12} lg={6}>
          <MDBox display="flex" justifyContent="space-around" alignItems="center" width="100%">
            <MDBox display="flex">
              <MDTypography variant="body2">Taken By:</MDTypography>
              {isLoading ? (
                <Skeleton classes="title" width="100px" margin="6px" />
              ) : (
                <MDTypography variant="body2" color="text" pl={0.5}>
                  {data.takenBy.fullName}
                </MDTypography>
              )}
            </MDBox>
            <MDBox display="flex">
              <MDTypography variant="body2">Participant:</MDTypography>
              {isLoading ? (
                <Skeleton classes="title" width="100px" margin="6px" />
              ) : (
                <MDTypography variant="body2" color="text" pl={0.5}>
                  {data.participant.fullName}
                </MDTypography>
              )}
            </MDBox>
          </MDBox>
        </Grid>
        <Grid item xs={12} lg={6}>
          <MDBox display="flex" justifyContent="space-around" alignItems="center" width="100%">
            <MDBox display="flex">
              <MDTypography variant="body2">Submitted On:</MDTypography>
              {isLoading ? (
                <Skeleton classes="title" width="100px" margin="6px" />
              ) : (
                <MDTypography variant="body2" color="text" pl={0.5}>
                  {data.unixTimestamp === -1
                    ? "Not Submitted"
                    : dayjs.unix(data.unixTimestamp).format("MMM DD, YYYY hh:mm A")}
                </MDTypography>
              )}
            </MDBox>
            <MDBox display="flex">
              <MDTypography variant="body2" pr={0.5}>
                Status:
              </MDTypography>
              <StatusCell status={data.status ?? 0} />
            </MDBox>
          </MDBox>
        </Grid>
      </Grid>

      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        pl={3}
        pr={3}
        style={{ minHeight: 235 }}
      >
        {data.sections.map((section, index) => (
          <Section
            data={data}
            sectionIndex={index}
            setData={setDataFn}
            isLoading={isLoading}
            // eslint-disable-next-line react/no-array-index-key
            key={`section-${index}`}
            action={action}
          />
        ))}
      </MDBox>
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        pb={3}
        pl={3}
        pr={3}
      >
        <MDButton variant="gradient" color="light" style={{ margin: 5 }} onClick={cancelClick}>
          cancel
        </MDButton>
        {action === "next" && (
          <MDButton
            variant="gradient"
            color="info"
            style={{ margin: 5, width: 180 }}
            onClick={save}
            loading={saving}
            loadingText="saving..."
          >
            <Icon sx={{ fontWeight: "regular" }}>save</Icon>
            &nbsp;save for later
          </MDButton>
        )}

        {action === "view" && (
          <MDButton variant="gradient" color="info" style={{ margin: 5 }} onClick={edit}>
            <Icon sx={{ fontWeight: "regular" }}>edit</Icon>
            &nbsp;edit
          </MDButton>
        )}
        {action !== "view" && (
          <MDButton
            variant="gradient"
            color="primary"
            style={{ margin: 5, width: 160 }}
            onClick={submit}
            loading={submitting}
            loadingText="submitting..."
          >
            submit&nbsp;
            <Icon sx={{ fontWeight: "bold" }}>thumb_up</Icon>
          </MDButton>
        )}
      </MDBox>
    </Card>
  );
};
export default ComplianceSurvey;
