/* eslint-disable react/destructuring-assignment */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// react-quill components
import ReactQuill from "react-quill";

// react-quill styles
import "react-quill/dist/quill.snow.css";

// Custom styles for the MDEditor
import MDEditorRoot from "components/MDEditor/MDEditorRoot";
import { useState } from "react";

function MDEditor(props) {
  const modules = {
    toolbar: [
      [{ size: [] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ color: [] }],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };

  const [focused, setFocused] = useState(false);
  /*
   * Quill editor formats
   * See https://quilljs.com/docs/formats/
   */
  const formats = [
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "color",
  ];

  return (
    <MDEditorRoot
      onFocus={() => {
        setFocused(true);
      }}
      onBlur={() => {
        setFocused(false);
      }}
      focused={focused}
      hideToolbar={props.hideToolbar}
      minHeight={props.minHeight}
      error={props.error}
    >
      <ReactQuill
        theme="snow"
        {...props}
        modules={modules}
        formats={formats}
        // eslint-disable-next-line react/destructuring-assignment
        placeholder={props.placeholder}
      />
    </MDEditorRoot>
  );
}

export default MDEditor;
