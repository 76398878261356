import Card from "@mui/material/Card";
import DataTable from "components/DataTable";

import TimestampCell from "components/DataTable/components/TimestampCell";

import QapiCell from "components/DataTable/components/QapiCell";
import QapiActionCell from "components/DataTable/components/ActionCells/QapiActionCell";
import { useMemo } from "react";

const dataTableData = (rows, isLoading) => ({
  columns: [
    {
      Header: "Title",
      accessor: "title",
      isSorted: false,
      width: "100%",
      Cell: ({ value: [title, problemStatement, color, tags] }) => (
        <QapiCell
          title={title}
          color={color}
          tags={tags}
          problemStatement={problemStatement}
          showMetaData
          isLoading={isLoading}
        />
      ),
    },
    {
      Header: "Start Date",
      accessor: "startDate",
      width: 100,
      Cell: ({ value }) => <TimestampCell timestamp={value} hideTime isLoading={isLoading} />,
    },
    {
      Header: "Next Evaluation",
      accessor: "nextEvalutation",
      width: 120,
      Cell: ({ value }) => <TimestampCell timestamp={value} hideTime isLoading={isLoading} />,
    },
    {
      Header: "Actions",
      accessor: "action",
      width: 100,
      isSorted: false,
      Cell: ({ value }) => <QapiActionCell id={value} isLoading={isLoading} />,
    },
  ],
  rows,
});

const OngoingQapis = ({ data, isLoading }) => {
  let rows = [];

  if (!isLoading) {
    rows = useMemo(
      () =>
        data
          ?.filter((val) => val.status === "SAVED" || val.status === "ON_GOING")
          .map((row) => ({
            title: [row.title, row.problemStatement, row.color, row.tags],
            startDate: row.startDate,
            nextEvalutation: row.nextEvaluationDate,
            action: row.id,
          })),
      [data]
    );
  } else {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 10; i++) {
      rows.push({
        title: ["", "", "", []],
        startDate: 1234,
        nextEvalutation: 1234,
        action: 1,
      });
    }
  }

  if (rows == null) {
    return null;
  }

  return (
    <Card id="past-surveys">
      <DataTable
        table={dataTableData(rows, isLoading)}
        entriesPerPage={{ defaultValue: 5, hide: true }}
        canSearch
        title="On-Going QAPIs"
        isSorted
      />
    </Card>
  );
};

export default OngoingQapis;
