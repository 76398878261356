/* eslint-disable no-nested-ternary */
import colors from "assets/theme/base/colors";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

export function DonutSmallChart({
  isLoading,
  valueCurrent,
  valueMaximum,
  textSize,
  animation = true,
}) {
  const options = {
    aspectRatio: 1,
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
    responsive: true,
    cutout: "85%",
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    plugins: {
      tooltip: {
        enabled: false, // <-- this option disables tooltips
      },
    },
    events: [],
    animation: {
      duration: animation ? 1000 : 0,
    },
  };

  const percent = isLoading ? 0 : valueCurrent / valueMaximum;
  const data = {
    datasets: [
      {
        data: [valueMaximum - valueCurrent, valueCurrent],
        backgroundColor: [
          colors.light.main,
          percent >= 0.7
            ? colors.success.main
            : percent >= 0.4
            ? colors.warning.main
            : colors.error.main,
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <MDBox style={{ width: "90px", height: "90px" }}>
      <MDBox
        display="flex"
        alignItems="center"
        alignContent="center"
        justifyContent="center"
        style={{
          marginTop: "4px",
          position: "absolute",
          height: "90px",
          width: "90px",
          textAlign: "center",
          zIndex: 100,
        }}
      >
        {!isLoading && (
          <MDTypography
            lineHeight="4"
            variant="h6"
            fontWeight="medium"
            color={percent >= 0.7 ? "success" : percent >= 0.4 ? "warning" : "error"}
          >
            <span style={{ fontSize: textSize === "medium" ? 20 : 26 }}>{valueCurrent}</span>
            <span style={{ fontSize: textSize === "medium" ? 14 : 18, color: colors.grey[500] }}>
              /{valueMaximum}
            </span>
          </MDTypography>
        )}
        {isLoading && (
          <MDTypography lineHeight="4" variant="h6" fontWeight="medium" color="light">
            ...
          </MDTypography>
        )}
      </MDBox>
      <Doughnut data={data} options={options} />
    </MDBox>
  );
}
