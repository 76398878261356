import Billing from "pages/billing";
import Icon from "@mui/material/Icon";
import { ROLES } from "utils/utils";
import Dashboard from "pages/Dashboard";
import ComplianceOverview from "pages/compliance/overview";
import QapiOverview from "pages/qapi/overview";
import FiveStarOverview from "pages/fivestar/overview";
import SatisfactionOverview from "pages/satisfaction/overview";
import UsersOverview from "pages/users/overview";
import ResidentsOverview from "pages/residents/overview";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    component: <Dashboard />,
    noCollapse: true,
    icon: <Icon fontSize="medium">dashboard_customize</Icon>,
    allowedRoles: [ROLES.User, ROLES.Corporate, ROLES.Admin],
  },
  {
    type: "collapse",
    name: "Compliance (LTCSP)",
    key: "compliance",
    route: "/compliance",
    component: <ComplianceOverview />,
    noCollapse: true,
    icon: <Icon fontSize="medium">verified</Icon>,
    allowedRoles: [ROLES.User, ROLES.Corporate, ROLES.Admin],
  },
  {
    type: "collapse",
    name: "QAPI",
    key: "qapi",
    route: "/qapi",
    component: <QapiOverview />,
    noCollapse: true,
    icon: <Icon fontSize="medium">inventory</Icon>,
    allowedRoles: [ROLES.User, ROLES.Corporate, ROLES.Admin],
  },
  {
    type: "collapse",
    name: "CMS 5 Star Rating",
    key: "5-star-rating",
    route: "5-star-rating",
    component: <FiveStarOverview />,
    noCollapse: true,
    icon: <Icon fontSize="medium">hotel_class</Icon>,
    allowedRoles: [ROLES.User, ROLES.Corporate, ROLES.Admin],
  },
  {
    type: "collapse",
    name: "Resident Satisfaction",
    key: "resident-satisfaction",
    route: "/resident-satisfaction",
    component: <SatisfactionOverview />,
    noCollapse: true,
    icon: <Icon fontSize="medium">insert_emoticon</Icon>,
    allowedRoles: [ROLES.User, ROLES.Corporate, ROLES.Admin],
  },
  {
    type: "collapse",
    name: "Risk Management",
    key: "risk-management",
    route: "/risk-management",
    component: <Billing />,
    noCollapse: true,
    icon: <Icon fontSize="medium">report</Icon>,
    allowedRoles: [ROLES.User, ROLES.Corporate, ROLES.Admin],
  },
  { type: "divider", key: "divider-0" },
  {
    type: "collapse",
    name: "Residents",
    key: "residents",
    route: "/residents",
    component: <ResidentsOverview />,
    noCollapse: true,
    icon: <Icon fontSize="medium">people_alt</Icon>,
    allowedRoles: [ROLES.User, ROLES.Corporate, ROLES.Admin],
  },
  {
    type: "collapse",
    name: "Users",
    key: "users",
    route: "/users",
    component: <UsersOverview />,
    noCollapse: true,
    icon: <Icon fontSize="medium">person_3</Icon>,
    allowedRoles: [ROLES.Corporate, ROLES.Admin],
  },
  {
    type: "collapse",
    name: "Facilities",
    key: "facilities",
    route: "/facilities",
    component: <Billing />,
    noCollapse: true,
    icon: <Icon fontSize="medium">home_work</Icon>,
    allowedRoles: [ROLES.Corporate],
  },
  { type: "divider", key: "divider-1" },
  {
    type: "collapse",
    name: "Settings",
    key: "settings",
    route: "/settings",
    component: <Billing />,
    noCollapse: true,
    icon: <Icon fontSize="medium">settings</Icon>,
    allowedRoles: [ROLES.User, ROLES.Corporate, ROLES.Admin],
  },
];

export default routes;
