/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
import { Card, Icon } from "@mui/material";
import colors from "assets/theme/base/colors";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import TimelineItem from "components/Timeline/TimelineItem";
import TimelineList from "components/Timeline/TimelineList";

import React, { useRef, useState } from "react";
import MDButton from "components/MDButton";
import dayjs from "dayjs";

const SupportingDocumentsEvaluations = ({ data }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const contentRef = useRef(null);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const getContentHeight = () => {
    if (contentRef.current) {
      return `${contentRef.current.scrollHeight}px`;
    }
    return "0px";
  };

  if (data.id === -1 || data.previousEvalutaions.length === 0) {
    return null;
  }
  return (
    <Card style={{ backgroundColor: colors.grey[300], width: "100%", marginTop: 30 }}>
      <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
        <MDBox
          color="white"
          bgColor="dark"
          variant="gradient"
          borderRadius="xl"
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="1.5rem"
          pl={2}
          pr={2}
          mt={-3}
        >
          <MDTypography variant="body2" color="white" fontSize="xs">
            Previous Evaluations
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox p={3}>
        <MDButton variant="gradient" color="info" onClick={handleToggle} sx={{ width: 120 }}>
          <Icon sx={{ fontWeight: "bold" }}>{isExpanded ? "remove" : "add"}</Icon>
          &nbsp;{isExpanded ? "Collapse" : "Expand"}
        </MDButton>
        <MDBox
          style={{
            overflow: "hidden",
            transition: "height 0.3s",
            height: isExpanded ? getContentHeight() : "0px",
          }}
        >
          <MDBox p={3} ref={contentRef}>
            <TimelineList>
              {data.actualCompletionDate > 0 && (
                <TimelineItem
                  color="success"
                  icon="check"
                  title="QAPI Resolved"
                  dateTime={dayjs
                    .unix(data.actualCompletionDate)
                    .format("DD MMM, YYYY hh:mm A")
                    .toUpperCase()}
                />
              )}
              {data.previousEvalutaions &&
                data.previousEvalutaions.map((evaluation) => (
                  <TimelineItem
                    color="primary"
                    icon="post_add"
                    title="Evaluation Commments"
                    dateTime={dayjs
                      .unix(evaluation.time)
                      .format("DD MMM, YYYY hh:mm A")
                      .toUpperCase()}
                    description={evaluation.comments}
                  />
                ))}

              <TimelineItem
                icon="add"
                title="QAPI Created"
                dateTime={dayjs
                  .unix(data.qapiStartDate)
                  .format("DD MMM, YYYY hh:mm A")
                  .toUpperCase()}
                lastItem
              />
            </TimelineList>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
};

export default React.memo(SupportingDocumentsEvaluations);
