/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function CMSCountCell({ count, total }) {
  return (
    <MDBox display="flex" alignItems="center">
      <MDTypography variant="body2" color="text" mb={0.5} fontWeight="bold">
        {count}
      </MDTypography>
      <MDTypography variant="caption" color="text" mb={0.5}>
        /{total}
      </MDTypography>
    </MDBox>
  );
}

// Typechecking props for the StatusCell
CMSCountCell.propTypes = {
  count: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
};

export default CMSCountCell;
