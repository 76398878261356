import Grid from "@mui/material/Grid";
import React, { useRef } from "react";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import { DonutSmallChart } from "components/charts/DonutSmallChart";
import MDVr from "components/MDVr";

const SatisfactionChart = React.memo(({ score }) => {
  const total = useRef(
    <DonutSmallChart valueCurrent={score.overall} valueMaximum={10} textSize="large" />
  );
  const long = useRef(
    <DonutSmallChart valueCurrent={score.longStay} valueMaximum={10} textSize="medium" />
  );

  const short = useRef(
    <DonutSmallChart valueCurrent={score.shortStay} valueMaximum={10} textSize="medium" />
  );

  return (
    <Grid container>
      <Grid item xs={5}>
        <MDBox display="flex" justifyContent="center" flexDirection="column" alignItems="center">
          <MDBox display="flex">{total.current}</MDBox>
          <MDTypography
            variant="h6"
            fontWeight="medium"
            textAlign="center"
            style={{ fontSize: 15 }}
          >
            Overall Score
          </MDTypography>
        </MDBox>
      </Grid>
      <Grid item xs={1}>
        <MDVr />
      </Grid>
      <Grid item xs={6}>
        <MDBox display="flex" justifyContent="space-around">
          <MDBox display="flex" justifyContent="center" flexDirection="column" alignItems="center">
            {long.current}
            <MDTypography variant="body2" style={{ fontSize: 15 }}>
              Long Term
            </MDTypography>
          </MDBox>
          <MDBox display="flex" justifyContent="center" flexDirection="column" alignItems="center">
            {short.current}
            <MDTypography variant="body2" style={{ fontSize: 15 }}>
              Short Term
            </MDTypography>
          </MDBox>
        </MDBox>
      </Grid>
    </Grid>
  );
});
export default SatisfactionChart;
