/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/prop-types */
import { createContext, useState } from "react";

const ScrollContext = createContext({});

export const ScrollProvider = ({ children }) => {
  const [scrollTo, setScrollTo] = useState({});

  return (
    <ScrollContext.Provider value={{ scrollTo, setScrollTo }}>{children}</ScrollContext.Provider>
  );
};

export default ScrollContext;
