import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import MDTypography from "components/MDTypography";

import useModal from "hooks/useModal";
import { useState } from "react";
import "./confirmModal.css";

const ConfirmModalWithSuccess = ({
  title,
  text,
  htmlText,
  loadingText,
  successTitle,
  successText,
  callback,
  arg,
}) => {
  const { closeModal } = useModal();
  const [step, setStep] = useState("confirming");

  const doAction = async () => {
    setStep("trying");
    const status = await callback(arg);
    if (status === "success") {
      setStep("done");
    } else {
      setStep("errored");
    }
  };
  return (
    <div style={{ width: 400 }}>
      <MDBox display="flex" justifyContent="center" alignItems="center" flexDirection="column">
        {title != null && (
          <MDTypography display="inline" variant="h6" textTransform="capitalize" fontWeight="bold">
            {(step === "confirming" || step === "trying") && title}
            {step === "done" && successTitle}
            {step === "errored" && "Something went wrong."}
          </MDTypography>
        )}
        {(step === "confirming" || step === "trying") && (
          <div className="warnContainer">
            <span className="warnOne" />
            <span className="warnTwo" />
          </div>
        )}
        {step === "done" && (
          <div className="successContainer">
            <div className="successOne" />
            <span className="successTwo" />
            <span className="successThree" />
            <div className="successFour" />
            <div className="successFive" />
            <div className="successSix" />
          </div>
        )}
        {step === "errored" && (
          <div className="errorContainer">
            <span className="errorOne">
              <span className="errorTwo" />
              <span className="errorThree" />
            </span>
          </div>
        )}
        {(step === "done" || step === "errored") && (
          <MDTypography display="inline" variant="body2" textAlign="center">
            {step === "done" && successText}
            {step === "errored" && `An error occured while ${loadingText}. Please try again`}
          </MDTypography>
        )}

        {(step === "confirming" || step === "trying") && text != null && (
          <MDTypography display="inline" variant="body2" textAlign="center">
            {text}
          </MDTypography>
        )}

        {(step === "confirming" || step === "trying") && htmlText != null && (
          <MDTypography display="inline" variant="body2" textAlign="center">
            <div
              style={{ display: "inline-block" }}
              dangerouslySetInnerHTML={{ __html: htmlText }}
            />
          </MDTypography>
        )}

        <MDBox flexDirection="row" display="flex" m={1}>
          {(step === "confirming" || step === "trying") && (
            <>
              <MDBox m={1}>
                <MDButton
                  onClick={() => closeModal({ action: "cancel" })}
                  variant="gradient"
                  color="light"
                  size="medium"
                >
                  No
                </MDButton>
              </MDBox>
              <MDBox m={1}>
                <MDButton
                  loadingText={loadingText}
                  loading={step === "trying"}
                  onClick={() => doAction()}
                  variant="gradient"
                  color="info"
                  size="medium"
                >
                  Yes
                </MDButton>
              </MDBox>
            </>
          )}
          {step !== "confirming" && step !== "trying" && (
            <MDBox m={1}>
              <MDButton
                onClick={() => closeModal({ action: "done" })}
                variant="gradient"
                color="info"
                size="medium"
              >
                okay
              </MDButton>
            </MDBox>
          )}
        </MDBox>
      </MDBox>
    </div>
  );
};

export default ConfirmModalWithSuccess;
