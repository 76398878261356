/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { Icon } from "@mui/material";
import useModal from "hooks/useModal";
import SelectSurveyModal from "components/modal/SelectSurvey";
import useResidents from "hooks/useResidents";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { useEffect } from "react";
import useCompliance from "hooks/useCompliance";
import useBackendError from "hooks/useBackendError";
import { useNavigate } from "react-router-dom";
import PrintModal from "components/modal/PrintModal";

const ComplianceOverviewMain = () => {
  const { residents, setResidents } = useResidents();
  const { surveys, setSurveys } = useCompliance();
  const axios = useAxiosPrivate();
  const { openModal } = useModal();
  const { setError } = useBackendError();
  const navigate = useNavigate();
  useEffect(() => {
    async function fetchData() {
      if (residents == null) {
        try {
          const response = await axios.get("/residents/list", {
            headers: { "Content-Type": "application/json" },
          });
          setResidents(response.data);
        } catch (err) {
          if (!err?.response) {
            setError("Failed to fetch the list of users in this facility. Please try again");
          }
        }
      }
    }
    fetchData();
  }, [axios, residents, setError, setResidents]);

  useEffect(() => {
    async function fetchData() {
      if (surveys == null) {
        try {
          const response = await axios.get("/compliance/list", {
            headers: { "Content-Type": "application/json" },
          });
          setSurveys(response.data);
        } catch (err) {
          if (!err?.response) {
            setError("Failed to fetch the list of pathways. Please try again");
          }
        }
      }
    }
    fetchData();
  }, [axios, setError, setSurveys, surveys]);

  const modalReturnedValue = (result) => {
    if (result.action === "cancel") {
      return;
    }
    navigate("/compliance/survey", { state: result });
  };

  const openModalForStartSurvey = () => {
    openModal(<SelectSurveyModal residents={residents} surveys={surveys} />, modalReturnedValue);
  };

  const openPrint = () => {
    const printVal = surveys.map((val) => ({ name: val.name, id: val.id }));
    openModal(
      <PrintModal type="mockSurvey" title="Print PDFs for Mock Survey" data={printVal} />,
      () => {}
    );
  };

  const navigateToAnalysis = () => {
    navigate("/compliance/analysis");
  };

  return (
    <Card id="compliance-overview">
      <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
        <MDBox
          color="white"
          bgColor="info"
          variant="gradient"
          borderRadius="lg"
          shadow="lg"
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="2.5rem"
          pl={2}
          pr={2}
          mt={-4}
        >
          <MDTypography variant="h6" fontWeight="medium" color="white">
            LTSCP Mock Survey
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        pb={3}
        pl={3}
        pr={3}
        style={{ minHeight: 235 }}
      >
        <MDBox
          pt={4}
          pl={5}
          pr={5}
          pb={2}
          fontSize="1rem"
          style={{ textAlign: "center", width: "100%" }}
        >
          LTCSP Mock Surveys give you an insight into your facility’s potential F-Tags. You can
          complete a Survey Task or Print the Offline Form and complete it later.
        </MDBox>
        <MDBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexWrap="wrap"
          pb={3}
          pl={3}
          pr={3}
        >
          <MDButton
            variant="gradient"
            color="light"
            style={{ margin: 5, width: 210 }}
            onClick={openPrint}
          >
            <Icon sx={{ fontWeight: "bold" }}>print</Icon>
            &nbsp;print survey forms
          </MDButton>

          <MDButton
            variant="gradient"
            color="primary"
            style={{ margin: 5, width: 210 }}
            onClick={openModalForStartSurvey}
          >
            <Icon sx={{ fontWeight: "regular" }}>assignment_turned_in</Icon>
            &nbsp;complete task
          </MDButton>

          <MDButton
            variant="gradient"
            color="info"
            style={{ margin: 5, width: 210 }}
            onClick={navigateToAnalysis}
          >
            <Icon sx={{ fontWeight: "bold" }}>insights</Icon>
            &nbsp;analysis
          </MDButton>
        </MDBox>
      </MDBox>
    </Card>
  );
};

export default ComplianceOverviewMain;
