/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable no-shadow */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import MDBox from "components/MDBox";

import useBackendError from "hooks/useBackendError";
import MDTypography from "components/MDTypography";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Card, FormHelperText, Icon } from "@mui/material";
import MDButton from "components/MDButton";
import { useEffect, useRef, useState } from "react";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import MDInput from "components/MDInput";
import MDHr from "components/MDHr";
import colors from "assets/theme/base/colors";
import Skeleton from "components/skeletons/Skeleton";
import ConfirmModal from "components/modal/ConfirmModal";
import useModal from "hooks/useModal";
import Section from "./Section";
import SectionSkeleton from "./skeleton/SectionSkeleton";
import ReadonlySection from "./readonly/ReadonlySection";

const Template = () => {
  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const grid = 1;

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 20px ${grid}px 20px`,
    ...draggableStyle,
  });

  const getListStyle = () => ({
    padding: grid,
  });

  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [isLoading, setLoading] = useState(true);
  const [isSaving, setSaving] = useState(false);
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState(false);
  const titleRef = useRef();
  const { openModal } = useModal();
  const isChanged = useRef();
  const isReadonly = useRef();
  const [items, setItems] = useState([
    {
      questions: [{}, {}, {}],
    },
    {
      questions: [{}, {}, {}, {}],
    },
    {
      questions: [{}, {}, {}],
    },
  ]);

  const setItemsAndIsChanged = (val) => {
    for (let i = val.length - 1; i >= 0; i--) {
      let sectionError = false;
      val[i].focus = false;
      for (let j = val[i].questions.length - 1; j >= 0; j--) {
        if (val[i].questions[j].questionText == null || val[i].questions[j].questionText === "") {
          sectionError = true;
        }
      }
      if (!sectionError) {
        val[i].blankQuestionError = false;
      }
    }
    setItems(val);
    isChanged.current = true;
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    isChanged.current = true;
    setItems(reorder(items, result.source.index, result.destination.index));
  };

  const { setError } = useBackendError();
  const axios = useAxiosPrivate();
  const navigate = useNavigate();

  const loadTemplate = async () => {
    if (id == null) {
      setItems([]);
      setLoading(false);
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post(`/satisfaction/template?id=${id}`, {
        headers: { "Content-Type": "application/json" },
      });
      setItems(response.data.sections);
      setTitle(response.data.name);
      isReadonly.current = !response.data.editable;
    } catch (err) {
      setError("Failed to fetch from the backend. Please try again");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadTemplate();
  }, []);

  const newSection = () => {
    isChanged.current = true;
    items.push({ id: Math.random(), questions: [] });
    setItems([...items]);
    setLoading(false);
  };

  const cancelConfirm = (prop) => {
    if (prop.action === "confirm") {
      navigate(`/resident-satisfaction/templates`);
    }
  };

  const cancel = () => {
    if (isChanged.current) {
      openModal(
        <ConfirmModal
          buttonLabels={["no", "yes"]}
          icon="warn"
          text="You have unsaved changes. Do you really want to cancel?"
        />,
        cancelConfirm
      );
    } else {
      navigate(`/resident-satisfaction/templates`);
    }
  };

  const save = async () => {
    let isError = false;
    let errorFocus = -1;
    for (let i = items.length - 1; i >= 0; i--) {
      if (items[i].name == null || items[i].name === "") {
        items[i].titleError = true;
        isError = true;
        errorFocus = i;
      }
      if (items[i].questions == null || items[i].questions.length === 0) {
        items[i].noQuestionError = true;
        isError = true;
        errorFocus = i;
      }
      for (let j = items[i].questions.length - 1; j >= 0; j--) {
        if (
          items[i].questions[j].questionText == null ||
          items[i].questions[j].questionText === ""
        ) {
          items[i].blankQuestionError = true;
          isError = true;
          errorFocus = i;
        }
      }
    }
    if (title == null || title === "") {
      isError = true;
      setTitleError(true);
      titleRef.current.focus();
    }
    if (isError && !titleError) {
      items[errorFocus].focus = true;
      setItems([...items]);
    }

    if (isError || titleError) {
      return;
    }

    setSaving(true);
    const data = { sections: items, name: title };
    try {
      const response = await axios.post(
        `/satisfaction/template${id != null ? `?id=${id}` : ""}`,
        data,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      isChanged.current = false;
      setItems(response.data.sections);
      const finalMessage = (
        <>
          <span>Survey template has been successfully saved. </span>
          <a href={`/resident-satisfaction/template?id=${response.data.id}`}>Click Here</a>
          <span> to view it.</span>
        </>
      );
      navigate(`/resident-satisfaction/templates`);
      openModal(
        <ConfirmModal buttonLabels={["okay"]} icon="success" text={finalMessage} />,
        () => {}
      );
    } catch (err) {
      setError("Failed to save to backend. Please try again");
    } finally {
      setSaving(false);
    }
  };

  return (
    <Card id="compliance-overview">
      <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
        <MDBox
          color="white"
          bgColor="info"
          variant="gradient"
          borderRadius="lg"
          shadow="lg"
          display="flex"
          justifyContent="center"
          alignItems="center"
          py={1}
          px={2}
          mt={-4}
          mb={3}
        >
          <MDTypography variant="h6" fontWeight="medium" color="white">
            Resident Satisfaction Template
          </MDTypography>
        </MDBox>
      </MDBox>

      {isLoading && (
        <>
          <MDBox display="flex" justifyContent="center" alignItems="center" pb={2}>
            <MDBox display="flex">
              <MDTypography
                variant="body2"
                fontSize="xs"
                fontWeight="medium"
                style={{ marginTop: 4 }}
              >
                Title
              </MDTypography>
              <MDBox ml={1} width="100%">
                <Skeleton width={300} height={40} />
              </MDBox>
            </MDBox>
          </MDBox>
          <MDBox ml={2} mr={4}>
            {items.map((item, index) => (
              <SectionSkeleton sectionIndex={index} data={items} key={`sec-${index}`} />
            ))}
          </MDBox>
        </>
      )}

      {!isLoading && isReadonly.current && (
        <>
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexWrap="wrap"
            pl={4}
            pb={2}
          >
            <MDBox display="flex">
              <MDTypography
                variant="body2"
                fontSize="xs"
                fontWeight="medium"
                style={{ marginTop: 4 }}
              >
                Title:
              </MDTypography>
              <MDTypography variant="body2" fontSize="xs" style={{ marginTop: 4, marginLeft: 4 }}>
                {title}
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDBox ml={3} mr={3}>
            {items.map((item, index) => (
              <ReadonlySection sectionIndex={index} data={items} key={`sec-${index}`} />
            ))}
          </MDBox>
        </>
      )}

      {!isLoading && !isReadonly.current && (
        <>
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexWrap="wrap"
            pl={4}
            pb={2}
          >
            <MDBox display="flex">
              <MDTypography
                variant="body2"
                fontSize="xs"
                fontWeight="medium"
                style={{ marginTop: 4 }}
              >
                Title
              </MDTypography>
              <MDBox ml={1} width="100%">
                <MDInput
                  style={{ width: 300, marginRight: 60 }}
                  placeholder="Enter a Title.."
                  value={title}
                  onChange={(event) => {
                    setTitle(event.target.value);
                    setTitleError(false);
                    isChanged.current = true;
                  }}
                  error={titleError}
                  id="title"
                  inputRef={titleRef}
                />
                <FormHelperText error>{titleError ? "* Please enter a Title" : ""}</FormHelperText>
              </MDBox>
            </MDBox>
          </MDBox>
          <MDBox>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {items.map((item, index) => (
                      <Draggable key={item.id} draggableId={`id-${item.id}`} index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                          >
                            <Section
                              isDragging={snapshot.isDragging}
                              sectionIndex={index}
                              data={items}
                              setData={setItemsAndIsChanged}
                            >
                              <div {...provided.dragHandleProps}>
                                <Icon fontSize="default" style={{ color: "white", marginTop: 8 }}>
                                  drag_indicator
                                </Icon>
                              </div>
                            </Section>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </MDBox>
        </>
      )}

      {items.length === 0 && (
        <MDBox
          px={4}
          pb={1}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <MDHr color={colors.grey[300]} style={{ width: 600, marginBottom: 16 }} />
          <MDTypography variant="body2" color="text" fontSize="xs">
            Start by adding a new section. You can add survey questions to each section.
          </MDTypography>
        </MDBox>
      )}

      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        pb={3}
        pl={3}
        pr={3}
      >
        <MDButton variant="gradient" color="light" style={{ margin: 5 }} onClick={cancel}>
          {isReadonly.current ? "back" : "cancel"}
        </MDButton>
        {!isReadonly.current && (
          <MDButton
            variant="gradient"
            color={items.length === 0 ? "primary" : "info"}
            onClick={newSection}
            style={{ margin: 5, width: 160 }}
          >
            New Section&nbsp;
            <Icon sx={{ fontWeight: "bold" }}>add</Icon>
          </MDButton>
        )}

        {!isReadonly.current && items.length !== 0 && (
          <MDButton
            variant="gradient"
            color="primary"
            onClick={save}
            style={{ margin: 5, width: 160 }}
            loadingText="saving..."
            loading={isSaving}
          >
            Save&nbsp;
            <Icon sx={{ fontWeight: "bold" }}>save</Icon>
          </MDButton>
        )}
      </MDBox>
    </Card>
  );
};
export default Template;
