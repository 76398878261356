/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router components
import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
import colors from "assets/theme/base/colors";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples
import Breadcrumbs from "components/Breadcrumbs";
import NotificationItem from "components/Items/NotificationItem";

import Select from "react-select";
import useAuth from "hooks/useAuth";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarDesktopMenu,
  navbarTopLine,
  navbarBottomLine,
  navbarMobileMenu,
  facilitySelectControl,
  facilitySelectOption,
  facilitySelectSingleValue,
  facilitySelectArrow,
} from "components/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 PRO React context
import { useMaterialUIController, setTransparentNavbar, setMiniSidenav } from "context";
import useSize from "hooks/useSize";
import useFacility from "hooks/useFacility";

function DashboardNavbar({ absolute, light, isMini }) {
  const { auth } = useAuth();
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);
  const { grey } = colors;
  const { setFacility } = useFacility();
  const handleChange = (val) => {
    // setProfile(); in backend
    setFacility({ id: val.value, name: val.label, selected: true });
  };

  let selectedFacilityIndex = 0;

  const options = auth?.profile?.facilities?.map((val, index) => {
    if (val.selected) {
      selectedFacilityIndex = index;
    }
    return { value: val.id, label: val.name };
  });

  const { scrollY } = useSize();

  useEffect(() => {
    const selFac = options[selectedFacilityIndex];
    setFacility({ id: selFac.value, name: selFac.label, selected: true });
  }, []);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // Call the handleTransparentNavbar function to set the state with the initial value.
    setTransparentNavbar(dispatch, false);
  }, [dispatch, fixedNavbar, scrollY]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem icon={<Icon>email</Icon>} title="Check new messages" />
      <NotificationItem icon={<Icon>podcasts</Icon>} title="Manage Podcast sessions" />
      <NotificationItem icon={<Icon>shopping_cart</Icon>} title="Payment successfully completed" />
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  const colourStyles = {
    control: (styles) => facilitySelectControl(styles),
    option: (styles) => facilitySelectOption(styles),
    singleValue: (styles) => facilitySelectSingleValue(styles),
    dropdownIndicator: (styles) => facilitySelectArrow(styles),
  };

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox sx={(theme) => navbarTopLine(theme)}>
          <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme)}>
            <MDBox style={{ paddingTop: 6 }}>
              <IconButton
                sx={navbarDesktopMenu}
                onClick={handleMiniSidenav}
                size="small"
                disableRipple
              >
                <Icon fontSize="medium" sx={iconsStyle}>
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu" : "menu_open"}
                </Icon>
              </IconButton>
            </MDBox>
            <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
          </MDBox>
          {isMini ? null : (
            <MDBox sx={(theme) => navbarRow(theme)}>
              <MDButton variant="outlined" color="info" size="small">
                HELP
              </MDButton>
            </MDBox>
          )}
        </MDBox>
        <MDBox sx={(theme) => navbarBottomLine(theme)}>
          <Select
            onChange={handleChange}
            options={options}
            isSearchable={false}
            styles={colourStyles}
            defaultValue={options[selectedFacilityIndex]}
            theme={(theme) => ({
              ...theme,
              borderRadius: 4,
              colors: {
                ...theme.colors,
                primary25: grey[300],
                primary: grey[500], // selected
                neutral0: grey[100], // option body
                primary50: grey[400], // click
              },
            })}
          />
          <IconButton
            size="small"
            disableRipple
            color="inherit"
            sx={navbarIconButton}
            aria-controls="notification-menu"
            aria-haspopup="true"
            variant="contained"
            onClick={handleOpenMenu}
          >
            <MDBadge badgeContent={9} color="error" size="xs" circular>
              <Icon sx={iconsStyle}>notifications</Icon>
            </MDBadge>
          </IconButton>
          {renderMenu()}
        </MDBox>
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
