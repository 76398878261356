/* eslint-disable react/no-array-index-key */
/* eslint-disable no-plusplus */
import MDBox from "components/MDBox";
import BaseLayout from "components/BaseLayout";

import MDTypography from "components/MDTypography";

import { Card } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import useBackendError from "hooks/useBackendError";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FtagCell from "components/DataTable/components/FtagCell";
import ScopeCell from "components/DataTable/components/ScopeCell";
import StatusCell from "components/DataTable/components/StatusCell";
import CMSCountCell from "components/DataTable/components/CMSCountCell";
import CMSAnalysisActionCell from "components/DataTable/components/ActionCells/CMSAnalysisActionCell";
import Grid from "@mui/system/Unstable_Grid/Grid";
import Tooltip from "@mui/material/Tooltip";
import { pdf } from "@react-pdf/renderer";
import colors from "assets/theme/base/colors";
import Skeleton from "components/skeletons/Skeleton";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import { useNavigate } from "react-router-dom";
import FileSaver from "file-saver";
import PrintDetails from "./print/Details";
import PrintSummary from "./print/Summary";
import Details from "./Details";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: colors.grey[400],
}));

const ComplianceAnalysis = () => {
  let rows = [];

  const myPdf = pdf([]);
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  const { setError } = useBackendError();
  const data = useRef();
  const axios = useAxiosPrivate();
  const [expanded, setExpanded] = useState(-1);
  const [isLoadingDetailsPrint, setLoadingDetailsPrint] = useState(false);
  const [isLoadingSummaryPrint, setLoadingSummaryPrint] = useState(false);

  const loadData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/compliance/analysis/list`, {
        headers: { "Content-Type": "application/json" },
      });
      data.current = response.data;
    } catch (err) {
      setError("Failed to fetch from the backend. Please try again");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  if (!isLoading) {
    rows = data.current.map((row) => ({
      ftag: row.ftag,
      description: row.description,
      scope: row.scope ?? "?",
      citation: row.citation,
      count: row.count,
      total: row.total,
      qapiId: row.qapiId,
      details: row.details,
    }));
  } else {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 10; i++) {
      // eslint-disable-next-line no-new-object
      rows.push(new Object());
    }
  }

  if (rows == null) {
    return null;
  }

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const callback = (val, index) => {
    rows[index].scope = val;
  };

  const doBack = () => {
    navigate("/compliance");
  };

  const printPdf = async (details) => {
    try {
      if (details) {
        myPdf.updateContainer(<PrintDetails data={rows} />);
        const blob = await myPdf.toBlob();
        FileSaver.saveAs(blob, `Compliance Analysis Details Report.pdf`);
      } else {
        myPdf.updateContainer(<PrintSummary data={rows} />);
        const blob = await myPdf.toBlob();
        FileSaver.saveAs(blob, `Compliance Analysis Summary Report.pdf`);
      }
    } catch (err) {
      setError("Failed to generate PDF. Please try again");
    } finally {
      setLoadingDetailsPrint(false);
      setLoadingSummaryPrint(false);
    }
  };

  const doPrintSummary = () => {
    setLoadingSummaryPrint(true);
    printPdf(false);
  };
  const doPrintDetails = () => {
    setLoadingDetailsPrint(true);
    printPdf(true);
  };
  return (
    <Card id="compliance-overview" style={{ minWidth: 900 }}>
      <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
        <MDBox
          color="white"
          bgColor="info"
          variant="gradient"
          borderRadius="lg"
          shadow="lg"
          display="flex"
          justifyContent="center"
          alignItems="center"
          py={1}
          px={2}
          mt={-4}
          mb={3}
        >
          <MDTypography variant="h6" fontWeight="medium" color="white">
            Mock Survey Analysis
          </MDTypography>
        </MDBox>
      </MDBox>
      {rows.map((row, index) => (
        <Accordion
          expanded={expanded === index}
          onChange={handleChange(index)}
          sx={{ backgroundColor: expanded === index ? colors.grey[400] : "white" }}
          key={`${index}-accordian`}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Tooltip title="Click to see the details" followCursor enterDelay={1000}>
              <Grid container width="100%">
                <Grid item="true" xs={5} p={1}>
                  {isLoading && (
                    <Skeleton classes="title" width={Math.floor(Math.random() * 200 + 100)} />
                  )}
                  {!isLoading && <FtagCell ftag={row.ftag} description={row.description} />}
                </Grid>
                <Grid item="true" xs={1.5} p={1}>
                  {isLoading && <Skeleton classes="title" width={120} />}
                  {!isLoading && (
                    <ScopeCell
                      scope={row.scope}
                      ftag={row.ftag}
                      index={index}
                      callback={callback}
                    />
                  )}
                </Grid>
                <Grid item="true" xs={2} p={1}>
                  {isLoading && <Skeleton classes="title" width={100} />}
                  {!isLoading && (
                    <StatusCell status={row.citation} description={false} showTooltip />
                  )}
                </Grid>
                <Grid item="true" xs={1.5} pt={2}>
                  {isLoading && <Skeleton classes="title" width={40} />}
                  {!isLoading && <CMSCountCell count={row.count} total={row.total} />}
                </Grid>
                <Grid item="true" xs={2}>
                  {isLoading && <Skeleton classes="title" width={130} />}
                  {!isLoading && <CMSAnalysisActionCell ftag={row.ftag} qapiId={row.qapiId} />}
                </Grid>
              </Grid>
            </Tooltip>
          </AccordionSummary>
          <AccordionDetails>
            <MDBox
              style={{ backgroundColor: "white", borderRadius: 8, border: "1px solid #d9d9d9" }}
            >
              {!isLoading && expanded === index && <Details data={row.details} />}
            </MDBox>
          </AccordionDetails>
        </Accordion>
      ))}
      <MDBox display="flex" justifyContent="center" alignItems="center" flexWrap="wrap" p={4}>
        <MDButton onClick={doBack} variant="gradient" color="light" style={{ margin: 5 }}>
          back
        </MDButton>

        <MDButton
          onClick={doPrintSummary}
          variant="gradient"
          color="primary"
          style={{ margin: 5, width: 180 }}
          loading={isLoadingSummaryPrint}
          loadingText="generating..."
        >
          print summary&nbsp;
          <Icon sx={{ fontWeight: "regular" }}>print</Icon>
        </MDButton>
        <MDButton
          onClick={doPrintDetails}
          variant="gradient"
          color="primary"
          style={{ margin: 5, width: 180 }}
          loading={isLoadingDetailsPrint}
          loadingText="generating..."
        >
          print details&nbsp;
          <Icon sx={{ fontWeight: "regular" }}>print</Icon>
        </MDButton>
      </MDBox>
    </Card>
  );
};
export default ComplianceAnalysis;
