/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import Skeleton from "components/skeletons/Skeleton";
import StarRating from "components/StarRating/StarRating";
import { colors } from "@mui/material";

function RatingCell({ rating, isLarge = false, isLoading }) {
  const content = (
    <MDBox display="flex" alignItems="center" width="100%">
      <MDBox mr={1} display="flex" flexDirection="column" width="100%" alignItems="center">
        {!isLoading && (
          <>
            <StarRating rating={rating} color="#FFA726" fontSize={isLarge ? "medium" : "inherit"} />
            <MDTypography
              variant="body2"
              style={{ fontSize: 12, color: colors.grey[600] }}
              fontWeight={isLarge ? "bold" : "regular"}
            >
              {rating} Star{rating > 1 ? "s" : ""}
            </MDTypography>
          </>
        )}
        {isLoading && (
          <>
            <Skeleton width={100} height={isLarge ? 20 : 10} />
            <Skeleton width={100} height={8} />
          </>
        )}
      </MDBox>
    </MDBox>
  );

  return content;
}

// Typechecking props for the StatusCell
RatingCell.propTypes = {
  rating: PropTypes.number.isRequired,
};

export default RatingCell;
