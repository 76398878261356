import Grid from "@mui/material/Grid";

import { useEffect, useRef, useState } from "react";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import useAuth from "hooks/useAuth";

import useBackendError from "hooks/useBackendError";

import { useCache } from "hooks/useCache";
import QapiOverviewMain from "./QapiOverviewMain";
import OngoingQapis from "./OngoingQapis";
import CompletedQapis from "./CompletedQapis";

const QapiOverview = () => {
  const [isLoading, setLoading] = useState(true);
  const data = useRef();
  const axios = useAxiosPrivate();
  const { auth, setAuth } = useAuth();
  const { timeline } = auth.profile;
  const { setError } = useBackendError();
  const { getItem, setItem } = useCache();
  const loadData = async () => {
    setLoading(true);
    data.current = getItem("qapi-overview");
    if (data.current !== null) {
      setLoading(false);
      return;
    }

    try {
      const response = await axios.get(
        `/qapi/list?type=${timeline.type}&startDate=${timeline.startDate}&endDate=${timeline.endDate}&quarter=${timeline.quarter}&year=${timeline.year}`,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      data.current = response.data;
      setItem("qapi-overview", response.data);
    } catch (err) {
      setError("Failed to fetch from the backend. Please try again");
    } finally {
      setLoading(false);
    }
  };

  const updateTimeline = (val) => {
    const newAuth = auth;
    newAuth.profile.timeline = val;
    setAuth(newAuth);
    loadData();
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <Grid container spacing={3} mb={3}>
        <Grid item xs={12}>
          <QapiOverviewMain data={data.current} isLoading={isLoading} />
        </Grid>
      </Grid>
      <Grid container spacing={3} mb={3}>
        <Grid item xs={12}>
          <OngoingQapis data={data.current} setTimeline={updateTimeline} isLoading={isLoading} />
        </Grid>
      </Grid>
      <Grid container spacing={3} mb={3}>
        <Grid item xs={12}>
          <CompletedQapis data={data.current} setTimeline={updateTimeline} isLoading={isLoading} />
        </Grid>
      </Grid>
    </>
  );
};
export default QapiOverview;
