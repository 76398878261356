import "./skeleton.css";

const Skeleton = ({ classes, width, margin, padding, height, borderRadius = 6, dark = false }) => {
  const classNames = dark
    ? `skeleton-dark ${classes} animate-pulse`
    : `skeleton ${classes} animate-pulse`;
  return <div className={classNames} style={{ width, margin, padding, height, borderRadius }} />;
};

export default Skeleton;
