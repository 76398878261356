import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function FtagCell({ ftag, description }) {
  return (
    <MDBox display="flex" alignItems="center">
      <MDBox
        mr={1}
        bgColor="dark"
        borderRadius="lg"
        shadow="lg"
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="2rem"
        pl={2}
        pr={2}
      >
        <MDTypography variant="caption" color="white" fontWeight="bold">
          {ftag}
        </MDTypography>
      </MDBox>
      <MDBox mr={1} display="flex" flexDirection="column">
        <MDTypography variant="body2" color="text">
          {description}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

// Typechecking props for the FtagCell
FtagCell.propTypes = {
  ftag: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default FtagCell;
