import { Grid, Icon, MenuItem, Select, TextField } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import MDTypography from "components/MDTypography";
import dayjs from "dayjs";
import useAuth from "hooks/useAuth";
import useModal from "hooks/useModal";
import { useState } from "react";

const SavedFivestarModal = () => {
  const { auth } = useAuth();
  const { closeModal } = useModal();

  const [selectionType, setSelectionType] = useState(auth.profile.timeline.type);
  const [quarter, setQuarter] = useState(auth.profile.timeline.quarter);

  const years = [2023, 2022, 2021];
  const [year, setYear] = useState(auth.profile.timeline.year);

  const [startDate, setStartDate] = useState(
    dayjs.unix(auth.profile.timeline.startDate).format("MM/DD/YYYY")
  );
  const [endDate, setEndDate] = useState(
    dayjs.unix(auth.profile.timeline.endDate).format("MM/DD/YYYY")
  );

  return (
    <MDBox width={{ xs: 500 }}>
      <div className="warnContainer">
        <span className="warnOne" />
        <span className="warnTwo" />
      </div>
      <MDBox display="flex" justifyContent="center">
        <MDTypography display="inline" variant="h6" textTransform="capitalize" fontWeight="bold">
          Saved Draft Exists..
        </MDTypography>
      </MDBox>
      <MDBox display="flex" justifyContent="center">
        <MDTypography display="inline" variant="body2" textAlign="center">
          Starting a new one would overwrite the existing saved draft.
        </MDTypography>
      </MDBox>
      <MDBox display="flex" justifyContent="center" mt={1}>
        <MDBox m={0.5}>
          <MDButton
            onClick={() => closeModal({ action: "cancel" })}
            variant="gradient"
            color="light"
            size="medium"
          >
            cancel
          </MDButton>
        </MDBox>

        <MDBox m={0.5}>
          <MDButton
            onClick={() => closeModal({ action: "new" })}
            variant="gradient"
            color="info"
            size="medium"
          >
            start new
          </MDButton>
        </MDBox>
        <MDBox m={0.5}>
          <MDButton
            onClick={() => closeModal({ action: "saved" })}
            variant="gradient"
            color="primary"
            size="medium"
          >
            continue saved
          </MDButton>
        </MDBox>
      </MDBox>
    </MDBox>
  );
};

export default SavedFivestarModal;
