/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import { FormHelperText, Grid, Icon } from "@mui/material";
import "./confirmModal.css";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import useModal from "hooks/useModal";
import { useState } from "react";

import MDBox from "components/MDBox";

import useAxiosPrivate from "hooks/useAxiosPrivate";
import MDDropzone from "components/MDDropzone";
import AttachedDocument from "components/AttachedDocument";
import Scrollbars from "react-custom-scrollbars";
import colors from "assets/theme/base/colors";
import useBackendError from "hooks/useBackendError";
import MDSDetails from "pages/residents/mds/MDSDetails";

const UploadMdsModal = () => {
  const [step, setStep] = useState(0);
  const [uploadResult, setUploadResult] = useState();
  const [isError, setIsError] = useState(false);
  const axios = useAxiosPrivate();
  const { openModal, closeModal } = useModal();
  const [uploadedFiles, setUpoadedFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const { setError } = useBackendError();
  const [mdsIndex, setMdsIndex] = useState();

  const onFilesChanged = (files) => {
    const newFiles = uploadedFiles.concat(files);
    setUpoadedFiles(newFiles);
    setIsError(false);
  };

  const details = () => {
    setStep(2);
  };

  const goToDetails = (index) => {
    setMdsIndex(index);
    setStep(3);
  };

  const upload = async () => {
    if (uploadedFiles.length === 0) {
      setIsError(true);
      return;
    }
    setUploading(true);
    try {
      const res = await axios.post("/mds/upload", uploadedFiles, {
        headers: { "Content-Type": "application/json" },
      });
      setUploadResult(res.data);
      setStep(1);
    } catch (err) {
      setError("Failed to fetch from the backend. Please try again");
    } finally {
      setUploading(false);
    }
  };

  if (step === 1) {
    return (
      <div style={{ minWidth: 400 }}>
        <MDBox display="flex" justifyContent="center" alignItems="center" flexDirection="column">
          <MDTypography display="inline" variant="h6" textTransform="capitalize" fontWeight="bold">
            Upload Status
          </MDTypography>
          {uploadResult?.total === 1 && uploadResult?.success === 0 && (
            <>
              <div className="errorContainer">
                <span className="errorOne">
                  <span className="errorTwo" />
                  <span className="errorThree" />
                </span>
              </div>
              <MDTypography display="inline" variant="body2" textAlign="center">
                MSD file upload failed. {uploadResult.mds[0].uploadFailedReason}
              </MDTypography>
            </>
          )}
          {uploadResult?.total === 1 && uploadResult?.success === 1 && (
            <>
              <div className="successContainer">
                <div className="successOne" />
                <span className="successTwo" />
                <span className="successThree" />
                <div className="successFour" />
                <div className="successFive" />
                <div className="successSix" />
              </div>
              <MDTypography display="inline" variant="body2" textAlign="center">
                MDS file uploaded successfully.
              </MDTypography>
            </>
          )}
          {uploadResult?.total > 1 && (
            <>
              {uploadResult.success !== 0 && uploadResult.success !== uploadResult.total && (
                <div className="warnContainer">
                  <span className="warnOne" />
                  <span className="warnTwo" />
                </div>
              )}
              {uploadResult.success === uploadResult.total && (
                <div className="successContainer">
                  <div className="successOne" />
                  <span className="successTwo" />
                  <span className="successThree" />
                  <div className="successFour" />
                  <div className="successFive" />
                  <div className="successSix" />
                </div>
              )}
              {uploadResult.success === 0 && (
                <div className="errorContainer">
                  <span className="errorOne">
                    <span className="errorTwo" />
                    <span className="errorThree" />
                  </span>
                </div>
              )}
              <MDTypography display="inline" variant="body2" textAlign="center">
                {uploadResult.success} out of {uploadResult.total} files{" "}
                {uploadResult.success === 1 ? "was" : "were"} uploaded successfully.
              </MDTypography>
            </>
          )}
          <MDBox flexDirection="row" display="flex" m={1}>
            <MDBox m={1}>
              {!(uploadResult?.total === 1 && uploadResult?.success === 0) && (
                <MDButton
                  onClick={details}
                  variant="gradient"
                  color="info"
                  size="medium"
                  style={{ margin: 5 }}
                >
                  details
                </MDButton>
              )}
              <MDButton
                onClick={() => closeModal({ action: "done" })}
                variant="gradient"
                color="primary"
                size="medium"
                style={{ margin: 5 }}
              >
                done
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </div>
    );
  }

  if (step === 2) {
    return (
      <div style={{ minWidth: 600 }}>
        <MDBox display="flex" justifyContent="center" alignItems="center" flexDirection="column">
          <MDTypography
            display="inline"
            variant="h6"
            textTransform="capitalize"
            fontWeight="bold"
            style={{ marginBottom: 10 }}
          >
            Upload Details
          </MDTypography>
          <Scrollbars
            style={{
              height:
                uploadResult.mds.length === 1 ? 66 : uploadResult.mds.length === 2 ? 150 : 210,
              backgroundColor: colors.grey[200],
              borderRadius: 8,
            }}
            renderTrackHorizontal={({ style, ...props }) => <div />}
            renderTrackVertical={({ style, ...props }) => (
              <div className="scrollbar-regular-vertical-track" {...props} />
            )}
            renderThumbVertical={({ style, ...props }) => (
              <div
                style={{
                  ...style,
                  backgroundColor: "rgba(0, 0, 0, 0.2)",
                  borderRadius: 8,
                  width: 6,
                  cursor: "pointer",
                }}
                {...props}
              />
            )}
          >
            {uploadResult.mds.map((res, index) => (
              <>
                {res.uploadFailedReason != null && (
                  <MDBox
                    borderRadius="lg"
                    style={{ border: "1px solid red", minHeight: 50, backgroundColor: "white" }}
                    display="flex"
                    alignItems="center"
                    p={1}
                    mx={2}
                    my={1}
                  >
                    <MDBox display="flex">
                      <Icon fontSize="small" color="error" style={{ marginRight: 5 }}>
                        error
                      </Icon>
                      <MDTypography display="inline" variant="body2" style={{ fontSize: 14 }}>
                        {res.filename}{" "}
                        <span style={{ color: "red" }}>- {res.uploadFailedReason}</span>
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                )}
                {res.uploadFailedReason == null && (
                  <MDBox
                    borderRadius="lg"
                    style={{ border: "1px solid green", minHeight: 50, backgroundColor: "white" }}
                    display="flex"
                    p={1}
                    alignItems="center"
                    justifyContent="space-between"
                    mx={2}
                    my={1}
                  >
                    <MDBox display="flex">
                      <Icon fontSize="small" color="success" style={{ marginRight: 5 }}>
                        check
                      </Icon>
                      <MDTypography display="inline" variant="body2" style={{ fontSize: 14 }}>
                        {res.filename}
                      </MDTypography>
                    </MDBox>
                    <MDButton
                      onClick={() => goToDetails(index)}
                      variant="gradient"
                      color="info"
                      size="small"
                    >
                      details
                    </MDButton>
                  </MDBox>
                )}
              </>
            ))}
          </Scrollbars>
          <MDBox flexDirection="row" display="flex" m={1}>
            <MDBox m={1}>
              <MDButton
                onClick={() => closeModal({ action: "done" })}
                variant="gradient"
                color="primary"
                size="medium"
              >
                done
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </div>
    );
  }

  if (step === 3) {
    return (
      <div style={{ minWidth: 600 }}>
        <MDBox display="flex" justifyContent="center" alignItems="center" flexDirection="column">
          <MDTypography display="inline" variant="h6" textTransform="capitalize" fontWeight="bold">
            Upload Status
          </MDTypography>
          <MDSDetails data={uploadResult.mds[mdsIndex]} />
          <MDBox flexDirection="row" display="flex">
            <MDButton
              onClick={details}
              variant="gradient"
              color="info"
              size="medium"
              style={{ margin: 5 }}
            >
              BACK
            </MDButton>

            <MDButton
              onClick={() => closeModal({ action: "done" })}
              variant="gradient"
              color="primary"
              size="medium"
              style={{ margin: 5 }}
            >
              done
            </MDButton>
          </MDBox>
        </MDBox>
      </div>
    );
  }

  return (
    <div style={{ width: 600 }}>
      <MDBox display="flex" flexDirection="column" textAlign="center">
        <MDTypography
          display="inline"
          variant="h6"
          textTransform="capitalize"
          fontWeight="bold"
          textAlign="center"
        >
          Upload MDS
        </MDTypography>
      </MDBox>
      <Grid container spacing={1} mt={1} style={{ marginLeft: -5 }}>
        <MDDropzone
          options={{
            addRemoveLinks: true,
            uploadMultiple: true,
            dictDefaultMessage: "Click or Drag and drop files here",
          }}
          onFilesChangedCallback={onFilesChanged}
        />
      </Grid>
      <FormHelperText error>
        {isError ? "* Please select at least one file to upload" : ""}
      </FormHelperText>

      {uploadedFiles?.length !== 0 && uploadedFiles?.length <= 4 && (
        <MDBox
          display="flex"
          flexWrap="wrap"
          style={{
            backgroundColor: colors.grey[100],
            borderRadius: 8,
            marginTop: 10,
          }}
        >
          {uploadedFiles.map((file, index) => (
            <MDBox m={1} width={265}>
              <AttachedDocument
                key={`files-${index}`}
                file={file}
                deleteMe={() => {}}
                undoDelete={() => {}}
                hideMetadata
              />
            </MDBox>
          ))}
        </MDBox>
      )}
      {uploadedFiles?.length > 4 && (
        <Scrollbars
          style={{
            height: 140,
            backgroundColor: colors.grey[100],
            borderRadius: 8,
            marginTop: 10,
          }}
          renderTrackHorizontal={({ style, ...props }) => <div />}
          renderTrackVertical={({ style, ...props }) => (
            <div className="scrollbar-regular-vertical-track" {...props} />
          )}
          renderThumbVertical={({ style, ...props }) => (
            <div
              style={{
                ...style,
                backgroundColor: "rgba(0, 0, 0, 0.2)",
                borderRadius: 8,
                width: 6,
                cursor: "pointer",
              }}
              {...props}
            />
          )}
        >
          <Grid container p={1} spacing={1}>
            {uploadedFiles !== null &&
              uploadedFiles.map((file, index) => (
                <Grid item xs={6} key={`attachedcoc-${index}`}>
                  <AttachedDocument
                    key={`files-${index}`}
                    file={file}
                    deleteMe={() => {}}
                    hideMetadata
                  />
                </Grid>
              ))}
          </Grid>
        </Scrollbars>
      )}
      <MDBox
        px={2}
        pb={2}
        mt={2}
        display="flex"
        textAlign="center"
        alignItems="center"
        justifyContent="center"
      >
        <MDBox mr={1}>
          <MDButton
            onClick={() => closeModal({ action: "cancel" })}
            variant="gradient"
            color="light"
            size="medium"
            m={1}
          >
            cancel
          </MDButton>
        </MDBox>
        <MDBox mr={1}>
          <MDButton
            onClick={upload}
            variant="gradient"
            color="primary"
            size="medium"
            loading={false}
            loadingText="processing..."
            style={{ width: 160 }}
          >
            submit
          </MDButton>
        </MDBox>
      </MDBox>
    </div>
  );
};

export default UploadMdsModal;
