/* eslint-disable no-param-reassign */
import { DesktopDatePicker } from "@mui/x-date-pickers";
import colors from "assets/theme/base/colors";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { Card, TextField } from "@mui/material";
import MDEditor from "components/MDEditor";
import { useEffect, useState } from "react";
import Skeleton from "components/skeletons/Skeleton";

const DetailsBaseSkeleton = ({ isMobile, isLarge }) => (
  <Card style={{ backgroundColor: colors.grey[300], width: "100%", marginTop: 30 }}>
    <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
      <MDBox
        color="white"
        bgColor="dark"
        variant="gradient"
        borderRadius="xl"
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="1.5rem"
        pl={2}
        pr={2}
        mt={-3}
      >
        <MDTypography variant="body2" color="white" fontSize="xs">
          Details
        </MDTypography>
      </MDBox>
    </MDBox>
    <MDBox display="flex" justifyContent="center" alignItems="center" flexWrap="wrap" p={3}>
      <Grid container mb={2} spacing={3}>
        <Grid item xs={12} lg={6}>
          <MDTypography variant="body2" fontSize="xs">
            Title
          </MDTypography>
          <Skeleton width="200px" height="30px" dark />
        </Grid>
        <Grid item xs={12} lg={6} pr={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={5}>
              <MDTypography variant="body2" fontSize="xs">
                Start Date
              </MDTypography>
              <Skeleton width="100px" height="30px" dark />
            </Grid>
            <Grid item xs={12} md={6} lg={7} textAlign={isLarge ? "right" : "left"}>
              <MDTypography variant="body2" fontSize="xs">
                Estimated Completion Date
              </MDTypography>
              <Skeleton width="100px" height="30px" dark />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <MDTypography variant="body2" fontSize="xs">
            Problem Statement
          </MDTypography>
          <Skeleton width="100%" height="100px" dark />
        </Grid>
        <Grid item xs={12} md={6}>
          <MDTypography variant="body2" fontSize="xs">
            Root Cause Analysis
          </MDTypography>
          <Skeleton width="100%" height="100px" dark />
        </Grid>
      </Grid>
    </MDBox>
  </Card>
);

export default DetailsBaseSkeleton;
