/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/prop-types */
import { createContext, useState } from "react";

const FacilityContext = createContext({});

export const FacilityProvider = ({ children }) => {
  const [facility, setFacility] = useState({});

  return (
    <FacilityContext.Provider value={{ facility, setFacility }}>
      {children}
    </FacilityContext.Provider>
  );
};

export default FacilityContext;
