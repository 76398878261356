import { ROLES } from "utils/utils";
import ComplianceSurvey from "pages/compliance/survey";
import ComplianceAnalysis from "pages/compliance/analysis";
import QapiDetails from "pages/qapi/details";
import FivestarCalculation from "pages/fivestar/calculation";
import Score from "pages/fivestar/calculation/Score";
import Simulator from "pages/fivestar/calculation/Simulator";
import SatisfactionSurvey from "pages/satisfaction/survey";
import Templates from "pages/satisfaction/templates";
import Template from "pages/satisfaction/templates/Template";
import UserDetail from "pages/users/user/UserDetail";
import MDS from "pages/residents/mds";
import Resident from "components/Resident";
import ResidentDetail from "pages/residents/details";

const innerRoutes = [
  {
    name: "Compliance",
    key: "compliance/survey",
    route: "/compliance/survey",
    component: <ComplianceSurvey />,
    allowedRoles: [ROLES.User, ROLES.Corporate, ROLES.Admin],
  },
  {
    name: "Compliance Analysis",
    key: "compliance/analysis",
    route: "/compliance/analysis",
    component: <ComplianceAnalysis />,
    allowedRoles: [ROLES.User, ROLES.Corporate, ROLES.Admin],
  },
  {
    name: "QAPI Details",
    key: "qapi/details",
    route: "/qapi/details",
    component: <QapiDetails />,
    allowedRoles: [ROLES.User, ROLES.Corporate, ROLES.Admin],
  },
  {
    name: "5 Star Rating",
    key: "5-star-rating/calculate",
    route: "/5-star-rating/calculate",
    component: <FivestarCalculation />,
    allowedRoles: [ROLES.User, ROLES.Corporate, ROLES.Admin],
  },
  {
    name: "5 Star Rating",
    key: "5-star-rating/score",
    route: "/5-star-rating/score",
    component: <Score />,
    allowedRoles: [ROLES.User, ROLES.Corporate, ROLES.Admin],
  },
  {
    name: "5 Star Rating",
    key: "5-star-rating/simulator",
    route: "/5-star-rating/simulator",
    component: <Simulator />,
    allowedRoles: [ROLES.User, ROLES.Corporate, ROLES.Admin],
  },
  {
    name: "Survey",
    key: "resident-satisfaction/survey",
    route: "/resident-satisfaction/survey",
    component: <SatisfactionSurvey />,
    allowedRoles: [ROLES.User, ROLES.Corporate, ROLES.Admin],
  },
  {
    name: "Templates",
    key: "resident-satisfaction/templates",
    route: "/resident-satisfaction/templates",
    component: <Templates />,
    allowedRoles: [ROLES.User, ROLES.Corporate, ROLES.Admin],
  },
  {
    name: "Template",
    key: "resident-satisfaction/template",
    route: "/resident-satisfaction/template",
    component: <Template />,
    allowedRoles: [ROLES.User, ROLES.Corporate, ROLES.Admin],
  },
  {
    name: "User",
    key: "users/user",
    route: "/users/user",
    component: <UserDetail />,
    allowedRoles: [ROLES.User, ROLES.Corporate, ROLES.Admin],
  },
  {
    name: "MDS",
    key: "residents/mds",
    route: "/residents/mds",
    component: <MDS />,
    allowedRoles: [ROLES.User, ROLES.Corporate, ROLES.Admin],
  },
  {
    name: "MDS",
    key: "residents/resident",
    route: "/residents/resident",
    component: <ResidentDetail />,
    allowedRoles: [ROLES.User, ROLES.Corporate, ROLES.Admin],
  },
];

export default innerRoutes;
