/* eslint-disable no-nested-ternary */
import { useEffect, useRef, useState } from "react";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import useBackendError from "hooks/useBackendError";

import MDBox from "components/MDBox";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Card, Icon } from "@mui/material";
import MDTypography from "components/MDTypography";
import useModal from "hooks/useModal";
import colors from "assets/theme/base/colors";
import { makeStyles } from "@material-ui/styles";

import MDButton from "components/MDButton";
import StickyStarRating from "./StickyStarRating";
import SimulateSlider from "./SimulateSlider";

const useStyles = makeStyles(() => ({
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },

  scrollingdiv: {
    width: "100%",
    position: "sticky",
    backdropFilter: "blur(5px)",
    display: "flex",
    justifyContent: "center",
    zIndex: 100,
  },
}));
const Simulator = () => {
  const axios = useAxiosPrivate();
  const [isLoading, setLoading] = useState(true);
  const simData = useRef();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const { setError } = useBackendError();
  const { openModal } = useModal();
  let lastCallTimestamp = 0;
  const [forceRender, setForceRender] = useState(false);

  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  const loadData = async (isReset) => {
    if (!isReset) {
      setLoading(true);
    }

    simData.current = {};

    try {
      const response = await axios.post(`/fivestar/simulate?id=${id}`, simData.current, {
        headers: { "Content-Type": "application/json" },
      });
      simData.current = response.data;
    } catch (err) {
      setError("Failed to fetch from the backend. Please try again");
    } finally {
      if (!isReset) {
        setLoading(false);
      } else {
        setForceRender((prevState) => !prevState);
      }
    }
  };

  const updateData = async () => {
    try {
      const response = await axios.post(`/fivestar/simulate?id=${id}`, simData.current, {
        headers: { "Content-Type": "application/json" },
      });
      simData.current = response.data;
    } catch (err) {
      setError("Failed to fetch from the backend. Please try again");
    }
  };

  const setSimData = async (type, index, value, isLast) => {
    if (type === "staffing") {
      simData.current.staffing[index].updatedValue = value;
    } else {
      simData.current.qualityMeasures[index].updatedValue = value;
    }
    const currentTimestamp = Date.now();
    if (isLast || currentTimestamp - lastCallTimestamp >= 800) {
      const prevRating = simData.current.starRating;
      await updateData();
      lastCallTimestamp = currentTimestamp;
      if (
        prevRating.overallRating !== simData.current.starRating.overallRating ||
        prevRating.healthInspectionRating !== simData.current.starRating.healthInspectionRating ||
        prevRating.staffRating !== simData.current.starRating.staffRating ||
        prevRating.qualityMeasuresRating !== simData.current.starRating.qualityMeasuresRating
      ) {
        setForceRender((prevState) => !prevState);
      }
    }
  };

  const classes = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    loadData(false);
  }, []);

  const handleBack = () => {
    navigate(-1);
  };
  const handleReset = () => {
    loadData(true);
  };
  const handlePrint = () => {};

  return (
    <Card id="compliance-overview">
      <MDBox pt={2} px={2} display="flex" justifyContent="left" alignItems="center">
        <MDBox
          color="white"
          bgColor="info"
          variant="gradient"
          borderRadius="lg"
          shadow="lg"
          display="flex"
          justifyContent="center"
          alignItems="center"
          py={1}
          px={2}
          mt={-4}
          mb={3}
        >
          <MDTypography variant="h6" fontWeight="medium" color="white">
            5 Star Rating Simulator
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox className={classes.container}>
        <div className={classes.scrollingdiv} style={{ top: isMobile ? 114 : 68 }}>
          <MDBox bgColor="white" pl={2} pr={1} pb={1} style={{ borderRadius: 20 }}>
            <StickyStarRating isLoading={isLoading} scoreData={simData.current?.starRating} />
          </MDBox>
        </div>

        <Card
          style={{
            backgroundColor: colors.grey[200],
            marginTop: 30,
            paddingBottom: 16,
            marginLeft: 30,
            marginRight: 30,
          }}
        >
          <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
            {!isLoading && (
              <MDBox
                color="white"
                bgColor="dark"
                variant="gradient"
                borderRadius="xl"
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="1.5rem"
                pl={2}
                pr={2}
                mt={-3}
              >
                <MDTypography variant="body2" color="white" fontSize="xs">
                  Staffing
                </MDTypography>
              </MDBox>
            )}
          </MDBox>
          <MDBox pl={3} pt={1}>
            {simData.current?.staffing?.map((staffing) => (
              <SimulateSlider
                key={`staff-slide-${staffing.index}`}
                item={staffing}
                setSimData={setSimData}
              />
            ))}
          </MDBox>
        </Card>
        <Card
          style={{
            backgroundColor: colors.grey[200],
            marginTop: 30,
            paddingBottom: 16,
            marginLeft: 30,
            marginRight: 30,
          }}
        >
          <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
            {!isLoading && (
              <MDBox
                color="white"
                bgColor="dark"
                variant="gradient"
                borderRadius="xl"
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="1.5rem"
                pl={2}
                pr={2}
                mt={-3}
              >
                <MDTypography variant="body2" color="white" fontSize="xs">
                  Quality Measures
                </MDTypography>
              </MDBox>
            )}
          </MDBox>
          <MDBox pl={3} pt={1}>
            {simData.current?.qualityMeasures?.map((quality) => (
              <SimulateSlider
                key={`quality-slide-${quality.index}`}
                item={quality}
                setSimData={setSimData}
              />
            ))}
          </MDBox>
        </Card>
      </MDBox>
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        pb={3}
        pt={2}
      >
        <MDButton variant="gradient" color="light" style={{ margin: 5 }} onClick={handleBack}>
          <Icon sx={{ fontWeight: "bold" }}>arrow_back</Icon> &nbsp;back
        </MDButton>

        <MDButton variant="gradient" color="info" onClick={handleReset} size="medium">
          reset to current &nbsp;
          <Icon sx={{ fontWeight: "bold" }}>rotate_left</Icon>
        </MDButton>
        <MDButton
          variant="gradient"
          color="primary"
          style={{ margin: 5 }}
          loadingText="submitting..."
          onClick={handlePrint}
        >
          <Icon sx={{ fontWeight: "bold" }}>print</Icon>
          &nbsp;print
        </MDButton>
      </MDBox>
    </Card>
  );
};
export default Simulator;
