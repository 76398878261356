/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/prop-types */
import { createContext, useState } from "react";

const ComplianceContext = createContext({});

export const ComplianceProvider = ({ children }) => {
  const [surveys, setSurveys] = useState();

  return (
    <ComplianceContext.Provider value={{ surveys, setSurveys }}>
      {children}
    </ComplianceContext.Provider>
  );
};

export default ComplianceContext;
